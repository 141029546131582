import React, {useState, useEffect, useContext} from 'react';

import {MdUnfoldMore, MdAdd, MdClose} from 'react-icons/md';
import PropTypes from 'prop-types';
import {AuthContext} from '../context/AuthContext';
import {createDoc, RerenderContext} from '../cud';

/**
  * Params:
  * selectedC: State with selected Stuff in Array
  * name: String to show above Input Field
  * optional: Whether to show 'optional' String above Input Field
  * @return {object} jsx
  */
export default function MultiSelect({
  selectedArrC,
  dataC,
  filter,
  search,
  collection,
  dataStructure,
  name = 'Auswählen',
  optional = false,
}) {
  // -------------------SECTION Variables-------------------------------

  // Data Context
  const [data, setData] = dataC;
  if (true === false) setData('');

  const setRerender = useContext(RerenderContext)[1];
  // Auth Context
  const {teamC, isOfflineC, toastsC} = useContext(AuthContext);
  const currentTeam = teamC[0];
  const isOffline = isOfflineC[0];
  const addToast = toastsC[1];


  // Selected Array that is returned
  const [selectedArr, setSelectedArr] = selectedArrC;

  // Utility States
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  // ------------------------------!SECTION------------------------------

  // ---------------------------SECTION Utility Functions----------------

  // Initializes Search Results when data array changes
  useEffect(
      function() {
        setSearchResults(getSearchResults(null));
      },
      [data],
  );

  /** Handles Search Input and sets Search Results
    * @param {object} event
    */
  function handleSearchInput(event) {
    const value = event.target.value;
    setInputValue(value);
    setSearchResults(getSearchResults(value));
  }

  /**
    * Searches Array Of Docs
    * @param {string} input Search Input
    * @return {array} Array of Search Results
    */
  function getSearchResults(input) {
    if (input) {
      const lowerInput = input.toLowerCase();
      setInputValue(input);

      // Filters Tags Array
      let searchResults = search(filter(data), lowerInput);

      // Looks for already selected Tags and removes them
      // from the search Results
      for (const el of selectedArr) {
        searchResults = searchResults.filter((result) => result.id != el);
      }
      return searchResults;
    } else {
      setInputValue('');

      // Filters Tags Array
      let searchResults = filter(data);

      // Looks for already selected Tags and removes them
      // from the search Results
      for (const el of selectedArr) {
        searchResults = searchResults.filter((result) => result.id != el);
      }

      return searchResults;
    }
  }

  /**
    * Gets triggered when an element is selected through ENTER or Click
    * @param {object} id Tag Id from Search Results
    */
  function handleSelect(id) {
    if (!selectedArr.includes(id)) {
      setSelectedArr([...selectedArr, id]);
    }

    // Reset Input Value
    setInputValue('');
  }

  // ---------------------------!SECTION----------------------

  return (
    <div className="flex flex-col md:grid md:grid-cols-3 relative">
      <div className='relative'>
        <div className="flex justify-between">
          <label
            htmlFor="address"
            className="block text-sm font-medium text-gray-700">
            {name}
          </label>
          {inputValue.length > 0 &&
           searchResults.length === 0 &&
           typeof selectedArr != 'object' &&
           showDropdown === true ? (
             <span className="text-sm text-gray-500">
               &quot;Enter&quot; drücken für neue Adresse.
             </span>
           ) : optional ? (
             <span className="text-sm text-gray-500">Optional</span>
           ) : (
             ''
           )}
        </div>
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            onKeyDown={(e) => {
              // Prevent default
              e.keyCode === 13 && e.preventDefault();

              // Save new ELement on ENTER (DISABLED because of color selection)
              //   e.keyCode === 13 &&
              //     inputValue.length > 0 &&
              //     searchResults.length === 0 &&
              //     TODO Renew AddDoc
              //     addDoc(currentUser, 'teams', 'tags', tags, {
              //       text: inputValue,
              //       color: 'green',
              //     }).then((arr) => {
              //       setTags(arr);
              //       handleSelect(arr[arr.length - 1].id);
              //     });

              // Handle Select on ENTER: Selects first Search Result
              e.keyCode === 13 &&
                 searchResults.length > 0 &&
                 handleSelect(searchResults[0].id);
            }}
            // Show Dropdown on Focus
            onFocus={() => {
              setShowDropdown(true);
            }}
            // onBlur={() => setTimeout(() => setShowDropdown(false), 100)}
            value={inputValue}
            onInput={(e) => handleSearchInput(e)}
            autoComplete="off"
            type="text"
            name={collection}
            className="focus:ring-blue-500 focus:border-blue-500
             block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
          />
          {/* Shows either the 'Toggle Dropdown' button,
           or the Color Select and Add Button,
           depending on Input Value and Search Results */}
          {inputValue.length > 0 &&
           searchResults.length === 0 &&
           showDropdown === true ? (
             <div
               className="absolute inset-y-0 right-0
             space-x-1.5 flex py-1.5 pr-2">
               <div>
                 <select
                   tabIndex={-1}
                   value={selectedColor}
                   onChange={(e) => setSelectedColor(e.target.value)}
                   className="focus:ring-indigo-500 focus:border-indigo-500
                 h-full py-0 pl-2 pr-7 border-transparent bg-transparent
                 text-gray-500 sm:text-sm rounded-md">
                   <option value="gray">Grau</option>
                   <option value="red">Rot</option>
                   <option value="yellow">Gelb</option>
                   <option value="green">Grün</option>
                   <option value="blue">Blau</option>
                   <option value="indigo">Indigo</option>
                   <option value="purple">Lila</option>
                   <option value="pink">Pink</option>
                 </select>
               </div>
               <button
                 name='add_element'
                 type="button"
                 onClick={() => {
                   inputValue.length > 0 &&
                     searchResults.length === 0 &&
                    createDoc(currentTeam.id, collection,
                        dataStructure(inputValue,
                            selectedColor), setRerender, isOffline)
                        .catch((e) => {
                          addToast('error',
                              'Fehler: Tag konnte nicht gespeichert werden');
                          return console.log(e);
                        });
                 }}>
                 <MdAdd
                   className={`h-5 w-5 bg-${selectedColor}-100 
                   text-${selectedColor}-400 rounded-md`}
                   aria-hidden="true"
                 />
               </button>
             </div>
           ) : (
             <button
               // Skip with TAB
               name='toggle_dropdown'
               tabIndex={-1}
               type="button"
               onClick={() => setShowDropdown(!showDropdown)}
               className="absolute inset-y-0 right-0 pr-3 flex
           items-center">
               <MdUnfoldMore
                 className="h-5 w-5 text-gray-400"
                 aria-hidden="true"
               />
             </button>
           )}
        </div>

        {/* -----------Dropdown------------- */}
        <div
          className=" mt-2 p-2 max-h-28
           overflow-y-auto overflow-x-hidden
           rounded-md shadow-lg bg-white ring-1 ring-black
           ring-opacity-5 focus:outline-none
           absolute w-full top-16 z-10"
          style={{display: showDropdown ? 'block' : 'none'}}>
          {/* Display either all results or Search Results,
           depending on the input Value */}
          {searchResults && searchResults.map((el, index) => (
            <button
              type="button"
              className="w-full text-left hover:bg-gray-100 rounded-md p-1"
              onMouseDown={() => handleSelect(el.id)}
              key={index}>
              <div className="px-4 py-2 whitespace-nowrap">
                <span
                  className={`px-2 inline-flex
                       text-xs leading-5 font-semibold
                       rounded-md bg-${el.color || 'gray'}-100 
                       text-${el.color || 'gray'}-800`}>
                  {el.text || el.name}
                </span>
              </div>
            </button>
          ))}
        </div>
      </div>
      {/* ------------------Display------------------------- */}
      <div id='display_selected'
        className="col-span-2 flex justify-start items-center space-x-4 p-4">
        {selectedArr && selectedArr.map((el, index) => {
          const element = data.find((tag) => tag.id === el);
          if (element) {
            return (
              <div
                key={el}
                className={`px-2 flex items-center justify-between h-6 min-w-16
               text-xs leading-5 font-semibold rounded-full bg-${
              element.color || 'gray'
              }-100 text-${element.color || 'gray'}-800`}>
                <p>{element.text || element.name}</p>
                <button
                  type="button"
                  onClick={() => {
                  // Removes Tag from selected Tags when clicked
                    const buffer = selectedArr;
                    buffer.splice(index, 1);
                    setSelectedArr([...buffer]);
                  }}>
                  <MdClose></MdClose>
                </button>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

// Prop Type Validation
MultiSelect.propTypes = {
  selectedArrC: PropTypes.array,
  dataC: PropTypes.array,
  collection: PropTypes.string,
  filter: PropTypes.func,
  search: PropTypes.func,
  dataStructure: PropTypes.func,
  name: PropTypes.string,
  optional: PropTypes.bool,
};


import React, {useContext} from 'react';
import {AuthContext} from './context/AuthContext';
import {Route as NormalRoute, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import TeamManagement from './TeamManagement';

/**
   * Private Route Component
   * - Redirects to component if user is authenticated and has an active team
   * - Redirects to Team Management if no active Team is set
   * - Redirects to Login if user is not authenticated
   * @param {object} param0
   * @return {object} JSX
   */
export default function AuthRoute({component: Component, ...rest}) {
  const {userC, teamC} = useContext(AuthContext);
  const currentUser = userC[0];
  const currentTeam = teamC[0];
  return (
    <NormalRoute
      {...rest}
      render={(props) => currentUser && currentUser.uid ?
          currentTeam && currentTeam.id ?
           <Component {...props} /> :
            <div className='fill_container p-32 flex-center'>
              <TeamManagement modeState={'noTeam'} {...props} /></div> :
          <Redirect to={{pathname: '/'}} />}
    />
  );
}

AuthRoute.propTypes = {
  component: PropTypes.any,
};


/** Custom Field Types Dropdown */

import React, { useState, useEffect } from 'react';
import { MdUnfoldMore, MdAdd } from 'react-icons/md';
import PropTypes from 'prop-types';
import { getSearchResults } from '../utilities';

/**
 *
 * @return {object} jsx
 */
const customFieldTypesDD = React.forwardRef(
  ({ selectedCustomFieldTypeC, customFieldValueRef }, ref) => {
    // -------------------Variables-------------------------------
    const customFieldTypes = ['Telefon', 'Email', 'Ansprechpartner'];

    const [showDropdown, setShowDropdown] = useState(false);

    const [searchResults, setSearchResults] = useState([]);
    const [selectedCustomFieldType, setSelectedCustomFieldType] =
      selectedCustomFieldTypeC;
    const [inputValue, setInputValue] = useState('');
    // -------------------Variables End-------------------------------

    useEffect(
      function () {
        // Updates Input Value when Type is selected
        selectedCustomFieldType.length > 0 &&
          setInputValue(selectedCustomFieldType);

        // empties Input value when Group is deleted
        selectedCustomFieldType.length === 0 && setInputValue('');
      },
      [selectedCustomFieldType],
    );

    // ---------------------------Utility Functions----------------------

    /** Handle Search Input
   * @param {object} value
   */
    function handleSearchInput(value) {
      setInputValue(value);
      setShowDropdown(true);

      if (value && value.length > 0) {
        setSearchResults(getSearchResults(value, customFieldTypes));
      } else {
        setSearchResults(customFieldTypes);
      };
    }

    /**
   *
   * @param {object} el
   */
    function handleSelect(el) {
      setSelectedCustomFieldType(el);
      setShowDropdown(false);
      customFieldValueRef.current.focus();
    }

    // ---------------------------Utility Functions End----------------------

    return (
      <div className='relative'>
        <div className="flex justify-between">
          <label
            className="block text-sm font-medium text-gray-700">
            Typ
          </label>
          {inputValue.length > 0 &&
            searchResults.length === 0 &&
            selectedCustomFieldType.length === 0 ? (
            <span className="text-sm text-gray-500">
              {/* &quot;Enter&quot; drücken für neuen Typ. */}
            </span>
          ) : (
            <span className="text-sm text-gray-500"></span>
          )}
        </div>

        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            name='custom_field_type'
            ref={ref}
            onKeyDown={(e) => {
              e.keyCode === 13 && e.preventDefault();

              // Save new Custom Field Type
              // e.keyCode === 13 &&
              // inputValue.length > 0 &&
              // searchResults.length === 0 &&
              // selectedCustomFieldType.length === 0 &&
              // TODO Save new Custom Field Type

              e.keyCode === 13 &&
                searchResults.length > 0 &&
                handleSelect(searchResults[0]);

              e.keyCode === 13 &&
                searchResults.length === 0 &&
                handleSelect(e.target.value);
            }}
            // Toggle Dropdown depending on Focus
            onFocus={() => {
              setShowDropdown(true);
              setSelectedCustomFieldType('');
              handleSearchInput('');
            }}
            onBlur={() => setShowDropdown(false)}
            onInput={(e) => handleSearchInput(e.target.value)}
            autoComplete="off"
            type="text"
            className="focus:ring-blue-500 focus:border-blue-500
          block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
            value={inputValue}
          />
          {inputValue.length > 0 &&
            searchResults.length === 0 &&
            selectedCustomFieldType.length === 0 ? (
            <button
              tabIndex="-1"
              type="button"
              onClick={() => handleSelect(ref.current.value)}
              className="absolute inset-y-0 right-0 pr-3 flex
        items-center">
              <MdAdd
                className="h-5 w-5 bg-blue-100 text-blue-400 rounded-md"
                aria-hidden="true"
              />
            </button>

          ) : (
            <button
              tabIndex="-1"
              type="button"
              onClick={() => setShowDropdown(!showDropdown)}
              className="absolute inset-y-0 right-0 pr-3 flex
        items-center">
              <MdUnfoldMore
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          )}
        </div>
        <div
          className=" mt-2 p-2 max-h-28 overflow-auto
        rounded-md shadow-lg bg-white ring-1 ring-black
        ring-opacity-5 focus:outline-none
        absolute w-full top-16 z-10"
          style={{
            display: showDropdown && searchResults.length ?
              'block' : 'none'
          }}>
          {searchResults.map((el) => (
            <button
              type="button"
              className="w-full text-left hover:bg-gray-100 rounded-md p-1"
              onMouseDown={() => handleSelect(el)}
              key={el}>
              {el}
            </button>
          ))}
        </div>
      </div>
    );
  },
);

customFieldTypesDD.propTypes = {
  selectedCustomFieldTypeC: PropTypes.array,
  ref: PropTypes.any,
  customFieldValueRef: PropTypes.any,
};

customFieldTypesDD.displayName = 'customFieldTypesDD';

export default customFieldTypesDD;

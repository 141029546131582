/** Addresses Dropdown
 * Returns selected Address as Object
 */

import React, {useContext, useState, useEffect} from 'react';
// import Localbase from 'localbase';
// const lb = new Localbase('E3BYTC3e42z8XLiLTK3K');

import {MdUnfoldMore} from 'react-icons/md';
import PropTypes from 'prop-types';
import {AuthContext} from '../context/AuthContext';
import {RerenderContext} from '../cud';
import Localbase from 'localbase';
import {getSearchResults} from '../utilities';

/**
 *
 * @return {object} jsx
 */
export default function AddressesDD({
  selectedAddressC,
  section,
  contactId,
  name = 'Adresse',
  optional = false,
}) {
  // -------------------Variables-------------------------------
  // Contacts Context
  const rerender = useContext(RerenderContext);

  const [addresses, setAddresses] = useState([]);

  // Auth Context
  const {teamC} = useContext(AuthContext);
  const currentTeam = teamC[0];

  // Selected Item State that is returned
  const [selectedAddress, setSelectedAddress] = selectedAddressC;

  // Utility States
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [inputValue, setInputValue] = useState('');
  // -------------------Variables End-------------------------------

  //

  // Update Contact Addresses whenever Contact ID changes
  useEffect(
      async function() {
        if (section === 'contacts') {
          const lb = new Localbase(currentTeam.id);
          // lb.config.debug = false;
          const contact = await lb.collection('contacts')
              .doc({id: contactId}).get();
          if (contact) setAddresses(contact.addresses);
          else setAddresses([]);
        }
      },
      [contactId, rerender],
  );

  // Update Team Addresses when team changes
  useEffect(
      async function() {
        if (section === 'team') {
          const lb = new Localbase(currentTeam.id);
          // lb.config.debug = false;
          const teamAddresses = await lb.collection('addresses').get();
          if (teamAddresses) {
            const elements = teamAddresses.filter((doc) => !doc.deleted);
            setAddresses(elements);
            // Auto Select first Team Address
            if (elements.length === 1) {
              handleSelect(elements[0]);
            }
          } else setAddresses([]);
        }
      },
      [currentTeam, rerender],
  );

  // Updates Input Value when Item is selected or typed
  useEffect(
      function() {
        const sa = selectedAddress;
        if (sa.name || sa.address || sa.postal_code || sa.city) {
          setInputValue(
              `${sa.salutation} ${sa.name}, ${sa.address}, ` +
              `${sa.postal_code} ${sa.city}`,
          );
        } else {
          setInputValue('');
        }
      },
      [selectedAddress, rerender],
  );

  // ---------------------------SECTION Utility Functions--------------------

  /** Handles Search Input and generates Search Results
   * @param {object} value
   */
  async function handleSearchInput(value) {
    // setSelectedAddress(value);
    setInputValue(value);
    setShowDropdown(true);

    // const lb = new Localbase(currentTeam.id);
    // // lb.config.debug = false;

    // const database = await lb.collection(type).get();
    if (value && value.length > 0) {
      setSearchResults(getSearchResults(value, addresses));
    } else {
      setSearchResults(addresses);
      setInputValue('');
    }
  }

  /**
   * Gets triggered when an element is selected through ENTER or Click
   * @param {object} el Element from Search Results
   */
  function handleSelect(el) {
    setSelectedAddress(el);
    setShowDropdown(false);
  }

  // ---------------------------!SECTION----------------------

  return (
    <div
      className='relative'>
      <div className="flex justify-between">
        <label
          htmlFor="address"
          className="block text-sm font-medium text-gray-700">
          {name}
        </label>
        {/* {inputValue.length > 0 &&
        searchResults.length === 0 &&
        typeof selectedAddress != 'object' &&
        showDropdown === true ? (
          // <span className="text-sm text-gray-500">
          //   &quot;Enter&quot; drücken für neue Adresse.
          // </span>
          ''
        ) : optional ? (
          <span className="text-sm text-gray-500">Optional</span>
        ) : (
          ''
        )} */}
      </div>

      <div className="mt-1 relative rounded-md shadow-sm">
        {/* Workaround to disable Autocomplete */}
        <input type='hidden' value='something'/>
        <input
          onKeyDown={(e) => {
            // Prevent default
            e.keyCode === 13 && e.preventDefault();

            // Save new Address
            // e.keyCode === 13 &&
            //   inputValue.length > 0 &&
            //   searchResults.length === 0 &&

            //   TODO REnew AddDoc
            //  addDoc(currentUser, 'teams', 'custom_positions', contacts, {
            //     name: selectedItem,
            //   }).then((arr) => {
            //     setCustomPositions(arr);
            //     handleSelect(arr[arr.length - 1]);
            //   });

            // Handle Select on ENTER
            e.keyCode === 13 &&
              searchResults.length > 0 &&
              handleSelect(searchResults[0]);
          }}
          // Show and Hide Dropdown depending on Focus
          onFocus={() => {
            handleSearchInput('');
            setSelectedAddress('');
            setShowDropdown(true);
          }}
          onBlur={() => setShowDropdown(false)}
          // Handle Search Input
          onInput={(e) => handleSearchInput(e.target.value)}
          // Workaround for Chrome Autocomplete Problem test
          autoComplete="whatever"
          type="text"
          name="address"
          className="focus:ring-blue-500 focus:border-blue-500
           block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
          value={inputValue}
        />
        {/* --------------------Dynamic Button---------------- */}
        {/* <button
          // Skip with TAB
          tabIndex={-1}
          type="button"
          // Right Button in Input:
          // Trigger Show Dropdown or
          // add new Address
          // onClick={() => {
          //   inputValue.length > 0 &&
          //   searchResults.length === 0 &&
          //   typeof selectedItem != 'object' &&
          // showDropdown === true
          //     ? console.log('neues Kategorie saved.')
          //     : setShowDropdown(!showDropdown);
          // }}

          className="absolute inset-y-0 right-0 pr-3 flex
         items-center">

          {inputValue.length > 0 &&
          searchResults.length === 0 &&
          typeof selectedAddress != 'object' &&
          showDropdown === true ? (
            <MdAdd
              className="h-5 w-5 bg-blue-100 text-blue-400 rounded-md"
              aria-hidden="true"
            />
          ) : (
            <MdUnfoldMore
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          )}
        </button> */}
        <button
          tabIndex={-1}
          type="button"
          className="absolute inset-y-0 right-0 pr-3 flex
         items-center"
          onClick={() => setShowDropdown(!showDropdown)}>
          <MdUnfoldMore className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      </div>
      <div
        className=" mt-2 p-2 max-h-28 overflow-y-auto overflow-x-hidden
         rounded-md shadow-lg bg-white ring-1 ring-black
         ring-opacity-5 focus:outline-none
         absolute w-full top-16 z-10"
        style={{display: showDropdown ? 'block' : 'none'}}>
        {/* Display Addresses or Search Results */}
        {searchResults.map((el, index) => (
          <button
            type="button"
            className="w-full text-left hover:bg-gray-100 rounded-md p-1"
            onMouseDown={() => handleSelect(el)}
            key={index}>
            {`${el.salutation} ${el.name}, ${el.address},
               ${el.postal_code} ${el.city}`}
          </button>
        ))}
      </div>
    </div>
  );
}

AddressesDD.propTypes = {
  selectedAddressC: PropTypes.array,
  contactId: PropTypes.string,
  name: PropTypes.string,
  optional: PropTypes.bool,
  section: PropTypes.string,
};

import React, {useContext} from 'react';
import {AuthContext} from './context/AuthContext';
import {HiCheckCircle,
  HiExclamation,
  HiExclamationCircle} from 'react-icons/hi';

/**
 *
 * @param {string} type Toast type
 * @return {object} jsx
 */
function getIcon(type) {
  if (type === 'success') {
    return (<HiCheckCircle/>);
  } else if (type === 'warning') {
    return (<HiExclamation/>);
  } else if (type === 'error') {
    return (<HiExclamationCircle/>);
  }
}

/**
 *
 * @param {string} type Toast Type
 * @return {string}
 */
function getColor(type) {
  if (type === 'success') {
    return 'bg-green-100 text-green-500';
  } else if (type === 'warning') {
    return 'bg-yellow-100 text-yellow-500';
  } else if (type === 'error') {
    return 'bg-red-100 text-red-500';
  }
}


/**
 *
 * @return {object} jsx
 */
export default function Toasts() {
  const {toastsC} = useContext(AuthContext);
  const toasts = toastsC[0];

  return (
    <div
      className="absolute top-0 right-0 z-50
        w-80 m-4">
      {toasts.map((toast, index) => (

        <div
          key={index} className={`${getColor(toast.type)} z-50
         rounded-md shadow-lg w-auto`}>
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <div className="flex-shrink-0">
                  {getIcon(toast.type)}

                </div>

                <p className="ml-3 font-medium">
                  {toast.text}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>);
};


import React from 'react';
// import {AuthContext} from '../context/AuthContext';
import {useLocation} from 'react-router-dom';
import {refreshDB} from '../utilities';

/**
 *
 * @return {object} jsx
 */
export default function AdvancedSettings() {
  const {pathname} = useLocation();

  return (
    <div
      className="space-y-6"
      style={{display: pathname === '/settings/advanced' ? 'block' : 'none'}}>
      <div
        className="shadow rounded-md
          sm:overflow-hidden hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6">
              <h3
                className="text-lg leading-6
                font-medium text-gray-900">
                Sicherung
              </h3>
              <p
                className="mt-1 text-sm
                text-gray-500">
                Hier können Sie eine lokale Kopie ihrer Unternehmensdatenbank
                erstellen und herunterladen. Diese Datei ist unverschlüsselt.
              </p>
            </div>
            <div className="col-span-6">
              <button
                className="inline-flex justify-center
                    py-2 px-4 border border-transparent
                    shadow-sm text-sm font-medium rounded-md
                    text-white bg-blue-600 hover:bg-blue-700
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-blue-500">
                Datenbank sichern
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6">
              <h3
                className="text-lg leading-6
                font-medium text-gray-900">
                Online-Aktualisierung
              </h3>
              <p
                className="mt-1 text-sm
                text-gray-500">
                Hier können Sie ihre lokale Datenbank manuell aktualisieren.
              </p>
            </div>
            <div className="col-span-6">
              <button
                onClick={() => {
                  refreshDB();
                }}
                className="inline-flex justify-center
                    py-2 px-4 border border-transparent
                    shadow-sm text-sm font-medium rounded-md
                    text-white bg-blue-600 hover:bg-blue-700
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-blue-500">
                Datenbank aktualisieren
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="shadow rounded-md
          sm:overflow-hidden hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6">
              <h3
                className="text-lg leading-6
                font-medium text-gray-900">
                Wiederherstellen
              </h3>
              <p
                className="mt-1 text-sm
                text-gray-500">
                Hier können Sie ihre Sicherungsdatei hochladen, und damit ihre
                Daten wiederherstellen. Alle momentanen Daten werden
                überschrieben.
              </p>
            </div>
            <div className="sm:col-span-6">
              <label
                htmlFor="cover-photo"
                className="block text-sm font-medium text-gray-700">
                Hochladen
              </label>
              <div
                className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2
              border-gray-300 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true">
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4
                      4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656
                      0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4
                      4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer
                       bg-white rounded-lg rounded-md
                      font-medium text-blue-600 hover:text-blue-500
                      focus-within:outline-none focus-within:ring-2
                      focus-within:ring-offset-2 focus-within:ring-blue-500">
                      <span>Datei auswählen</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                      />
                    </label>
                    <p className="pl-1">oder hier hinein ziehen</p>
                  </div>
                  <p className="text-xs text-gray-500">PNG, JPG bis 10MB</p>
                </div>
              </div>
            </div>
            <div className="col-span-6 flex justify-end">
              <button
                className="inline-flex justify-center
                    py-2 px-4 border border-transparent
                    shadow-sm text-sm font-medium rounded-md
                    text-white bg-blue-600 hover:bg-blue-700
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-blue-500">
                Wiederherstellen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/** Login Component */
import React, {useEffect, useState, useContext} from 'react';
import {AuthContext, login} from '../context/AuthContext';
import PropTypes from 'prop-types';

import logoBlue from './../assets/img/logo_blue.png';
import {Link} from 'react-router-dom';

/**
 *
 * @return {object} jsx
 */
export default function Login({history}) {
  const data = {
    email: '',
    password: '',
  };

  const [loginData, setLoginData] = useState(data);
  const {userC} = useContext(AuthContext);
  const currentUser = userC[0];
  const [loginError, setLoginError] = useState(false);

  useEffect(
      function() {
        if (currentUser) {
          setTimeout(() => history.push('/dashboard/'), 1000);
        }
      },
      [currentUser],
  );

  /** Change Listener for Inputs
   * @param {object} event
   */
  function handleChange(event) {
    const {value, name} = event.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  }

  /** Submit Listener
   * @param {object} e
   *
   */
  function onFormSubmit(e) {
    e.preventDefault();
    try {
      login(loginData, setLoginError);
      // return <Redirect to="/" />;
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div
      className="min-h-screen w-screen
       bg-gray-50 flex flex-col justify-center
    py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-20 w-auto" src={logoBlue} />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Login zu Warest
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Oder{' '}
          <Link to='/register'
            className="font-medium text-blue-600 hover:text-blue-500">
            starten Sie eine 14 tägige Testphase
          </Link>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={(e) => onFormSubmit(e)}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                Email Adresse
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={(e) => handleChange(e)}
                  value={loginData.email}
                  required
                  className="appearance-none block w-full px-3 py-2 border
                  border-gray-300 rounded-md shadow-sm placeholder-gray-400
                  focus:outline-none focus:ring-blue-500
                  focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700">
                Passwort
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  onChange={(e) => handleChange(e)}
                  value={loginData.password}
                  className="appearance-none block w-full px-3 py-2
                  border
                  border-gray-300 rounded-md shadow-sm
                  placeholder-gray-400
                  focus:outline-none focus:ring-blue-500
                  focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-blue-600
                  focus:ring-blue-500
                  border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900">
                  Eingeloggt bleiben
                </label>
              </div>

              <div className="text-sm">
                <a
                  href="#"
                  className="font-medium text-blue-600
                  hover:text-blue-500">
                  Passwort vergessen?
                </a>
              </div>
            </div>

            <div className={`w-full justify-center ${loginError ?
               'flex' : 'hidden'}`}>
              <p className="text-red-500">
                Fehler bei der Anmeldung
              </p>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border
                border-transparent rounded-md shadow-sm text-sm
                font-medium
                text-white bg-blue-600 hover:bg-blue-700
                focus:outline-none
                focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  history: PropTypes.any,
  push: PropTypes.any,
};

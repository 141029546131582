// import Localbase from 'localbase';
import React, {useContext, useRef, useEffect, useState} from 'react';
import {MdMail, MdDelete} from 'react-icons/md';
// import {AuthContext} from '../context/AuthContext';
import {useLocation} from 'react-router-dom';
import {AuthContext, lb} from '../context/AuthContext';
import {fs} from '../firebase';
import {makeId} from '../utilities';


/**
 *
 * @return {object} jsx
 */
export default function TeamSettings() {
  const {pathname} = useLocation();

  const [show, setShow] = useState(false);

  const {teamC,
    userC} = useContext(AuthContext);
  const currentUser = userC[0];
  // const membershipRoles = membershipRolesC[0];
  const [memberships, setMemberships] = useState([]);
  const currentTeam = teamC[0];

  const [teamInvitations, setTeamInvitations] = useState([]);

  const emailRef = useRef(null);
  const teamRoleRef = useRef(null);

  useEffect(async function() {
    if (currentTeam) {
      const teamInvitations = await lb.collection('team_invitations').get();
      setTeamInvitations(teamInvitations);

      const memberships = await lb.collection('memberships').get();
      const activeMembership = await lb.collection('memberships')
          .doc({id: currentUser.id}).get();
      if (activeMembership && activeMembership.team_role === 'Administrator') {
        setShow(true);
      }

      setMemberships(memberships);
    }
  }, []);

  /**
   *
   * @param {string} email Email
   * @param {string} role Team Role
   * @return {string}
   */
  function createInvitation(email, role) {
    const pin = makeId(12);


    // Check for double Invitations
    if (teamInvitations.findIndex((el) => el.email === email) != -1) {
      return alert('Für diese Email existiert bereits eine Einladung');
    }

    // Create Invitation in Database
    fs.collection('teams').doc(currentTeam.id).collection('invitations')
        .add({
          email: email,
          password: pin,
          role: role,
        // }).then(function (docRef) {
        //   team_ref.collection('invitations').doc(docRef.id).set({
        //     invitation_id: docRef.id
        //   }, { merge: true })
        });
  }

  return (
    <div
      className="space-y-6"
      style={{display: pathname === '/settings/team' ? 'block' : 'none'}}>
      <div
        style={{display: show ? 'block' : 'none'}}
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div>
            <h3
              className="text-lg leading-6
                font-medium text-gray-900">
              Mitglieder
            </h3>
            <p
              className="mt-1 text-sm
                text-gray-500">
              Hier können Sie Mitglieder ihres Unternehmens verwalten.
            </p>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <div
              className="bg-white rounded-lg shadow overflow-hidden
            rounded-md col-span-6">
              <ul className="divide-y divide-gray-200">
                {memberships && memberships.map((el) => (
                  <li key={el.id}>
                    <a href="#" className="block ">
                      <div
                        className="px-4 py-4 flex items-center
                      sm:px-6 bg-gray-50 hover:bg-gray-100">
                        <div
                          className="min-w-0 flex-1 sm:flex
                        sm:items-center sm:justify-between">
                          <div className="truncate">
                            <div className="flex text-sm">
                              <p
                                className="font-medium
                              text-blue-600 truncate">
                                {el.name}
                              </p>
                              {/* <p
                                className="ml-1 flex-shrink-0
                              font-normal text-gray-500">
                                in {el.department}
                              </p> */}
                            </div>
                            {/* <div className="mt-2 flex">
                              <div
                                className="flex items-center
                              text-sm text-gray-500">
                                <MdDateRange
                                  className="flex-shrink-0 mr-1.5
                                  h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <p>
                                  Closing on{' '}
                                  <time dateTime={el.closeDate}>
                                    {el.closeDateFull}
                                  </time>
                                </p>
                              </div>
                            </div> */}
                          </div>
                          <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                            <div className="flex overflow-hidden -space-x-1">
                            </div>
                          </div>
                        </div>
                        <select
                          onInput={(e) => {
                            // editDoc('teams', currentTeam.id,
                            //  'membership_roles',
                            //     el.id, membershipRoles,
                            //  {role: e.target.value},
                            //     true);
                            // TODO
                          }}
                          // value={memberships && memberships.find((mb) =>
                          //   mb.id === el.id).team_role}
                          name="teamRole"
                          className="focus:ring-indigo-500
                           focus:border-indigo-500 h-full py-0 pl-2 pr-7
                            border-transparent bg-transparent text-gray-500
                             sm:text-sm rounded-md"
                        >
                          <option>Administrator</option>
                          <option>Verwaltung</option>
                          <option>Lager</option>
                        </select>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div>
            <h3
              className="text-lg leading-6
                font-medium text-gray-900">
              Mitglied einladen
            </h3>
            <p
              className="mt-1 text-sm
                text-gray-500">
              Laden Sie hier ein neues Mitglied ein.
            </p>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 md:col-span-3 md:col-start-1">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div
                  className="absolute inset-y-0 left-0 pl-3 flex
                      items-center pointer-events-none">
                  <MdMail
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="email"
                  name="email"
                  ref={emailRef}
                  className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pl-10 sm:text-sm border-gray-300
                        rounded-md"
                />
              </div>
            </div>
            <div className="col-span-6 md:col-span-2 ">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700">
                Berechtigung
              </label>
              <select
                id="location"
                name="location"
                className="mt-1 block w-full pl-3 pr-10 py-2
                text-base border-gray-300 focus:outline-none
                focus:ring-blue-500 focus:border-blue-500
                sm:text-sm rounded-md"
                ref={teamRoleRef}>
                <option>Lager</option>
                <option>Dokumente</option>
                <option>Verwaltung</option>
                <option>Administrator</option>
              </select>
            </div>

            <div className="col-span-1 flex items-end justify-start">
              <button
                onClick={() => createInvitation(emailRef.current.value,
                    teamRoleRef.current.value)}
                className="flex-center px-4 py-2 border
                    border-transparent shadow-sm text-sm font-medium
                    rounded-md text-white bg-blue-600 hover:bg-blue-700
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-blue-500">
                Einladen
              </button>
            </div>
          </div>
          <div style={{display: teamInvitations &&
             teamInvitations.length > 0 ? 'block':'none'}}>
            <p
              className="mt-1 text-sm
            text-gray-500">
              Aktive Einladungen:
            </p>
          </div>
          <ul className="divide-y divide-gray-200">
            {teamInvitations && teamInvitations.map((el) => (
              <li key={el.id}>
                <div
                  className="px-4 py-4 flex items-center rounded-md
                      sm:px-6 bg-gray-50 hover:bg-gray-100">
                  <div
                    className="min-w-0 flex-1 sm:flex
                        sm:items-center sm:justify-between">
                    <div className="truncate">
                      <div className="flex text-sm">
                        <p
                          className="font-medium
                              text-blue-600 truncate">
                          {el.email}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                      <div className="flex overflow-hidden -space-x-1">
                      </div>
                    </div>
                  </div>
                  <p
                    className="font-medium
                              text-gray-600 truncate">
                    {el.role}
                  </p>
                  <div className="flex-center ml-4">
                    <button
                      onClick={() => {
                      // Delete Invitation
                        fs.collection('teams')
                            .doc(currentTeam.id)
                            .collection('invitations')
                            .doc(el.id)
                            .delete();
                      }}>
                      <MdDelete size='1.3rem'
                        className='text-red-500'></MdDelete>
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6">
              <h3
                className="text-lg leading-6
                font-medium text-gray-900">
                Unternehmen verlassen
              </h3>
              <p
                className="mt-1 text-sm
                text-gray-500">
                Hier können Sie ihr aktives Unternehmen verlassen.
              </p>
            </div>
            <div className="col-span-6">
              <button
                disabled
                className="inline-flex justify-center
                    py-2 px-4 border border-transparent
                    shadow-sm text-sm font-medium rounded-md
                    text-white bg-blue-600 hover:bg-blue-700
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-blue-500 disabled:opacity-50">
                Unternehmen verlassen
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{display: show ? 'block' : 'none'}}
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6">
              <h3
                className="text-lg leading-6
                font-medium text-gray-900">
                Unternehmen löschen
              </h3>
              <p
                className="mt-1 text-sm
                text-gray-500">
                Hier können Sie ihr Unternehmen vollständig löschen.
              </p>
            </div>
            <div className="col-span-6">
              <button
                disabled
                className="inline-flex justify-center
                    py-2 px-4 border border-transparent
                    shadow-sm text-sm font-medium rounded-md
                    text-white bg-blue-600 hover:bg-blue-700
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-blue-500 disabled:opacity-50">
                Unternehmen löschen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

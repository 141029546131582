/** THis is
 * Authentication Context
 *  */

import React, {createContext, useState, useEffect} from 'react';
import {auth, fs} from '../firebase';
import PropTypes from 'prop-types';
import Localbase from 'localbase';
import {makeId} from '../utilities';

export let lb = new Localbase(localStorage.getItem('teamId'));
export const mdb = new Localbase('warest');

export const AuthContext = createContext();
/**
 * @param {obj} props -
 *
 * @return {obj} a context provider for user data

 *  */
export function AuthProvider(props) {
  const [currentUser, setCurrentUser] =
   useState(localStorage.getItem('userId') ? {
     uid: localStorage.getItem('userId'),
   }: null);

  // TODO Remove
  const [currentTeam, setCurrentTeam] =
   useState(localStorage.getItem('teamId') ? {
     id: localStorage.getItem('teamId'),
   }: null);

  const [isOffline, setIsOffline] = useState(false);

  const [toasts, setToasts] = useState([]);
  const [nkProblems, setNkProblems] = useState([]);
  const [isSyncing, setIsSyncing] = useState(true);

  /**
       *
       * @param {string} type
       * @param {string} text
       * @param {number} duration in MS
       */
  function addToast(type, text, duration = 3000) {
    const dur = type === 'error' ? 10000 : duration;
    const id = makeId(12);
    setToasts([
      ...toasts,
      {
        type,
        text,
        dur,
        id,
      },
    ]);

    setTimeout(() => {
      removeToast(id);
    }, dur);
  }

  /**
         *
         * @param {string} id Toast Id
         */
  function removeToast(id) {
    const toastBuffer = toasts;
    toastBuffer.splice(toastBuffer.findIndex((el) => el.id === id), 1);
    setToasts(toastBuffer);
  }

  // Offline Checker
  useEffect(function() {
    setInterval(function() {
      console.log('ping');
      fetch('https://app.warest.de', {mode: 'no-cors'})
          .then(() => {
            fetch('https://google.de', {mode: 'no-cors'})
                .then(() => {
                  setIsOffline(false);
                })
                .catch(() => {
                  console.log('offline');
                  setIsOffline(true);
                });
          })
          .catch(() => {
            console.log('offline');
            setIsOffline(true);
          });
    }, 5000);
  }, []);

  // // Realtime DB Listener to check online status
  // useEffect(function() {
  //   if (rtdb) {
  //     const connectedRef = rtdb.ref('.info/connected');
  //     connectedRef.on('value', (snap) => {
  //       fetch('https://app.warest.de', {mode: 'no-cors'})
  //           .catch(() => setIsOffline(true));
  //       if (snap.val() === true) {
  //         setIsOffline(false);
  //         console.log('online');
  //       } else {
  //         setIsOffline(true);
  //         console.log('offline');
  //       }
  //     });
  //   }
  // }, []);

  // User Authentication
  useEffect(function() {
    auth.onAuthStateChanged(async function(user) {
      if (user) {
        // Change userId in Local Storage
        // Set User State
        localStorage.setItem('userId', user.uid);
        setCurrentUser({...user});
        console.log('User logged in, UID: ' + user.uid);

        // Update Team
        let userDoc = await mdb.collection('meta').doc('user').get();
        if (!userDoc) {
          const buffer = await fs.collection('users').doc(user.uid).get();
          userDoc = buffer ? buffer.data() : null;
        };
        const activeTeam = userDoc ? userDoc.active_team :null;
        if (activeTeam && (!currentTeam || activeTeam != currentTeam.id)) {
          console.log('setting current Team');
          fs
              .collection('teams')
              .doc(String(activeTeam))
              .get()
              .then((currentTeam) => {
                setCurrentTeam(currentTeam || {});
                localStorage.setItem('teamId', currentTeam.id);
                lb = new Localbase(currentTeam.id);
              // lb.config.debug = false;
              });
        } else if (!activeTeam) {
          localStorage.removeItem('teamId');
        }
      } else {
        setCurrentUser(null);
        localStorage.removeItem('userId');
        localStorage.removeItem('teamId');
      }
    });
  }, []);

  return (
    <div>
      <AuthContext.Provider
        value={{
          userC: [currentUser, setCurrentUser],
          teamC: [currentTeam, setCurrentTeam],

          isOfflineC: [isOffline, setIsOffline],
          toastsC: [toasts, addToast],
          nkProblemsC: [nkProblems, setNkProblems],
          isSyncingC: [isSyncing, setIsSyncing],
        }}>
        {props.children}
      </AuthContext.Provider>
    </div>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.object,
  history: PropTypes.any,
};

/** Login for User
 * @param {string} email
 * @param {function} setLoginError
 */
export function login({email, password}, setLoginError) {
  auth.signInWithEmailAndPassword(email, password).catch(() => {
    setLoginError(true);
    setTimeout(() => setLoginError(false), 5000);
  });
}

/**
 * Creates a new User
 * @param {object} param0 params
 */
export async function signup({email, password, userName}) {
  try {
    const userCredential =
     await auth.createUserWithEmailAndPassword(email, password);

    console.log(userCredential);
    const user = userCredential.user;
    const uid = user.uid;

    user.updateProfile({
      displayName: userName,
    });

    const date = Date.now();

    await fs.collection('users')
        .doc(uid)
        .set({

          name: userName,
          email: email,

          team_memberships: [],

          created_date: date,
          updated_date: date,
          deleted: false,
        }, {merge: true});

    return 'success';
  } catch (e) {
    alert(`code: ${e.code} message: ${e.message}`);
  }
}

/**
 * Logout User
 */
export async function logout() {
  await lb.delete();
  await mdb.delete();
  auth.signOut();
}

import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  MdPayment,
  MdGroup,
  // MdAdd,
  MdAccountCircle,
  // MdInbox,
  MdInsertDriveFile,
  MdSecurity,
} from 'react-icons/md';
import {Link, useLocation} from 'react-router-dom';
// import {AuthContext} from '../context/AuthContext';
import AccountSettings from './AccountSettings';
import TeamSettings from './TeamSettings';
import WarehouseSettings from './WarehouseSettings';
import DocumentsSettings from './DocumentsSettings';
import ContactsSettings from './ContactsSettings';
import BillingSettings from './BillingSettings';
import ImportSettings from './ImportSettings';
import AdvancedSettings from './AdvancedSettings';
import {AuthContext, mdb} from '../context/AuthContext';
import {auth} from '../firebase';
import {fs} from '../firebase';
import {createDoc, RerenderContext, updateDoc} from '../cud';
// import Localbase from 'localbase';
import Localbase from 'localbase';
import {lb} from '../context/AuthContext';


const navData = [
  {
    name: 'Account',
    href: '/settings/account',
    icon: <MdAccountCircle size="1.3rem" />,
    current: false,
  },
  {
    name: 'Unternehmen',
    href: '/settings/team',
    icon: <MdGroup size="1.3rem" />,
    current: false,
  },
  // {
  //   name: 'Lager',
  //   href: '/settings/warehouse',
  //   icon: <MdInbox size="1.3rem" />,
  //   current: false,
  // },
  {
    name: 'Dokumente',
    href: '/settings/documents',
    icon: <MdInsertDriveFile size="1.3rem" />,
    current: false,
  },
  {
    name: 'Kontakte',
    href: '/settings/contacts',
    icon: <MdGroup size="1.3rem" />,
    current: false,
  },
  {
    name: 'Abrechnung',
    href: '/settings/billing',
    icon: <MdPayment size="1.3rem" />,
    current: false,
  },
  // {
  //   name: 'Import',
  //   href: '/settings/import',
  //   icon: <MdAdd size="1.3rem" />,
  //   current: false,
  // },
  {
    name: 'Erweitert',
    href: '/settings/advanced',
    icon: <MdSecurity size="1.3rem" />,
    current: false,
  },
];

/**
 *
 * @param  {...any} classes
 * @return {any}
 */
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/**
 *
 * @return {obj} jsx
 */
export default function Settings({history}) {
  const [rerender, setRerender] = useContext(RerenderContext);
  const {teamC, userC, isOfflineC, toastsC} = useContext(AuthContext);
  const currentTeam = teamC[0];
  const currentUser = userC[0];
  const isOffline = isOfflineC[0];
  const addToast = toastsC[1];

  const [documentTypes, setDocumentTypes] = useState([]);
  const [teamAddresses, setTeamAddresses] = useState([]);
  const [membershipDoc, setMembershipDoc] = useState({});

  const [navigation, setNavigation] = useState(navData);
  const {pathname} = useLocation();

  const [isSaving, setIsSaving] = useState(false);

  // Settings States
  const [accountSettings, setAccountSettings] = useState({
    email: '',
    userName: '',
    reset: '',
    publicName: '',
    publicContact: '',
  });
  const [billingSettings, setBillingSettings] = useState({
    salutation: '',
    name: '',
    name_2: '',
    address: '',
    address_2: '',
    postal_code: '',
    city: '',
    region: '',
    country: '',
  });

  const [documentSettings, setDocumentSettings] = useState({
    nks: [],
    activateSettleFeature: false,
    showSenderAddress: false,
    autoTagging: {},
    internalNk: '',
    validUntilDays: '',
    reminders: [],
  });

  const [contactSettings, setContactSettings] = useState({
    nks: [],
  });

  // Load Data
  useEffect(async function() {
    if (currentTeam) {
      const lb = new Localbase(currentTeam.id);
      // lb.config.debug = false;
      const documentTypes = await lb.collection('document_types').get();
      setDocumentTypes(documentTypes);

      const teamAddresses = await lb.collection('addresses').get();
      setTeamAddresses(teamAddresses);

      const membershipDoc = await lb.collection('memberships')
          .doc({id: currentUser.uid}).get();
      setMembershipDoc(membershipDoc);
    }
  }, [rerender]);

  // Updates Navigation Panels depending on pathname
  useEffect(
      function() {
        const nav = [...navigation];
        for (const el of nav) el.current = false;
        const index = nav.findIndex((el) => el.href === pathname);
        index != -1 && (nav[index].current = true);
        setNavigation(nav);
      },
      [pathname],
  );

  // Init Settings
  useEffect(async function() {
    // Billing Address
    let address;
    if (teamAddresses) {
      address = teamAddresses
          .find((el) => el.type === 'billing');
    }
    console.log(teamAddresses);
    console.log(address);
    address && setBillingSettings(address);

    // Document Settings
    const nkBuffer = [];
    console.log(documentTypes);
    for (const type of documentTypes) {
      nkBuffer.push({name: type.name,
        id: type.id,
        nk: type.number,
        abbreviation: type.abbreviation,
        data: {...type}});
    }
    const teamDoc = await mdb.collection('meta').doc('team').get();
    console.log(teamDoc);
    setDocumentSettings({
      nks: nkBuffer,
      activateSettleFeature: teamDoc &&
       teamDoc.activate_settle_feature ||
       false,
      showSenderAddress: teamDoc &&
      teamDoc.show_sender_address || false,
      autoTagging: teamDoc &&
      teamDoc.auto_tagging || {},
      internalNk: teamDoc && teamDoc.internal_nk || '',
      validUntilDays: teamDoc && teamDoc.valid_until_days || '',
      reminders: teamDoc && teamDoc.reminders || [],
    });

    // Contact Settings
    setContactSettings({
      nks: [
        {name: 'Kunden',
          nk: teamDoc && teamDoc.customer_nk || ''},
        {name: 'Lieferanten',
          nk: teamDoc && teamDoc.supplier_nk || ''}],
    });

    // Account Settings
    const membershipDoc = await lb.collection('memberships')
        .doc({id: currentUser.uid}).get();
    if (currentUser && membershipDoc) {
      setAccountSettings({
        userName: currentUser.displayName,
        email: currentUser.email,
        publicName: membershipDoc.public_name || '',
        publicContact: membershipDoc.public_contact || '',
        reset: false,
      });
    }
  }, [teamAddresses, documentTypes, currentTeam, currentUser]);


  /**
   * Saves Settings directly to FS
   */
  async function saveSettings() {
    console.log('Saving settings');
    setIsSaving(true);
    try {
      // Save Account Settings
      await auth.currentUser.updateProfile({
        displayName: accountSettings.userName,
      });

      await auth.currentUser.updateEmail(accountSettings.email);

      await updateDoc(currentTeam.id, 'memberships', currentUser.uid, {
        ...membershipDoc,
        public_name: accountSettings.publicName,
        public_contact: accountSettings.publicContact,
      }, null, isOffline);

      // Save Billing Settings
      let address;
      if (teamAddresses) {
        address = teamAddresses
            .find((el) => el.type === 'billing');
      }
      if (address) {
        updateDoc(currentTeam.id, 'addresses',
            address.id, {...billingSettings}, null, isOffline);
      } else {
        createDoc(currentTeam.id, 'addresses',
            {...billingSettings, type: 'billing'}, null, isOffline)
            .catch((e) => {
              addToast('error', `Fehler:
           Zahlungsadresse konnte nicht hinzugefügt werden`);
              return console.log(e);
            });
      }

      // Save Document Settings
      fs.collection('teams').doc(currentTeam.id)
          .set({
            activate_settle_feature: documentSettings.activateSettleFeature,
            show_sender_address: documentSettings.showSenderAddress,
            auto_tagging: documentSettings.autoTagging,
            internal_nk: documentSettings.internalNk,
            valid_until_days: documentSettings.validUntilDays,
            reminders: documentSettings.reminders,

            // Save Contact Nks
            customer_nk: contactSettings.nks
                .find((el) => el.name === 'Kunden').nk,
            supplier_nk: contactSettings.nks
                .find((el) => el.name === 'Lieferanten').nk,
          }, {merge: true});

      // Update Nks
      for (const nk of documentSettings.nks) {
        await fs.collection('teams').doc(currentTeam.id)
            .collection('document_types').doc(nk.id).set({
              ...nk.data,
              number: nk.nk,
              abbreviation: nk.abbreviation,
              updated_date: Date.now(),
            }, {merge: true});
      }

      setIsSaving(false);
      setRerender('');
      setRerender('all');
      // history.push('/dashboard');
      window.location.href = '/dashboard';
      addToast('success', 'Einstellungen gespeichert');
    } catch (e) {
      addToast('error', `Fehler:
       Einstellungen konnten nicht gespeichert werden`);
      return console.log(e);
    }
  }

  return (
    <div className="flex flex-col p-2 md:p-8 bg-gray-100 fill_container">
      <div
        className="flex flex-col lg:grid lg:grid-cols-12
    lg:gap-x-5 h-full">
        <aside
          className="py-6 px-2 sm:px-6
      lg:py-0 lg:px-0 lg:col-span-3 flex flex-col justify-between">
          <nav className="space-y-1">
            {navigation.map((item, index) => (
              <Link
                to={item.href}
                key={item.name}
                className={classNames(
                  item.current ?
                    `bg-gray-50 text-blue-700
                    hover:text-blue-700 hover:bg-white rounded-lg` :
                    'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                  `group rounded-md px-3 py-2
                  flex items-center text-sm font-medium`,
                )}
                aria-current={item.current ? 'page' : undefined}>
                <div className="mx-2">{item.icon}</div>
                <span className="truncate">{item.name}</span>
              </Link>
            ))}
          </nav>
          <div className="px-4 py-3 flex-center text-right sm:px-6">
            <button
              disabled={isSaving || isOffline}
              onClick={() => saveSettings()}
              className="bg-blue-600 border
                border-transparent rounded-md
                shadow-sm py-2 px-4 inline-flex
                justify-center text-sm font-medium
                text-white hover:bg-blue-700 disabled:opacity-50
                focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-blue-500">
              {isOffline ? 'Offline' :
               isSaving ? 'Wird gespeichert...' : 'Speichern'}
            </button>
          </div>
        </aside>

        <div
          className="sm:px-6
      lg:px-0 lg:col-span-9 overflow-y-auto flex-shrink md:h-full">
          <AccountSettings history={history}
            state={[accountSettings, setAccountSettings]}>
          </AccountSettings>
          <TeamSettings></TeamSettings>
          <WarehouseSettings></WarehouseSettings>
          <DocumentsSettings state={[documentSettings, setDocumentSettings]}>
          </DocumentsSettings>
          <ContactsSettings state={[contactSettings, setContactSettings]}>
          </ContactsSettings>
          <BillingSettings state={[billingSettings, setBillingSettings]}>
          </BillingSettings>
          <ImportSettings></ImportSettings>
          <AdvancedSettings></AdvancedSettings>
        </div>
      </div>
    </div>
  );
}

Settings.propTypes = {
  history: PropTypes.object,
};

import React from 'react';
// import {AuthContext} from '../context/AuthContext';
import {useLocation} from 'react-router-dom';
import {MdAccountBalance} from 'react-icons/md';
import PropTypes from 'prop-types';

/**
 *
 * @return {object} jsx
 */
export default function BillingSettings({state}) {
  const {pathname} = useLocation();

  const [billingSettings, setBillingSettings] = state;

  /**
   * Handles Billing Address input
   * @param {object} event
   */
  function handleChange(event) {
    const {value, name} = event.target;

    setBillingSettings({
      ...billingSettings,
      [name]: value,
    });
  }

  return (
    <div
      className="space-y-6"
      style={{display: pathname === '/settings/billing' ? 'block' : 'none'}}>
      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div>
            <h3
              className="text-lg leading-6
                font-medium text-gray-900">
              Firmenadresse
            </h3>
            <p
              className="mt-1 text-sm
                text-gray-500">
              Geben Sie hier die Rechnungsadresse ihrer Firma an. Diese wird
              standartmäßig auf ihren Dokumenten als Absenderadresse verwendet.
            </p>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-4 sm:col-span-2">
              <label
                htmlFor="salutation"
                className="block text-sm font-medium text-gray-700">
                Anrede
              </label>
              <select
                onInput={(e) => handleChange(e)}
                value={billingSettings.salutation}
                name="salutation"
                className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md">
                <option></option>
                <option>Herr</option>
                <option>Frau</option>
                <option>Firma</option>
              </select>
            </div>
            <div className="col-span-6 sm:col-span-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700">
                Name/Firma
              </label>
              <input
                onInput={(e) => handleChange(e)}
                value={billingSettings.name}
                type="text"
                name="name"
                autoComplete="family-name"
                className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="col-span-6 sm:col-span-4 sm:col-start-3">
              <label
                htmlFor="name_2"
                className="block text-sm font-medium text-gray-700">
                Name 2
              </label>
              <input
                onInput={(e) => handleChange(e)}
                value={billingSettings.name_2}
                type="text"
                name="name_2"
                autoComplete="family-name"
                className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="address"
                className="block text-sm font-medium text-gray-700">
                Adresse
              </label>
              <input
                onInput={(e) => handleChange(e)}
                value={billingSettings.address}
                type="text"
                name="address"
                autoComplete="address"
                className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="address_2"
                className="block text-sm font-medium text-gray-700">
                Zusatz
              </label>
              <input
                onInput={(e) => handleChange(e)}
                value={billingSettings.address_2}
                type="text"
                name="address_2"
                autoComplete="address"
                className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
              <label
                htmlFor="postal_code"
                className="block text-sm font-medium text-gray-700">
                PLZ
              </label>
              <input
                onInput={(e) => handleChange(e)}
                value={billingSettings.postal_code}
                type="text"
                name="postal_code"
                className="mt-1 focus:ring-blue-500
                            focus:border-blue-500
                            block w-full shadow-sm sm:text-sm
                            border-gray-300 rounded-md"
              />
            </div>
            <div className="col-span-6 sm:col-span-6 lg:col-span-4">
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700">
                Stadt
              </label>
              <input
                onInput={(e) => handleChange(e)}
                value={billingSettings.city}
                type="text"
                name="city"
                className="mt-1 focus:ring-blue-500
                            focus:border-blue-500
                            block w-full shadow-sm sm:text-sm
                            border-gray-300 rounded-md"
              />
            </div>
            <div
              className="col-span-6 sm:col-span-3
                      lg:col-span-2 lg:col-start-1">
              <label
                htmlFor="region"
                className="block text-sm font-medium text-gray-700">
                Region
              </label>
              <input
                onInput={(e) => handleChange(e)}
                value={billingSettings.region}
                type="text"
                name="region"
                className="mt-1 focus:ring-blue-500
                            focus:border-blue-500
                            block w-full shadow-sm sm:text-sm
                            border-gray-300 rounded-md"
              />
            </div>
            <div className="col-span-6 sm:col-span-2">
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700">
                Land
              </label>
              <input
                onInput={(e) => handleChange(e)}
                value={billingSettings.country}
                type="text"
                name="country"
                className="mt-1 block w-full py-2 px-3 border
                            border-gray-300 bg-white
                             rounded-lg rounded-md shadow-sm
                            focus:outline-none focus:ring-blue-500
                            focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div>
            <h3
              className="text-lg leading-6
                font-medium text-gray-900">
              Aktive Zahlungsmethode
            </h3>
            {/* <p
              className="mt-1 text-sm
                text-gray-500">
              Verwalten Sie hier ihre aktive Zahlungsmethode.
            </p> */}
          </div>

          <div
            className="rounded-md bg-gray-50 px-6 py-5 sm:flex
          sm:items-start sm:justify-between">
            <div className="sm:flex sm:items-start">
              <MdAccountBalance size="1.5rem" />
              <div className="mt-3 sm:mt-0 sm:ml-4">
                <div className="text-sm font-medium text-gray-900">
                  Rechnung
                </div>
                <div
                  className="mt-1 text-sm text-gray-600 sm:flex
                sm:items-center">
                  {/* <div>Expires 12/20</div> */}
                  <span className="hidden sm:mx-2 sm:inline" aria-hidden="true">
                    &middot;
                  </span>
                  {/* <div className="mt-1 sm:mt-0">
                    Last updated on 22 Aug 2017
                  </div> */}
                </div>
              </div>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
              <button
                disabled
                type="button"
                className="inline-flex items-center px-4 py-2 border
                border-gray-300
                shadow-sm font-medium rounded-md
                 text-gray-400 bg-white rounded-lg
                hover:bg-gray-50 cursor-default
                focus:outline-none focus:ring-2 focus:ring-offset-2
                focus:ring-blue-500 sm:text-sm">
                Bearbeiten
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BillingSettings.propTypes = {
  state: PropTypes.array,
};

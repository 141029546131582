import React, {
  Fragment, useContext,
  useState, useEffect, useRef
} from 'react';
import { Menu, Transition } from '@headlessui/react';
import ModalConfirm from '../ModalConfirm';


// Import Utility Functions
import {
  numberFormatter,
  millsToDate,
  markAsOverdue,
  dataToPdf,
  getSearchResults,
  analyseNkProblems,
  valArr,
} from '../utilities';

// Import Google Icons
import {
  MdCheckCircle,
  MdEdit,
  MdClose,
  MdLocalOffer,
  MdLocalShipping,
  MdPayment,
  MdSearch,
  MdDelete,
  MdFilterList,
  MdFileDownload,
  MdContentCopy,
  MdArrowForward,
  MdList,
  MdAddCircleOutline,
  MdMoreVert,
  MdKeyboardArrowLeft,
  MdPrint,
} from 'react-icons/md';
import ShowDocument from './ShowDocument';

import { Link } from 'react-router-dom';
import { AuthContext, lb, mdb } from '../context/AuthContext';
import SettleDocument from './SettleDocument';
import { deleteDoc, RerenderContext, updateDoc } from '../cud';
import NkProblems from './NkProblems';
import ReactTooltip from 'react-tooltip';
import InvoiceOutBook from './InvoiceOutBook';


/**
 *
 * @return {object} jsx
 */
export default function Documents() {
  const [tags, setTags] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [documentComments, setDocumentComments] = useState([]);
  const [teamDoc, setTeamDoc] = useState({});

  const { teamC, isOfflineC, nkProblemsC } = useContext(AuthContext);
  const currentTeam = teamC[0];
  const isOffline = isOfflineC[0];
  const [nkProblems, setNkProblems] = nkProblemsC;

  const [displayDocuments, setDisplayDocuments] = useState([]);

  // document Types state for Document Type Filter
  const [documentTypes, setDocumentTypes] = useState([]);

  const [activeFilter, setActiveFilter] = useState([]);
  const [activeSearch, setActiveSearch] = useState({ value: '' });


  const [showDocument, setShowDocument] = useState(null);
  const [settleDoc, setSettleDoc] = useState(null);
  const [showNkProblems, setShowNkProblems] = useState(false);


  const [rerender, setRerender] = useContext(RerenderContext);

  const [modalConfirm, setModalConfirm] = useState({});

  const searchInputRef = useRef(null);

  const [showInvoiceOutBook, setShowInvoiceOutBook] = useState(false);

  // Load Data
  useEffect(async function () {
    if (currentTeam) {
      const tags = await lb.collection('tags').get();
      valArr(tags) && setTags(tags);

      const contacts = await lb.collection('contacts').get();

      valArr(contacts) && setContacts(contacts);

      const documentComments = await lb.collection('document_comments').get();
      valArr(documentComments) && setDocumentComments(documentComments);

      const documentTypes = await lb.collection('document_types').get();
      valArr(documentTypes) && setDocumentTypes(documentTypes);

      const teamDoc = await mdb.collection('meta').doc('team').get();
      setTeamDoc(teamDoc);
    }
  }, [rerender]);

  // Init saved Search String
  useEffect(function () {
    // Fetch Saved Searches from Database
    const savedSearches = JSON.parse(localStorage.getItem('savedSearches'));
    // if saved string exists, handle search with it
    if (savedSearches && savedSearches.documents) {
      handleSearchInput(savedSearches.documents);
      searchInputRef.current.value = savedSearches.documents;
    }
  }, []);

  // Update Search Index and Display Documents State when
  // Documents Change
  useEffect(
    async function () {
      if (currentTeam) {
        console.log('---rerendering documents---');

        // Load Docs from LB
        let buffer = await lb.collection('documents').get();
        if (!valArr(buffer)) return;
        const indexDoc = await lb.collection('search-indexes')
          .doc('documents').get();
        const index = indexDoc && indexDoc.index;

        // Search
        if (activeSearch.value.length > 0) {
          buffer = activeSearch.filter(buffer, index);
        }

        // Filter out deleted Docs
        buffer = buffer.filter((el) => !el.deleted);


        // Apply active Filter
        for (const filter of activeFilter) {
          buffer = filter.filter(buffer);
        }

        // Sort by created_date or updated_date
        buffer = buffer.sort((el1, el2) => {
          const sortPreset = JSON.parse(localStorage.getItem('sortPresets'));
          if (sortPreset && sortPreset.documents) {
            return el2[sortPreset.documents] - el1[sortPreset.documents];
          }
          return el2['created_date'] - el1['created_date'];
        });


        // Limit Length to 40
        buffer = buffer.filter((el, i) => i <= 40);

        setDisplayDocuments([...buffer]);
      }
    },
    [activeFilter, activeSearch, rerender, currentTeam],
  );

  // Check NK Problems on render
  useEffect(function () {
    analyseNkProblems(setNkProblems);
  }, []);

  /**
   * Searches Documents
   * @param {string} input
   */
  function handleSearchInput(input) {
    // Save Search String to Local Storage for persistance
    const savedSearches = JSON.parse(localStorage.getItem('savedSearches'));
    localStorage.setItem('savedSearches', JSON.stringify({
      ...savedSearches,
      documents: input,
    }));


    setActiveSearch({
      value: input,
      filter: function (docs, index) {
        return getSearchResults(input, docs, index);
      },
    });
  }

  /**
   * Returns the next Document Type in the
   * document continuation Logic
   * @param {object} doc Document Data
   * @return {object} JSX
   */
  function nextType(doc) {
    let links;
    const name = doc.type.name;
    if (name === 'Angebot') {
      links = ['Auftragsbestätigung', 'Rechnung'];
    } else if (name === 'Auftragsbestätigung') {
      links = ['Rechnung'];
    } else if (name === 'Rechnung') {
      links = ['Lieferschein', 'Gutschrift'];
    } else if (name === 'Lieferschein') {
      links = ['Rechnung'];
    } else {
      links = [];
    }
    return links;
  }
  /**
 * Checks Payment reminder stages that user has set,
 * and fills dropdown accordingly
 * @param {object} doc document Data
 * @param {string} classN Style classes
 * @return {object} reminder
 */
  function reminders(doc, classN) {
    if (doc.type.name === 'Rechnung') {
      const reminder = Object.keys(teamDoc).length > 0 &&
        teamDoc.reminders &&
        teamDoc.reminders.length > 0 ?
        teamDoc.reminders[doc.reminder || 0] : {};

      return (
        <Link
          onClick={(e) => {
            e.stopPropagation();
            let reminder = 0;
            if (doc.reminder) {
              reminder = doc.reminder;
            }
            reminder++;
            updateDoc(currentTeam.id, 'documents', doc.id, {
              ...doc,
              reminder: reminder,
            }, setRerender, isOffline)
              .catch((e) => {
                addToast('error', `Fehler: Stufen der Zahlungserinnerungen
         konnten nicht geladen werden`);
                return console.log(e);
              });
          }}
          to={{
            pathname: '/documents/input',
            state: {
              docData: {
                ...doc,
                ancestor: doc.id,
                created_date: null,
                id: null,
                comment_1: (() => {
                  const comment = documentComments
                    .find((el) => el.id ===
                      reminder.comment);
                  return comment ? comment.text : '';
                })(),
                positions: [
                  ...doc.positions,
                  {
                    amount: 1,
                    unit: '',
                    title: reminder.fee_name,
                    price: reminder.fee,
                    weight: '',
                    total_weight: '',
                    discount: '',
                    total_price: reminder.fee,
                  },
                ],
                type: {
                  name: `${reminder.name} ${reminder.addon} ${doc.type.name}`,
                  number: doc.type.number,
                  abbreviation: doc.type.abbreviation,

                }
              }
            },
          }}

          className={classN ? classN : `flex justify-start
                                 px-4 py-2 w-full text-sm hover:bg-gray-100`}>
          {reminder.name}
        </Link>
      );
    } else {
      return '';
    }
  }


  return (
    <>
      {/* Confirm Modal */}
      <ModalConfirm stateC={[modalConfirm, setModalConfirm]} />

      <NkProblems
        showC={[showNkProblems, setShowNkProblems]}></NkProblems>
      <ShowDocument
        showDocumentC={[showDocument, setShowDocument]}></ShowDocument>
      <SettleDocument
        settleDocC={[settleDoc, setSettleDoc]}></SettleDocument>
      <div className="flex flex-col p-4 bg-gray-100 h-full">
        <div className="w-full pb-4 shrink-0 flex space-x-2 md:justify-between">
          {/* <label
            htmlFor="search"
            className="block text-sm font-medium text-gray-700">
            Quick search
          </label> */}
          <div className="flex">

            <div className="relative flex items-center w-96">
              <div
                className="absolute inset-y-0 left-0 pl-3 flex
            items-center pointer-events-none"
                aria-hidden="true">
                <MdSearch
                  className="mr-3 h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                ref={searchInputRef}
                onKeyDown={(e) => {
                  e.keyCode === 13 && handleSearchInput(e.target.value);
                }}
                onInput={(e) => {
                  if (!e.target.value) handleSearchInput('');
                }}
                type="text"
                name="search"
                placeholder="Suche"
                id="search"
                className="shadow-sm focus:ring-blue-500
              focus:border-blue-500 block w-full pr-12 pl-9
              sm:text-sm border-gray-300 rounded-md"
              />
              <div
                className="absolute inset-y-0 right-0 flex
            py-1.5 pr-1.5">
                <kbd
                  className="inline-flex items-center border
              border-gray-200 rounded px-2 text-sm font-sans
              font-medium text-gray-400">
                  ⌘K
                </kbd>
              </div>
            </div>
            <button data-tip='Nummernkreisprobleme erkannt' onClick={() => {
              setShowNkProblems(true);
            }} className={`rounded-md bg-red-100 px-3 ml-2 animate-pulse ${nkProblems.length ? 'flex-center' : 'hidden'
              }`}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54
                  0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464
                   0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </button>
            <ReactTooltip place="right" type="dark" effect="solid" />
          </div>



          <div className="flex space-x-2">
            <div className="flex items-center">
              <button onClick={() => setShowInvoiceOutBook(true)}>
                <MdPrint className="h-6 w-6 text-gray-400" data-tip='Rechnungsausgangsbuch drucken' />
              </button>
              <ReactTooltip place="right" type="dark" effect="solid" />
              <InvoiceOutBook show={showInvoiceOutBook} setShow={setShowInvoiceOutBook} />
            </div>

            <Menu as="div" className="relative inline-block z-20 text-left">
              <div
                className={`relative items-center px-4 py-2
             rounded-md border border-gray-300 bg-white text-sm
             font-medium  focus:z-10 h-full
              focus:outline-none focus:ring-1 focus:ring-blue-500
               focus:border-blue-500 flex space-x-2
                text-gray-700 hover:bg-gray-50`}
              >
                <Menu.Button className='flex-center'>
                  <p className='mr-2 font-medium
                  hide_mobile'>
                    Sortieren
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round"
                      strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                  </svg>
                </Menu.Button>
              </div>


              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right
                 absolute right-0 mt-2 w-56
         rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
          focus:outline-none">
                  <div className="p-2">
                    <Menu.Item>
                      {({ active }) => (
                        <button className={`flex justify-start px-4 py-2 w-full
                      text-sm rounded-md font-medium hover:bg-gray-50`}
                          onClick={() => {
                            const sortPresets =
                              JSON.parse(localStorage.getItem('sortPresets'));
                            localStorage.setItem('sortPresets', JSON.stringify({
                              ...sortPresets,
                              documents: 'created_date',
                            }));
                            setRerender('');
                            setRerender('documents');
                          }}>
                          Erstellungsdatum</button>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <button className={`flex justify-start px-4 py-2 w-full
                      text-sm rounded-md font-medium hover:bg-gray-50`}
                          onClick={() => {
                            const sortPresets =
                              JSON.parse(localStorage.getItem('sortPresets'));
                            localStorage.setItem('sortPresets', JSON.stringify({
                              ...sortPresets,
                              documents: 'updated_date',
                            }));

                            setRerender('');
                            setRerender('documents');
                          }}>
                          Bearbeitungsdatum</button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            <Menu as="div" className="relative inline-block z-20 text-left">
              <div
                className={`relative items-center px-4 py-2
             rounded-md border border-gray-300 bg-white text-sm
             font-medium  focus:z-10 h-full
              focus:outline-none focus:ring-1 focus:ring-blue-500
               focus:border-blue-500 flex space-x-2
               ${activeFilter.length > 0 ?
                    'bg-blue-500 text-blue-100 hover:bg-blue-600' :
                    'text-gray-700 hover:bg-gray-50'}`}
              >
                <Menu.Button className='flex'>
                  <p className='mr-2 font-medium
                  hide_mobile'>{activeFilter.length > 0 ?
                      'Filter aktiv' :
                      'Filtern'}
                  </p>

                  <button
                    className='flex-center'
                    onClick={(e) => {
                      if (activeFilter.length > 0) {
                        e.stopPropagation();
                        setActiveFilter([]);
                      }
                    }}>
                    {activeFilter.length > 0 ?
                      <MdClose size='1.3rem'></MdClose> :
                      <MdFilterList size='1.3rem'></MdFilterList>
                    }
                  </button>
                </Menu.Button>
              </div>


              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right
                 absolute right-0 mt-2 w-56
         rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
          focus:outline-none">
                  <div className="p-2">
                    <Menu.Item>

                      <Menu as="div" className="relative
                                 inline-block">

                        <Menu.Button className='w-full
                                   items-center py-2 text-sm font-medium
                                    flex space-x-1'>
                          <MdKeyboardArrowLeft size='1.3rem'>

                          </MdKeyboardArrowLeft>
                          Dokumententyp
                        </Menu.Button>

                        <Menu.Items className="origin-top-right
                                   absolute
             top-0 right-full mt-2 text-black z-20
         rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
          focus:outline-none">
                          <div className="p-2 flex flex-col">
                            {documentTypes.map((type) => (
                              <button key={type.id}
                                className={`flex items-center justify-start
                              space-x-2 px-4 py-2 w-full text-sm rounded-md
                               font-medium ${activeFilter.map((el) =>
                                  el.type).includes(`docType:${type.id}`) ?
                                    'bg-blue-500 hover:bg-blue-600 text-white' :
                                    'hover:bg-gray-50'}`}
                                onClick={() => {
                                  // Save Filter State to Buffer
                                  const buffer = activeFilter;
                                  // Remove old Filter with same Type
                                  const oldFilter = buffer.findIndex((el) =>
                                    el.type.includes(`docType`));
                                  if (oldFilter !== -1) {
                                    buffer.splice(oldFilter, 1);
                                  };
                                  // Add New filter to filter array
                                  buffer.push({
                                    type: `docType:${type.id}`,
                                    filter: function (buffer) {
                                      return buffer.filter((doc, index) => {
                                        return doc.type.id === type.id;
                                      });
                                    },
                                  });
                                  // Set Filter State
                                  setActiveFilter([...buffer]);
                                }}>
                                <p>{type.name}</p></button>
                            ))}
                          </div>
                        </Menu.Items>
                      </Menu>
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button className={`flex justify-start px-4 py-2 w-full
                      text-sm rounded-md font-medium ${activeFilter.map((el) =>
                          el.type).includes('overdue') ?
                            'bg-blue-500 hover:bg-blue-600 text-white' :
                            'hover:bg-gray-50'}`}
                          onClick={() => {
                            // Save Filter State to Buffer
                            const buffer = activeFilter;
                            // Remove old Filter with same Type
                            const oldFilter = buffer.findIndex((el) =>
                              el.type === 'overdue');
                            if (oldFilter !== -1) buffer.splice(oldFilter, 1);
                            // Add New filter to filter array
                            buffer.push({
                              type: 'overdue',
                              filter: function (buffer) {
                                return buffer.filter((doc, index) => {
                                  const days = doc.payment_target_days;
                                  if (days &&
                                    doc.type.name === 'Rechnung' &&
                                    doc.open_amount &&
                                    (Date.now() - doc.created_date >
                                      days * 86400000)) {
                                    return true;
                                  };
                                });
                              },
                            });
                            // Set Filter State
                            setActiveFilter([...buffer]);
                          }}>
                          Überfällige Dokumente</button>


                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div className='flex flex-col px-4 py-2'>
                          <div className="flex items-center
                           justify-between py-2">
                            <label
                              htmlFor="name"
                              className="block text-sm
                               font-medium text-gray-700">
                              Von
                            </label>

                            <input
                              value={(() => {
                                const value = activeFilter.find((el) =>
                                  el.type === 'start_date');
                                if (value) return value.value;
                                else return '';
                              })()}
                              onClick={(e) => e.stopPropagation()}
                              onInput={(e) => {
                                const buffer = activeFilter;
                                const oldFilter = buffer.findIndex((el) =>
                                  el.type === 'start_date');
                                if (oldFilter !== -1) {
                                  buffer.splice(oldFilter, 1);
                                }
                                buffer.push({
                                  value: e.target.value,
                                  type: 'start_date',
                                  filter: function (buffer) {
                                    return buffer.filter((doc, index) => {
                                      const date = new Date(e.target.value)
                                        .getTime();
                                      // With Time Correction
                                      return doc.created_date >=
                                        date - 7200000;
                                    });
                                  },
                                });
                                setActiveFilter([...buffer]);
                              }}
                              type="date"
                              name="start_date"
                              className="m-1 focus:ring-blue-500
                                  focus:border-blue-500 block w-36
                                  shadow-sm sm:text-sm
                                   border-gray-300 rounded-md"
                            />
                          </div>
                          <div className="flex items-center
                           justify-between py-2">
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium
                               text-gray-700">
                              Bis
                            </label>

                            <input
                              value={(() => {
                                const value = activeFilter.find((el) =>
                                  el.type === 'end_date');
                                if (value) return value.value;
                                else return '';
                              })()}
                              onClick={(e) => e.stopPropagation()}
                              onInput={(e) => {
                                const buffer = activeFilter;
                                const oldFilter = buffer.findIndex((el) =>
                                  el.type === 'end_date');
                                if (oldFilter !== -1) {
                                  buffer.splice(oldFilter, 1);
                                }
                                buffer.push({
                                  value: e.target.value,
                                  type: 'end_date',
                                  filter: function (buffer) {
                                    return buffer.filter((doc, index) => {
                                      const date = new Date(e.target.value)
                                        .getTime();
                                      // With Time Correction
                                      return doc.created_date <=
                                        date + 79200000;
                                    });
                                  },
                                });
                                setActiveFilter([...buffer]);
                              }}
                              type="date"
                              name="end_date"
                              className="m-1 focus:ring-blue-500
                                  focus:border-blue-500 block w-36
                                  shadow-sm sm:text-sm
                                   border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      )}
                    </Menu.Item>

                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>

        </div>
        <div
          className="shadow overflow-auto flex-grow
          border-b border-gray-200 rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 table-fixed">
            <thead className="bg-gray-50">
              <tr className='hide_mobile'>
                <th scope="col" style={{ width: '25%' }} className="table_header">
                  Typ
                </th>
                <th scope="col" style={{ width: '20%' }} className="table_header">
                  Kunde
                </th>
                <th scope="col" style={{ width: '25%' }} className="table_header">
                  Tags
                </th>
                <th scope="col" style={{ width: '8%' }} className="table_header">
                  Intern
                </th>
                <th scope="col" style={{ width: '10%' }} className="table_header">
                  Erstellt
                </th>
                <th
                  scope="col"
                  style={{ width: '4%' }}
                  className="table_header"></th>
                <th
                  scope="col"
                  style={{ width: '4%' }}
                  className="table_header"></th>
                <th
                  scope="col"
                  style={{ width: '4%' }}
                  className="table_header"></th>
                <th
                  scope="col"
                  style={{ width: '4%' }}
                  className="table_header"></th>
                <th
                  scope="col"
                  style={{ width: '4%' }}
                  className="table_header"></th>
                <th
                  scope="col"
                  style={{ width: '4%' }}
                  className="table_header"></th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 ">
              {displayDocuments.map((doc, index) => {
                const contact = contacts.find((c) =>
                  c.id === doc.contact);
                return doc.contact &&
                  doc.type &&
                  doc.tags ? (
                  <tr
                    key={doc.id}
                    className={(() => {
                      let buffer = '';
                      if (markAsOverdue(doc)) {
                        buffer += 'bg-red-100 ';
                      } else if (!(index % 2 === 0)) {
                        buffer += 'bg-gray-100 ';
                      }
                      if (!doc.synced) {
                        buffer += 'unsynced ';
                      }
                      return buffer;
                    })()}>
                    {/* className={' ' + ( ? 'bg-gray-100' : '')}> */}
                    <td className="px-3 md:px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div
                          className="flex flex-shrink-0 h-10 w-10 items-center
                        justify-center hide_mobile">
                          {doc.type.name === 'Angebot' ? <MdLocalOffer /> : ''}
                          {doc.type.name === 'Auftragsbestätigung' ? <MdCheckCircle /> : ''}
                          {doc.type.name === 'Rechnung' ? <MdPayment /> : ''}
                          {doc.type.name === 'Lieferschein' ? <MdLocalShipping /> : ''}
                          {doc.type.name === 'Abschlagsrechnung' ? <MdList /> : ''}
                          {doc.type.name === 'Gutschrift' ? <MdAddCircleOutline /> : ''}
                        </div>
                        <div className="ml-4">
                          <button
                            data-tip='Vorschau ansehen'
                            onClick={function () {
                              if (window.screen.availWidth < 768) return;
                              setShowDocument(doc);
                            }}
                            className="text-sm font-medium
                          text-gray-900 flex space-x-1">
                            <p>{`${doc.type.name} `}</p>
                            {doc.type.number ?
                              <p>{doc.type.number}</p> : !isOffline ?
                                <div className="h-5 w-5 p-1 flex-center">
                                  <div className="spinner"></div>
                                </div> : ''}
                          </button>

                          <div className='flex space-x-2'>
                            <button
                              // Toggle Document Paid and unpaid, depending
                              // on open_amount attribute: red = unpaid, green = paid
                              onClick={async () => {
                                if (doc.type.name === 'Rechnung') {
                                  try {
                                    const oa = doc.open_amount ?
                                      0 : doc.gross_total_after_reductions || doc.gross_total
                                    await updateDoc(currentTeam.id,
                                      'documents', doc.id, {
                                      ...doc, open_amount: oa,
                                    }, setRerender, isOffline)
                                  } catch (e) {
                                    addToast('error', `Fehler:
                                     Zahlung konnte nicht gebucht werden`);
                                    return console.log(e);

                                  }
                                }
                              }}
                              className={`text-sm ${doc.open_amount ?
                                'text-red-500' : 'text-gray-500'}
                                ${doc.open_amount === 0 &&
                                'text-green-500'}`}>
                              {`${`${numberFormatter(doc
                                .gross_total)}€`}${doc.gross_total_after_reductions ?
                                  ` (${numberFormatter(doc
                                    .gross_total_after_reductions)}€)` : ''}`}
                            </button>
                            <p className="show_mobile text-sm text-gray-500">
                              {contact && contact.name}</p>
                          </div>
                        </div>
                        {doc.canceled ? (
                          <div className="h-full flex-center flex-grow">
                            <span
                              className={`px-1 flex-center h-6 mx-1 my-1
                      text-xs leading-5 font-semibold
                      rounded-md bg-red-100 text-red-800`}>
                              STORNIERT
                            </span>
                          </div>
                        ) : ''}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap hide_mobile">
                      <div
                        className="text-sm
                      text-gray-900">
                        {contact && contact.name}
                      </div>
                      <div
                        className="text-sm
                      text-gray-500">
                        {`Nr. ${contact && contact.number}`}
                      </div>
                    </td>
                    <td className="px-6 py-4 min-w-96 hide_mobile
                     flex flex-wrap">
                      {doc.tags.map((el) => {
                        const result = tags && tags.find((tag) => {
                          return tag.id === el;
                        });
                        const { text, color } = result ? result : {};
                        return (
                          <span
                            key={el}
                            className={`px-2 flex-center h-6 mx-1 my-1
                      text-xs leading-5 font-semibold
                      rounded-md bg-${color}-100 text-${color}-800`}>
                            {text}
                          </span>
                        );
                      })}
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap
                    text-sm text-gray-500 hide_mobile">
                      {doc.internal_nr ?
                        <p>{doc.internal_nr}</p> : !isOffline ?
                          <div className="h-5 w-5 p-1 flex-center">
                            <div className="spinner"></div>
                          </div> : ''}
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap
                    text-sm text-gray-500 hide_mobile">
                      {millsToDate(doc.created_date)}
                    </td>
                    <td
                      className="px-4 py-4 pt-3 whitespace-nowrap
                    text-sm text-gray-500 hide_mobile">
                      <Link
                        to={{
                          pathname: '/documents/input',
                          state: {
                            docData: {
                              ...doc,
                              ancestor: doc.id,
                              created_date: null,
                              document_created_date: Date.now(),
                              id: null,
                              type: {},
                              internal_nr: null,
                              reductions: []
                            }
                          },
                        }}
                        className="">
                        <MdContentCopy
                          data-tip='Dokument duplizieren' size="1.3rem" />
                        {/* <ReactTooltip place="top"
                          type="info" effect="solid"/> */}
                      </Link>
                    </td>
                    <td
                      className="px-4 py-4 pt-5 whitespace-nowrap
                    text-sm text-gray-500 hide_mobile">
                      <div className="dropdown">
                        <MdArrowForward
                          data-tip='Dokument weiterführen'
                          size="1.3rem"></MdArrowForward>
                        {/* <ReactTooltip place="top"
                          type="info" effect="solid"/> */}
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            // Get current Document Tags
                            // and auto Tagging Settings
                            const tags = doc.tags;
                            const settings = teamDoc.auto_tagging;
                            // If Auto Tagging on Download is set, push
                            // Tag to array (if it isn't already in there)
                            if (settings && settings.onContinue) {
                              !tags.includes(settings.onContinue) &&
                                tags.push(settings.onContinue);
                            }
                            // Save to DB
                            updateDoc(currentTeam.id, 'documents', doc.id, {
                              ...doc,
                              tags: tags,
                            }, setRerender, isOffline)
                              .catch((e) => {
                                addToast('error', `Fehler beim Weiterführen
                               des Dokuments`);
                                return console.log(e);
                              });
                          }}
                          className=" dropdown-content z-10
                         origin-top-right absolute
                         right-0 top-3 w-48 rounded-md shadow-lg bg-white
                          ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {nextType(doc).map((el) => (
                            <Link
                              key={el}
                              to={{
                                pathname: '/documents/input',
                                state: {
                                  docData: {
                                    ...doc,
                                    ancestor: doc.id,
                                    created_date: null,
                                    document_created_date: Date.now(),
                                    id: null,
                                    type: { select: el }
                                  }
                                },
                              }}
                              className='flex justify-start
                                     px-4 py-2 w-full text-sm
                                      hover:bg-gray-100'>{el}
                            </Link>
                          ))}
                          {doc.type.name === 'Rechnung' ? (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                updateDoc(currentTeam.id, 'documents', doc.id, {
                                  ...doc,
                                  canceled: true,
                                }, setRerender, isOffline)
                                  .catch((e) => {
                                    addToast('error', `Fehler
                                 beim Stornieren des Dokuments`);
                                    return console.log(e);
                                  });
                              }}
                              className='flex justify-start
                                     px-4 py-2 w-full text-sm
                                      hover:bg-gray-100'>Storno
                            </button>
                          ) : ''}

                          {reminders(doc)}

                        </div>
                      </div>
                    </td>
                    <td className='hide_mobile'></td>
                    <td
                      className="px-4 py-4 pt-5 whitespace-nowrap
                    text-sm text-gray-500 hide_mobile">
                      <div className="dropdown">
                        <MdFileDownload data-tip='Dokument herunterladen'
                          size="1.3rem"></MdFileDownload>
                        {/* <ReactTooltip place="top"
                          type="info" effect="solid"/> */}
                        <div className=" dropdown-content z-10
                         origin-top-right absolute
                         right-0 top-3 w-48 rounded-md shadow-lg bg-white
                          ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <button
                            // Add Auto Tag
                            onClick={(e) => {
                              e.stopPropagation();
                              dataToPdf(doc,
                                teamDoc
                                  .show_sender_address || false,
                                currentTeam.id)
                                .then((blob) => {
                                  const a = document.createElement('a');

                                  a.href = blob;
                                  a.download =
                                    `${doc.type.name} ${doc.type.abbreviation} ${doc.type.number} `;
                                  document.body.appendChild(a);
                                  // we need to append
                                  // the element to the dom ->
                                  // otherwise it will not work in firefox
                                  a.click();
                                  a.remove();
                                  // afterwards we remove the element again
                                });
                              // Get current Document Tags
                              // and auto Tagging Settings
                              const tags = doc.tags;
                              const settings = teamDoc.auto_tagging;
                              // If Auto Tagging on Download is set, push
                              // Tag to array (if it isn't already in there)
                              if (settings && settings.onDownloadBlank) {
                                !tags.includes(settings.onDownloadBlank) &&
                                  tags.push(settings.onDownloadBlank);
                              }
                              // Save to DB
                              updateDoc(currentTeam.id, 'documents',
                                doc.id, { ...doc, tags: tags },
                                setRerender, isOffline)
                                .catch((e) => {
                                  addToast('error', `Fehler
                                     beim Herunterladen`);
                                  return console.log(e);
                                });
                            }}
                            className='flex justify-start px-4 py-2 w-full
                            text-sm hover:bg-gray-100'>
                            Blank
                          </button>
                          <button
                            // Add Auto Tag
                            onClick={(e) => {
                              e.stopPropagation();

                              // PDF Server
                              dataToPdf(doc,
                                teamDoc
                                  .show_sender_address || false,
                                currentTeam.id,
                                teamDoc.external_letter_paper_url)
                                .then((blob) => {
                                  const a = document.createElement('a');

                                  a.href = blob;
                                  a.download =
                                    `${doc.type.name} ${doc.type.abbreviation} ${doc.type.number} `;
                                  document.body.appendChild(a);
                                  // we need to append
                                  // the element to the dom ->
                                  // otherwise it will not work in firefox
                                  a.click();
                                  a.remove();
                                  // afterwards we remove the element again
                                });


                              // Get current Document Tags
                              // and auto Tagging Settings
                              const tags = doc.tags;
                              const settings =
                                teamDoc.auto_tagging;
                              // If Auto Tagging on Download is set, push
                              // Tag to array (if it isn't already in there)
                              if (settings && settings.onDownloadLetterPaper) {
                                !tags.includes(settings
                                  .onDownloadLetterPaper) &&
                                  tags.push(settings.onDownloadLetterPaper);
                              }
                              // Save to DB
                              updateDoc(currentTeam.id, 'documents',
                                doc.id, { ...doc, tags: tags },
                                setRerender, isOffline)
                                .catch((e) => {
                                  addToast('error', `Fehler
                                     beim Herunterladen`);
                                  return console.log(e);
                                });
                            }}
                            className='flex justify-start px-4 py-2 w-full
                            text-sm hover:bg-gray-100'>
                            Briefpapier
                          </button>

                          {/* <button className='flex
                           justify-start px-4 py-2 w-full
                           text-sm
                            hover:bg-gray-100'>Briefpapier + Anhang</button> */}
                        </div>
                      </div>
                    </td>
                    <td
                      className="px-4 py-4 pt-3 whitespace-nowrap
                    text-sm text-gray-500 hide_mobile">
                      <Link
                        to={{
                          pathname: '/documents/input',
                          state: {
                            docData: { ...doc, id: doc.id },
                            heading: 'Dokument bearbeiten'
                          },
                        }}
                        className="">
                        <MdEdit
                          data-tip='Dokument bearbeiten'
                          size="1.3rem" />
                        {/* <ReactTooltip place="top"
                          type="info" effect="solid"/> */}
                      </Link>
                    </td>
                    <td
                      className="px-4 py-4 whitespace-nowrap
                    text-sm text-gray-500 hide_mobile">
                      <button
                        className=''
                        onClick={() => {
                          setModalConfirm({
                            data: {
                              title: 'Dokument löschen',
                              text: 'Sie sind dabei, ein Dokument zu löschen. Möchten Sie fortfahren?',
                              buttonText: 'Löschen'
                            },
                            open: true,
                            func: async () => {
                              await deleteDoc(
                                currentTeam.id,
                                'documents',
                                doc.id,
                                setRerender,
                              );
                            }

                          })
                        }}
                      >
                        <MdDelete size="1.3rem" />
                      </button>
                    </td>
                    {/* Mobile Menu */}
                    <td className="show_mobile">
                      <Menu as="div" className="relative inline-block">
                        <div className="p-1 h-full w-full">
                          <div className='flex-center h-full w-full z-10'>
                            <Menu.Button>
                              <MdMoreVert size='1.3rem'></MdMoreVert>
                            </Menu.Button>
                          </div>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute
             top-8 -left-36 mt-2 w-40 text-black z-20
         rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
          focus:outline-none">
                            <div className="p-2 flex flex-col">


                              <Menu.Item>
                                <Menu as="div" className="relative
                                 inline-block">

                                  <Menu.Button className='w-full
                                   items-center py-2 text-sm font-medium
                                    flex space-x-1'>
                                    <MdKeyboardArrowLeft size='1.3rem'>

                                    </MdKeyboardArrowLeft>
                                    Herunterladen
                                  </Menu.Button>

                                  <Menu.Items className="origin-top-right
                                   absolute
             top-0 -left-36 mt-2 w-32 text-black z-20
         rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
          focus:outline-none">
                                    <div className="p-2 flex flex-col">
                                      <button
                                        // Add Auto Tag
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          dataToPdf(doc,
                                            teamDoc
                                              .show_sender_address || false,
                                            currentTeam.id)
                                            .then((blob) => {
                                              const a =
                                                document.createElement('a');

                                              a.href = blob;
                                              a.download =
                                                `${doc.type.name} ${doc.type.abbreviation} ${doc.type.number} `;
                                              document.body.appendChild(a);
                                              // we need to append
                                              // the element to the dom ->
                                              // otherwise
                                              // it will not work in firefox
                                              a.click();
                                              a.remove();
                                              // afterwards
                                              // we remove the element again
                                            });
                                          // Get current Document Tags
                                          // and auto Tagging Settings
                                          const tags = doc.tags;
                                          const settings =
                                            teamDoc.auto_tagging;
                                          // If Auto Tagging
                                          // on Download is set, push
                                          // Tag to array
                                          // (if it isn't already in there)
                                          if (settings &&
                                            settings.onDownloadBlank) {
                                            !tags.includes(settings
                                              .onDownloadBlank) &&
                                              tags.push(settings.onDownloadBlank);
                                          }
                                          // Save to DB
                                          updateDoc(currentTeam.id, 'documents',
                                            doc.id, { ...doc, tags: tags },
                                            setRerender, isOffline)
                                            .catch((e) => {
                                              addToast('error', `Fehler beim
                                                Herunterladen`);
                                              return console.log(e);
                                            });
                                        }}
                                        className='flex justify-start
                                     px-4 py-2 w-full text-sm
                                      hover:bg-gray-100'>
                                        Blank
                                      </button>
                                      <button
                                        // Add Auto Tag
                                        onClick={(e) => {
                                          e.stopPropagation();

                                          // PDF Server
                                          dataToPdf(doc,
                                            teamDoc
                                              .show_sender_address || false,
                                            currentTeam.id,
                                            teamDoc
                                              .external_letter_paper_url)
                                            .then((blob) => {
                                              const a =
                                                document.createElement('a');

                                              a.href = blob;
                                              a.download =
                                                `${doc.type.name} ${doc.type.abbreviation} ${doc.type.number} `;
                                              document.body.appendChild(a);
                                              // we need to append
                                              // the element to the dom ->
                                              // otherwise it will
                                              // not work in firefox
                                              a.click();
                                              a.remove();
                                              // afterwards we
                                              // remove the element again
                                            });


                                          // Get current Document Tags
                                          // and auto Tagging Settings
                                          const tags = doc.tags;
                                          const settings =
                                            teamDoc.auto_tagging;
                                          // If Auto Tagging on
                                          // Download is set, push
                                          // Tag to array
                                          // (if it isn't already in there)
                                          if (settings &&
                                            settings.onDownloadLetterPaper) {
                                            !tags.includes(settings
                                              .onDownloadLetterPaper) &&
                                              tags.push(settings.onDownloadLetterPaper);
                                          }
                                          // Save to DB
                                          updateDoc(currentTeam.id, 'documents',
                                            doc.id, { ...doc, tags: tags },
                                            setRerender, isOffline)
                                            .catch((e) => {
                                              addToast('error', `Fehler beim
                                                Herunterladen`);
                                              return console.log(e);
                                            });
                                        }}
                                        className='flex justify-start
                                         px-4 py-2 w-full
                            text-sm hover:bg-gray-100'>
                                        Briefpapier
                                      </button>
                                    </div>
                                  </Menu.Items>
                                </Menu>
                              </Menu.Item>
                              <Menu.Item>
                                <Menu as="div" className="relative
                                 inline-block">

                                  <Menu.Button className='w-full
                                   items-center py-2 text-sm font-medium
                                    flex space-x-1'>
                                    <MdKeyboardArrowLeft size='1.3rem'>

                                    </MdKeyboardArrowLeft>
                                    Weiterführen
                                  </Menu.Button>

                                  <Menu.Items className="origin-top-right
                                   absolute
             top-0 -left-36 mt-2 w-32 text-black z-20
         rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
          focus:outline-none">
                                    <div className="p-2 flex flex-col">
                                      {nextType(doc).map((el) => (
                                        <Link
                                          key={el}
                                          to={{
                                            pathname: '/documents/input',
                                            state: {
                                              docData: {
                                                ...doc,
                                                ancestor: doc.id,
                                                created_date: null,
                                                id: null,
                                                type: { select: el }
                                              }
                                            },
                                          }}
                                          className='flex justify-start
                                     px-4 py-2 w-full text-sm
                                      hover:bg-gray-100'>{el}
                                        </Link>
                                      ))}

                                      {doc.type.name === 'Rechnung' ? (
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            updateDoc(currentTeam.id, 'documents', doc.id, {
                                              ...doc,
                                              canceled: true,
                                            }, setRerender, isOffline)
                                              .catch((e) => {
                                                addToast('error', `Fehler beim Stornieren`);
                                                return console.log(e);
                                              });
                                          }}
                                          className='flex justify-start
                            px-4 py-2
                     text-sm rounded-md
                      hover:bg-gray-50'>Storno
                                        </button>
                                      ) : ''}
                                      {reminders(doc, `flex justify-start
                                       px-4 py-2
                                text-sm rounded-md
                                 hover:bg-gray-50`)}
                                    </div>
                                  </Menu.Items>
                                </Menu>
                              </Menu.Item>

                              <Menu.Item>
                                <Link
                                  to={{
                                    pathname: '/documents/input',
                                    state: {
                                      docData: {
                                        ...doc,
                                        ancestor: doc.id,
                                        created_date: null,
                                        id: null,
                                        type: {},
                                        internal_nr: null,
                                        reductions: [],
                                      }
                                    },
                                  }}
                                  className='flex justify-start px-5 py-2 w-full
                                text-sm rounded-md font-medium
                                 hover:bg-gray-50'>
                                  Duplizieren
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <Link
                                  to={{
                                    pathname: '/documents/input',
                                    state: {
                                      docData: { ...doc, id: doc.id },
                                      heading: 'Dokument bearbeiten'
                                    },
                                  }}
                                  className='flex justify-start px-5 py-2 w-full
                                text-sm rounded-md font-medium
                                 hover:bg-gray-50'>
                                  Bearbeiten
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <button
                                  className='flex justify-start px-5 py-2 w-full
                              text-sm rounded-md font-medium hover:bg-gray-50
                               opacity-50'
                                  onClick={() => {
                                    setModalConfirm({
                                      data: {
                                        title: 'Dokument löschen',
                                        text: 'Sie sind dabei, ein Dokument zu löschen. Möchten Sie fortfahren?',
                                        buttonText: 'Löschen'
                                      },
                                      open: true,
                                      func: async () => {
                                        await deleteDoc(
                                          currentTeam.id,
                                          'documents',
                                          doc.id,
                                          setRerender,
                                        );
                                      }

                                    })
                                  }}
                                >
                                  Löschen
                                </button>
                              </Menu.Item>


                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </td>
                  </tr>
                ) : (
                  <tr
                    key={doc.id}
                    className={
                      'flex-center py-4 ' +
                      (!(index % 2 === 0) ? 'bg-gray-100' : '')
                    }>
                    <td>Nicht lesbar.</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

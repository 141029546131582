import React, {useContext, useState, useEffect} from 'react';
// import PropTypes from 'prop-types';

import {Link} from 'react-router-dom';
import {MdClose} from 'react-icons/md';
import {numberFormatter, addLogEntry} from '../utilities';
import PropTypes from 'prop-types';
import ItemsDD from '../dropdowns/ItemsDD';
import {AuthContext} from '../context/AuthContext';
import {RerenderContext, updateDoc} from '../cud';
// import Localbase from 'localbase';
import Localbase from 'localbase';


/**
 *
 * @return {object} jsx
 */
export default function ManualInOut({history}) {
  const [rerender, setRerender] = useContext(RerenderContext);

  const [items, setItems] = useState([]);

  const {teamC, userC, isOfflineC, toastsC} = useContext(AuthContext);
  const currentTeam = teamC[0];
  const currentUser = userC[0];
  const isOffline = isOfflineC[0];
  const addToast = toastsC[1];

  const [selectedItem, setSelectedItem] = useState();
  const [selectedItems, setSelectedItems] = useState([]);

  const [isSaving, setIsSaving] = useState(false);

  const [stocks, setStocks] = useState({});

  // Load Data
  useEffect(async function() {
    if (currentTeam) {
      const lb = new Localbase(currentTeam.id);
      // lb.config.debug = false;

      const items = await lb.collection('items').get();
      setItems(items);
    }
  }, [rerender]);


  useEffect(function() {
    const buffer = selectedItems;
    console.log(selectedItem);
    if (selectedItem && selectedItem.id && !buffer.includes(selectedItem.id)) {
      buffer.push(selectedItem.id);
      setSelectedItems([...buffer]);
      setSelectedItem('');
    }
  }, [selectedItem]);


  /**
   * Handles Stock Input
   * @param {string} id item Id
   * @param {object} e Event
   */
  function handleStockInput(id, e) {
    const stockBuffer = stocks;
    stockBuffer[id] = Number(e.target.value);
    console.log({...stockBuffer});
    setStocks({...stockBuffer});
  }

  /**
   * Handles Submit
   * @param {string} mode
   */
  async function book(mode) {
    setIsSaving(true);
    for (const [key, value] of Object.entries(stocks)) {
      const data = items.find((el) => el.id === key);
      const stockBuffer = data.stock;
      let newStock;
      // Add Or Subtract Stock
      if (mode === 'in') {
        newStock = stockBuffer + value;
      } else {
        newStock = stockBuffer - value;
      }
      // Save New Stock to db
      try {
        await updateDoc(currentTeam.id, 'items', key,
            {...data, stock: newStock}, setRerender, isOffline);
      } catch (e) {
        addToast('error', `Fehler: ${data.name || 'Artikel'}
         konnte nicht gebucht werden`);
      }
    };
    addToast('success', 'Buchung erfolgreich');

    // Add Log Entry
    addLogEntry(currentTeam.id, 'manual_bookings',
        currentUser.uid,
        {action: mode === 'in' ?
        'eingebucht' : 'ausgebucht', number: '',
        name: `${Object.keys(stocks).length} Artikel`,
        details: {items: stocks}}, setRerender, isOffline);


    setIsSaving(false);
    history.push('/warehouse');
  }

  return (
    <div className="h-screen bg-gray-100 flex flex-col fill_container">
      <div className="pb-5 border-b border-gray-200 p-8 flex justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Manuelle Ein/Ausbuchung
        </h3>
        <Link to="/warehouse">
          <div className="flex-center text-gray-900">
            <MdClose size="2rem"></MdClose>
          </div>
        </Link>
      </div>
      <div className="overflow-y-auto flex-grow-1">
        <div className="space-y-6 p-2 md:p-8">
          <div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Artikel hinzufügen
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Fügen Sie hier alle Artikel zur Buchung hinzu.
                   Danach können Sie die Buchungsmengen eingeben.
                </p>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-6">

                  <div className='col-span-6 md:col-span-4 md:col-start-2'>
                    <ItemsDD filterFunc={(buffer) => {
                      return buffer.filter((buf) =>
                        !selectedItems.includes(buf.id));
                    }} disableCreation={true}
                    selectedItemC={[selectedItem, setSelectedItem]}
                    showDescription={true}>
                    </ItemsDD>
                  </div>


                </div>
              </div>
            </div>
          </div>

          {/* <div className="bg-white shadow px-4 py-5 rounded-lg
           sm:p-6 divide-y divide-gray-200 "> */}
          <div
            className="shadow overflow-auto h-full
          border-b border-gray-200 rounded-lg">
            <table className="min-w-full divide-y
               divide-gray-200 table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  <th style={{width: '20%'}}
                    scope="col" className="table_header">
                  Name
                  </th>
                  <th style={{width: '30%'}}
                    scope="col" className="table_header hide_mobile">
                  Beschreibung
                  </th>
                  <th style={{width: '10%'}}
                    scope="col" className="table_header hide_mobile">
                  Preis
                  </th>
                  <th style={{width: '10%'}}
                    scope="col" className="table_header">
                  Bestand
                  </th>
                  <th style={{width: '30%'}}
                    scope="col" className="table_header">
                  Buchung
                  </th>
                  {/* <th scope="col" className="table_header"></th>
                    <th scope="col" className="table_header"></th> */}
                </tr>
              </thead>
              <tbody className='bg-white'>
                {selectedItems.map((el, index) => {
                  const item = items.find((item) => item.id === el);
                  return (
                    <tr
                      key={item.id}
                      className={' ' + (!(index % 2 === 0) ?
       'bg-gray-100' : '')}>
                      <td className="px-3 md:px-6 py-4 whitespace-nowrap">
                        <p
                          className="text-sm font-medium
            text-gray-900">
                          {`${item.name}`}
                        </p>
                      </td>

                      <td className="px-3 md:px-6 py-4
                       whitespace-nowrap hide_mobile">
                        <p
                          className="text-sm
            text-gray-900">
                          {`${item.product_description}`}
                        </p>
                      </td>

                      <td className="px-3 md:px-6 py-4
                       whitespace-nowrap hide_mobile">
                        {item.retail_price &&
        <p
          className="text-sm
            text-gray-900">
          {`${numberFormatter(item.retail_price)}€`}
        </p>
                        }
                      </td>

                      <td className="px-3 md:px-6 py-4 whitespace-nowrap">
                        <p
                          className="text-sm font-medium
            text-gray-900">
                          {`${item.stock}`}
                        </p>
                      </td>

                      <td
                        className="px-3 md:px-6 py-4 whitespace-nowrap
      text-sm text-gray-500">
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="number"
                            name="weight"
                            onInput={(e) => handleStockInput(item.id, e)}
                            className="focus:ring-blue-500 focus:border-blue-500
        block w-full md:pr-9 sm:text-sm
        border-gray-300 rounded-md"
                          />
                          <div
                            className="absolute inset-y-0 right-0 pr-3 flex
      items-center pointer-events-none hide_mobile">
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="price-currency">
                              {item.unit}
                            </span>
                          </div>
                        </div>
                      </td>


                    </tr>
                  );
                } )
                }
              </tbody>
            </table>
          </div>
          {/* </div> */}

          <div className="flex justify-center md:justify-end">
            <Link
              to="/warehouse"
              className="bg-white py-2 px-4 border border-gray-300
          rounded-md shadow-sm text-sm font-medium text-gray-700
          hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2
          focus:ring-blue-500">
              Abbrechen
            </Link>
            <button
              disabled={isSaving}
              onClick={() => book('out')}
              className="ml-3 inline-flex justify-center py-2 px-4 border
          border-transparent shadow-sm text-sm font-medium rounded-md
          text-white bg-red-500 hover:bg-red-600 focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
              Ausbuchen
            </button>
            <button
              disabled={isSaving}
              onClick={() => book('in')}
              className="ml-3 inline-flex justify-center py-2 px-4 border
          border-transparent shadow-sm text-sm font-medium rounded-md
          text-white bg-blue-600 hover:bg-blue-700 focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Einbuchen
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

ManualInOut.propTypes = {
  history: PropTypes.object,
};

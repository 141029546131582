import Localbase from 'localbase';
import { fs } from '../../firebase';
import { millsToDate, numberFormatter } from '../../utilities';

/**
   * Generates dynamic html
   * @param {object} data
   * @param {string} letterPaper
   * @param {string} teamId
   * @return {string} HTML
   *
   */
export async function html(data, letterPaper, teamId) {
  const lb = new Localbase(teamId);
  // lb.config.debug = false;
  const contacts = await lb.collection('contacts').get();
  let memberships = await fs.collection('teams')
    .doc(String(teamId)).collection('memberships').get();
  memberships = memberships.docs.map((el) => el.data());
  // get documet type for template Id
  const documentType = await lb.collection('document_types')
    .doc({ id: data.type.id }).get();
  // fetch template from lb
  const templateString = await lb.collection('document_templates')
    .doc({ id: documentType.template }).get();
  let returnString = templateString.template;
  // templateBlocks
  const templateBlocks = {
    name: data.type.name,
    name2: data.type.name,
    letterPaper: letterPaper,
    isCanceled: data.canceled ? (
      '<img id="canceled_icon" src="https://firebasestorage.googleapis.com/v0/b/warest-1f6da.appspot.com/o/storno.png?alt=media&token=cc35c5cc-cc55-4d98-bdd3-52d719d4e7d6" alt=""></img>'
    ) : '',
    krans: (() => {
      const a = data.sender_address;
      if (!a) return '';
      let buffer = '';

      a.salutation && (buffer += `${a.salutation}`);
      a.name && (buffer += ` ${a.name}`);
      a.name_2 && (buffer += ` ${a.name_2}`);
      a.address && (buffer += `, ${a.address}`);
      a.address_2 && (buffer += ` ${a.address_2}`);
      a.postal_code && (buffer += `, ${a.postal_code}`);
      a.city && (buffer += ` ${a.city}`);

      return buffer;
    })(),
    billing_address: (() => {
      const a = data.billing_address;
      if (!a) return '';
      let buffer = '';

      a.salutation && (buffer += `${a.salutation}<br>`);
      a.name && (buffer += ` ${a.name}<br>`);
      a.name_2 && (buffer += ` ${a.name_2}<br>`);
      a.address && (buffer += ` ${a.address}<br>`);
      a.address_2 && (buffer += ` ${a.address_2}<br>`);
      a.postal_code && (buffer += ` ${a.postal_code} `);
      a.city && (buffer += ` ${a.city}`);

      return buffer;
    })(),
    contact: (() => {
      if (!contacts) return '';
      const c = contacts.find((contact) => contact.id === data.contact);
      return c.number ?
        (`<td><b>Kundennr.</b><br />${c.number}</td>`) :
        (`<td></td>`);
    })(),
    type_number: (() => {
      return (`<td><b>Beleg Nr.</b><br />${data.type.number}</td>`);
    })(),
    payment_conditions_schmidt: (() => {
      return (`<td><b>Zahlungsbed.</b><br />${data.payment_conditions}</td>`);
    })(),
    public_name: (() => {
      const o = memberships.find((member) =>
        member.id === data.official_in_charge);
      return o && o.public_name ?
        (`<td><b>Bearbeiter</b><br />${o.public_name}</td>`) :
        (`<td></td>`);
    })(),
    executor: (() => {
      const o = memberships.find((member) =>
        member.id === data.official_in_charge);
      return o && o.public_name ?
        (`<td><b>Ausgeführt</b><br />${o.public_name}</td>`) :
        (`<td></td>`);
    })(),
    public_contact: (() => {
      const o = memberships.find((member) =>
        member.id === data.official_in_charge);
      return o && o.public_contact ?
        (`<td><b>Kontakt</b><br />${o.public_contact}</td>`) :
        (`<td></td>`);
    })(),
    created_date: (() => {
      return (`<td><b>Datum</b><br />${millsToDate(data.document_created_date || data.created_date)}</td>`);
    })(),
    vat_nr: (() => {
      if (!contacts) return '';
      const c = contacts.find((contact) => contact.id === data.contact);
      return c.vat_nr ?
        (`<td><b>USt. ID</b><br />${c.vat_nr}</td>`) :
        (``);
    })(),
    valid_until_date: (() => {
      return data.valid_until_date ?
        (`<td><b>Gültig bis</b><br />${millsToDate(data.valid_until_date)}</td>`) :
        (``);
    })(),
    delivery_date: (() => {
      return data.delivery_date ?
        (`<td><b>Leistungsdatum</b><br />${millsToDate(data.delivery_date)}</td>`) :
        (``);
    })(),
    external_order_nr: (() => {
      return data.external_order_nr ?
        (`<td><b>Bestellnr.</b><br />${data.external_order_nr}</td>`) :
        (``);
    })(),
    delivery_address: (() => {
      const a = data.delivery_address;
      if (!a || Object.keys(a).length === 0) return '';
      let buffer = `<b>Lieferadresse:</b> `;

      a.salutation && (buffer += `${a.salutation}`);
      a.name && (buffer += ` ${a.name}`);
      a.name_2 && (buffer += ` ${a.name_2}`);
      a.address && (buffer += `, ${a.address}`);
      a.address_2 && (buffer += ` ${a.address_2}`);
      a.postal_code && (buffer += `, ${a.postal_code}`);
      a.city && (buffer += ` ${a.city}`);

      return buffer;
    })(),
    delivery_schmidt: (() => {
      const a = data.delivery_address;
      if (!a || Object.keys(a).length === 0) return '';
      let buffer = ``;

      a.salutation && (buffer += `${a.salutation}`);
      a.name && (buffer += ` ${a.name}`);
      a.name_2 && (buffer += ` ${a.name_2}`);
      a.address && (buffer += `,<br> ${a.address}`);
      a.address_2 && (buffer += ` ${a.address_2}`);
      a.postal_code && (buffer += `, ${a.postal_code}`);
      a.city && (buffer += ` ${a.city}`);

      return buffer;
    })(),
    abbreviation: data.type.abbreviation,
    number: data.type.number,
    comment_1: data.comment_1,
    indexes: (() => {
      // Re Calculate Indexes
      let indexCounter = 0;
      for (const [index, position] of data.positions.entries()) {
        if (position !== 'break-page') {
          data.positions[index].index = indexCounter;
          indexCounter++;
        }
      }

      let buffer = '';
      for (const position of data.positions) {
        if (position === 'break-page') {
          buffer += `<tr class='break_row'></tr>`;
          continue;
        } else {
          buffer += (
            `<tr class=${position.title.includes('[Seitenumbruch]') &&
            'break_row'} >
                  <td>${position.index || position.index === 0 ?
              position.index + 1 : ''}</td>
                  <td style='text-align: right;'>${position.amount}</td>
                  <td> ${position.unit}</td>
                  <td><b>${position.title.replace('[Seitenumbruch]', '')}</b></td>
                  <td style='text-align: right;'>${numberFormatter(position.price)}</td>
                  <td style='text-align: right;'>${position.discount}</td>
                  <td>${numberFormatter(position.total_price)}</td>
                </tr>`
          );
        }
        const descriptionBuffer = position.description.split(/\n/);
        const descriptionLines = [];

        // Check for Manual Page Breaks in Description
        descriptionBuffer.forEach((line) => {
          descriptionLines.push(line.replace('[Seitenumbruch]', ''));
          if (line.includes('[Seitenumbruch]')) {
            descriptionLines.push('[break]');
          }
        });

        for (const line of descriptionLines) {
          if (line == '[break]') {
            buffer += `<tr class='break_row'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
              </tr>`;
          } else {
            buffer += `
                  <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>${line}</td>
                  <td></td>
                  <td></td>
                  <td></td>
              </tr>`;
          }
        }
      }
      return buffer;
    }


    )(),
    positionsSchmidtBestellung: (() => {
      // Re Calculate Indexes

      let buffer = '';
      for (const position of data.positions) {
        if (position === 'break-page') {
          buffer += `<tr class='break_row'></tr>`;
          continue;
        } else {
          buffer += (
            `<tr>
              <td>${position.code || ''}</td>
              <td><b>${position.title}</b></td>
              <td style='text-align: center;'>${position.description}</td>
              <td style='text-align: center;'>${position.amount}</td>
              <td style='text-align: center;'></td>
              <td style='text-align: right;'></td>
                </tr>`
          );
        }
      }
      return buffer;
    }
    )(),
    positionsSchmidt: (() => {
      // Re Calculate Indexes

      let buffer = '';
      for (const position of data.positions) {
        if (position === 'break-page') {
          buffer += `<tr class='break_row'></tr>`;
          continue;
        } else {
          buffer += (
            `<tr>
              <td>${position.code || ''}</td>
              <td><b>${position.title}</b></td>
              <td style='text-align: center;'>${position.description}</td>
              <td style='text-align: center;'>${position.amount}</td>
              <td style='text-align: center;'>${numberFormatter(position.price)}</td>
              <td style='text-align: right;'>${numberFormatter(position.total_price)}</td>
                </tr>`
          );
        }
      }
      return buffer;
    }
    )(),
    
    discount_percentage: data.total_discount_percentage ?

      `<tr id="last" class="border-top">
          <td></td>
          <td></td>
          <td></td>
          <td>Summe:</td>
          <td></td>
          <td></td>
          <td>${numberFormatter(data.positions_total)}</td>
      </tr>

      <tr>
          <td></td>
          <td></td>
          <td></td>
          <td>${data.total_discount_type || 'Rabatt'} ${numberFormatter(data.total_discount_percentage)}%</td>
          <td></td>
          <td></td>
          <td>${numberFormatter(data.total_discount_amount)}</td>
      </tr>` :
      `<tr></tr>`,
    tax_incl: data.tax_incl ?
      `<tr id="last" class="border-top border-bottom">
      <td></td>
      <td></td>
      <td></td>
      <td><b>Gesamtbetrag (enthält USt. ${numberFormatter(
        data.total_tax_amount)}€ (${data.total_tax_split &&
        Object.keys(data.total_tax_split)
          .map((el, index) => {
            if (!index || index ===
              Object.keys(data
                .total_tax_split)
                .length - 1) return `${el}%`;
            else return `${el}%/`;
          })}):</b></td>
      <td></td>
      <td></td>

      <td>${numberFormatter(data.gross_total)}€</td>
  </tr>` :
      `<tr id="last" class="border-top">
  <td></td>
  <td></td>
  <td></td>
  <td>Positionen netto:</td>
  <td></td>
  <td></td>
  <td>${numberFormatter(data.net_total)}€</td>
</tr>

${(() => {
        let buffer = '';
        for (const [key, value] of Object.entries(data.total_tax_split)) {
          buffer += `<tr>
      <td></td>
      <td></td>
      <td></td>
      <td>Positionen USt. ${numberFormatter(key)}%</td>
      <td></td>
      <td></td>
      <td>${numberFormatter(value)}€</td>
  </tr>`;
        }
        return buffer;
      })()}


<tr id="last" class="border-bottom">
  <td></td>
  <td></td>
  <td></td>
  <td><b>Gesamtbetrag:</b></td>
  <td></td>
  <td></td>

  <td>${numberFormatter(data.gross_total)}€</td>
</tr>`,
    fliesen_huebner_tax_incl: data.tax_incl ?
      `<tr id="last" class="border-top border-bottom">
      <td></td>
      <td></td>
      <td></td>
      <td><b>Gesamtbetrag (enthält USt. ${numberFormatter(
        data.total_tax_amount)}€ (${data.total_tax_split &&
        Object.keys(data.total_tax_split)
          .map((el, index) => {
            if (!index || index ===
              Object.keys(data
                .total_tax_split)
                .length - 1) return `${el}%`;
            else return `${el}%/`;
          })}):</b></td>
      <td></td>
      <td></td>

      <td>${numberFormatter(data.gross_total)}€</td>
  </tr>` :
      `<tr id="last" class="border-top">
  <td></td>
  <td></td>
  <td></td>
  <td>Positionen netto:</td>
  <td></td>
  <td></td>
  <td>${numberFormatter(data.net_total)}€</td>
</tr>

${(() => {
        let buffer = '';
        for (const [key, value] of Object.entries(data.total_tax_split)) {
          buffer += `<tr>
      <td></td>
      <td></td>
      <td></td>
      <td>Positionen USt. ${numberFormatter(key)}%</td>
      <td></td>
      <td></td>
      <td>${numberFormatter(value)}€</td>
  </tr>`;
        }
        return buffer;
      })()}


<tr id="last" class="border-bottom">
  <td></td>
  <td></td>
  <td></td>
  <td><b>Gesamtbetrag ${!data.total_tax_amount ? 'nach §13b UStG:' : ':'}</b></td>
  <td></td>
  <td></td>

  <td>${numberFormatter(data.gross_total)}€</td>
</tr>`,
    end_block_schmidt: `<div id='end_block_schmidt'>
    ${data.total_discount_percentage ?

        `
      <div>
        <p>Summe:</p>
        <p>${numberFormatter(data.positions_total)}€</p>
      </div>
      <div>
        <p>${data.total_discount_type || 'Rabatt'} ${numberFormatter(data.total_discount_percentage)}%:</p>
        <p>-${numberFormatter(data.total_discount_amount)}€</p>
      </div>

` : ``}${data.tax_incl ?
        `
    
    <div>
    <p><b>Gesamtbetrag (enthält USt. ${numberFormatter(
          data.total_tax_amount)}€ (${data.total_tax_split &&
          Object.keys(data.total_tax_split)
            .map((el, index) => {
              if (!index || index ===
                Object.keys(data
                  .total_tax_split)
                  .length - 1) return `${el}%`;
              else return `${el}%/`;
            })}):</b></p>
      <p><b>${numberFormatter(data.gross_total)}€</b></p>
    </div>

` :
        `
  <div>
    <p>Positionen netto:</p>
    <p>${numberFormatter(data.net_total)}€</p>
  </div>

${(() => {
          let buffer = '';
          for (const [key, value] of Object.entries(data.total_tax_split)) {
            buffer += `
<div>
  <p>Positionen USt. ${numberFormatter(key)}%:</p>
  <p>${numberFormatter(value)}€</p>
</div>
`;
          }
          return buffer;
        })()}

<div>
<p><b>Gesamtbetrag:</b></p>
<p><b>${numberFormatter(data.gross_total)}</b>€</p>
</div>

`}</div>`,

    end_block_text_schmidt: `
<div id="bottom-block-text">
  <p>Der Gesamtbetrag setzt sich wie folgt zusammen: ${(() => {
        let buffer = '';
        for (const [key, value] of Object.entries(data.total_tax_split)) {
          buffer += ` EUR ${numberFormatter(value)} zu MwSt ${numberFormatter(key)}% auf EUR ${numberFormatter(data.net_total)} netto.
`;
        }
        return buffer;
      })()}</p>
</div>
`,

    data_reductions: data.reductions && data.reductions.length > 0 ?
      `<table id="reductions">
    <colgroup>
        <col style='width: 60%;' />
        <col style='width: 10%;' />
        <col style='width: 15%;' />
        <col style='width: 15%;' />
    </colgroup>
    <thead class="table_header">
        <th>Abzgl. bereits erhalten:</th>
        <th>Netto</th>
        <th>USt.</th>
        <th style='text-align: right;'>Brutto</th>
    </thead>
    <tbody>
        ${data.reductions.map((r) => {
        //   if (!documents) return '';
        //   const r =
        //       documents.find((document) => document.id === id);
        return (
          `<tr>
                <td>${`${r.type.name} ${r.type.abbreviation}${r.type.number}`} vom ${r.document_created_date ?
            millsToDate(r.document_created_date) :
            millsToDate(r.created_date)}</td>
                <td>${numberFormatter(r.net_total)}€</td>
                <td>${numberFormatter(
              r.total_tax_amount)}€ (${r.total_tax_split &&
              Object.keys(r.total_tax_split)
                .map((el, index) => {
                  if (!index || index ===
                    Object.keys(r
                      .total_tax_split)
                      .length - 1) return `${el}%`;
                  else return `${el}%/`;
                })})</td>
                <td style='text-align: right;'>${numberFormatter(r.gross_total)}€</td>
            </tr>`
        );
      })}

        <tr id="last" class="border-top border-bottom
         grey-background">
            <td>Forderungsbetrag (enthält USt. ${numberFormatter(data
        .total_tax_amount_after_reductions)}€):</td>
            <td></td>
            <td></td>
            <td style='text-align: right;'>${numberFormatter(
          data.gross_total_after_reductions)}€</td>
        </tr>
    </tbody>
</table>`: '',
    payment_conditions: data.payment_conditions ?
      '<b>Zahlungsbedingungen:</b>' + ' ' +
      data.payment_conditions : '',
    delivery_condition: data.delivery_conditions ?
      '<b>Lieferbedingungen:</b>' +
      ' ' + data.delivery_conditions : '',
    document_comment: data.contact.document_comment || '',
    comment_2: data.comment_2 || '',

  };
  // this function searches for evey string with the
  // type templateBlocks.TEMPLATE-KEY eg: templateBlocks.name
  // which is used in <title>templateBlocks.name</title>
  // and replaces it with the key above i.e :
  // <title>Rechnung</title> ; similar to your idea :)
  // Limitation : Each key must pe unique (to be fixed latter)
  Object.keys(templateBlocks).forEach((key) => {
    returnString = returnString.replace('templateBlocks.' + key,
      templateBlocks[key]);
  });
  //
  if (data && data.type) {
    return returnString;
  } else {
    console.log('nicht Lesbar');
    return '<p>Nicht Lesbar.</p>';
  }
}


import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {AuthContext} from '../context/AuthContext';
import {analyseNkProblems} from '../utilities';

/**
 *
 * @param {array} showC Show or hide Modal
 * @return {object} jsx
 */
export default function NkProblems({showC}) {
  const [show, setShow] = showC;

  const {nkProblemsC} = useContext(AuthContext);
  const [nkProblems, setNkProblems] = nkProblemsC;

  /**
     * Closes Modal
     */
  function close() {
    setShow(false);
  }

  return show ? (
        <button
          onClick={(e) => {
            if (e.nativeEvent.pointerType === 'mouse') {
              close();
            }
          }}
          style={{display: show ? 'block' : 'none'}}
          className="w-full h-full absolute right-0 bg-black
           p-32 overflow-hidden
          z-50 bg-opacity-50 xl:px-96">
          <div
            className="bg-white shadow overflow-hidden sm:rounded-lg
            h-full cursor-default flex flex-col"
            onClick={function(e) {
              e.stopPropagation();
            }}>
            <div className='flex justify-between p-4'>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Nummernkreisprobleme
              </h3>
              <button
                onClick={() => {
                  analyseNkProblems(setNkProblems);
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 4v5h.582m15.356 2A8.001 8.001
                   0 004.582 9m0 0H9m11 11v-5h-.581m0
                    0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
              </button>
            </div>

            <div style={{gridTemplateRows: '100%'}}
              className="grid grid-cols-2 p-12 gap-12 flex-shrink
             overflow-hidden">
              <div className=''>
                <h1>Mehrfach vorhanden</h1>

                <div className='flex flex-col space-y-2 h-full
               overflow-auto p-1'>
                  {nkProblems
                      .filter((p) => p.documents.length)
                      .map((p, i) => {
                        return (
                          <div key={i}
                            className='rounded-md bg-red-100 flex px-8 py-2
                            justify-between hover:bg-red-200 text-red-500'>
                            <p>{p.number}</p>
                          </div>
                        );
                      })}
                </div>
              </div>

              <div>
                <h1>Lücken</h1>

                <div className='flex flex-col space-y-2 flex-grow
               overflow-auto p-1'>
                  {nkProblems
                      .filter((p) => !p.documents.length)
                      .map((p, i) => {
                        return (
                          <div key={i}
                            className='rounded-md bg-red-100 flex px-8 py-2
                            justify-between hover:bg-red-200 text-red-500'>
                            <p>{p.number}</p>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>

          </div>
        </button>
    ) : '';
}

NkProblems.propTypes = {
  showC: PropTypes.array,
};

import React, {useContext} from 'react';
import logoWhite from './assets/img/logo_white.png';
import {Link, useLocation} from 'react-router-dom';
import {
  MdInbox,
  MdInsertDriveFile,
  MdGroup,
  MdAddCircleOutline,
  MdSettings,
  MdHelp,
  MdExitToApp,
  MdList,
  MdCompareArrows,
} from 'react-icons/md';
import {AuthContext, logout} from './context/AuthContext';
import ReactTooltip from 'react-tooltip';

/**
 * sidebar element containing all
 * navigation elements for the app
 * @return {object} jsx
 */
export default function Sidebar() {
  const {pathname} = useLocation();

  const {isOfflineC, isSyncingC} = useContext(AuthContext);
  const isOffline = isOfflineC[0];
  const isSyncing = isSyncingC[0];
  return (
    <div id='sidebar' style={{display: pathname === '/' ||
     pathname === '/register' ? 'none' : ''}}
    className="h-screen bg-color_1 w-16 flex flex-col
     text-white hidden md:flex">
      <Link
        to="/dashboard"
        className="w-full h-16
        flex-center flex-shrink-0"
        onClick={(e) => isSyncing && e.preventDefault()}>
        <img alt="" src={logoWhite} className="w-8/12 h-8/12"></img>
      </Link>

      <div id='sections' className="w-full grid bg-blue-500 rounded-md">

        <Link to="/warehouse" className='flex-center w-16 h-16'
          data-tip="Warenlager"
          onClick={(e) => isSyncing && e.preventDefault()}>
          <MdInbox className={`${
            pathname === '/warehouse' ?'bg-blue-400 rounded-md' : ''}
             w-12 h-12 p-3`}/>
        </Link>
        <ReactTooltip place="right" type="dark" effect="solid"/>

        <Link to="/documents" className='flex-center w-16 h-16'
          data-tip="Dokumente"
          onClick={(e) => isSyncing && e.preventDefault()}>
          <MdInsertDriveFile className={`${
            pathname === '/documents' ?'bg-blue-400 rounded-md' : ''}
            w-12 h-12 p-3`}/>
        </Link>
        <ReactTooltip place="right" type="dark" effect="solid"
        />

        <Link to="/contacts" className='flex-center w-16 h-16'
          data-tip="Kontakte"
          onClick={(e) => isSyncing && e.preventDefault()}>
          <MdGroup className={`${
            pathname ==='/contacts' ?'bg-blue-400 rounded-md' : ''}
            w-12 h-12 p-3`} />
        </Link>
        <ReactTooltip place="right" type="dark" effect="solid"
        />
      </div>

      <div className="flex flex-col justify-between h-full">
        <div className="tools_navigation">
          {pathname === '/warehouse' && (
            <Link
              to="/warehouse/input">
              <MdAddCircleOutline className="w-16 h-16 p-5"
                data-tip="Artikel hinzufügen"/>
              <ReactTooltip place="right" type="dark" effect="solid"/>
            </Link>

          )}
          {pathname === '/warehouse' && (

            <Link
              to="/warehouse/inventory">
              <MdList className="w-16 h-16 p-5"
                data-tip="Inventur starten"/>
              <ReactTooltip place="right" type="dark" effect="solid"/>
            </Link>
          )}
          {pathname === '/warehouse' && (

            <Link
              to="/warehouse/manual">
              <MdCompareArrows className="w-16 h-16 p-5"
                data-tip="Ein/Ausbuchung"/>
              <ReactTooltip place="right" type="dark" effect="solid"/>
            </Link>
          )}

          {pathname === '/contacts' && (
            <Link
              to="/contacts/input">
              <MdAddCircleOutline className="w-16 h-16 p-5"
                data-tip="Kontakt hinzufügen"/>
              <ReactTooltip place="right" type="dark" effect="solid"
              />
            </Link>
          )}

          {pathname === '/documents' && (
            <Link
              to="/documents/input">
              <MdAddCircleOutline className="w-16 h-16 p-5"
                data-tip="Dokument hinzufügen"/>
              <ReactTooltip place="right" type="dark" effect="solid"
              />
            </Link>
          )}
        </div>

        <div className="flex flex-col" id='nav'>
          <Link to="/support" className="cursor-pointer
           flex-center w-16 h-16"
          onClick={(e) => isSyncing && e.preventDefault()}>
            <MdHelp className='bg-red-500 rounded-md w-12 h-12 p-3'
              data-tip="Hilfe"/>
            <ReactTooltip place="right" type="dark" effect="solid"
            />
          </Link>

          <Link

            className="cursor-pointer"
            to="/settings/account"
            onClick={(e) => isSyncing && e.preventDefault()}>
            <MdSettings className={`${pathname === '/settings/account'?
              'bg-blue-lighter' : ''} w-16 h-16 p-5`}
            data-tip="Einstellungen"/>
            <ReactTooltip place="right" type="dark" effect="solid"
            />
          </Link>

          <button
            onClick={() => !isOffline && !isSyncing && logout()}>
            <MdExitToApp className="w-16 h-16 p-5 bg-blue-light"
              data-tip="Logout"/>
            <ReactTooltip place="right" type="dark" effect="solid"
            />
          </button>
        </div>
      </div>
    </div>
  );
}

/** Items Component */
import React, { Fragment, useContext, useState, useEffect, useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';
// import ReactTooltip from 'react-tooltip';

// import {AuthContext} from '../context/AuthContext';
import { Link } from 'react-router-dom';
import ShowItem from './ShowItem';
import {
  limitString,
  millsToDate,
  lowStock,
  numberFormatter,
  getSearchResults,
  valArr,
} from '../utilities';
import {
  MdDelete, MdEdit, MdSearch, MdClose,
  MdFilterList, MdWarning, MdContentCopy, MdMoreVert, MdKeyboardArrowLeft
} from 'react-icons/md';
import { RerenderContext, deleteDoc, updateDoc } from '../cud';
// import Localbase from 'localbase';
import { AuthContext } from '../context/AuthContext';
import Localbase from 'localbase';
import ModalConfirm from '../ModalConfirm';



/**
 *
 * @return {object} jsx
 */
export default function Items() {
  const [rerender, setRerender] = useContext(RerenderContext);

  const { teamC, isOfflineC } = useContext(AuthContext);
  const currentTeam = teamC[0];
  const isOffline = isOfflineC[0]

  const [items, setItems] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [inventories, setInventories] = useState([]);

  const [displayItems, setDisplayItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [activeFilter, setActiveFilter] = useState([]);
  const [activeSearch, setActiveSearch] = useState({ value: '' });

  const [modalConfirm, setModalConfirm] = useState({});

  const searchInputRef = useRef(null);
  const multiSelectWarehouseRef = useRef(null);

  // const {teamC} = useContext(AuthContext);
  // const currentTeam = teamC[0];
  // const isOffline = isOfflineC[0];

  const [showItem, setShowItem] = useState({ display: 'none', itemData: {} });

  const [notifications, setNotifications] = useState([]);

  // Load Data
  useEffect(async function () {
    if (currentTeam) {
      const lb = new Localbase(currentTeam.id);
      // lb.config.debug = false;
      const items = await lb.collection('items').get();
      valArr(items) && setItems(items);

      const warehouses = await lb.collection('warehouses').get();
      valArr(warehouses) && setWarehouses(warehouses);

      const inventories = await lb.collection('inventories').get();
      valArr(inventories) && setInventories(inventories);
    }
  }, [rerender, currentTeam]);

  // Init saved Search String
  useEffect(function () {
    // Fetch Saved Searches from Database
    const savedSearches = JSON.parse(localStorage.getItem('savedSearches'));
    // if saved string exists, handle search with it
    if (savedSearches && savedSearches.items) {
      handleSearchInput(savedSearches.items);
      searchInputRef.current.value = savedSearches.items;
    }
  }, []);

  // Update Notifications
  useEffect(function () {
    const openInventories = [];
    for (const inventory of inventories) {
      if (inventory.ongoing) {
        const warehouse = warehouses && warehouses.find((el) =>
          el.id === inventory.warehouse);
        openInventories.push({
          short_title: `Aktive Inventur: ${warehouse ?
            warehouse.name : ''}!`,
          title: `Aktive Inventur: ${warehouse ?
            warehouse.name : ''}.
             Alle Bestandsänderungen in diesem Lager werden
             beim Abschluss der Inventur überschrieben!`,
          type: 'warning',
        });
      }
    }
    setNotifications([
      ...openInventories,
    ]);
  }, [inventories]);

  useEffect(
    async function () {
      if (currentTeam) {
        const buffer = {};
        console.log('---rerendering items---');
        const lb = new Localbase(currentTeam.id);
        // lb.config.debug = false;

        // Load Docs from LB
        let items = await lb.collection('items').get();
        const indexDoc = await lb.collection('search-indexes')
          .doc('items').get();
        const index = indexDoc && indexDoc.index;

        // Search
        if (activeSearch.value.length > 0) {
          items = activeSearch.filter(items, index);
        }

        // Filter out deleted Docs
        items = items.filter((el) => !el.deleted);

        // Reorder to group Article Variations
        for (const item of items) {
          if (!buffer[item.code]) {
            // Init Group Object
            buffer[item.code] = {
              created_date: 0,
              items: [item],
            };
          } else {
            buffer[item.code].items.push(item);
          }

          if (buffer[item.code].created_date <
            item.created_date) {
            buffer[item.code].created_date = item.created_date;
          }
        }


        // Sort Array of groups after created date
        const groupBuffer = Object.values(buffer).sort((a, b) => {
          function maxNum(array) {
            return Math.max.apply(Math, array);
          }
          const sortPreset = JSON.parse(localStorage.getItem('sortPresets'));
          if (sortPreset && sortPreset.items) {
            return maxNum(b.items.map((el) => el[sortPreset.items])) - maxNum(a.items.map((el) => el[sortPreset.items]));
          }
          return b['created_date'] - a['created_date'];
        });
        let buffer3 = [];

        // Add Background Colors
        let counter = 0;
        for (const el of Object.values(groupBuffer)) {
          if ((counter % 2) != 0) {
            el.items = el.items
              .map((el) => ({ ...el, background: 'bg-gray-100' }));
          } else {
            el.items = el.items
              .map((el) => ({ ...el, background: 'bg-white' }));
          }

          counter++;
        }

        for (const group of groupBuffer) {
          buffer3.push(...group.items);
        }

        // Apply Filters
        for (const filter of activeFilter) {
          buffer3 = filter.filter(buffer3);
        }

        // Limit Length to 500
        buffer3 = buffer3.filter((el, i) => i <= 500);

        setDisplayItems(buffer3);
      }
    },
    [rerender, activeFilter, activeSearch, currentTeam],
  );

  /**
 * Searches Items
 * @param {string} input
 */
  function handleSearchInput(input) {
    // Save Search String to Local Storage for persistance
    const savedSearches = JSON.parse(localStorage.getItem('savedSearches'));
    localStorage.setItem('savedSearches', JSON.stringify({
      ...savedSearches,
      items: input,
    }));

    setActiveSearch({
      value: input,
      filter: function (docs, index) {
        return getSearchResults(input, docs, index);
      },
    });
  }

  return (
    <>
      {/* Confirm Modal */}
      <ModalConfirm stateC={[modalConfirm, setModalConfirm]} />
      <ShowItem showItemC={[showItem, setShowItem]}></ShowItem>
      <div className="flex flex-col p-4 bg-gray-100 flex-grow h-full">

        {notifications.map((el, index) => (

          <div key={index} className="bg-red-100 text-red 500 rounded-md mb-4">
            <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
              <div className="flex-center flex-wrap">
                <div className="w-0 flex-1 flex-center">
                  <MdWarning className="h-6 w-6 text-red-800"
                    aria-hidden="true" />

                  <p className="ml-3 font-medium text-red-800 truncate">
                    <span className="md:hidden">{el.short_title} f
                    </span>
                    <span className="hidden md:inline">{el.title}</span>
                  </p>
                </div>
                {/* <button type="button">
                  <MdClose className="h-6 w-6 text-red-800"></MdClose>
                </button> */}
              </div>
            </div>
          </div>
        ))}


        <div className="w-full pb-4 flex space-x-2 md:justify-between">
          <div className="w-96 shrink-0">
            {/* <label
            htmlFor="search"
            className="block text-sm font-medium text-gray-700">
            Quick search
          </label> */}
            <div className="relative flex items-center">
              <div
                className="absolute inset-y-0 left-0 pl-3 flex
            items-center pointer-events-none"
                aria-hidden="true">
                <MdSearch
                  className="mr-3 h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                ref={searchInputRef}
                onKeyDown={(e) => {
                  e.keyCode === 13 && handleSearchInput(e.target.value);
                }}
                onInput={(e) => {
                  if (!e.target.value) handleSearchInput('');
                }}
                type="text"
                name="search"
                placeholder="Suche"
                id="search"
                className="shadow-sm focus:ring-blue-500
              focus:border-blue-500 block w-full pr-12 pl-9
              sm:text-sm border-gray-300 rounded-md"
              />
              <div
                className="absolute inset-y-0 right-0 flex
            py-1.5 pr-1.5 hide_mobile">
                <kbd
                  className="inline-flex items-center border
              border-gray-200 rounded px-2 text-sm font-sans
              font-medium text-gray-400">
                  ⌘K
                </kbd>
              </div>
            </div>
          </div>
          <div className={Object.keys(selectedItems).length ? 'flex space-x-4' : 'hidden'}>
            <button onClick={async () => {
              for (const id of Object.keys(selectedItems)) {
                await deleteDoc(
                  currentTeam.id,
                  'items',
                  id,
                  setRerender,
                );
              }
              setSelectedItems({})
            }} className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-red-700
             bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'>Ausgewählte Löschen</button>
            <select className='block pl-3 pr-10 py-2 text-base
             border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md' ref={multiSelectWarehouseRef}>
              {warehouses.map((warehouse) => (
                <option value={warehouse.id}>{warehouse.name}</option>
              ))}
            </select>
            <button onClick={async () => {
              for (const [id, item] of Object.entries(selectedItems)) {
                await updateDoc(currentTeam.id, 'items', id, {
                  ...item,
                  warehouse: multiSelectWarehouseRef.current.value,
                }, setRerender, isOffline);
              }
              setSelectedItems({})
            }} className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700
            bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>Ausgewählte Umbuchen</button>
          </div>

          <div className="flex space-x-2">
            <Menu as="div" className="relative inline-block z-20 text-left">
              <div
                className={`relative items-center px-4 py-2
             rounded-md border border-gray-300 bg-white text-sm
             font-medium  focus:z-10 h-full
              focus:outline-none focus:ring-1 focus:ring-blue-500
               focus:border-blue-500 flex space-x-2
                text-gray-700 hover:bg-gray-50`}
              >
                <Menu.Button className='flex-center'>
                  <p className='mr-2 font-medium
                  hide_mobile'>
                    Sortieren
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round"
                      strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                  </svg>
                </Menu.Button>
              </div>


              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right
                 absolute right-0 mt-2 w-56
         rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
          focus:outline-none">
                  <div className="p-2">
                    <Menu.Item>
                      {({ active }) => (
                        <button className={`flex justify-start px-4 py-2 w-full
                      text-sm rounded-md font-medium hover:bg-gray-50`}
                          onClick={() => {
                            const sortPresets =
                              JSON.parse(localStorage.getItem('sortPresets'));
                            localStorage.setItem('sortPresets', JSON.stringify({
                              ...sortPresets,
                              items: 'stock',
                            }));
                            setRerender('');
                            setRerender('items');
                          }}>
                          Bestand</button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button className={`flex justify-start px-4 py-2 w-full
                      text-sm rounded-md font-medium hover:bg-gray-50`}
                          onClick={() => {
                            const sortPresets =
                              JSON.parse(localStorage.getItem('sortPresets'));
                            localStorage.setItem('sortPresets', JSON.stringify({
                              ...sortPresets,
                              items: 'created_date',
                            }));
                            setRerender('');
                            setRerender('items');
                          }}>
                          Erstellungsdatum</button>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <button className={`flex justify-start px-4 py-2 w-full
                      text-sm rounded-md font-medium hover:bg-gray-50`}
                          onClick={() => {
                            const sortPresets =
                              JSON.parse(localStorage.getItem('sortPresets'));
                            localStorage.setItem('sortPresets', JSON.stringify({
                              ...sortPresets,
                              items: 'updated_date',
                            }));

                            setRerender('');
                            setRerender('items');
                          }}>
                          Bearbeitungsdatum</button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            <Menu as="div" className="relative inline-block text-left">
              <div
                className={`relative items-center px-4 py-2
             rounded-md border border-gray-300 bg-white text-sm
             font-medium  focus:z-30 h-full
              focus:outline-none focus:ring-1 focus:ring-blue-500
               focus:border-blue-500 flex space-x-2
               ${activeFilter.length > 0 ?
                    'bg-blue-500 text-blue-100 hover:bg-blue-600' :
                    'text-gray-700 hover:bg-gray-50'}`}
              >
                <Menu.Button className='flex'>
                  <p className='mr-2 font-medium
                  hide_mobile'>{activeFilter.length > 0 ?
                      'Filter aktiv' :
                      'Filtern'}
                  </p>

                  <button
                    className='flex-center'
                    onClick={(e) => {
                      if (activeFilter.length > 0) {
                        e.stopPropagation();
                        setActiveFilter([]);
                      }
                    }}>
                    {activeFilter.length > 0 ?
                      <MdClose size='1.3rem'></MdClose> :
                      <MdFilterList size='1.3rem'></MdFilterList>
                    }
                  </button>
                </Menu.Button>
              </div>


              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute
                 right-0 mt-2 w-56
         rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-30
          focus:outline-none">
                  <div className="p-2">
                    <Menu.Item>

                      <Menu as="div" className="relative
           inline-block">

                        <Menu.Button className='w-full
             items-center py-2 text-sm font-medium
              flex space-x-1'>
                          <MdKeyboardArrowLeft size='1.3rem'>

                          </MdKeyboardArrowLeft>
                          Lager
                        </Menu.Button>

                        <Menu.Items className="origin-top-right
             absolute
top-0 right-full mt-2 text-black z-20
rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
focus:outline-none">
                          <div className="p-2 flex flex-col">
                            {warehouses.map((warehouse) => (
                              <button key={warehouse.id}
                                className={`flex items-center justify-start
        space-x-2 px-4 py-2 w-full text-sm rounded-md text-left
         font-medium ${activeFilter.map((el) =>
                                  el.type).includes(`warehouse:${warehouse.id}`) ?
                                    'bg-blue-500 hover:bg-blue-600 text-white' :
                                    'hover:bg-gray-50'}`}
                                onClick={() => {
                                  // Save Filter State to Buffer
                                  const buffer = activeFilter;
                                  // Remove old Filter with same Type
                                  const oldFilter = buffer.findIndex((el) =>
                                    el.type.includes(`warehouse`));
                                  if (oldFilter !== -1) {
                                    buffer.splice(oldFilter, 1);
                                  };
                                  // Add New filter to filter array
                                  buffer.push({
                                    type: `warehouse:${warehouse.id}`,
                                    filter: function (buffer) {
                                      return buffer.filter((doc, index) => {
                                        return doc.warehouse === warehouse.id;
                                      });
                                    },
                                  });
                                  // Set Filter State
                                  setActiveFilter([...buffer]);
                                }}>
                                <p>{warehouse.name}</p></button>
                            ))}
                          </div>
                        </Menu.Items>
                      </Menu>
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button className={`flex justify-start px-4 py-2 w-full
                      text-sm rounded-md font-medium ${activeFilter.map((el) =>
                          el.type).includes('stock_warning') ?
                            'bg-blue-500 hover:bg-blue-600 text-white' :
                            'hover:bg-gray-50'}`}
                          onClick={() => {
                            // Save Filter State to Buffer
                            const buffer = activeFilter;
                            // Remove old Filter with same Type
                            const oldFilter = buffer.findIndex((el) =>
                              el.type === 'stock_warning');
                            if (oldFilter !== -1) buffer.splice(oldFilter, 1);
                            // Add New filter to filter array
                            buffer.push({
                              type: 'stock_warning',
                              filter: function (buffer) {
                                return buffer.filter((doc, index) =>
                                  lowStock(doc));
                              },
                            });
                            // Set Filter State
                            setActiveFilter([...buffer]);
                          }}>
                          Bestandswarnungen</button>


                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div className='flex flex-col px-4 py-2'>
                          <div className="flex items-center
                           justify-between py-2">
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium
                               text-gray-700">
                              Von
                            </label>

                            <input
                              value={(() => {
                                const value = activeFilter.find((el) =>
                                  el.type === 'start_date');
                                if (value) return value.value;
                                else return '';
                              })()}
                              onClick={(e) => e.stopPropagation()}
                              onInput={(e) => {
                                const buffer = activeFilter;
                                const oldFilter = buffer.findIndex((el) =>
                                  el.type === 'start_date');
                                if (oldFilter !== -1) {
                                  buffer.splice(oldFilter, 1);
                                }
                                buffer.push({
                                  value: e.target.value,
                                  type: 'start_date',
                                  filter: function (buffer) {
                                    return buffer.filter((doc, index) => {
                                      const date = new Date(e.target.value)
                                        .getTime();
                                      // With Time Correction
                                      return doc.created_date >=
                                        date - 7200000;
                                    });
                                  },
                                });
                                setActiveFilter([...buffer]);
                              }}
                              type="date"
                              name="start_date"
                              className="m-1 focus:ring-blue-500
                                  focus:border-blue-500 block w-36
                                  shadow-sm sm:text-sm
                                   border-gray-300 rounded-md"
                            />
                          </div>
                          <div className="flex items-center justify-between
                           py-2">
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium
                               text-gray-700">
                              Bis
                            </label>

                            <input
                              value={(() => {
                                const value = activeFilter.find((el) =>
                                  el.type === 'end_date');
                                if (value) return value.value;
                                else return '';
                              })()}
                              onClick={(e) => e.stopPropagation()}
                              onInput={(e) => {
                                const buffer = activeFilter;
                                const oldFilter = buffer.findIndex((el) =>
                                  el.type === 'end_date');
                                if (oldFilter !== -1) {
                                  buffer
                                    .splice(oldFilter, 1);
                                }
                                buffer.push({
                                  value: e.target.value,
                                  type: 'end_date',
                                  filter: function (buffer) {
                                    return buffer.filter((doc, index) => {
                                      const date = new Date(e.target.value)
                                        .getTime();
                                      // With Time Correction
                                      return doc.created_date <=
                                        date + 79200000;
                                    });
                                  },
                                });
                                setActiveFilter([...buffer]);
                              }}
                              type="date"
                              name="end_date"
                              className="m-1 focus:ring-blue-500
                                  focus:border-blue-500 block w-36
                                  shadow-sm sm:text-sm
                                   border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      )}
                    </Menu.Item>

                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>

        </div>
        <div
          className="shadow overflow-auto flex-grow
          border-b border-gray-200 rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 table-fixed">
            <thead className="bg-gray-50">
              <tr className='hide_mobile'>
                <th scope="col" className="table_header">

                </th>
                <th scope="col" className="table_header">
                  Name
                </th>
                <th scope="col" className="table_header">
                  Beschreibung
                </th>
                <th scope="col" className="table_header">
                  Preis
                </th>
                <th scope="col" className="table_header">
                  Bestand
                </th>
                <th scope="col" className="table_header">
                  Lagerplatz
                </th>
                <th scope="col" className="table_header">
                  Erst./Bearb.
                </th>
                <th scope="col" className="table_header"></th>
                <th scope="col" className="table_header"></th>
                <th scope="col" className="table_header"></th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 ">
              {displayItems.map((doc, index) => {
                return doc.code ? (
                  <tr
                    key={doc.id}
                    // className={(() => {
                    //   let countBuffer;
                    //   if (displayItems[index-1] &&
                    //      displayItems[index-1].code ===
                    // doc.code) {
                    //     countBuffer = index-1;
                    //   } else countBuffer = index;
                    //   console.log(displayItems[index-1] &&
                    //      displayItems[index-1].code === doc.code,
                    //   countBuffer);
                    //   return !(countBuffer % 2 === 0) ? 'bg-gray-100' : '';
                    // })()}>
                    className={(() => {
                      let buffer = selectedItems[doc.id] ? 'bg-blue-200 ' : '';
                      if (!doc.synced) {
                        buffer += 'unsynced ';
                      }

                      // Add Background that has been set in Groups
                      buffer += doc.background;

                      return buffer;
                    })()}
                  >
                    <td>
                      <div className="flex items-center justify-center flex-shrink min-w-0">
                        <input
                          onInput={(e) => {
                            if (!selectedItems[doc.id]) {
                              // select current Item
                              const selIt = selectedItems;
                              selIt[doc.id] = doc;
                              setSelectedItems({ ...selIt });
                            } else {
                              // Unselect current Item
                              const selIt = selectedItems;
                              delete selIt[doc.id]
                              setSelectedItems({ ...selIt });
                            }
                          }}
                          checked={selectedItems[doc.id]}
                          type="checkbox"
                          className="focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                    </td>
                    <td className="px-2 md:px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div
                          className="flex flex-shrink-0 h-10 w-10 items-center
                        justify-center md:hidden">
                          <span
                            className={`md:hidden ${lowStock(doc) ?
                              'small_tag bg-red-100 text-red-800' :
                              'small_tag bg-green-100 text-green-800'}`
                            }>
                            {numberFormatter(doc.stock, false)}
                          </span>
                        </div>
                        <button
                          data-tip='Artikeldetails ansehen'
                          onClick={function () {
                            setShowItem({
                              display: 'flex',
                              itemData: items
                                .find((el) => el.id === doc.id)
                              ,
                            });
                          }}
                          className="ml-4">
                          <div
                            className="text-sm font-medium
                          text-gray-900 text-left">
                            {`${limitString(doc.name, 50)}`}
                          </div>
                          <div
                            className="text-sm
                          text-gray-500 text-left">
                            {`Artikelcode: ${doc.code}`}
                          </div>
                        </button>
                        {/* <ReactTooltip place="top"
                       type="info" effect="solid"/> */}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap hide_mobile">
                      <div
                        className="text-sm
                      text-gray-900">
                        {limitString(doc.product_description, 50)}
                      </div>
                      {/* <div
                      className="text-sm
                      text-gray-500">
                      {`Kunde ${doc.max_stock}`}
                    </div> */}
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap
                        text-sm text-gray-500 hide_mobile">
                      {doc.retail_price ?
                        `${numberFormatter(doc.retail_price)}€` : ''}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap hide_mobile">
                      <span
                        className={
                          lowStock(doc) ?
                            'tag bg-red-100 text-red-800' :
                            'tag bg-green-100 text-green-800'
                        }>
                        {numberFormatter(doc.stock, false)}
                      </span>
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap
                    text-sm text-gray-500 hide_mobile">
                      {warehouses
                        .filter(function (warehouse) {
                          return warehouse.id === doc.warehouse;
                        })
                        .map(function (el) {
                          return <p key={el.id}>{el.name}</p>;
                        })}
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap
                    text-sm text-gray-500 hide_mobile">
                      {millsToDate(doc.created_date)} <br />
                      ({millsToDate(doc.updated_date)})
                    </td>
                    <td className="show_mobile">
                      <Menu as="div" className="relative inline-block">
                        <div className="p-1 h-full w-full">
                          <div className='flex-center h-full w-full z-10'>
                            <Menu.Button>
                              <MdMoreVert size='1.3rem'></MdMoreVert>
                            </Menu.Button>
                          </div>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute
             top-8 -left-48 mt-2 w-56 text-black z-20
         rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
          focus:outline-none">
                            <div className="p-2 flex flex-col">


                              <Menu.Item>
                                <Link
                                  className='flex justify-start px-4 py-2 w-full
                                text-sm rounded-md font-medium hover:bg-gray-50'
                                  to={{
                                    pathname: '/warehouse/input',
                                    state: {
                                      docData: {
                                        ...doc,
                                        id: null,
                                        created_date: null
                                      }, variation: true
                                    },
                                  }}>
                                  Variation hinzufügen
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <Link
                                  className='flex justify-start px-4 py-2 w-full
                              text-sm rounded-md font-medium hover:bg-gray-50'
                                  to={{
                                    pathname: '/warehouse/input',
                                    state: {
                                      docData: { ...doc, id: doc.id },
                                      heading: 'Artikel bearbeiten'
                                    },
                                  }}>
                                  Artikel bearbeiten
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <button
                                  className='flex justify-start px-4 py-2 w-full
                              text-sm rounded-md font-medium hover:bg-gray-50
                              '
                                  onClick={() => {
                                    setModalConfirm({
                                      data: {
                                        title: 'Artikel löschen',
                                        text: 'Sie sind dabei, einen Artikel zu löschen. Möchten Sie fortfahren?',
                                        buttonText: 'Löschen'
                                      },
                                      open: true,
                                      func: async () => {
                                        await deleteDoc(
                                          currentTeam.id,
                                          'items',
                                          doc.id,
                                          setRerender,
                                        );
                                      }

                                    })
                                  }}
                                >
                                  Löschen
                                </button>
                              </Menu.Item>


                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </td>
                    <td
                      className="px-4 py-4 whitespace-nowrap
                    text-sm text-gray-500 hide_mobile">
                      <Link
                        to={{
                          pathname: '/warehouse/input',
                          state: {
                            docData: {
                              ...doc,
                              id: null,
                              created_date: null
                            }, variation: true
                          },
                        }}
                        className="">
                        <MdContentCopy data-tip='Variation hinzufügen'
                          size="1.3rem" />
                        {/* <ReactTooltip place="top"
                       type="info" effect="solid"/> */}
                      </Link>
                    </td>
                    <td
                      className="px-4 py-4 whitespace-nowrap
                    text-sm text-gray-500 hide_mobile">
                      <Link
                        to={{
                          pathname: '/warehouse/input',
                          state: {
                            docData: { ...doc, id: doc.id },
                            heading: 'Artikel bearbeiten'
                          },
                        }}
                        className="">
                        <MdEdit data-tip='Artikel bearbeiten'
                          size="1.3rem" />
                        {/* <ReactTooltip place="top"
                       type="info" effect="solid"/> */}
                      </Link>
                    </td>
                    <td
                      className="px-4 py-4 whitespace-nowrap
                    text-sm text-gray-500 hide_mobile">
                      <button
                        // className='opacity-50'
                        onClick={() => {
                          setModalConfirm({
                            data: {
                              title: 'Artikel löschen',
                              text: 'Sie sind dabei, einen Artikel zu löschen. Möchten Sie fortfahren?',
                              buttonText: 'Löschen'
                            },
                            open: true,
                            func: async () => {
                              await deleteDoc(
                                currentTeam.id,
                                'items',
                                doc.id,
                                setRerender,
                              );
                            }

                          })
                        }}
                      >
                        <MdDelete size="1.3rem" />
                      </button>
                    </td>
                  </tr>
                ) : (
                  <tr
                    key={doc.id}
                    className={
                      'flex-center py-4 ' +
                      (!(index % 2 === 0) ? 'bg-gray-100' : '')
                    }>
                    <td>Nicht lesbar.</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

import React, {useContext} from 'react';
import {MdWarning} from 'react-icons/md';
import {AuthContext} from './context/AuthContext';

/**
 *
 * @return {object} JSX
 */
export default function Notifications() {
  const {isOfflineC} = useContext(AuthContext);
  const isOffline = isOfflineC[0];
  return (
    <div
      style={{display: isOffline ? 'block': 'none'}}
      className="bg-red-100 text-red 500 rounded-md mx-4 mt-4">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex justify-center items-center">
            <MdWarning className="h-6 w-6 text-red-800"
              aria-hidden="true" />

            <p className="ml-3 font-medium text-red-800 truncate">
              <span className="md:hidden">Offline Modus
              </span>
              <span className="hidden md:inline">Keine Internetverbindung
               verfügbar. Änderungen werden nicht synchronisiert.</span>
            </p>
          </div>
          {/* <button type="button">
            <MdClose className="h-6 w-6 text-red-800"></MdClose>
          </button> */}
        </div>
      </div>
    </div>
  );
}

/** Shows Item Details */
import React from 'react';
import PropTypes from 'prop-types';
import {MdClose} from 'react-icons/md';


/**
 *
 * @return {object} jsx
 */
export default function PositionModal({showPositionDetailsC,
  documentData, handleChange}) {
  const [showPositionDetails, setShowPositionDetails] = showPositionDetailsC;
  const data = documentData && documentData.positions &&
   documentData.positions[showPositionDetails] ?
   documentData.positions[showPositionDetails] : {};


  return (
    <button
      onClick={function(e) {
        if (e.nativeEvent.pointerType === 'mouse') {
          setShowPositionDetails();
        }
      }}
      style={{display: showPositionDetails || showPositionDetails === 0 ?
         'block' : 'none'}}
      className="fill_container absolute right-0 bg-black
       p-0 md:p-16 overflow-hidden
      z-50 bg-opacity-50 xl:px-40">
      <div
        className="bg-white shadow overflow-hidden sm:rounded-lg
        h-full cursor-default"
        onClick={function(e) {
          e.stopPropagation();
        }}>
        <div className="px-4 py-5 sm:px-6 h-20 flex justify-between">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {data.title}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {/* {`${contact.number}`}{' '} */}
            {/* <span
              className={`px-2 inline-flex text-xs leading-5
                      font-semibold rounded-full ${
                        contact.type === 'Lieferant' ?
                          'bg-yellow-100 text-yellow-800' :
                          'bg-green-100 text-green-800'
    }`}> */}
            {/* {contact.type || 'Kunde'} */}
            {/* </span> */}
          </p>
          <button
            onClick={function() {
              setShowPositionDetails('');
            }}>
            <MdClose size='2rem'></MdClose>
          </button>
        </div>
        <div
          style={{height: 'calc(100% - 5rem)'}}
          className="border-t border-gray-200 text-left">
          <dl className="overflow-y-auto h-full">
            <div
              className="bg-gray-100 px-6 py-5 grid
                grid-cols-3 sm:gap-4 sm:px-6">
              <dt
                className="text-sm font-medium
                  text-gray-500">
                Menge
              </dt>
              <dd
                className="text-right text-sm text-gray-900
                  sm:mt-0 col-span-2">
                <input
                  className="shadow-sm focus:ring-indigo-500
                focus:border-indigo-500 block w-full pr-12
                sm:text-sm border-gray-300 rounded-md"
                  onInput={(e) => handleChange(showPositionDetails,
                      'amount', e)}
                  value={data.amount}
                  type="number" />
              </dd>
            </div>
            <div
              className="px-6 py-5 grid
                grid-cols-3 sm:gap-4 sm:px-6">
              <dt
                className="text-sm font-medium
                  text-gray-500">
                Einheit
              </dt>
              <dd
                className="text-right text-sm text-gray-900
                  sm:mt-0 col-span-2">
                <input
                  className="shadow-sm focus:ring-indigo-500
                focus:border-indigo-500 block w-full pr-12
                sm:text-sm border-gray-300 rounded-md"
                  onInput={(e) => handleChange(showPositionDetails,
                      'unit', e)}
                  value={data.unit}
                  type="text" />
              </dd>
            </div>

            <div
              className="bg-gray-100 px-6 py-5 grid
                grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Gewicht</dt>
              <dd
                className="text-right text-sm text-gray-900 flex-center
                 overflow-x-auto sm:mt-0 col-span-2">
                <input
                  className="shadow-sm focus:ring-indigo-500
                focus:border-indigo-500 block w-full pr-12
                sm:text-sm border-gray-300 rounded-md"
                  onInput={(e) => handleChange(showPositionDetails,
                      'weight', e)}
                  value={data.weight}
                  type="number" />
              </dd>
            </div>
            <div
              className="bg-white px-6 py-5 grid
                grid-cols-3 sm:gap-4 sm:px-6">
              <dt
                className="text-sm font-medium
                  text-gray-500">
                Preis
              </dt>
              <dd
                className="text-right text-sm text-gray-900
                  sm:mt-0 col-span-2">
                <input
                  className="shadow-sm focus:ring-indigo-500
                focus:border-indigo-500 block w-full pr-12
                sm:text-sm border-gray-300 rounded-md"
                  onInput={(e) => handleChange(showPositionDetails,
                      'price', e)}
                  value={data.price}
                  type="number" />
              </dd>
            </div>
            <div
              className="bg-gray-100 px-6 py-5 grid
                grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Steuer
              </dt>
              <dd
                className="text-right text-sm text-gray-900
                  sm:mt-0 col-span-2">
                <input
                  className="shadow-sm focus:ring-indigo-500
                focus:border-indigo-500 block w-full pr-12
                sm:text-sm border-gray-300 rounded-md"
                  onInput={(e) => handleChange(showPositionDetails,
                      'tax', e)}
                  value={data.tax}
                  type="number" />
              </dd>
            </div>
            <div
              className="bg-white px-6 py-5 grid
                grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Rabatt
              </dt>
              <dd
                className="text-right text-sm text-gray-900
                  sm:mt-0 col-span-2">
                <input
                  className="shadow-sm focus:ring-indigo-500
                focus:border-indigo-500 block w-full pr-12
                sm:text-sm border-gray-300 rounded-md"
                  onInput={(e) => handleChange(showPositionDetails,
                      'discount', e)}
                  value={data.discount}
                  type="number" />
              </dd>
            </div>
            <div
              className="bg-gray-100 px-6 py-5 grid
                grid-cols-3 sm:gap-4 sm:px-6">
              <dt
                className="text-sm font-medium
                  text-gray-500">
                Beschreibung
              </dt>
              <dd
                className="text-right text-sm text-gray-900
                  sm:mt-0 col-span-2">
                <textarea
                  className="shadow-sm focus:ring-indigo-500
                focus:border-indigo-500 block w-full pr-12
                sm:text-sm border-gray-300 rounded-md"
                  onInput={(e) => handleChange(showPositionDetails,
                      'description', e)}
                  value={data.description}></textarea>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </button>
  );
}

PositionModal.propTypes = {
  //   location: PropTypes.object,
  //   state: PropTypes.object,
  showPositionDetailsC: PropTypes.array,
  documentData: PropTypes.object,
  handleChange: PropTypes.func,
};

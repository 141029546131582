import React from 'react';
import {MdMail, MdGroup} from 'react-icons/md';
import {useLocation} from 'react-router-dom';
import TeamManagement from '../TeamManagement';
// import TeamSettings from './TeamSettings';
import PropTypes from 'prop-types';
import {auth} from '../firebase';
/**
 *
 * @return {object} jsx
 */
export default function AccountSettings({state, history}) {
  const [accountSettings, setAccountSettings] = state;

  const {pathname} = useLocation();

  /**
   * Handles Change in Input
   * @param {string} name
   * @param {any} value

   */
  function handleChange(name, value) {
    const settings = accountSettings;
    settings[name] = value;
    console.log(settings);
    setAccountSettings({
      ...settings,
    });
  }

  return (
    <div
      className="space-y-6"
      style={{display: pathname === '/settings/account' ? 'block' : 'none'}}>
      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white py-6 px-4 rounded-lg
            space-y-6 sm:p-6">
          <div>
            <h3
              className="text-lg leading-6
                font-medium text-gray-900">
              Persönliche Daten
            </h3>
            <p
              className="mt-1 text-sm
                text-gray-500">
              Aktualisieren Sie hier ihren internen Nutzernamen
              und ihre Email Adresse.
            </p>
          </div>

          <div
            className="grid grid-cols-6
              gap-6">
            <div className="col-span-6 md:col-span-3">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                Benutzername
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div
                  className="absolute inset-y-0 left-0 pl-3 flex
                      items-center pointer-events-none">
                  <MdGroup
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="userName"
                  value={accountSettings.userName}
                  onInput={(e) => handleChange(e.target.name, e.target.value)}
                  className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pl-10 sm:text-sm border-gray-300
                        rounded-md"
                />
              </div>
            </div>

            <div className="col-span-6 md:col-span-3 md:col-start-1">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div
                  className="absolute inset-y-0 left-0 pl-3 flex
                      items-center pointer-events-none">
                  <MdMail
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="email"
                  name="email"
                  value={accountSettings.email}
                  onInput={(e) => handleChange(e.target.name, e.target.value)}
                  className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pl-10 sm:text-sm border-gray-300
                        rounded-md"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
        <div>
          <h3
            className="text-lg leading-6
                font-medium text-gray-900">
              Öffentliche Daten
          </h3>
          <p
            className="mt-1 text-sm
                text-gray-500">
              Geben Sie hier ihre öffentlichen Kontaktdaten an. Diese können z.B
              auf erstellten Dokumenten verwendet werden.
          </p>
        </div>

        <div
          className="grid grid-cols-6
              gap-6">
          <div className="col-span-6 md:col-span-3">
            <label
              htmlFor="publicName"
              className="block text-sm font-medium text-gray-700">
                Kontaktname/Kürzel
            </label>

            <input
              type="text"
              name="publicName"
              value={accountSettings.publicName}
              onInput={(e) => handleChange(e.target.name, e.target.value)}
              className="focus:ring-blue-500 focus:border-blue-500
                        block w-full sm:text-sm border-gray-300
                        rounded-md"
            />

          </div>

          <div className="col-span-6 md:col-span-3">
            <label
              htmlFor="publicContact"
              className="block text-sm font-medium text-gray-700">
                Kontaktinformation (Tel/Email)
            </label>

            <input
              type="text"
              name="publicContact"
              value={accountSettings.publicContact}
              onInput={(e) => handleChange(e.target.name, e.target.value)}
              className="focus:ring-blue-500 focus:border-blue-500
                        block w-full sm:text-sm border-gray-300
                        rounded-md"
            />
          </div>

        </div>
      </div>


      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <TeamManagement history={history}></TeamManagement>
        </div>
      </div>

      {/* <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white py-6 px-4
            space-y-6 sm:p-6">
          <div>
            <h3
              className="text-lg leading-6
                font-medium text-gray-900">
              Neues Team
            </h3>
            <p
              className="mt-1 text-sm
                text-gray-500">
              Treten Sie hier einem Unternehmen bei, oder erstellen sie ein
              Neues.
            </p>
          </div>

          <TeamManagement></TeamManagement>
        </div>
      </div> */}

      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6">
              <h3
                className="text-lg leading-6
                font-medium text-gray-900">
                Passwort
              </h3>
              <p
                className="mt-1 text-sm
                text-gray-500">
                Setzen Sie hier ihr Passwort zurück.
              </p>
            </div>
            <div className="col-span-6">
              <button
                onClick={() => {
                  auth.sendPasswordResetEmail(accountSettings.email);
                  setAccountSettings({
                    ...accountSettings,
                    reset: true,
                  });
                }}
                className="inline-flex justify-center
                    py-2 px-4 border border-transparent
                    shadow-sm text-sm font-medium rounded-md
                    text-white bg-blue-600 hover:bg-blue-700
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-blue-500">
                {accountSettings.reset ? 'Email gesendet!' :
                 'Passwort zurücksetzen'}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6">
              <h3
                className="text-lg leading-6
                font-medium text-gray-900">
                Account löschen
              </h3>
              <p
                className="mt-1 text-sm
                text-gray-500">
                Hier können Sie ihren Account vollständig entfernen.
              </p>
            </div>
            <div className="col-span-6">
              <a
                href={'mailto:info@instream-media.com?' +
                'subject=Löschungsanfrage'}
                className="inline-flex justify-center
                    py-2 px-4 border border-transparent
                    shadow-sm text-sm font-medium rounded-md
                    text-white bg-blue-600 hover:bg-blue-700
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-blue-500">
                Account löschen
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AccountSettings.propTypes = {
  state: PropTypes.array,
  history: PropTypes.object,
};

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      debug: true,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      resources: {
        en: {
          translation: {
            dashboard: {
              welcome: 'Welcome Back',
              overview: 'Overview',
              activity: 'Recent activity',
              notifications: 'Notifications',
              stock_warnings: 'Stock Warnings',
              overdue_docs: 'Overdue Documents',
              warehouse_value: 'Warehouse Value',
              new_contacts: 'New Contacts (last 30 days)',
              revenue: 'Revenue (last 30 days)',
            },
          },
        },
        de: {
          translation: {
            dashboard: {
              welcome: 'Willkommen zurück',
              overview: 'Übersicht',
              activity: 'Aktivitäten',
              notifications: 'Benachrichtigungen',
              stock_warnings: 'Bestandswarnungen',
              overdue_docs: 'Überfällige Dokumente',
              warehouse_value: 'Gesamtwert Lager',
              new_contacts: 'Neue Kontakte (30 Tage)',
              revenue: 'Netto-Umsatz (30 Tage)',
            },
          },
        },
      },
    });

export default i18n;

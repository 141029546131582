import React, {useContext, useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {AuthContext} from '../context/AuthContext';
import {createDoc, RerenderContext} from '../cud';

import Localbase from 'localbase';
import {getSearchResults} from '../utilities';


/**
 *
 * @return {object} jsx
 */
export default function SuperTB({
  providedDocumentCommentC,
  name = 'Kommentar',
  optional = false,
  tbCategory,
}) {
  const {teamC, isOfflineC} = useContext(AuthContext);
  const currentTeam = teamC[0];
  const isOffline = isOfflineC[0];


  const [rerender, setRerender] = useContext(RerenderContext);
  const [documentComments, setDocumentComments] = useState([]);


  const [showDropdown, setShowDropdown] = useState(false);

  const [searchString, setSearchString] = useState('');

  const [searchResults, setSearchResults] = useState([]);

  const [cursorPos, setCursorPos] = useState(0);

  const [providedDocumentComment, setProvidedDocumentComment] =
    providedDocumentCommentC;

  const textAreaRef = useRef(null);

  // Load Data
  useEffect(async function() {
    if (currentTeam) {
      const lb = new Localbase(currentTeam.id);
      // lb.config.debug = false;
      let documentComments = await lb.collection('document_comments').get();
      documentComments = documentComments.filter((el) => !el.deleted);
      if (tbCategory) {
        setDocumentComments(documentComments.filter((el) => (
          el.category === tbCategory || el.category === ''
        )));
      } else {
        setDocumentComments(documentComments);
      }
    }
  }, [rerender]);

  /**
   * Handles Select
   * @param {number} pos cursor Position
   * @param {number} index cursor Position
   */
  function handleSelect(pos, index = 0) {
    let position;
    if (pos) {
      console.log('POS is set!');
      position = pos;
    } else {
      position = cursorPos;
    }
    if (searchResults.length > 0) {
      const s = providedDocumentComment;
      const sub = s.substring(0, position);
      const remainder = s.substring(position);
      setProvidedDocumentComment(
          `${sub
              .substring(0, sub.length - searchString.length)}${
            searchResults[index].text} ${remainder}`,
      );
      setSearchString('');
    }
  }

  return (
    <div className='relative'>
      <div className="flex justify-between">
        <label
          htmlFor="account-number"
          className="block text-sm font-medium text-gray-700">
          {name}
        </label>
        <div className="flex justify-end space-x-2">
          {searchString.length ? (
          <span className="text-sm text-gray-500" id="email-optional">
            &quot;TAB&quot; für neue Suche
          </span>
        ): ''}
          {searchString.length && searchResults.length ? (
          <span className="text-sm text-gray-500" id="email-optional">
            &quot;Ctrl + Enter&quot; zum Einfügen
          </span>
        ): ''}
          {searchString.length && !searchResults.length ? (
          <span className="text-sm text-gray-500" id="email-optional">
            &quot;Ctrl + Enter&quot; für neuen Textbaustein
          </span>
        ): ''}

          {optional ? (
          <span className="text-sm text-gray-500">Optional</span>
        ) : (
          ''
        )}

        </div>
      </div>

      <div className="mt-1 relative rounded-md shadow-sm">
        <textarea
          ref={textAreaRef}
          rows={3}
          onKeyDown={(e) => {
            if (textAreaRef.current.selectionStart) {
              setCursorPos(textAreaRef.current.selectionStart);
            }

            // -------- TAB Key --------
            if (!e.ctrlKey && e.keyCode === 9) {
              // Replaces String with comment Object
              e.preventDefault();

              // Insert space at cursor Position
              const s = providedDocumentComment;
              const sub = s.substring(0, cursorPos + 1);
              const remainder = s.substring(cursorPos + 1);
              setProvidedDocumentComment(
                  `${sub} ${remainder}`,
              );
              setSearchString('');
            }

            // -------- Ctrl + Enter --------
            if (e.ctrlKey && e.keyCode === 13) {
              e.preventDefault();

              // Check if search String & search Results exists
              // If Yes, Insert Result, if no add textblock
              if (searchString.length && searchResults.length) {
                setSearchString('');
                handleSelect(textAreaRef.current.selectionStart);
              } else if (searchString.length) {
                // Adds Textblock to DB
                createDoc(currentTeam.id, 'document_comments', {
                  text: searchString,
                  category: tbCategory || '',
                }, setRerender, isOffline);
              }

              // ??? TODO
              if (e.keyCode > 36 && e.keyCode < 40) {
                setSearchString('');
              }
            }
          }}
          // Toggle Dropdown depending on Focus
          onFocus={(el) => {
            setShowDropdown(true);
            setSearchString('');
            setSearchResults(
                getSearchResults('', documentComments));
          }}
          onBlur={() => setShowDropdown(false)}
          onInput={(e) => {
            // Add Value of Pressed Key to string
            setProvidedDocumentComment(e.target.value);

            // Handle Text Input
            const textValue = e.target.value;
            const newChar = e.nativeEvent.data;
            let searchBuffer = searchString;

            if (e.nativeEvent.inputType === 'insertLineBreak') {
              if (searchBuffer.length) {
                searchBuffer += '\n';
              }
            } else if (textValue.length -
               providedDocumentComment.length === 1) {
              searchBuffer += newChar;
            } else if (e.nativeEvent.inputType === 'deleteContentBackward') {
              searchBuffer = searchString
                  .substring(0, searchString.length -1);
            } else {
              // Reset Search Array
              searchBuffer = newChar;
            };
            if (!searchBuffer) searchBuffer = '';
            console.log(searchBuffer);
            setSearchString(searchBuffer);

            // Handle Search
            searchBuffer.length > 0 ?
              setSearchResults(
                  getSearchResults(searchBuffer,
                      documentComments)) :
              setSearchResults([]);
          }}
          onClick={() => {
            setSearchString('');
          }}
          autoComplete="off"
          type="text"
          name={name || 'super_textbox'}
          className="focus:ring-blue-500 focus:border-blue-500
          block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
          value={providedDocumentComment}></textarea>
        {/* -------------- Buttons (currently disabled) -------------- */}
        {/* {
          // Checks if input exists and there are no search results
          providedDocumentComment.length > 0 && searchResults.length === 0 ? (
            <button
              type="button"
              onClick={() => {
                // Adds new Document Comment
                const arr = providedDocumentComment.split(' ');

                // Checks if input exists and there are no search results
                providedDocumentComment.length > 0 &&
                  searchResults.length === 0 &&
                  // Adds Comment to DB
                  createDoc(currentTeam.id, 'document_comments', {
                    text: arr[arr.length - 1],
                  }, setRerender, isOffline)
                      .then(() => {
                        setProvidedDocumentComment(
                            providedDocumentComment + ' ');
                      })
                      .catch((e) => {
                        addToast('error', `Fehler: Textbaustein konnte nicht
                        hinzugefügt werden`);
                        return console.log(e);
                      });
              }}
              className="absolute inset-y-0 right-0 pr-3 flex
        items-center">
              <MdAdd
                className="h-5 w-5 bg-blue-100 text-blue-400 rounded-md"
                aria-hidden="true"
              />
            </button>
          ) : (
            <button
              type="button"
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
              onClick={() => setShowDropdown(!showDropdown)}>
              <MdUnfoldMore
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          )
        } */}
      </div>
      <div
        className=" mt-2 p-2 max-h-28 overflow-auto
        rounded-md shadow-lg bg-white ring-1 ring-black
        ring-opacity-5 focus:outline-none
        absolute w-full top-24 z-10"
        style={{display: showDropdown ? 'block' : 'none'}}>
        {
          searchResults.map((el, ind) => (
            <button
              type="button"
              className="w-full text-left hover:bg-gray-100
               rounded-md p-1 truncate whitespace-pre-wrap"
              onMouseDown={() => {
                console.log(cursorPos);
                handleSelect(null, ind);
              }}
              key={el.id}>
              {el.text}
            </button>
          ))}
      </div>
    </div>
  );
}
SuperTB.propTypes = {
  providedDocumentCommentC: PropTypes.array,
  name: PropTypes.string,
  optional: PropTypes.bool,
  tbCategory: PropTypes.string,
};

/** Document Types Dropdown
 * Returns Selected Document Type as Object,
 * including name, template etc
 */

import React, {useContext, useState, useEffect} from 'react';
import {MdUnfoldMore} from 'react-icons/md';
import PropTypes from 'prop-types';
import {RerenderContext} from '../cud';
// import Localbase from 'localbase';
// const lb = new Localbase('E3BYTC3e42z8XLiLTK3K');
import Localbase from 'localbase';
import {AuthContext} from '../context/AuthContext';
import {getSearchResults} from '../utilities';


/**
 *
 * @return {object} jsx
 */
export default function DocumentTypesDD({selectedDocumentTypeC,
  disabled = false}) {
  // -------------------Variables-------------------------------
  const rerender = useContext(RerenderContext);

  const {teamC} = useContext(AuthContext);
  const currentTeam = teamC[0];

  const [showDropdown, setShowDropdown] = useState(false);

  const [searchResults, setSearchResults] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = selectedDocumentTypeC;
  const [inputValue, setInputValue] = useState('');
  // -------------------Variables End-------------------------------

  useEffect(
      function() {
      // Updates Input Value when Type is selected
        selectedDocumentType.id && setInputValue(selectedDocumentType.name);

        // empties Input value when Type is deleted/empty
        if (!selectedDocumentType.id && selectedDocumentType.name) {
          setInputValue(selectedDocumentType.name);
        } else if (!selectedDocumentType.id) {
          // empties Input value when Type is deleted/empty
          setInputValue('');
        }
      },
      [selectedDocumentType, rerender],
  );

  // ---------------------------Utility Functions------------------------------

  /** Handle Search Input and calls search function
   * @param {object} value
   */
  async function handleSearchInput(value) {
    setInputValue(value);
    setShowDropdown(true);
    const lb = new Localbase(currentTeam.id);
    // lb.config.debug = false;
    let documentTypes = await lb.collection('document_types').get();

    // Sort by sort attribute
    documentTypes = documentTypes.sort((a, b) => a.sort -b.sort);

    if (value && value.length > 0) {
      setSearchResults(getSearchResults(value, documentTypes));
    } else {
      setSearchResults(documentTypes);
    }
  }

  /**
   * Get's triggered when Document Type is selected through
   * ENTER or Click
   * @param {object} el
   */
  function handleSelect(el) {
    setSelectedDocumentType({
      id: el.id,
      abbreviation: el.abbreviation,
      name: el.name,
      // number: el.number,
      // template: el.template,
    });
    setShowDropdown(false);
  }

  // ---------------------------Utility Functions End----------------------

  return (
    <div className='relative'>
      <label
        htmlFor="account-number"
        className="block text-sm font-medium text-gray-700">
        Dokumententyp
      </label>

      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          disabled={disabled}
          onKeyDown={(e) => {
            e.keyCode === 13 && e.preventDefault();

            e.keyCode === 13 &&
              searchResults.length > 0 &&
              handleSelect(searchResults[0]);
          }}
          // Toggle Dropdown depending on Focus
          onFocus={() => {
            setShowDropdown(true);
            setSelectedDocumentType('');
            handleSearchInput('');
          }}
          onBlur={() => setShowDropdown(false)}
          onInput={(e) => handleSearchInput(e.target.value)}
          value={inputValue}
          autoComplete="off"
          type="text"
          className={`focus:ring-blue-500 focus:border-blue-500
          block w-full pr-10 sm:text-sm border-gray-300 rounded-md
         ${disabled && 'bg-gray-100'}`}
        />
        <button
          tabIndex={-1}
          type="button"
          onClick={() => setShowDropdown(!showDropdown)}
          className="absolute inset-y-0 right-0 pr-3 flex
        items-center">
          <MdUnfoldMore className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      </div>
      <div
        className=" mt-2 p-2 max-h-28 overflow-auto
        rounded-md shadow-lg bg-white ring-1 ring-black
        ring-opacity-5 focus:outline-none
        absolute w-full top-16 z-10"
        style={{display: showDropdown ? 'block' : 'none'}}>
        {searchResults.map((el) => (
          <button
            type="button"
            className="w-full text-left hover:bg-gray-100 rounded-md p-1"
            onMouseDown={() => handleSelect(el)}
            key={el.id}>
            {el.name}
          </button>
        ))}
      </div>
    </div>
  );
}

DocumentTypesDD.propTypes = {
  selectedDocumentTypeC: PropTypes.array,
  disabled: PropTypes.bool,
};


import React, { Fragment, useRef, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import * as XLSX from 'xlsx';
import { useState } from 'react';
import { useEffect } from 'react';
import { lb } from '../context/AuthContext';
import { millsToDate, numberFormatter } from '../utilities';


export default function InvoiceOutBook({ show, setShow, children }) {

    const cancelButtonRef = useRef(null);

    const downloadExcel = (data) => {
        if (!data.length) return alert('No data to download');

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, `warest-export-${millsToDate(Date.now())}.xlsx`);
    };

    const [dataToExport, setDataToExport] = useState([]);
    const [exportOptions, setExportOptions] = useState({ from: "", to: "" });

    function handleDateInput(type, e) {
        const { value } = e.target;
        setExportOptions({ ...exportOptions, [type]: value });
    }

    console.log(exportOptions)

    useEffect(() => {
        (async () => {

            if (exportOptions.from && exportOptions.to) {
                const fromDate = new Date(exportOptions.from).getTime();
                const toDate = new Date(exportOptions.to).getTime();
                const documentType = exportOptions.documentType;
                const documents = await lb.collection('documents').get();

                const filteredDocuments = documents.filter((doc) => {
                    const date = doc.created_date;
                    const matchingDate = date >= fromDate && date <= toDate;
                    const matchingDocumentType = documentType ? doc.type?.name === documentType : true;
                    return matchingDate && matchingDocumentType;
                }).sort((a, b) => a.created_date - b.created_date);

                const mappedData = filteredDocuments.map((doc) => {
                    return {
                        "Datum": millsToDate(doc.created_date),
                        "Name": `${doc.type.name}`,
                        "Name": `${doc.type.abbreviation} ${doc.type.number}`,
                        "Kunde": `${doc.billing_address.name} | ${doc.billing_address.city}`,
                        "Netto": `${numberFormatter(doc.net_total)} €`,
                        "Brutto": `${numberFormatter(doc.gross_total)} €`,
                    }
                })
                const divider = { "Datum": "------------------", "Name": "------------------", "Kunde": "------------------", "Netto": "------------------", "Brutto": "------------------" };
                const metaData = {
                    "Netto": `${numberFormatter(filteredDocuments.reduce((acc, doc) => acc + doc.net_total, 0))} €`,
                    "Brutto": `${numberFormatter(filteredDocuments.reduce((acc, doc) => acc + doc.gross_total, 0))} €`,
                }
                setDataToExport([...mappedData, divider, metaData]);

            }
        })()
    }, [exportOptions])


    return (
        <Transition.Root show={show ? true : false} as={Fragment}>
            <Dialog as="div" className="fixed z-30 inset-0
       overflow-y-auto" initialFocus={cancelButtonRef} onClose={() => setShow && setShow()}>
                <div className="flex items-end justify-center
         min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0
             bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser
           into centering the modal contents. */}
                    <span className="hidden sm:inline-block
           sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white
             rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden
              shadow-xl transform transition-all sm:my-8 sm:align-middle
               sm:max-w-lg sm:w-full sm:p-6">
                            <div className='flex w-full space-x-2'>
                                <div>
                                    <label htmlFor="startDate">Von</label>
                                    <input name='startDate' className='w-full' type='date' value={exportOptions.from} onChange={(e) => handleDateInput('from', e)} id=""></input>
                                </div>
                                <div>
                                    <label htmlFor="endDate">Bis</label>
                                    <input name='endDate' className='w-full' type='date' value={exportOptions.to} onChange={(e) => handleDateInput('to', e)} id=""></input>
                                </div>
                            </div>
                            <div className='mt-6'>
                                <label htmlFor="documentType">Dokumententyp</label>
                                <select name='documentType' className='w-full' value={exportOptions.documentType} onInput={(e) => setExportOptions({ ...exportOptions, documentType: e.target.value })} id="">
                                    <option value=""></option>
                                    <option value="Angebot">Angebot</option>
                                    <option value="Auftragsbestätigung">Auftragsbestätigung</option>
                                    <option value="Rechung">Rechnung</option>
                                    <option value="Lieferschein">Lieferschein</option>
                                </select>
                            </div>

                            <div className='mt-8 flex justify-center'>
                                <button className='p-2 bg-blue-500 rounded-md text-white' onClick={() => downloadExcel(dataToExport)}>Download</button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}


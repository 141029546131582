import React from 'react';
import {MdDateRange, MdArrowDropDown} from 'react-icons/md';
// import {AuthContext} from '../context/AuthContext';
import {useLocation} from 'react-router-dom';

const positions = [
  {
    id: 1,
    title: 'Back End Developer',
    department: 'Engineering',
    closeDate: '2020-01-07',
    closeDateFull: 'January 7, 2020',
    applicants: [
      {
        name: 'Dries Vincent',
        email: 'driesvincent@example.com',
        imageUrl:
          'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
      {
        name: 'Lindsay Walton',
        email: 'lindsaywalton@example.com',
        imageUrl:
          'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
      {
        name: 'Courtney Henry',
        email: 'courtneyhenry@example.com',
        imageUrl:
          'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
      {
        name: 'Tom Cook',
        email: 'tomcook@example.com',
        imageUrl:
          'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
    ],
  },
];
/**
 *
 * @return {object} jsx
 */
export default function ImportSettings() {
  const {pathname} = useLocation();

  return (
    <div
      className="space-y-6"
      style={{display: pathname === '/settings/import' ? 'block' : 'none'}}>
      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div>
            <h3
              className="text-lg leading-6
                font-medium text-gray-900">
              Kontaktgruppen
            </h3>
            <p
              className="mt-1 text-sm
                text-gray-500">
              Hier können Sie Kontakte in Gruppen aufteilen.
            </p>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <div
              className="bg-white rounded-lg shadow overflow-hidden
            rounded-md col-span-6">
              <ul className="divide-y divide-gray-200">
                {positions.map((position) => (
                  <li key={position.id}>
                    <a href="#" className="block ">
                      <div
                        className="px-4 py-4 flex items-center
                      sm:px-6 bg-gray-50 hover:bg-gray-100">
                        <div
                          className="min-w-0 flex-1 sm:flex
                        sm:items-center sm:justify-between">
                          <div className="truncate">
                            <div className="flex text-sm">
                              <p
                                className="font-medium
                              text-blue-600 truncate">
                                {position.title}
                              </p>
                              <p
                                className="ml-1 flex-shrink-0
                              font-normal text-gray-500">
                                in {position.department}
                              </p>
                            </div>
                            <div className="mt-2 flex">
                              <div
                                className="flex items-center
                              text-sm text-gray-500">
                                <MdDateRange
                                  className="flex-shrink-0 mr-1.5
                                  h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <p>
                                  Closing on{' '}
                                  <time dateTime={position.closeDate}>
                                    {position.closeDateFull}
                                  </time>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                            <div className="flex overflow-hidden -space-x-1">
                              {/* {position.applicants.map((applicant) => (
                        <img
                          key={applicant.email}
                          className="inline-block h-6 w-6
                          rounded-full ring-2 ring-white"
                          src={applicant.imageUrl}
                          alt={applicant.name}
                        />
                      ))} */}
                            </div>
                          </div>
                        </div>
                        <div className="ml-5 flex-shrink-0">
                          <MdArrowDropDown
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div>
            <h3
              className="text-lg leading-6
                font-medium text-gray-900">
              Nummernkreise
            </h3>
            <p
              className="mt-1 text-sm
                text-gray-500">
              Verwalten Sie hier die Nummernkreise ihrer Kontakte.
            </p>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <div
              className="bg-white rounded-lg shadow overflow-hidden
            rounded-md col-span-6">
              <ul className="divide-y divide-gray-200">
                {positions.map((position) => (
                  <li key={position.id}>
                    <a href="#" className="block ">
                      <div
                        className="px-4 py-4 flex items-center
                      sm:px-6 bg-gray-50 hover:bg-gray-100">
                        <div
                          className="min-w-0 flex-1 sm:flex
                        sm:items-center sm:justify-between">
                          <div className="truncate">
                            <div className="flex text-sm">
                              <p
                                className="font-medium
                              text-blue-600 truncate">
                                {position.title}
                              </p>
                              <p
                                className="ml-1 flex-shrink-0
                              font-normal text-gray-500">
                                in {position.department}
                              </p>
                            </div>
                            <div className="mt-2 flex">
                              <div
                                className="flex items-center
                              text-sm text-gray-500">
                                <MdDateRange
                                  className="flex-shrink-0 mr-1.5
                                  h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <p>
                                  Closing on{' '}
                                  <time dateTime={position.closeDate}>
                                    {position.closeDateFull}
                                  </time>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                            <div className="flex overflow-hidden -space-x-1">
                              {/* {position.applicants.map((applicant) => (
                        <img
                          key={applicant.email}
                          className="inline-block h-6 w-6
                          rounded-full ring-2 ring-white"
                          src={applicant.imageUrl}
                          alt={applicant.name}
                        />
                      ))} */}
                            </div>
                          </div>
                        </div>
                        <div className="ml-5 flex-shrink-0">
                          <MdArrowDropDown
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/** Login Component */
import React, {useEffect, useState, useContext} from 'react';
import {AuthContext, signup} from '../context/AuthContext';
import PropTypes from 'prop-types';

import logoBlue from './../assets/img/logo_blue.png';
import {Link} from 'react-router-dom';
import business from './../assets/img/business.svg';

/**
 *
 * @return {object} jsx
 */
export default function Register({history}) {
  const data = {
    email: '',
    password: '',
    passwordRepeat: '',
    userName: '',
    agb: false,
    privacy: false,
  };

  const [loginData, setLoginData] = useState(data);
  const {userC} = useContext(AuthContext);
  const currentUser = userC[0];

  useEffect(
      function() {
        if (currentUser) ;
        // if (currentUser) history.push('/dashboard/');
      },
      [currentUser],
  );

  /** Change Listener for Inputs
   * @param {string} name
   * @param {string} value
   */
  function handleChange(name, value) {
    setLoginData({
      ...loginData,
      [name]: value,
    });
    console.log({
      ...loginData,
      [name]: value,
    });
  }

  /** Submit Listener
   * @param {object} e
   *
   */
  async function onFormSubmit(e) {
    e.preventDefault();
    try {
      await signup(loginData);
      history.push('/dashboard');
      // return <Redirect to="/" />;
    } catch (e) {
      console.log(e);
    }
    setLoginData(data);
  }

  return (
    <div
      className="min-h-screen w-full bg-gray-50 flex-center justify-center
    py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-20 w-auto" src={logoBlue} />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Willkommen bei Warest!
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Bestehender Account?{' '}
          <Link to='/'
            className="font-medium text-blue-600 hover:text-blue-500">
            Login
          </Link>
        </p>
        <img className='my-8' src={business} alt="" />
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-4" onSubmit={(e) => onFormSubmit(e)}>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                Vollständiger Name
              </label>
              <div className="mt-1">
                <input
                  name="userName"
                  type="text"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  value={loginData.userName}
                  required
                  className="appearance-none block w-full px-3 py-2 border
                  border-gray-300 rounded-md shadow-sm placeholder-gray-400
                  focus:outline-none focus:ring-blue-500
                  focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                Email Adresse
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  value={loginData.email}
                  required
                  className="appearance-none block w-full px-3 py-2 border
                  border-gray-300 rounded-md shadow-sm placeholder-gray-400
                  focus:outline-none focus:ring-blue-500
                  focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700">
                Passwort
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  value={loginData.password}
                  className="appearance-none block w-full px-3 py-2
                  border
                  border-gray-300 rounded-md shadow-sm
                  placeholder-gray-400
                  focus:outline-none focus:ring-blue-500
                  focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className={`block text-sm font-medium
                 ${loginData.passwordRepeat ?
                  loginData.password === loginData.passwordRepeat ?
                  'text-white' : 'text-red-500' :
                   'text-gray-700'}`}>
                {loginData.passwordRepeat ?
                 loginData.password === loginData.passwordRepeat ?
                 'Übereinstimmung' : 'Keine Übereinstimmung' :
                  'Passwort wiederholen'}
              </label>
              <div className="mt-1">
                <input
                  name="passwordRepeat"
                  type="password"
                  required
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  value={loginData.passwordRepeat}
                  className="appearance-none block w-full px-3 py-2
                  border
                  border-gray-300 rounded-md shadow-sm
                  placeholder-gray-400
                  focus:outline-none focus:ring-blue-500
                  focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="flex items-center justify-start">
              <div className="flex items-center">
                <input
                  onChange={(e) => handleChange(e.target.name,
                      e.target.checked)}
                  value={loginData.agb}
                  name="agb"
                  type="checkbox"
                  className="h-4 w-4 text-blue-600
                  focus:ring-blue-500
                  border-gray-300 rounded"
                />
                <label
                  htmlFor="agb"
                  className="ml-2 block text-sm text-gray-900">
                  Ich habe die AGBs gelesen und akzeptiere sie.
                </label>
              </div>
            </div>

            <div className="flex items-center justify-start">
              <div className="flex items-center">
                <input
                  onChange={(e) => handleChange(e.target.name,
                      e.target.checked)}
                  value={loginData.privacy}
                  name="privacy"
                  type="checkbox"
                  className="h-4 w-4 text-blue-600
                  focus:ring-blue-500
                  border-gray-300 rounded"
                />
                <label
                  htmlFor="privacy"
                  className="ml-2 block text-sm text-gray-900">
                  Ich habe die Datenschutzerklärung gelesen und akzeptiere sie.
                </label>
              </div>
            </div>

            <div>
              <button
                disabled={loginData.password === loginData.passwordRepeat &&
                  loginData.password.length > 5 &&
                   loginData.passwordRepeat.length > 5 &&
                   loginData.agb && loginData.privacy ?
                false: true}
                type="submit"
                className="w-full flex justify-center py-2 px-4 border
                border-transparent rounded-md shadow-sm text-sm
                font-medium
                text-white bg-blue-600 hover:bg-blue-700
                focus:outline-none
                focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                 disabled:opacity-50">
                {
                  loginData.password.length > 5 &&
                   loginData.passwordRepeat.length > 5 ?
                'Registrieren': 'Passwort zu kurz'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

Register.propTypes = {
  history: PropTypes.any,
  push: PropTypes.any,
};

/** Add Item Component */
import React, { useState, useContext, useEffect } from 'react';
// import ProductCategoryDD from './ProductCategoryDD';
// import WarehousesDD from './WarehousesDD';
// import {MdHelp} from 'react-icons/md';
import PropTypes from 'prop-types';

import WarehousesDD from '../dropdowns/WarehousesDD';
import ContactsDD from '../dropdowns/ContactsDD';
import MultiSelect from '../dropdowns/MultiSelect';

import { MdAddCircle, MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { addLogEntry, numberFormatter } from '../utilities';
import { MdClose } from 'react-icons/md';
import { AuthContext } from '../context/AuthContext';
import { createDoc, RerenderContext, updateDoc } from '../cud';
// import Localbase from 'localbase';
import Localbase from 'localbase';


/**
 *
 * @return {object} jsx
 */
export default function ItemPopup({ location, history }) {
  const [rerender, setRerender] = useContext(RerenderContext);

  const dataStructure = {
    code: '',
    name: '',
    stock: '',
    max_stock: '',
    min_stock: '',
    retail_price: '',
    product_description: '',
    internal_note: '',
    tax: '',
    unit: '',
    weight: '',
    warehouse: '',
    categories: [],
    contact_specific_fields: {},
  };
  const [itemData, setItemData] = useState(dataStructure);

  const csfDataStructure = {
    contact_id: null,
    code: null,
    price: null,
  };

  const [contactSpecificData, setContactSpecificData] =
    useState(csfDataStructure);

  const [contacts, setContacts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);

  const { teamC, isOfflineC, userC, toastsC } = useContext(AuthContext);
  const currentTeam = teamC[0];
  const isOffline = isOfflineC[0];
  const currentUser = userC[0];
  const addToast = toastsC[1];

  const [selectedProductCategories,
    setSelectedProductCategories] = useState([]);
  const [selectedContact, setSelectedContact] = useState('');
  const [selectedWarehouse, setSelectedWarehouse] = useState('');

  const [isSaving, setIsSaving] = useState(false);


  let docData;
  let variation;
  if (location.state) docData = location.state.docData;
  if (location.state) variation = location.state.variation;

  // Load Data
  useEffect(async function () {
    if (currentTeam) {
      const lb = new Localbase(currentTeam.id);
      // lb.config.debug = false;
      const contacts = await lb.collection('contacts').get();
      setContacts(contacts);

      const productCategories = await lb.collection('product_categories').get();
      setProductCategories(productCategories);
    }
  }, [rerender]);


  // Load Contact Data to edit
  useEffect(function () {
    if (docData) {
      console.log(docData);
      setItemData({ ...docData });

      // Init Values of Dropdowns/Textboxes
      setSelectedProductCategories(docData.categories || []);
      setSelectedWarehouse(docData.warehouse || '');
    }
  }, []);

  /**
   * Handles Change in Input
   * @param {object} event
   * @param {string} dataType

   */
  function handleChange(event, dataType = 'string') {
    const { value, name } = event.target;
    dataType === 'string' &&
      setItemData({
        ...itemData,
        [name]: String(value),
      });

    dataType === 'number' &&
      setItemData({
        ...itemData,
        [name]: Number(value),
      });

    dataType === 'number' &&
      value === '' &&
      setItemData({
        ...itemData,
        [name]: '',
      });
  }

  /**
   * Handles Form Submit
   * @param {object} event
   */
  async function handleSubmit() {
    setIsSaving(true);

    if (itemData.created_date && itemData.id) {
      // Edit existing Document
      try {
        await updateDoc(currentTeam.id, 'items', itemData.id, {
          ...itemData,
          categories: selectedProductCategories,
          warehouse: selectedWarehouse,
        }, setRerender, isOffline);
      } catch (e) {
        addToast('error', 'Fehler: Artikel konnte nicht gespeichert werden');
        setIsSaving(false);
        return console.log(e);
      }
      addToast('success', 'Artikel bearbeitet');
      addLogEntry(currentTeam.id, 'warehouse', currentUser.uid,
        {
          action: 'bearbeitet',
          number: itemData.code,
          name: itemData.name,
        }, setRerender, isOffline);
    } else {
      // Save New Document
      try {
        await createDoc(currentTeam.id, 'items', {
          ...itemData,
          categories: selectedProductCategories,
          warehouse: selectedWarehouse,

        }, setRerender, isOffline);
      } catch (e) {
        addToast('error', 'Fehler: Artikel konnte nicht erstellt werden');
        setIsSaving(false);
        return console.log(e);
      }
      addToast('success', 'Artikel erstellt');
      // Add Log Entry
      addLogEntry(currentTeam.id, 'warehouse', currentUser.uid,
        {
          action: 'erstellt',
          number: itemData.code,
          name: itemData.name,
        }, setRerender, isOffline);
    }

    // Empty Selected Stuff (not required: Component is unmounted)
    setItemData(dataStructure);

    setIsSaving(false);

    history.push('/warehouse');
  }

  return (
    <div className="flex-shrink h-full bg-gray-100 flex flex-col">
      <div className="pb-5 border-b border-gray-200 p-8 flex justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {(() => {
            if (location && location.state) {
              return (location.state.heading || 'Artikel hinzufügen');
            }
          })()}
        </h3>
        <Link to="/warehouse">
          <div className="flex-center text-gray-900">
            <MdClose size="2rem"></MdClose>
          </div>
        </Link>
      </div>
      <div className="flex-shrink overflow-y-auto min-h-0 space-y-6 p-2 md:p-8 flex flex-col items-center">
        <div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6 w-full max-w-6xl">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Allgemeine Informationen
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Fügen Sie hier allgemeine Artikelinformationen ein.
              </p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-2">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700">
                    Artikelcode
                  </label>
                  <input
                    disabled={variation}
                    required
                    type="text"
                    name="code"
                    onChange={(e) => handleChange(e)}
                    value={itemData.code}
                    className={`mt-1 focus:ring-blue-500
                    focus:border-blue-500 block w-full shadow-sm
                    sm:text-sm border-gray-300 rounded-md
                     ${variation && 'bg-gray-100'}`}
                  />
                </div>

                <div className="col-span-6 sm:col-span-4">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    required
                    type="text"
                    name="name"
                    onChange={(e) => handleChange(e)}
                    value={itemData.name}
                    className="mt-1 focus:ring-blue-500
                    focus:border-blue-500 block w-full shadow-sm
                    sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6">

                  <MultiSelect
                    selectedArrC={[
                      selectedProductCategories,
                      setSelectedProductCategories,
                    ]}
                    dataC={[productCategories, setProductCategories]}
                    collection='product_categories'
                    filter={(data) => data}
                    search={function (data, input) {
                      return data.filter((el) => {
                        const name = el.name;
                        return name && name.toLowerCase().includes(input);
                      });
                    }}
                    dataStructure={function (inputValue, selectedColor) {
                      return {
                        name: inputValue,
                        color: selectedColor,
                      };
                    }}
                    name='Produktkategorien'
                    optional={false}
                  ></MultiSelect>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6 w-full max-w-6xl">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Beschreibungen
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Fügen sie ihrem Artikel eine offizielle Produktbeschreibung,
                und eine interne Notiz hinzu.
              </p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div>
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700">
                  Produktbeschreibung
                </label>
                <div className="mt-1">
                  <textarea
                    name="product_description"
                    onChange={(e) => handleChange(e)}
                    value={itemData.product_description}
                    rows={3}
                    className="shadow-sm focus:ring-blue-500
                    focus:border-blue-500 block w-full sm:text-sm border
                    border-gray-300 rounded-md"
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Eine offizielle Produktbeschreibung des Artikels. Diese
                  erscheint auf Dokumenten.
                </p>
              </div>

              <div>
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700">
                  Interne Notiz
                </label>
                <div className="mt-1">
                  <textarea
                    name="internal_note"
                    onChange={(e) => handleChange(e)}
                    value={itemData.internal_note}
                    rows={3}
                    className="shadow-sm focus:ring-blue-500
                    focus:border-blue-500 block w-full sm:text-sm border
                    border-gray-300 rounded-md"
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Interne Notiz für ihren Artikel.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6 w-full max-w-6xl">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Weitere Informationen
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Geben Sie hier weiter Informationen zum Artikel an (optional).
              </p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-3 sm:col-span-2">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700">
                    Bestand
                  </label>
                  <input
                    type="number"
                    name="stock"
                    onChange={(e) => handleChange(e, 'number')}
                    value={itemData.stock}
                    autoComplete="given-name"
                    className="mt-1 focus:ring-blue-500
                    focus:border-blue-500 block w-full shadow-sm
                    sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-3 sm:col-span-2">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700">
                    Einheit
                  </label>
                  <input
                    required
                    type="text"
                    name="unit"
                    onChange={(e) => handleChange(e)}
                    value={itemData.unit}
                    autoComplete="family-name"
                    className="mt-1 focus:ring-blue-500
                    focus:border-blue-500 block w-full shadow-sm
                    sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-3 sm:col-span-1">
                  <label
                    htmlFor="price"
                    className="block text-sm font-medium text-gray-700">
                    Gewicht
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="number"
                      name="weight"
                      onChange={(e) => handleChange(e, 'number')}
                      value={itemData.weight}
                      className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pr-9 sm:text-sm
                        border-gray-300 rounded-md"
                      placeholder="0,00"
                      aria-describedby="price-currency"
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex
                      items-center pointer-events-none">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency">
                        KG
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-span-3 col-start-1
                   sm:col-span-6 lg:col-span-2">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700">
                    Maximalbestand
                  </label>
                  <input
                    type="number"
                    name="max_stock"
                    onChange={(e) => handleChange(e, 'number')}
                    value={itemData.max_stock}
                    className="mt-1 focus:ring-blue-500
                    focus:border-blue-500 block w-full shadow-sm sm:text-sm
                    border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-3 sm:col-span-3 lg:col-span-2">
                  <label
                    htmlFor="state"
                    className="block text-sm font-medium text-gray-700">
                    Minimalbestand
                  </label>
                  <input
                    type="number"
                    name="min_stock"
                    onChange={(e) => handleChange(e, 'number')}
                    value={itemData.min_stock}
                    className="mt-1 focus:ring-blue-500
                    focus:border-blue-500 block w-full shadow-sm
                    sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 col-start-1">
                  <WarehousesDD
                    selectedWarehouseC={[
                      selectedWarehouse,
                      setSelectedWarehouse,
                    ]}></WarehousesDD>
                </div>

                <div
                  className="col-span-3
                  sm:col-span-2 col-start-1 lg:col-span-2">
                  <label
                    htmlFor="price"
                    className="block text-sm font-medium text-gray-700">
                    Verkaufspreis
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div
                      className="absolute inset-y-0 left-0 pl-3
                      flex items-center pointer-events-none">
                      <span className="text-gray-500 sm:text-sm">€</span>
                    </div>
                    <input
                      type="number"
                      name="retail_price"
                      onChange={(e) => handleChange(e, 'number')}
                      value={itemData.retail_price}
                      className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pl-7 pr-12 sm:text-sm
                        border-gray-300 rounded-md"
                      placeholder="0,00"
                      aria-describedby="price-currency"
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex
                      items-center pointer-events-none">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency">
                        EUR
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-span-3 sm:col-span-2 lg:col-span-1">
                  <label
                    htmlFor="price"
                    className="block text-sm font-medium text-gray-700">
                    Steuer
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="number"
                      name="tax"
                      onChange={(e) => handleChange(e, 'number')}
                      value={itemData.tax}
                      className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pr-8 sm:text-sm border-gray-300
                        rounded-md"
                      placeholder="0,00"
                      aria-describedby="price-currency"
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex
                      items-center pointer-events-none">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency">
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6 w-full max-w-6xl">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Kontaktspezifische Daten
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Hier können Artikelinformationen, wie z.B ein Verkaufspreis,
                mit einem Kontakt verknüpft werden (optional).
              </p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <ContactsDD
                    selectedContactC={[
                      selectedContact,
                      setSelectedContact,
                    ]}></ContactsDD>
                </div>

                <div className="col-span-3 sm:col-span-6 lg:col-span-2">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-700">
                    Art
                  </label>
                  <select
                    defaultValue=''
                    name="contact_specific_field_type"
                    onChange={(e) => {
                      setContactSpecificData({
                        ...contactSpecificData,
                        value: '',
                        value_type: e.target.value,
                      });
                    }}
                    className="mt-1 block w-full py-2 px-3 border
                      border-gray-300
                      bg-white rounded-md shadow-sm focus:outline-none
                      focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                    <option></option>
                    <option value='price'>Preis</option>
                    <option value='code'>Artikelcode</option>
                  </select>
                </div>

                <div className="col-span-3 sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700">
                    Wert
                  </label>
                  <input
                    type="text"
                    name="contact_specific_field_value"
                    onChange={(e) => {
                      setContactSpecificData({
                        ...contactSpecificData,
                        value: contactSpecificData.value_type === 'price' ?
                          Number(e.target.value) : e.target.value,
                      });
                    }}
                    value={contactSpecificData.value}
                    autoComplete="family-name"
                    className="mt-1 focus:ring-blue-500
                    focus:border-blue-500 block w-full shadow-sm
                    sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 md:col-span-2
                   flex items-end justify-end">
                  <button
                    onClick={function () {
                      const csf = itemData.contact_specific_fields;
                      const id = selectedContact;
                      // Init custom Fields Object
                      if (!csf[id]) csf[id] = {};

                      csf[id][contactSpecificData.value_type] =
                        contactSpecificData.value;
                      csf[id].contact_id = id;
                      setItemData({
                        ...itemData,
                        contact_specific_fields: csf,
                      });
                      // setContactSpecificData(csfDataStructure);
                      // setSelectedContact('');
                    }}
                    type="button"
                    className="inline-flex items-center px-4 py-2 border
                    border-transparent shadow-sm text-sm font-medium
                    rounded-md text-white bg-blue-600 hover:bg-blue-700
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-blue-500">
                    <MdAddCircle
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Hinzufügen
                  </button>
                </div>

                <div className="col-span-6 max-h-48 overflow-auto">
                  <ul className="divide-y divide-gray-200">
                    {itemData.contact_specific_fields &&
                      Object.values(itemData.contact_specific_fields).map(
                        (element) => (
                          <li key={element.contact_id}>
                            <div
                              className="flex items-center px-4
                              py-4 sm:px-6">
                              <div
                                className="min-w-0 flex-1 flex
                                items-center">
                                <div
                                  className="min-w-0 flex-1 px-4
                                    md:grid
                              md:grid-cols-2 md:gap-4">
                                  <div>
                                    <p
                                      className="text-sm font-medium
                                  text-blue-600 truncate">
                                      {(() => {
                                        console.log(element.contact_id);

                                        const res = contacts
                                          .find(function (el) {
                                            const cid = element.contact_id;
                                            return el.id === cid;
                                          });

                                        if (res) return res.name;
                                      })()
                                      }
                                    </p>
                                  </div>
                                  <div className="hidden md:block">
                                    <div>
                                      {' '}
                                      {/* TODO */}
                                      <p className="text-sm text-gray-900">
                                        Ein-/Verkaufspreis:
                                        {element['price'] &&
                                          ` ${numberFormatter(
                                            element['price'])}€`}
                                      </p>
                                      <p className="text-sm text-gray-900">
                                        Artikelcode: {element['code']}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <button
                                  onClick={() => {
                                    const csf = itemData
                                      .contact_specific_fields;

                                    delete csf[element.contact_id];


                                    setItemData({
                                      ...itemData,
                                      contact_specific_fields: csf,
                                    });
                                  }}>

                                  <MdDelete
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </li>
                        ),
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="flex justify-end w-full max-w-6xl p-2 md:py-4 mx-auto">
        <button
          onClick={() => history && history.goBack()}
          className="bg-white py-2 px-4 border border-gray-300
          rounded-md shadow-sm text-sm font-medium text-gray-700
          hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2
          focus:ring-blue-500">
          Abbrechen
        </button>
        <button
          disabled={isSaving}
          onClick={() => handleSubmit()}
          className="ml-3 inline-flex justify-center py-2 px-4 border
          border-transparent shadow-sm text-sm font-medium rounded-md
          text-white bg-blue-600 hover:bg-blue-700 focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Speichern
        </button>
      </div>
    </div>
  );
}

ItemPopup.propTypes = {
  location: PropTypes.object,
  state: PropTypes.object,
  bdId: PropTypes.string,
  history: PropTypes.object,
};

import React, {useEffect, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {MdContentCopy, MdDelete,
  MdArrowForward, MdFileDownload, MdEdit} from 'react-icons/md';
import {dataToPdf} from '../utilities';
import {AuthContext, mdb} from '../context/AuthContext';
// import Localbase from 'localbase';
import {RerenderContext, updateDoc} from '../cud';
// const lb = new Localbase('E3BYTC3e42z8XLiLTK3K');

/**
 *
 * @param {string} param0
 * @return {obj} jsx
 */
export default function ShowDocument({showDocumentC}) {
  const [doc, setDoc] = showDocumentC;
  const [pdf, setPdf] = useState(null);

  const {teamC, isOfflineC} = useContext(AuthContext);
  const currentTeam = teamC[0];
  const isOffline = isOfflineC[0];
  const [teamDoc, setTeamDoc] = useState({});


  const [rerender, setRerender] = useContext(RerenderContext);

  // Load Data
  useEffect(async function() {
    if (currentTeam) {
      const teamDoc = await mdb.collection('meta').doc('team').get();
      setTeamDoc(teamDoc);
    }
  }, [rerender]);


  useEffect(function() {
    if (doc) {
      setPdf('spinner');
      dataToPdf(doc,
          teamDoc.show_sender_address || false,
          currentTeam.id,
          teamDoc.external_letter_paper_url).then(
          (url) => {
            setPdf(url);
          },
      );
    }
  }, [doc]);

  return (
    <div
      onClick={() => setDoc(null)}
      style={{display: doc ? 'flex' : 'none'}}
      className="h-full w-full flex-col absolute right-0 bg-black px-16
      py-8 overflow-hidden z-30 bg-opacity-50 xl:px-32">
      <div className='flex w-full justify-end'>
        <div className='grid grid-cols-6 p-3 bg-white rounded-md
       w-80 mb-4'>
          <div
            className="flex-center text-sm text-gray-500">
            <Link
              to={{
                pathname: '/documents/input',
                state: {docData: doc && doc.id && {...doc,
                  created_date: null,
                  id: null,
                  type: {}}},
              }}
              className="">
              <MdContentCopy size="1.3rem" />
            </Link>
          </div>
          <div
            className="flex-center text-sm text-gray-500">
            <div className="dropdown">
              <MdArrowForward size="1.3rem"></MdArrowForward>
              <div className=" dropdown-content z-10
                         origin-top-right absolute
                         right-0 top-3
                          mt-2 w-48 rounded-md shadow-lg bg-white
                          ring-1 ring-black ring-opacity-5 focus:outline-none">
                {(() => {
                // Check Type name, and display next Types
                  let links;
                  const name = doc && doc.id && doc.type.name;
                  if (name === 'Angebot') {
                    links = ['Auftragsbestätigung', 'Rechnung'];
                  } else if (name === 'Auftragsbestätigung') {
                    links = ['Rechnung'];
                  } else if (name === 'Rechnung') {
                    links = ['Lieferschein', 'Gutschrift'];
                  } else if (name === 'Lieferschein') {
                    links = ['Rechnung'];
                  } else {
                    links = [];
                  }
                  return (
                    links.map((el) => (
                      <Link
                        key={el}
                        to={{
                          pathname: '/documents/input',
                          state: {docData: doc && doc.id &&
                           {...doc,
                             created_date: null,
                             id: null,
                             type: {select: el}}},
                        }}
                        className='flex justify-start
                                     px-4 py-2 w-full text-sm
                                      hover:bg-gray-100'>{el}
                      </Link>
                    ))
                  );
                })()}

              </div>
            </div>
          </div>
          <div></div>
          <div
            className="flex-center text-sm text-gray-500">
            <div className="dropdown">
              <MdFileDownload size="1.3rem"></MdFileDownload>
              <div className=" dropdown-content z-10
                         origin-top-right absolute
                         right-0 top-3
                          mt-2 w-48 rounded-md shadow-lg bg-white
                          ring-1 ring-black ring-opacity-5 focus:outline-none">
                <button
                  // Add Auto Tag
                  onClick={(e) => {
                    e.stopPropagation();
                    dataToPdf(doc,
                        teamDoc
                            .show_sender_address || false,
                        currentTeam.id)
                        .then((blob) => {
                          const a = document.createElement('a');

                          a.href = blob;
                          a.download =
                                     `${doc.type.name} ${
                                       doc.type.abbreviation} ${
                                       doc.type.number} `;
                          document.body.appendChild(a);
                          // we need to append
                          // the element to the dom ->
                          // otherwise it will not work in firefox
                          a.click();
                          a.remove();
                          // afterwards we remove the element again
                        });
                    // Get current Document Tags
                    // and auto Tagging Settings
                    const tags = doc.tags;
                    const settings = teamDoc.auto_tagging;
                    // If Auto Tagging on Download is set, push
                    // Tag to array (if it isn't already in there)
                    if (settings && settings.onDownload) {
                      !tags.includes(settings.onDownload) &&
                                tags.push(settings.onDownload);
                    }
                    // Save to DB
                    updateDoc(currentTeam.id, 'documents', doc.id, {
                      ...doc,
                      tags: tags,
                    }, setRerender, isOffline)
                        .catch((e) => {
                          addToast('error', `Fehler beim Herunterladen`);
                          return console.log(e);
                        });
                  }}
                  className='flex justify-start px-4 py-2 w-full
                            text-sm hover:bg-gray-100'>
                              Blank
                </button>
                <button
                  // Add Auto Tag
                  onClick={(e) => {
                    e.stopPropagation();

                    // Client Side using JSPDF
                    // const pdfDoc =
                    // new JsPDF('portrait', 'pt', 'A4');
                    // pdfDoc.html(
                    //     template(doc, letterPaper), {
                    //       callback: () => {
                    //         pdfDoc.save('test.pdf');
                    //       },
                    //     });

                    // PDF Server

                    const a = document.createElement('a');

                    a.href = pdf;
                    a.download =
                                     `${doc.type.name} ${
                                       doc.type.abbreviation} ${
                                       doc.type.number} `;
                    document.body.appendChild(a);
                    // we need to append
                    // the element to the dom ->
                    // otherwise it will not work in firefox
                    a.click();
                    a.remove();
                    // afterwards we remove the element again


                    // Get current Document Tags
                    // and auto Tagging Settings
                    const tags = doc.tags;
                    const settings =
                               teamDoc.auto_tagging;
                    // If Auto Tagging on Download is set, push
                    // Tag to array (if it isn't already in there)
                    if (settings && settings.onDownload) {
                      !tags.includes(settings.onDownload) &&
                                tags.push(settings.onDownload);
                    }
                    // Save to DB
                    updateDoc(currentTeam.id, 'documents', doc.id, {
                      ...doc,
                      tags: tags,
                    }, setRerender, isOffline)
                        .catch((e) => {
                          addToast('error', `Fehler beim Herunterladen`);
                          return console.log(e);
                        });
                  }}
                  className='flex justify-start px-4 py-2 w-full
                            text-sm hover:bg-gray-100'>
                              Briefpapier
                </button>
              </div>
            </div>
          </div>
          <div
            className="flex-center text-sm text-gray-500">
            <Link
              to={{
                pathname: '/documents/input',
                state: {docData: doc && doc.id &&
                 {...doc, id: doc.id}},
              }}
              className="">
              <MdEdit size="1.3rem" />
            </Link>
          </div>
          <div
            className="flex-center text-sm text-gray-500">
            <button
              // onClick={async () => {
              // TODO DELETE DOC
              // }}
            >
              <MdDelete size="1.3rem" />
            </button>
          </div>
        </div>

      </div>
      <div
        onClick={function(e) {
          e.stopPropagation();
        }}
        className="bg-white w-full shadow overflow-hidden sm:rounded-lg
        flex-center flex-grow">
        {pdf === 'spinner' ? (
          <div className="w-16 h-16">
            <div className="spinner"></div>
          </div>
        ) : (
          <iframe
            className="h-full w-full"
            src={pdf}
            type="application/pdf"
            frameBorder="0"></iframe>
        )}

        {/* React-pdf Library */}

        {/* {doc &&
        <PDFViewer width='100px'showToolbar={false} className='w-full h-full'>
          <Document>
            <Page size="A4" style={styles.page}>
              {doc &&
              <Html>{html(doc, letterPaper)}</Html>
              }
            </Page>
          </Document>
        </PDFViewer>
        } */}
      </div>
    </div>
  );
}

ShowDocument.propTypes = {
  showDocumentC: PropTypes.array,
};

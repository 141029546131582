/** Shows Item Details */
import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {millsToDate} from '../utilities';
// import Localbase from 'localbase';
import {RerenderContext} from '../cud';
import {AuthContext} from '../context/AuthContext';
import {MdClose} from 'react-icons/md';
import Localbase from 'localbase';


/**
 *
 * @return {object} jsx
 */
export default function ShowContact({showContactC}) {
  const rerender = useContext(RerenderContext);
  const {teamC} = useContext(AuthContext);
  const currentTeam = teamC[0];
  const [contactGroups, setContactGroups] = useState([]);

  const [showContact, setShowContact] = showContactC;
  const contact = showContact.contactData;

  // Load Data
  useEffect(async function() {
    if (currentTeam) {
      const lb = new Localbase(currentTeam.id);
      // lb.config.debug = false;

      const contactGroups = await lb.collection('contact_groups').get();
      setContactGroups(contactGroups);
    }
  }, [rerender, currentTeam]);


  return (
    <button
      onClick={function() {
        setShowContact({display: 'none', contactData: {}});
      }}
      style={{display: showContact.display}}
      className="fill_container absolute right-0 bg-black
       p-0 md:p-16 overflow-hidden flex-center
      z-50 bg-opacity-50">

      <div onClick={function(e) {
        e.stopPropagation();
      }} className="bg-white shadow max-w-5xl overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex justify-between">
          <div className='flex flex-col items-start'>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {contact.name}</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {`${contact.number}`}{' '}
              <span
                className={`px-2 inline-flex text-xs leading-5 
                      font-semibold rounded-full ${
                        contact.type === 'Lieferant' ?
                          'bg-yellow-100 text-yellow-800' :
                          'bg-green-100 text-green-800'
    }`}>
                {contact.type || 'Kunde'}
              </span></p>
          </div>
          <button
            onClick={function() {
              setShowContact({display: 'none', contactData: {}});
            }}>
            <MdClose size='2rem'></MdClose>
          </button>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6 text-left">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1 ">
              <dt className={`text-sm font-medium text-gray-500 ${
                !contact.custom_fields ? 'hidden' : ''}`}>Daten</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {contact.custom_fields &&
                  contact.custom_fields.map((el, index) => (
                    <p key={index}>
                      {el.custom_field_name}: {el.custom_field_value}
                    </p>
                  ))}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className={`text-sm font-medium text-gray-500 ${
                !contact.addresses ? 'hidden' : ''}`}>Adressen</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {contact.addresses &&
                  contact.addresses.map((el, index) => (
                    <p key={index}>
                      {`${index+1}. ${el.salutation} ${el.name},
                      ${el.address}, ${el.postal_code} ${el.city}`}
                    </p>
                  ))}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className={`text-sm font-medium text-gray-500 ${
                !contact.groups ||
                 !contact.groups.length ? 'hidden' : ''}`}>Gruppen</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {contact.groups && contact.groups.map((el, index) => {
                  const element = contactGroups.find((tag) => tag.id === el);
                  if (element) {
                    return (
                      <div
                        key={el}
                        className={`px-2 flex-center h-6 w-40 overflow-hidden
               text-xs leading-5 font-semibold rounded-full bg-${
                      element.color || 'gray'
                      }-100 text-${element.color || 'gray'}-800`}>
                        <p>{element.text || element.name}</p>
                      </div>
                    );
                  }
                })}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className={`text-sm font-medium text-gray-500 ${
                !contact.vat_nr ? 'hidden' : ''}`}>
                Umsatzsteuer Id</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {`${contact.vat_nr}`}</dd>
            </div>
            <div className="sm:col-span-2">
              <dt className={`text-sm font-medium text-gray-500 ${
                !contact.document_comment ? 'hidden' : ''}`}>
                Dokumentenkommentar</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {`${contact.document_comment}`}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className={`text-sm font-medium text-gray-500 ${
                !contact.payment_conditions ? 'hidden' : ''}`}>
                Zahlungsbedingungen</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {`${contact.payment_conditions}`}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className={`text-sm font-medium text-gray-500 ${
                !contact.delivery_conditions ? 'hidden' : ''}`}>
                Lieferbedingungen</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {`${contact.delivery_conditions}`}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className={`text-sm font-medium text-gray-500 ${
                !contact.updated_date ? 'hidden' : ''}`}>
                Zuletzt Bearbeitet</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {`${millsToDate(contact.updated_date)}`}
              </dd>
            </div>
          </dl>
        </div>
      </div>

    </button>
  );
}

ShowContact.propTypes = {
  //   location: PropTypes.object,
  //   state: PropTypes.object,
  showContactC: PropTypes.array,
};

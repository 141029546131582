/** Contacts Dropdown
 * Returns Object with Contact Information
 */

import React, {useContext, useState, useEffect} from 'react';
import {MdUnfoldMore} from 'react-icons/md';
import {getSearchResults, limitString} from '../utilities';
import PropTypes from 'prop-types';
import {RerenderContext} from '../cud';
import Localbase from 'localbase';
import {AuthContext} from '../context/AuthContext';
// import Localbase from 'localbase';
// const lb = new Localbase('E3BYTC3e42z8XLiLTK3K');


/**
 *
 * @return {object} jsx
 */
export default function ContactsDD({selectedContactC,
  selectedBillingAddressC, selectedDeliveryAddressC, name = 'Kontakt',
  type = 'contacts'}) {
  const rerender = useContext(RerenderContext);

  // Auth Context
  const {teamC} = useContext(AuthContext);
  const currentTeam = teamC[0];

  // Utility States
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // Selected Contact State that is returned
  const [selectedContact, setSelectedContact] = selectedContactC;

  let setSelectedBillingAddress;
  let setSelectedDeliveryAddress;
  if (selectedBillingAddressC) {
    setSelectedBillingAddress = selectedBillingAddressC[1];
  }
  if (selectedDeliveryAddressC) {
    selectedDeliveryAddressC[1];
  }


  useEffect(
      async function() {
        const lb = new Localbase(currentTeam.id);
        // lb.config.debug = false;
        const contact = await lb.collection(type)
            .doc({id: selectedContact}).get();
        // Updates Input Value when Contact is selected
        if (contact) {
          setInputValue(contact.name);
        } else {
          // empties Input value when Contact is deleted
          setInputValue('');
        }
      },
      [selectedContact, rerender],
  );

  /** Handle Search Input
   * @param {object} value
   */
  async function handleSearchInput(value) {
    setInputValue(value);
    setShowDropdown(true);
    const lb = new Localbase(currentTeam.id);
    // lb.config.debug = false;

    const database = await lb.collection(type).get();
    const elements = database.filter((doc) => !doc.deleted);
    if (value && value.length > 0) {
      setSearchResults(getSearchResults(value, elements));
    } else {
      setSearchResults(elements);
    }
  }

  /**
   *
   * @param {object} el
   */
  function handleSelect(el) {
    setSelectedContact(
        // id: el.id,
        // name: el.name || '',
        // number: el.number || '',
        // vat_nr: el.vat_nr || '',
        // document_comment: el.document_comment || '',
        // payment_conditions: el.payment_conditions || '',
        // delivery_conditions: el.delivery_conditions || '',
        el.id,
    );
    setShowDropdown(false);
    setSelectedBillingAddress && setSelectedBillingAddress('');
    setSelectedDeliveryAddress && setSelectedDeliveryAddress('');
  }

  /**
   * Adds a new PCat and selects it
   * @param {string} name Name of Product Category
   */
  // function addContact(name) {
  //   setShowDropdown(false);
  //   // Add new Product Category
  //   addDoc('teams', currentTeam.id, 'contacts',
  //       contacts, {
  //         name: name,
  //         blocked: false,
  //       }).then((arr) => {
  //     handleSelect(arr[0].id);
  //   });
  // }

  return (
    <div className='relative'>
      <div className="flex justify-between">
        <label
          htmlFor="account-number"
          className="block text-sm font-medium text-gray-700">
          {name}
        </label>
        {/* {inputValue.length > 0 &&
          searchResults.length === 0 &&
           (
          <span className="text-sm text-gray-500" id="email-optional">
              &quot;Enter&quot; drücken für neuen Kunden.
          </span>
        )} */}
      </div>

      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          onKeyDown={(e) => {
            e.keyCode === 13 && e.preventDefault();

            // Save new Contact
            // e.keyCode === 13 &&
            //   inputValue.length > 0 &&
            //   searchResults.length === 0 &&
            //   selectedContact.length === 0 &&
            //   console.log('New Kunde saved.');

            // Select first result on ENTER
            e.keyCode === 13 &&
              searchResults.length > 0 &&
              handleSelect(searchResults[0]);
          }}
          // Toggle Dropdown depending on Focus
          onFocus={() => {
            setShowDropdown(true);
            setInputValue('');
            setSelectedContact('');
            handleSearchInput('');
          }}
          onBlur={() => setShowDropdown(false)}
          onInput={(e) => handleSearchInput(e.target.value)}
          value={inputValue}
          autoComplete="off"
          type="text"
          className="focus:ring-blue-500 focus:border-blue-500
          block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
        />

        {/* ------------Dynamic Button (for adding new contacts)------------ */}
        {/* <button
          type="button"
          onClick={() => {
            inputValue.length > 0 &&
            searchResults.length === 0 &&
            selectedContact.length === 0
              ? console.log('neues Kunde saved.')
              : setShowDropdown(!showDropdown);
          }}
          className="absolute inset-y-0 right-0 pr-3 flex
        items-center">
          {inputValue.length > 0 &&
          searchResults.length === 0 &&
          selectedContact.length === 0 ? (
            <MdAdd
              className="h-5 w-5 bg-blue-100 text-blue-400 rounded-md"
              aria-hidden="true"
            />
          ) : (
            <MdUnfoldMore
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          )}
        </button> */}

        <button
          tabIndex={-1}
          type="button"
          onClick={() => setShowDropdown(!showDropdown)}
          className="absolute inset-y-0 right-0 pr-3 flex
        items-center">
          <MdUnfoldMore className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      </div>
      <div
        className=" mt-2 p-2 max-h-28 overflow-auto
        rounded-md shadow-lg bg-white ring-1 ring-black
        ring-opacity-5 focus:outline-none
        absolute w-full top-16 z-10"
        style={{display: showDropdown ? 'block' : 'none'}}>
        {searchResults.map((el) => (
          <button
            type="button"
            className="w-full text-left hover:bg-gray-100 rounded-md p-1"
            onMouseDown={() => handleSelect(el)}
            // onClick={(e) => console.log(e)}
            key={el.id}>
            {limitString(el.name, 20)}
          </button>
        ))}
      </div>
    </div>
  );
}
ContactsDD.propTypes = {
  selectedContactC: PropTypes.array,
  selectedBillingAddressC: PropTypes.array,
  selectedDeliveryAddressC: PropTypes.array,
  name: PropTypes.string,
  type: PropTypes.string,
};

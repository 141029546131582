/** Dashboard Component */
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, lb, mdb } from '../context/AuthContext';
import {
  markAsOverdue, numberFormatter,
  lowStock, valArr, millsToTime
} from '../utilities';
// import Localbase from 'localbase';
// const lb = new Localbase('E3BYTC3e42z8XLiLTK3K');
import { RerenderContext } from '../cud';
import { useTranslation } from 'react-i18next';

import {
  MdCompareArrows,
  MdGroup,
  MdInbox,
  MdInsertDriveFile,
  MdList,
  MdNotifications,
  MdStore,
  MdClose,
  MdWarning,
} from 'react-icons/md';
import { millsToDate } from '../utilities';
import { fs } from '../firebase';
import { Link } from 'react-router-dom';
import Localbase from 'localbase';

/**
 *
 * @return {object} jsx
 */
export default function Dashboard() {
  const { t } = useTranslation();
  const { teamC, userC, isOfflineC, isSyncingC } = useContext(AuthContext);
  const currentTeam = teamC[0];
  const currentUser = userC[0];
  const [notifications, setNotifications] = useState([]);
  const isOffline = isOfflineC[0];
  const isSyncing = isSyncingC[0];

  const [userDoc, setUserDoc] = useState({});
  const [teamDoc, setTeamDoc] = useState({});
  const [memberships, setMemberships] = useState([]);
  const rerender = useContext(RerenderContext)[0];

  const [stockWarnings, setStockWarnings] = useState([]);
  const [overdueDocuments, setOverdueDocuments] = useState([]);
  const [log, setLog] = useState([]);

  // Mockup state for displaying the user which collections are being synced
  // It's not real, it only rotates through an array
  const [syncingCollection, setSyncingCollection] = useState('Daten');

  const [stats, setStats] = useState([
    { name: t('dashboard.warehouse_value'), stat: '' },
    { name: 'Netto-Umsatz', stat: '' },
    { name: t('dashboard.new_contacts'), stat: '' },
  ]);

  // Initialize Ticker for syncing Collection State
  useEffect(function () {
    setTimeout(function () {
      setSyncingCollection('Artikel');
    }, 5000);

    setTimeout(function () {
      setSyncingCollection('Dokumente');
    }, 10000);

    setTimeout(function () {
      setSyncingCollection('Kontakte');
    }, 25000);

    setTimeout(function () {
      setSyncingCollection('Daten');
    }, 35000);
  }, []);

  // Load Data
  useEffect(async function () {
    if (currentTeam) {
      const lb = new Localbase(currentTeam.id);
      // lb.config.debug = false;
      const userDoc = await mdb.collection('meta').doc('user').get();
      setUserDoc(userDoc);

      const teamDoc = await mdb.collection('meta').doc('team').get();
      setTeamDoc(teamDoc);

      const memberships = await lb.collection('memberships').get();
      valArr(memberships) && setMemberships(memberships);
    }
  }, [rerender, currentTeam]);

  /**
   * Update Stats
   */
  useEffect(async function () {
    if (currentTeam) {
      const lb = new Localbase(currentTeam.id);
      setTimeout(async () => {
        // Update Warehouse value
        let value = 0;
        const items = await lb.collection('items').get() || [];
        for (const item of items) {
          value += item.stock * item.retail_price;
        }

        // Update Money
        const documents = await lb.collection('documents').get() || [];
        const docArr = documents.filter((el) => {
          if ((Date.now() - el.created_date) < 2592000000 &&
            el.type && el.type.name === 'Rechnung') {
            return true;
          } else return false;
        });

        let money = 0;
        for (const el of docArr) {
          if (el.net_total) money += el.net_total;
        }

        const contacts = await lb.collection('contacts').get() || [];
        // Update new contacts
        const contactsAmount = contacts.filter((el) => {
          return ((Date.now() - el.created_date) < 2592000000);
        }).length;

        setStats([
          { name: 'Gesamtwert Lager', stat: `${numberFormatter(value)}€` },
          { name: 'Netto-Umsatz (30 Tage)', stat: `${numberFormatter(money)}€` },
          { name: 'Neue Kontakte (30 Tage)', stat: contactsAmount },
        ]);
      }, 200);
    }
  }, [currentTeam, rerender]);

  // Load Data
  useEffect(async function () {
    if (currentTeam) {
      const items = await lb.collection('items').get() || [];
      items && setStockWarnings(items.filter(function (doc) {
        return lowStock(doc);
      }));

      const documents = await lb.collection('documents').get() || [];
      documents && setOverdueDocuments(documents.filter((doc) =>
        markAsOverdue(doc)));

      const log = await lb.collection('log')
        .orderBy('created_date', 'desc').limit(50).get() || [];
      log && setLog(log);
    }
  }, [rerender]);

  /** Update notifications */
  useEffect(
    async function () {
      if (memberships) {
        const data = await fs
          .collection('teams')
          .doc(currentTeam.id)
          .collection('memberships')
          .doc(currentUser.uid)
          .collection('notifications')
          .get();
        setNotifications(data.docs);
      }
    },
    [memberships],
  );

  /**
   * Deletes a notification
   * @param {string} ID
   */
  function deleteNotification(ID) {
    fs.collection('teams')
      .doc(currentTeam.id)
      .collection('memberships')
      .doc(currentUser.uid)
      .collection('notifications')
      .doc(ID)
      .delete()
      .then(() => {
        setNotifications(notifications.filter((el) => el.id != ID));
      });
  }

  return (
    <div className="flex-shrink h-full bg-gray-100 flex flex-col">
      <div className="pb-5 border-b border-gray-200 p-4 md:p-8" id='header'>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {t('dashboard.welcome')}, {userDoc && userDoc.name}
        </h3>
        <div className="flex">
          <MdStore size="1.5rem"></MdStore>
          <p>{teamDoc && teamDoc.name}</p>
        </div>
        <div className="flex flex-col show_mobile">
          <Link
            onClick={(e) => isOffline && e.preventDefault()}
            className="cursor-pointer"
            to="/settings/account">
            {/* <MdSettings size='1.5rem' className=''/> */}
            <p className='text-sm text-blue-500'>Einstellungen</p>
          </Link>

          <button
            onClick={() => !isOffline && logout()}>
            {/* <MdExitToApp size='1.5rem' className=""
            /> */}
            <p className='text-sm text-left text-blue-500'>Logout</p>
          </button>
        </div>
      </div>

      {/* --------------------- Loading Placeholder ---------------------- */}
      <div className={`space-y-6 px-4 py-8 md:p-8 overflow-y-auto flex-grow ${isSyncing ? '' : 'hidden'
        }`}>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t('dashboard.overview')}
          </h3>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {stats.map((item) => (
              <div
                key={item.name}
                className="px-4 py-5 bg-white shadow rounded-lg
                  overflow-hidden sm:p-6 animate-pulse">
                <dt className="h-4 bg-gray-400 w-1/2 rounded-md">
                </dt>
                <dd className="mt-5 bg-gray-400 h-4 w-full rounded-md">

                </dd>
              </div>
            ))}
          </dl>
        </div>

        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t('dashboard.activity')}
          </h3>

          <div
            className="bg-white shadow py-6 px-2 md:px-16 mt-5
            rounded-lg max-h-96 overflow-hidden animate-pulse">
            <ul className="-mb-8">
              {(() => {
                const array = [1, 2, 3, 4, 5];
                return array.map((el) => {
                  return (
                    <li key={el}>
                      <div className="relative pb-12">
                        <div className="relative flex space-x-3">
                          <div
                            className="h-8 w-8 rounded-full
                       flex items-center
                justify-center text-white
                ring-8 ring-white bg-gray-400">
                          </div>
                          <div
                            className="min-w-0 flex-1 flex
        justify-between items-center space-x-4">
                            <div className='h-4 w-4/5 rounded-md bg-gray-400'>
                            </div>
                            <div className="text-right
                       text-sm whitespace-nowrap
          bg-gray-400 h-4 w-20 rounded-md">
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                });
              })()}
            </ul>
          </div>

          <div className='animate-pulse flex items-center justify-center mt-8'>
            <p>
              {syncingCollection} werden synchronisiert...
            </p>
          </div>

        </div>
      </div>

      {/* ----------------- Real Section ----------------------- */}
      <div className={`space-y-6 px-4 py-8 md:p-8 overflow-y-auto flex-grow ${isSyncing ? 'hidden' : ''
        }`}>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t('dashboard.overview')}
          </h3>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {stats.map((item) => (
              <div
                key={item.name}
                className="px-4 py-5 bg-white shadow rounded-lg
                  overflow-hidden sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">
                  {item.name}
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {item.stat}
                </dd>
              </div>
            ))}
          </dl>
        </div>

        <div style={{ display: notifications.length > 0 ? 'block' : 'none' }}>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t('dashboard.notifications')}
          </h3>

          <div
            className="bg-white shadow px-4 py-5 mt-5
            rounded-lg sm:p-6 max-h-96 overflow-auto">
            <ul className="divide-y divide-gray-200">
              {notifications.map(function (doc) {
                return (
                  <li key={doc.id} className="">
                    <div
                      className={`flex space-x-3 p-4 rounded-md ${doc.data().highlight === true ? 'bg-red-200' : ''
                        }`}>
                      <div className="flex-center">

                        <MdNotifications className='text-gray-800'
                          size='1.3rem' />
                      </div>
                      <div className="flex-1 space-y-1">
                        <div
                          className="flex flex-col items-start
                                  justify-between">
                          <h3 className="text-sm font-medium">
                            {`${doc.data().title} `}
                          </h3>
                          <p
                            className="text-sm
                              text-gray-700">
                            {doc.data().message}
                          </p>
                        </div>
                        <p className="text-sm text-gray-700 italic">
                          {doc.data().sender_name || ''}
                        </p>
                      </div>
                      <button onClick={() => deleteNotification(doc.id)}>
                        <MdClose className='text-gray-800'
                          size='1.3rem'></MdClose>
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>


        <div style={{
          display: stockWarnings
            .length > 0 ? 'block' : 'none',
        }}>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t('dashboard.stock_warnings')}
          </h3>

          <div
            className="bg-white shadow px-4 py-5 mt-5
            rounded-lg sm:p-6 max-h-96 overflow-auto">
            <ul className="divide-y divide-gray-200">
              {stockWarnings
                .map(function (doc) {
                  return (
                    <li key={doc.id} className="py-4">
                      <div className="flex space-x-3">
                        <MdWarning size='1.3rem' />
                        <div className="flex-1 space-y-1">
                          <div
                            className="flex items-center
                                  justify-between">
                            <div className='flex space-x-2'>
                              <h3 className="text-sm">
                                {`${doc.code}`}
                              </h3>
                              <h3 className="text-sm font-medium">
                                {`${doc.name}`}
                              </h3>
                            </div>
                            <p
                              className="text-sm
                              text-gray-500">
                              {`${doc.stock ? doc.stock : '0'
                                } ${doc.unit ? doc.unit : ''}`}
                            </p>
                          </div>
                          <p className="text-sm text-gray-500">
                            {/* {`${
                                doc.open_amount
                                  ? numberFormatter(doc.open_amount)
                                  : numberFormatter(
                                      doc.gross_total_after_reductions
                                    )
                              }€`} */}
                          </p>
                        </div>
                      </div>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        </div>


        <div style={{
          display:
            overdueDocuments.length > 0 ? 'block' : 'none',
        }}>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t('dashboard.overdue_docs')}
          </h3>


          <div
            className="bg-white shadow px-4 py-5 mt-5
            rounded-lg sm:p-6 max-h-96 overflow-auto">
            <ul className="divide-y divide-gray-200">
              {overdueDocuments
                .map(function (doc) {
                  return (
                    <li key={doc.id} className="py-4">
                      <div className="flex space-x-3">
                        <MdInsertDriveFile size='1.3rem' />
                        <div className="flex-1 space-y-1">
                          <div
                            className="flex items-center
                                  justify-between">
                            <h3 className="text-sm font-medium">
                              {`${doc.type.name} ${doc.type.number
                                }`}
                            </h3>
                            <p
                              className="text-sm
                                    text-gray-500">
                              {doc.contact.name}
                            </p>
                          </div>
                          <p className="text-sm text-gray-500">
                            {`${doc.open_amount ?
                                numberFormatter(doc.open_amount) :
                                numberFormatter(
                                  doc.gross_total_after_reductions,
                                )
                              }€`}
                          </p>
                        </div>
                      </div>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        </div>


        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t('dashboard.activity')}
          </h3>

          <div
            className="bg-white shadow py-6 px-2 md:px-16 mt-5
            rounded-lg max-h-96 overflow-auto">
            <div className="flow-root">
              <ul className="-mb-8">
                {log && log.sort((el1, el2) => {
                  return el2.created_date - el1.created_date;
                })
                  .map((event, eventIdx) => (
                    <li key={event.id}>
                      <div className="relative pb-8">
                        {eventIdx !== log.length - 1 ? (
                          <span
                            className="absolute top-4 left-4
                               -ml-px h-full w-0.5
                      bg-gray-200"
                            aria-hidden="true"
                          />
                        ) : null}
                        <div className="relative flex space-x-3">
                          <div>
                            <span
                              className="h-8 w-8 rounded-full
                                   flex items-center
                            justify-center text-white
                            ring-8 ring-white bg-blue-400">
                              {event.type === 'warehouse' && (
                                <MdInbox />
                              )}
                              {event.type === 'documents' && (
                                <MdInsertDriveFile />
                              )}
                              {event.type === 'contacts' && (
                                <MdGroup />
                              )}
                              {event.type === 'inventories' && (
                                <MdList />
                              )}
                              {event.type === 'manual_bookings' && (
                                <MdCompareArrows />
                              )}
                            </span>
                          </div>
                          <div
                            className="min-w-0 flex-1 pt-1.5 flex
                    justify-between space-x-4">
                            <div>
                              <p className="text-sm text-gray-500">
                                {`${(function () {
                                  const user = memberships
                                    .find((el) => el.id === event
                                      .official_in_charge);
                                  if (user) return user.name;
                                  else return '[Unbekannter User]';
                                })()} hat ${event.name}
                                   ${event.number} ${typeof (event.details) === 'string' ?
                                    event.details : ''} ${event.action
                                  }`}
                                {/* <a
                            href={event.href}
                            className="font-medium text-gray-900">
                            {event.target}
                          </a> */}
                              </p>
                            </div>
                            <div
                              className="text-right
                                   text-sm whitespace-nowrap
                      text-gray-500">
                              <p>
                                {`${millsToTime(event.created_date)} | ${millsToDate(event.created_date)}`}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
// import {AuthContext} from '../context/AuthContext';
import {useLocation} from 'react-router-dom';

/**
 *
 * @return {object} jsx
 */
export default function ContactsSettings({state}) {
  const {pathname} = useLocation();

  const [contactSettings, setContactSettings] =state;

  return (
    <div
      className="space-y-6"
      style={{display: pathname === '/settings/contacts' ? 'block' : 'none'}}>

      {/* <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div>
            <h3
              className="text-lg leading-6
                font-medium text-gray-900">
              Kontaktgruppen
            </h3>
            <p
              className="mt-1 text-sm
                text-gray-500">
              Hier können Sie Kontakte in Gruppen aufteilen.
            </p>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <div
              className="bg-white rounded-lg shadow overflow-hidden
            rounded-md col-span-6">
              <ul className="divide-y divide-gray-200">
                {positions.map((position) => (
                  <li key={position.id}>
                    <a href="#" className="block ">
                      <div
                        className="px-4 py-4 flex items-center
                      sm:px-6 bg-gray-50 hover:bg-gray-100">
                        <div
                          className="min-w-0 flex-1 sm:flex
                        sm:items-center sm:justify-between">
                          <div className="truncate">
                            <div className="flex text-sm">
                              <p
                                className="font-medium
                              text-blue-600 truncate">
                                {position.title}
                              </p>
                              <p
                                className="ml-1 flex-shrink-0
                              font-normal text-gray-500">
                                in {position.department}
                              </p>
                            </div>
                            <div className="mt-2 flex">
                              <div
                                className="flex items-center
                              text-sm text-gray-500">
                                <MdDateRange
                                  className="flex-shrink-0 mr-1.5
                                  h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <p>
                                  Closing on{' '}
                                  <time dateTime={position.closeDate}>
                                    {position.closeDateFull}
                                  </time>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                            <div className="flex overflow-hidden -space-x-1">

                            </div>
                          </div>
                        </div>
                        <div className="ml-5 flex-shrink-0">
                          <MdArrowDropDown
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div> */}

      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div>
            <h3
              className="text-lg leading-6
                font-medium text-gray-900">
              Nummernkreise
            </h3>
            <p
              className="mt-1 text-sm
                text-gray-500">
              Verwalten Sie hier die Nummernkreise ihrer Kontakte.
            </p>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <div
              className=" col-span-6 shadow overflow-auto h-full
          border-b border-gray-200 rounded-lg">
              <table className="min-w-full divide-y
               divide-gray-200 table-fixed">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" style={{width: '25%'}}
                      className="table_header">
                  Typ
                    </th>
                    <th scope="col" style={{width: '25%'}}
                      className="table_header">
                  Nummer
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white
                 rounded-lg divide-y divide-gray-200 ">
                  {contactSettings.nks.map((el, index) => (
                    <tr
                      key={el.name}>

                      <td className='p-2'>
                        <div className="truncate">
                          <div className="flex text-sm">
                            <p
                              className="font-medium
                              text-blue-600 truncate">
                              {el.name}
                            </p>
                            {/* <p
                                  className="ml-1 flex-shrink-0
                              font-normal text-gray-500">
                                in {el.department}
                                </p> */}
                          </div>
                        </div>
                      </td>
                      <td className='p-2'>
                        <input
                          className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pl-10 sm:text-sm border-gray-300
                        rounded-md"
                          value={el.nk}
                          onInput={(e) => {
                            const nks = contactSettings.nks;
                            nks[index].nk = Number(e.target.value);
                            setContactSettings({
                              ...contactSettings,
                              nks: nks,
                            });
                          }}
                          type="text" />
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ContactsSettings.propTypes = {
  state: PropTypes.array,
};

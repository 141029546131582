import firebase from 'firebase';
import 'firebase/auth';

const app = firebase.initializeApp({
  // Old
  // apiKey: 'AIzaSyAM6aiov9G2Vsd8idRMIHSq8xU7MzBivrw',
  // authDomain: 'warenwirtschaft-4906e.firebaseapp.com',
  // projectId: 'warenwirtschaft-4906e',
  // storageBucket: 'warenwirtschaft-4906e.appspot.com',
  // messagingSenderId: '766595098242',
  // appId: '1:766595098242:web:fddd5dab9d4b6c9332769b',
  // measurementId: 'G-3DVJEV6T51',
  // databaseURL: 'https://warenwirtschaft-4906e-default-rtdb.europe-west1.firebasedatabase.app',
  apiKey: 'AIzaSyAs-CkZOyIfhZ1v24BlRIYqCBgAHmBnykw',
  authDomain: 'warest-1f6da.firebaseapp.com',
  projectId: 'warest-1f6da',
  storageBucket: 'warest-1f6da.appspot.com',
  messagingSenderId: '475925062372',
  appId: '1:475925062372:web:6fcca67bdea6a048a1c2f1',
  databaseURL: 'https://warest-1f6da-default-rtdb.europe-west1.firebasedatabase.app',
});

export const auth = app.auth();

export const fs = app.firestore();

export const FieldValue = firebase.firestore.FieldValue;

export const rtdb = app.database();

// export const storage = app.storage();

export default app;

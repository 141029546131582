import { fs } from './firebase';
import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeId, snapListener } from './utilities';
import { AuthContext, mdb } from './context/AuthContext';
import { lb } from './context/AuthContext';
import Localbase from 'localbase';

export const RerenderContext = createContext();

/**
     *@param {object} props
     *
     * @return {obj} a context provider for user data

     *  */
export function RerenderProvider(props) {
  const [rerender, setRerender] = useState('');

  const { teamC, userC, isOfflineC, isSyncingC } = useContext(AuthContext);
  const currentTeam = teamC[0];
  const currentUser = userC[0];
  const isOffline = isOfflineC[0];
  const [, setIsSyncing] = isSyncingC;


  // // Init Localbase
  // useEffect(function() {
  //   if (currentTeam) {
  //     lb = new Localbase(currentTeam.id);
  //     // lb.config.debug = false;
  //   }
  // }, [currentTeam]);

  // // Fetch Online DB
  // useEffect(function() {
  //   if (currentTeam) {
  //     const lb = new Localbase(currentTeam.id);
  //     lb.delete();
  //   }
  //   if (currentUser && currentTeam && false) {
  //     fetchOnlineDb(currentUser.uid, currentTeam.id, setRerender);
  //   }
  // }, [currentUser, currentTeam]);

  useEffect(() => {
    const teamId = localStorage.getItem('teamId');

    if (teamId) {
      Promise.all([
        // Contacts Listener
        snapListener('teams', teamId, 'contacts', setRerender),

        // Documents Listener
        snapListener('teams', teamId, 'documents', setRerender),

        // Contact Groups Listener
        snapListener('teams', teamId, 'contact_groups', setRerender),

        // Log Listener
        snapListener('teams', teamId, 'log', setRerender, 50),

        // Document Comments Listener
        snapListener('teams', teamId, 'document_comments',
          setRerender),

        // Document Types Listener
        snapListener('teams', teamId, 'document_types',
          null),

        // Document Templates Listener
        snapListener('teams', teamId, 'document_templates',
          null),

        // Tags Listener
        snapListener('teams', teamId, 'tags', setRerender),

        // Items Listener
        snapListener('teams', teamId, 'items', setRerender),

        // Warehouses Listener
        snapListener('teams', teamId, 'warehouses',
          setRerender),

        // Product Categories Listener
        snapListener('teams', teamId, 'product_categories',
          setRerender),

        // customPositions Listener
        snapListener('teams', teamId, 'custom_positions',
          setRerender),

        // Inventories Listener
        snapListener('teams', teamId, 'inventories',
          setRerender),

        // Team Addresses Listener
        snapListener('teams', teamId, 'addresses',
          setRerender),

        // Invitations Listener
        snapListener('teams', teamId, 'invitations',
          setRerender),
      ]).then(() => setIsSyncing(false));
    }
  }, [currentTeam]);

  // Team Doc Listener
  useEffect(async function () {
    if (currentTeam) {
      const lb = new Localbase(currentTeam.id);
      // lb.config.debug = false;
      const localDoc = await mdb.collection('meta')
        .doc('team').get();
      fs
        .collection('teams')
        .doc(currentTeam.id)
        .onSnapshot(async (dbDoc) => {
          console.log('TEAM SNAPSHOT triggered');
          if (localDoc) {
            await mdb.collection('meta').doc('team').set(
              { ...dbDoc.data(), id: dbDoc.id, synced: true });
          } else {
            await mdb.collection('meta').add(
              { ...dbDoc.data(), id: dbDoc.id, synced: true }, 'team');
          }

          // Init Sync Cue
          const syncDoc = await lb.collection('meta')
            .doc('sync-cue').get();
          if (!syncDoc) {
            await lb.collection('meta').add({}, 'sync-cue');
          }
          setRerender('');
          setRerender('team');
        });
    }
  }, [currentTeam]);

  // User Doc Listener
  useEffect(async function () {
    if (currentUser && currentTeam) {
      console.log(currentTeam.id);
      const localDoc = await mdb.collection('meta')
        .doc('user').get();
      console.log(localDoc);
      fs
        .collection('users')
        .doc(currentUser.uid)
        .onSnapshot(async (dbDoc) => {
          if (localDoc) {
            await mdb.collection('meta').doc('user').set(
              { ...dbDoc.data(), id: dbDoc.id, synced: true });
          } else {
            await mdb.collection('meta').add(
              { ...dbDoc.data(), id: dbDoc.id, synced: true }, 'user');
          }

          setRerender('');
          setRerender('user');
        });
    }
  }, [currentUser, currentTeam]);

  // Sync Cue
  useEffect(async function () {
    if (!isOffline && currentTeam) {
      const lb = new Localbase(currentTeam.id);
      // lb.config.debug = false;
      const syncCue = await lb.collection('meta').doc('sync-cue').get();
      if (!syncCue) return;
      for (const [key, val] of Object.entries(syncCue)) {
        console.log('syncing:' + key);
        const doc = await lb.collection(val.collection)
          .doc({ id: key }).get();
        if (val.type === 'create') {
          await createFs(val.teamId,
            val.collection, doc, key, Date.now());
        } else if (val.type === 'update') {
          await updateFS(val.teamId, val.collection, key, doc);
        } else if (val.type === 'delete') {
          await deleteFS(val.teamId, val.collection, key);
        }
        const cue = await lb.collection('meta').doc('sync-cue').get();
        delete cue[key];
        await lb.collection('meta').doc('sync-cue').set(cue);
      }
    }
  }, [isOffline, currentTeam]);

  // Memberships Listener
  useEffect(() => {
    currentTeam &&
      snapListener('teams', currentTeam.id, 'memberships',
        setRerender);
  }, [currentTeam]);

  return (
    <div>
      <RerenderContext.Provider
        value={[rerender, setRerender]}>
        {props.children}
      </RerenderContext.Provider>
    </div>
  );
}

RerenderProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};


const teamCollections = [
  'documents',
  'document_comments',
  'document_types',

  'contacts',
  'contact_groups',

  'items',
  'warehouses',
  'product_categories',
  'custom_positions',

  'addresses',
  'tags',
  // 'log',
];

/** Fetch Online Database and write to indexed DB
 * @param {string} teamId Team ID
 * @param {string} collection collection to fetch
 * @param {function} rerender rerender Callback func
 * @param {number} limit Limit number of Docs
 */
export async function fetchOnlineDocs(teamId, collection, rerender, limit) {
  // await lb.collection(collection).delete();
  try {
    let dbDocs;
    if (limit) {
      dbDocs = await fs
        .collection('teams')
        .doc(teamId)
        .collection(collection)
        .orderBy('created_date', 'desc')
        .limit(limit)
        .get();
    } else {
      dbDocs = await fs
        .collection('teams')
        .doc(teamId)
        .collection(collection)
        .get();
    }


    for (const doc of dbDocs.docs) {
      const localDoc = await lb.collection(collection).doc({ id: doc.id }).get();
      if (localDoc) {
        await lb.collection(collection).doc({ id: doc.id }).set(
          { ...doc.data(), id: doc.id, synced: true });
      } else {
        await lb.collection(collection).add(
          { ...doc.data(), id: doc.id, synced: true });
      }
    }
  } catch (e) {
    console.log('Error while fetching online Docs');
    console.log(e);
  }

  // Re Render Components
  rerender && rerender('');
  rerender && rerender(collection);
}

/** Fetch Online Database and write to indexed DB
 * @param {boolean} userId User ID
 * @param {boolean} teamId Team ID
 * @param {function} rerender rerender Callback func
 */
export async function fetchOnlineDb(userId, teamId, rerender) {
  lb.delete();
  for (const col of teamCollections) {
    try {
      const dbDocs = await fs
        .collection('teams')
        .doc(teamId)
        .collection(col)
        .get();

      for (const doc of dbDocs.docs) {
        await lb.collection(col)
          .add({ ...doc.data(), id: doc.id, synced: true });
      }
    } catch (e) {
      console.log(e);
    }
  }

  // Re Render Components
  rerender && rerender('');
  rerender && rerender('all');
}

/**
 * creates a document
 * @param {string} teamId
 * @param {string} collection
 * @param {object} docData
 * @param {string} callback
 * @param {boolean} isOffline
 */
export async function createDoc(teamId, collection, docData, callback,
  isOffline) {
  console.log('CREATE DOC TRIGGERED, collection ' + collection);
  const key = makeId(20);
  const date = Date.now();
  // Create Mock Obj in LB
  const lb = new Localbase(teamId);
  await lb.collection(collection).add({
    ...docData, id: key,
    created_date: date, updated_date: date
  });

  // Re Render Components
  callback && callback(collection);
  console.log(isOffline);
  return createFs(teamId, collection, docData, key, date, isOffline);
}

/**
 *
 * @param {string} teamId
 * @param {string} collection
 * @param {object} docData
 * @param {string} key
 * @param {any} date
 * @param {boolean} isOffline
 *
 */
async function createFs(teamId, collection, docData, key, date, isOffline) {
  // Fetch Local Document
  const lb = new Localbase(teamId);

  const lDoc = await lb.collection(collection).doc({ id: key }).get();
  const data = { ...lDoc };
  let createdDate;
  // let currentNumber;
  // let internalNumber;
  // const teamDoc = await fs.collection('teams')
  //     .doc(teamId).get();
  // Fetch Document, try to write it to FS
  if (!isOffline) {
    try {
      // Get Number Circles
      // if (collection === 'documents') {
      //   if (!docData.type.number) {
      //     createdDate = docData.created_date;
      //     const typeDoc = await fs.collection('teams')
      //         .doc(teamId).collection('document_types')
      //         .doc(docData.type.id).get();
      //     currentNumber = typeDoc.data().number;
      //     data.type.number = currentNumber;
      //   }


      //   // if Internal Nr doesn't exist, fetch it and save into doc Data
      //   if (!docData.internal_nr) {
      //     internalNumber = teamDoc.data().internal_nk || '';
      //     data.internal_nr = internalNumber;
      //   }
      // }

      // if (collection === 'contacts') {
      //   if (docData.type === 'Lieferant') {
      //     currentNumber = teamDoc.data().supplier_nk;
      //   } else if (docData.type === 'Kunde') {
      //     currentNumber = teamDoc.data().customer_nk;
      //   }
      //   data.number = currentNumber;
      // }

      // // Write it to FS
      // await fs.collection('teams').doc(teamId)
      //     .collection(collection).doc(key).set(
      //         {...data,
      //           deleted: false,
      //           created_date: createdDate || date,
      //           updated_date: date,
      //           // local_id: key,
      //         });

      console.log('SENDING POST REQUEST')
      const res = await fetch(`https://gateway-v2.warest.de/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            ...data,
            deleted: false,
            created_date: createdDate || date,
          },
          teamId,
          collection,
          key,
        })
      })
      console.log(await res.json());

      //     // Increment Number Circles
      //     if (collection === 'documents') {
      //       await fs.collection('teams')
      //           .doc(teamId).collection('document_types')
      //           .doc(docData.type.id).set({
      //             number: Number(currentNumber) + 1}, {merge: true});
      //       // Check if Internal Nr exists
      // (Only exists when continuing document)
      //       // If it doesn't, incremenet Internal NK in database
      //       if (!docData.internal_nr) {
      //         await fs
      //             .collection('teams')
      //             .doc(teamId)
      //             .set({internal_nk:
      // Number(internalNumber) + 1}, {merge: true});
      //       }
      //     }
      //     // Increment Contact NK
      //     if (collection === 'contacts') {
      //       const obj = docData.type === 'Lieferant' ?
      //  {supplier_nk: Number(currentNumber) + 1} :
      //   {customer_nk: Number(currentNumber) + 1};
      //       await fs
      //           .collection('teams')
      //           .doc(teamId)
      //           .set(obj, {merge: true});
      //     }
    } catch (e) {
      console.log(e);

      const lb = new Localbase(teamId);
      const obj = {};
      obj[key] = {
        teamId: teamId,
        collection: collection,
        docId: key,
        type: 'create',
      };
      lb.collection('meta').doc('sync-cue').update(obj);
    }
  } else {
    // Add Doc to sync cue. As soon as the client reconnects, the sync
    // cue is checked, and documents in it are synced to fs
    const lb = new Localbase(teamId);
    const obj = {};
    obj[key] = {
      teamId: teamId,
      collection: collection,
      docId: key,
      type: 'create',
    };
    lb.collection('meta').doc('sync-cue').update(obj);
  }
}

/** Edit Document
 * @param {string} teamId Team ID
 * @param {string} collection Collection
 * @param {string} docId Document Id
 * @param {object} docData Doc Data
 * @param {function} callback Callback function
 * @param {boolean} isOffline
 *
 *
 */
export async function updateDoc(teamId, collection, docId, docData, callback,
  isOffline) {
  // Create Mock Obj in LB
  await lb.collection(collection).doc({ id: docId })
    .set({ ...docData, synced: false, id: docId, updated_date: Date.now() });

  // Re Render Components
  callback && callback('');
  callback && callback(collection);

  // Fetch Local Document
  const lDoc = await lb.collection(collection).doc({ id: docId }).get();
  const data = { ...lDoc };

  // Fetch new Number Circle if Document Type has been edited
  if (collection === 'documents' && !docData.type.number) {
    const typeDoc = await fs.collection('teams')
      .doc(teamId).collection('document_types')
      .doc(docData.type.id).get();
    const currentNumber = typeDoc.data().number;
    data.type.number = currentNumber;
  }

  // Edit Online Doc
  await updateFS(teamId, collection, docId, data, isOffline);
}

/**
 *
 * @param {string} teamId Team Id
 * @param {string} collection
 * @param {string} docId
 * @param {object} data Doc Data
 * @param {boolean} isOffline
 *
 */
async function updateFS(teamId, collection, docId, data, isOffline) {
  if (!isOffline) {
    console.log('writing to FS...');
    try {
      await fs
        .collection('teams')
        .doc(teamId)
        .collection(String(collection))
        .doc(docId)
        .set({ ...data, updated_date: Date.now() }, { merge: true });
    } catch (e) {
      console.log(e);
      const lb = new Localbase(teamId);
      // lb.config.debug = false;
      const obj = {};
      obj[docId] = {
        teamId: teamId,
        collection: collection,
        docId: docId,
        type: 'update',
      };
      await lb.collection('meta').doc('sync-cue').update(obj);
    }
  } else {
    const lb = new Localbase(teamId);
    // lb.config.debug = false;
    const obj = {};
    obj[docId] = {
      teamId: teamId,
      collection: collection,
      docId: docId,
      type: 'update',
    };
    await lb.collection('meta').doc('sync-cue').update(obj);
  }
}

/** delete Document
 * @param {string} teamId Team ID
 * @param {string} collection Collection
 * @param {string} docId Document Id
 * @param {function} callback Callback function
 * @param {boolean} isOffline
 *
 *
 * @return {array} Array with all docs, including added one
 */
export async function deleteDoc(teamId, collection, docId, callback,
  isOffline) {
  // Get local doc
  const docData = await lb.collection(collection).doc({ id: docId }).get();
  // delete Local Doc
  await lb.collection(collection).doc({ id: docId })
    .update({ ...docData, deleted: true, synced: false });

  // Re Render Components
  callback && callback('');
  callback && callback(collection);

  await deleteFS(teamId, collection, docId, docData, isOffline);
}
/**
 *
 * @param {string} teamId
 * @param {string} collection
 * @param {string} docId
 * @param {object} docData
 * @param {boolean} isOffline
 *
 */
async function deleteFS(teamId, collection, docId, docData, isOffline) {
  // flag Online Doc
  if (!isOffline) {
    try {
      await fs
        .collection('teams')
        .doc(teamId)
        .collection(String(collection))
        .doc(docId)
        .set({
          ...docData, deleted: true,
          updated_date: Date.now()
        }, { merge: true });
    } catch (e) {
      console.log(e);
      const lb = new Localbase(teamId);
      // lb.config.debug = false;
      const obj = {};
      obj[docId] = {
        teamId: teamId,
        collection: collection,
        docId: docId,
        type: 'delete',
      };
      lb.collection('meta').doc('sync-cue').update(obj);
    }
  } else {
    const lb = new Localbase(teamId);
    // lb.config.debug = false;
    const obj = {};
    obj[docId] = {
      teamId: teamId,
      collection: collection,
      docId: docId,
      type: 'delete',
    };
    lb.collection('meta').doc('sync-cue').update(obj);
  }
}



import React, {Fragment} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {
  MdInbox,
  MdInsertDriveFile,
  MdGroup,
  MdAdd,
  MdDashboard,
} from 'react-icons/md';
import {Menu, Transition} from '@headlessui/react';


/**
 * sidebar element containing all
 * navigation elements for the app
 * @return {object} jsx
 */
export default function MobileSidebar() {
  const {pathname} = useLocation();

  return (
    <div id='sidebar' style={{display: pathname === '/' ||
     pathname === '/register' ? 'none' : ''}}
    className="bg-color_1 w-full
     flex flex-col text-white md:hidden h-16">

      <div id='sections' className="w-full grid grid-cols-5
       bg-blue-500 justify-center">

        <Link
          to="/dashboard"
          className="w-full h-16
        flex-center flex-shrink-0">
          <MdDashboard className={`${
            pathname === '/dashboard' ?'bg-blue-400 rounded-md' : ''}
             w-12 h-12 p-3`}/>
        </Link>

        <Link to="/warehouse" className='flex-center w-full h-full'>
          <MdInbox className={`${
            pathname === '/warehouse' ?'bg-blue-400 rounded-md' : ''}
             w-12 h-12 p-3`}/>
        </Link>

        <Menu as="div" className="relative inline-block z-40">
          <div className="h-full w-full flex-center">
            <div className='flex-center
           bg-blue-400 rounded-full h-16 w-16'>
              <Menu.Button>
                <MdAdd size='2rem'></MdAdd>
              </Menu.Button>
              <button>
                {/* {activeFilter.length > 0 ?
                    <MdAdd size='1.3rem'></MdAdd> :
                    <MdFilterList size='1.3rem'></MdFilterList>
                  } */}

              </button>

            </div>

          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-bottom absolute
             bottom-20 mt-2 w-56 text-black
         rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
          focus:outline-none">
              <div className="p-2 flex flex-col">


                {pathname === '/warehouse' && (
                  <Menu.Item>
                    <Link
                      className='flex justify-start px-4 py-2 w-full
                    text-sm rounded-md font-medium hover:bg-gray-50'
                      to="/warehouse/input">
                      Artikel hinzufügen
                    </Link>
                  </Menu.Item>

                )}
                {pathname === '/warehouse' && (
                  <Menu.Item>
                    <Link
                      className='flex justify-start px-4 py-2 w-full
                    text-sm rounded-md font-medium hover:bg-gray-50'
                      to="/warehouse/inventory">
                      Inventur starten
                    </Link>
                  </Menu.Item>
                )}
                {pathname === '/warehouse' && (
                  <Menu.Item>
                    <Link
                      className='flex justify-start px-4 py-2 w-full
                    text-sm rounded-md font-medium hover:bg-gray-50'
                      to="/warehouse/manual">
                      Ein/Ausbuchung
                    </Link>
                  </Menu.Item>
                )}

                {pathname === '/contacts' && (
                  <Menu.Item>
                    <Link
                      className='flex justify-start px-4 py-2 w-full
                    text-sm rounded-md font-medium hover:bg-gray-50'
                      to="/contacts/input">
                      Kontakt hinzufügen
                    </Link>
                  </Menu.Item>
                )}

                {pathname === '/documents' && (
                  <Menu.Item>

                    <Link
                      className='flex justify-start px-4 py-2 w-full
                    text-sm rounded-md font-medium hover:bg-gray-50'
                      to="/documents/input">
                      Dokument hinzufügen
                    </Link>
                  </Menu.Item>
                )}

              </div>
            </Menu.Items>
          </Transition>
        </Menu>

        <Link to="/documents" className='flex-center w-full h-full'>
          <MdInsertDriveFile className={`${
            pathname === '/documents' ?'bg-blue-400 rounded-md' : ''}
            w-12 h-12 p-3`}/>
        </Link>

        <Link to="/contacts" className='flex-center w-full h-full'>
          <MdGroup className={`${
            pathname ==='/contacts' ?'bg-blue-400 rounded-md' : ''}
            w-12 h-12 p-3`} />
        </Link>
      </div>
    </div>
  );
}

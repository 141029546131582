import { fs } from './firebase';
import { html } from './assets/templates/Standard';
// import Localbase from 'localbase';
import { createDoc, fetchOnlineDocs } from './cud';
import Localbase from 'localbase';
import { lb } from './context/AuthContext';

/**
 *
 * @param {number} length Length of id
 * @return {string} Id
 */
export function makeId(length) {
  if (typeof length !== 'number') return '';
  const result = [];
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() *
      charactersLength)));
  }
  return result.join('');
}

/**
 * Rounds a given number
 * @param {number} x Number to round
 * @param {number} n factor
 * @return {number}
 */
export function round(x, n = 0) {
  if (typeof x !== 'number') return '';

  const faktor = Math.pow(10, n);
  return (Number(Math.round(x * faktor) / faktor));
}

/** brings Number into viewable format
 * @param {number} number Input Number
 * @return {string} Formatted Number
 */
export function numberFormatter(number, showDigits = true) {
  if (isNaN(number)) return '';
  if (typeof number === 'string' && number.length === 0) return '';
  let denominator = '';
  const fixedNum = Number(number).toFixed(2);
  const split = fixedNum.split('.');
  let newNumStr = '';
  if (split[0][0] === '-') {
    const abs = split[0].split('');
    abs.shift();
    split[0] = abs.join('');
    denominator = '-';
  }
  for (let i = split[0].length - 1; i >= 0; i--) {
    if (
      (split[0].length - i) % 3 === 0 &&
      i != split[0].length - 1 &&
      i !== 0
    ) {
      newNumStr = newNumStr + split[0][i] + '.';
    } else {
      newNumStr = newNumStr + split[0][i];
    }
  }
  if (showDigits) return denominator + newNumStr.split('').reverse().join('') + ',' + split[1];
  else return denominator + newNumStr.split('').reverse().join('')
}

/** brings Date into viewable format
 * @param {number} mills Input Date in ms
 * @param {string} type Data Type (view, date_input)
 * @return {string} Formatted Date
 */
export function millsToDate(mills, type) {
  if (mills && typeof mills === 'number') {
    const today = new Date(mills);
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    if (type === 'date_input') return yyyy + '-' + mm + '-' + dd;
    else return dd + '.' + mm + '.' + yyyy;
  } else {
    return '';
  }
}

/** Reads out hours and minutes out of milliseconds
 * @param {number} mills Input Date in ms
 * @return {string} Formatted Date
 */
export function millsToTime(mills) {
  if (mills && typeof mills === 'number') {
    const today = new Date(mills);
    const hh = String(today.getHours()).padStart(2, '0');
    const mm = String(today.getMinutes() + 1).padStart(2, '0');
    return hh + ':' + mm;
  } else {
    return '';
  }
}

/**
 * Decides whether a document is overdue
 * @param {object} docData
 * @return {boolean}
 */
export function markAsOverdue(docData) {
  if (!docData) return false;

  // Check every Rechnung that has an open amount and is older
  // than the specified payment target
  const days = docData.payment_target_days;
  if (days && docData.type.name === 'Rechnung' && docData.open_amount &&
    (Date.now() - docData.created_date > days * 86400000)) {
    return true;
  } else return false;
}

/**
 * @param {object} docData
 * @return {boolean}
 */
export function lowStock(docData) {
  if (!docData) return false;
  return (
    docData.stock != undefined &&
      docData.stock != '' &&
      docData.min_stock &&
      docData.min_stock > docData.stock ?
      true : false
  );
}

/**
 * Limits Length of String, replaces last 3 chars with '...'
 * @param {String} str
 * @param {number} len
 * @return {string} Shortened String
 */
export function limitString(str, len = 12) {
  if (str && typeof str === 'string') {
    return str.length > len ? str.substring(0, len - 3) + '...' : str;
  } else return '';
}

/**
 * Generates a search Index
 * @param {string} teamId
 * @param {string} section
 * @param {string} selector
 *
 * @return {array}
 */
export async function generateSearchIndex(teamId, section, selector) {
  const lb = new Localbase(teamId);
  // lb.config.debug = false;
  const array = await lb.collection(section).get();
  const contacts = await lb.collection('contacts').get();
  const tags = await lb.collection('tags').get();
  const warehouses = await lb.collection('warehouses').get();
  const index = [];

  array.map((el) => {
    const data = el;
    const obj = el;

    // Convert Dates
    data.created_date && (obj.created_date = millsToDate(data.created_date));
    data.updated_date && (obj.updated_date = millsToDate(data.updated_date));

    if (section === 'documents') {
      // Add Contact
      const contact = contacts.find((c) => c.id === el.contact);
      if (contact) {
        obj.contact_name = contact.name;
        obj.contact_number = contact.number;
      }
      // Convert Tags
      obj.tags = [];
      for (const tag of data.tags) {
        const doc = tags.find((el) => el.id === tag);
        doc && obj.tags.push(doc.text);
      }

      // Convert Document Sums
      obj.gross_total = numberFormatter(data.gross_total);
    }

    if (section === 'items') {
      // Convert Warehouses
      if (data.warehouse) {
        const warehouse = warehouses.find((el) => el.id === data.warehouse);
        if (warehouse) obj.warehouse = warehouse.name;
      }

      // Convert Prices
      data.retail_price &&
        (data.retail_price = numberFormatter(data.retail_price));
    }
    index.push(JSON.stringify(obj).toLowerCase());
  });
  lb.collection('search-indexes').doc(section).get().then((doc) => {
    if (doc) {
      lb.collection('search-indexes').doc(section)
        .set({ index: index });
    } else {
      lb.collection('search-indexes')
        .add({ index: index }, section);
    }
  });
}


// ----------------------------SECTION Show Document-----------------------

/**
 * Document Data to Pdf
 * @param {object} data
 * @param {boolean} showSenderAddress
 * @param {string} teamId
 * @param {string} letterPaper
 * @return {string} url
 */
export async function dataToPdf(data, showSenderAddress, teamId,
  letterPaper) {
  const documents = await lb.collection('documents').get();
  const modifiedData = { ...data };

  if (!showSenderAddress) modifiedData.sender_address = '';

  if (modifiedData.reductions) {
    let reductionsTotal = 0;
    let grossTotalAfterReductions = 0;

    // Calculate Reductions Total
    for (const id of modifiedData.reductions) {
      const reduction = documents.find((el) => el.id === id);
      if (reduction) reductionsTotal += reduction.gross_total;
    }

    // Adjust reduction data
    modifiedData.reductions = modifiedData.reductions.filter((re) => {
      return documents.find((el) => el.id === re);
    }).map((re) => {
      return documents.find((el) => el.id === re);
    });
    console.log(modifiedData.reductions);

    // Calc Gross Total after reductions
    if (reductionsTotal) {
      grossTotalAfterReductions = modifiedData.gross_total - reductionsTotal;
    }

    // Calc taxAmountAfterReductions
    const totalTaxAmountAfterReductions =
      round((modifiedData.total_tax_amount / modifiedData.gross_total) *
        grossTotalAfterReductions, 2);

    modifiedData.reductions_total = reductionsTotal;
    modifiedData.gross_total_after_reductions = grossTotalAfterReductions;
    modifiedData.total_tax_amount_after_reductions =
      totalTaxAmountAfterReductions;
    console.log(modifiedData.total_tax_amount_after_reductions);
  }

  const sendData = {
    'html': await html(modifiedData, letterPaper, teamId),
    'content': {},
  };

  const response = await fetch('https://www.htmltopdfapi.xyz/convert/', {
    method: 'post',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(sendData),
  });
  const blob = await response.blob();

  return window.URL.createObjectURL(blob);
}

/**
 * Modifies PDF data for readability
 * @param {object} sendDataContent
 * @return {object}
 */
export function modifyPdfData(sendDataContent) {
  // Set current Date
  sendDataContent.current_date = millsToDate(sendDataContent.current_date);

  // Check Type and Format valid until Date
  if (
    sendDataContent['type_name'] === 'Angebot' ||
    sendDataContent['type_name'] === 'Auftragsbestätigung'
  ) {
    sendDataContent['valid_until'] = millsToDate(sendDataContent.valid_until);
  } else {
    delete sendDataContent.valid_until;
  }

  // Numbers to fixed Strings
  if (sendDataContent.positions_total) {
    sendDataContent.positions_total = numberFormatter(
      sendDataContent.positions_total,
    );
  }
  if (sendDataContent.net_total) {
    sendDataContent.net_total = numberFormatter(sendDataContent.net_total);
  }
  if (sendDataContent.gross_total) {
    sendDataContent.gross_total = numberFormatter(sendDataContent.gross_total);
  }
  if (sendDataContent.total_discount_amount) {
    sendDataContent.total_discount_amount = numberFormatter(
      sendDataContent.total_discount_amount,
    );
  }
  if (sendDataContent.total_tax_amount) {
    sendDataContent.total_tax_amount = numberFormatter(
      sendDataContent.total_tax_amount,
    );
  }

  // Format Position total Prices
  for (const position of Object.values(sendDataContent.positions)) {
    if (position.total_price) {
      position.total_price = numberFormatter(position.total_price);
    }
  }

  // Positions Object to Array
  if (sendDataContent.positions) {
    const sendPositionsArray = Object.values(sendDataContent.positions);
    const positionNumberArray = Object.keys(sendDataContent.positions);
    for (let i = 0; i < positionNumberArray.length; i++) {
      sendPositionsArray[i].number = positionNumberArray[i];

      // Check for Page Break in title
      if (sendPositionsArray[i].title.includes('[Seitenumbruch]')) {
        sendPositionsArray[i].break_page = true;
        sendPositionsArray[i].title = sendPositionsArray[i].title.replace(
          '[Seitenumbruch]',
          '',
        );
      }

      // Split Comment into Lines
      let descriptionLines;
      if (sendPositionsArray[i].description) {
        descriptionLines = sendPositionsArray[i].description.split(/\n/);
        sendPositionsArray[i].description_lines = [];

        // Check for Manual Page Breaks in Description
        descriptionLines.forEach((line) => {
          sendPositionsArray[i].description_lines.push(
            line.replace('[Seitenumbruch]', ''),
          );
          if (line.includes('[Seitenumbruch]')) {
            sendPositionsArray[i].description_lines.push('[break]');
          }
        });
      }
    }
    sendDataContent.positions = sendPositionsArray;
  }

  // Format Article Price of positions
  for (const position of sendDataContent.positions) {
    if (position.price) {
      position.price = numberFormatter(position.price);
    }
  }

  // Reductions Object to array
  if (sendDataContent.reductions) {
    const sendReductionsArray = Object.values(sendDataContent.reductions);
    const positionNumberArray = Object.keys(sendDataContent.reductions);
    for (let i = 0; i < positionNumberArray.length; i++) {
      sendReductionsArray[i].number = positionNumberArray[i];
    }
    sendDataContent.reductions = sendReductionsArray;
  }

  // Format Reductions
  for (const reduction of sendDataContent.reductions) {
    reduction.creation_date = millsToDate(reduction.creation_date);
    reduction.net_total = numberFormatter(reduction.net_total);
    reduction.gross_total = numberFormatter(reduction.gross_total);
  }
  if (sendDataContent.gross_total_after_reductions) {
    sendDataContent.gross_total_after_reductions = numberFormatter(
      sendDataContent.gross_total_after_reductions,
    );
  }

  return sendDataContent;
}

// ----------------!SECTION--------------
// -------------------------SECTION Snapshot Listener--------------------
/**
 *
 * @param {string} section Database Section
 * @param {string} id Database Doc id
 * @param {string} collection Database collection
 * @param {function} rerender Function to rerender components
 * @param {number} limit Limit Amount of Docs
 */
export async function snapListener(section,
  id,
  collection,
  rerender, limit) {
  const lb = new Localbase(id);
  // lb.config.debug = false;
  let localDocs = await lb.collection(collection).get();

  // If there are no Docs in Local State,
  // Fetch all Docs from Database
  if (!localDocs || localDocs.length === 0) {
    console.log('fetching ' + collection);
    await fetchOnlineDocs(id, collection, rerender, limit);
    console.log('Fetched ' + collection);

    // const onlineDocs = await fs.collection(section)
    //     .doc(id).collection(collection).get();
    // for (const doc of onlineDocs.docs) {
    //   await lb.collection(collection)
    //       .add({...doc.data(), synced: true, id: doc.id});
    // }
    generateSearchIndex(id, collection);
    localDocs = await lb.collection(collection).get();
    rerender && rerender('');
    rerender && rerender(collection);
  }

  // Calc newest Doc Updated Date
  let newestDocUpdatedDate = 0;
  for (const doc of localDocs) {
    if (doc && doc.updated_date) {
      const newDate = doc.updated_date;
      if (newDate > newestDocUpdatedDate) {
        newestDocUpdatedDate = newDate;
      }
    }
  }

  console.log('init, date ' + newestDocUpdatedDate);
  const unsub = fs
    .collection(section)
    .doc(id)
    .collection(collection)
    .where('updated_date', '>', newestDocUpdatedDate)
    .onSnapshot(async (snap) => {
      if (collection === 'document_types') {
        console.log('DOC TYPES SNAPSHOT triggered');
      }
      console.log('Snapshot triggered!');
      if (snap.docs.length > 0) {
        // Write Docs to LB
        for (const el of snap.docs) {
          // Check if incoming change or self made
          // If change is selfmade, then the el.id doesn't match with
          // the id in the lb, that's why we need to check for the
          // el.data().id as well
          const localRef1 = await lb.collection(collection)
            .doc({ id: el.id }).get();

          const localRef2 = await lb.collection(collection)
            .doc({ id: el.data().id }).get();

          if (localRef1) {
            await lb.collection(collection)
              .doc({ id: el.id })
              .set({ ...el.data(), synced: true, id: el.id });
          } else if (localRef2) {
            await lb.collection(collection)
              .doc({ id: el.data().id })
              .set({ ...el.data(), synced: true, id: el.id });
          } else {
            await lb.collection(collection)
              .add({ ...el.data(), synced: true, id: el.id });
          }
        }
        generateSearchIndex(id, collection);

        rerender && rerender('');
        rerender && rerender(collection);
        unsub();
        snapListener(section, id, collection, rerender);
      } else {
      }
    });
  return true;
}
// ----------------!SECTION--------------
/**
 * Calculates Document Sums
 * @param {object} documentData Document Data State
 * @param {array} documents documents
 * @param {string} discountMode Percentage or Amount
 * @return {object} calculated sums
 */
export function calcDocumentSums(documentData, documents, discountMode, linkedReductions) {
  // Calculate Positions Total
  let positionsTotal = 0;
  for (const value of Object.values(documentData.positions)) {
    if (value.total_price) {
      positionsTotal += Number(round(value.total_price, 2));
    }
  }

  // Calc Discount Percentage and Amount
  let totalDiscountAmount;
  let totalDiscountPercentage;
  if (discountMode === 'percentage') {
    totalDiscountPercentage = documentData.total_discount_percentage;
    totalDiscountAmount = (documentData.total_discount_percentage / 100) *
      positionsTotal;
  } else if (discountMode === 'amount') {
    totalDiscountAmount = documentData.total_discount_amount;
    totalDiscountPercentage = (documentData.total_discount_amount * 100) /
      positionsTotal;
  }


  // Calc Taxes
  const positions = documentData.positions;
  let totalTaxAmount = 0;
  const totalTaxSplit = {};
  const tDisP = totalDiscountPercentage;
  for (const pos of positions) {
    if (pos.total_price && pos.tax) {
      // Calc Tax depending on tax_incl
      let taxBuffer = documentData.tax_incl ?
        round(pos.total_price, 2) - (round(pos.total_price, 2) /
          (1 + pos.tax / 100)) :
        round(pos.total_price, 2) * pos.tax / 100;

      taxBuffer = taxBuffer * (1 - tDisP / 100);

      totalTaxAmount += round(taxBuffer, 2);

      // Add to Tax Split Array
      if (!totalTaxSplit[pos.tax]) {
        totalTaxSplit[pos.tax] = round(taxBuffer, 2);
      } else {
        let buffer = totalTaxSplit[pos.tax];
        buffer += round(taxBuffer, 2);
        totalTaxSplit[pos.tax] = buffer;
      }
    }
  }

  let netTotal;
  let grossTotal;

  if (documentData.tax_incl) {
    // Calc Gross Total
    grossTotal = positionsTotal - totalDiscountAmount;

    // Calc Net Total
    netTotal = grossTotal - totalTaxAmount;
  } else {
    // Calc Net Total
    netTotal = positionsTotal - totalDiscountAmount;

    // Calc Gross Total
    grossTotal = netTotal + totalTaxAmount;
  }

  // Calc Reductions


  let reductionsTotal = 0;
  let grossTotalAfterReductions = 0;
  let totalTaxAmountAfterReductions = 0;
  if (linkedReductions?.length) {

    // Calculate Reductions Total
    for (const id of linkedReductions) {
      const reduction = documents.find((el) => el.id === id);
      if (reduction) reductionsTotal += reduction.gross_total;
    }

    // // Adjust reduction data
    // documentData.reductions = documentData.reductions.filter((re) => {
    //   return documents.find((el) => el.id === re);
    // }).map((re) => {
    //   return documents.find((el) => el.id === re);
    // });
    // console.log(documentData.reductions);

    // Calc Gross Total after reductions
    if (reductionsTotal) {
      grossTotalAfterReductions = grossTotal - reductionsTotal;
    }

    // Calc taxAmountAfterReductions
    totalTaxAmountAfterReductions =
      round((totalTaxAmount / grossTotal) *
        grossTotalAfterReductions, 2);

  }
  const returnObj = {
    positionsTotal,
    totalTaxAmount,
    totalTaxSplit,
    netTotal,
    grossTotal,
    reductionsTotal,
    grossTotalAfterReductions,
    totalTaxAmountAfterReductions,
    totalDiscountPercentage,
    totalDiscountAmount,
  }
  console.log(returnObj)

  return returnObj
}
// -----------------SECTION Calc Document Sums---------------


// ----------------!SECTION--------------
// ----------------SECTION Log --------------
/**
 * Creates a log entry
 * @param {object} id currentTeam Id
 * @param {string} type Log Entry Type:
 * documents, contacts, warehouse, inventories, manual_bookings
 * @param {object} userId ID of current User
 * @param {object} param2 Options
 * @param {function} setRerender Options
 * @param {boolean} isOffline Options
 */
export async function addLogEntry(id, type, userId,
  { action, number, name, details, callback }, setRerender, isOffline) {
  return await createDoc(id, 'log', {
    type,
    official_in_charge: userId,
    action,
    number: number || '',
    name: name || '',
    details: details || '',
    // TODO !
  }, setRerender, isOffline);
}
// ----------------!SECTION--------------

/**
* General Search Algorithm used throughout the app
* @param {string} input
* @param {array} database
* @param {array} index
* @return {array} Search Results
*/
export function getSearchResults(input, database, index) {
  // Split Search Input into Words
  const arr = input.split(' ');
  const buffer = index || database.map((el) =>
    JSON.stringify(el).toLowerCase());

  // Filter Items by checking if Search Index contains every
  // Single Word of Search Input
  return database.filter((el, index) => {
    let boo = false;
    for (const element of arr) {
      if (buffer[index] && buffer[index].includes(element.toLowerCase())) {
        // if (JSON.stringify(database[index])
        //     .toLowerCase()
        //     .includes(element.toLowerCase())) {
        boo = true;
      } else {
        boo = false;
        break;
      }
    }
    return boo;
  });
}

/**
 * Deletes Local instance of Indexed DB & fetches online DB
 */
export async function refreshDB() {
  await lb.delete();
  window.location.href = '/dashboard';
}


/**
 * @param {function} setProblems
 */
export async function analyseNkProblems(setProblems) {
  setProblems([]);
  const problems = [];

  // Fetch Documents
  let documents = await lb.collection('documents').get();
  // Filter out deleted Docs
  documents = documents.filter((el) => !el.deleted);

  // Limit to current Year
  const date = new Date();
  const currentYear = date.getFullYear();
  documents = documents.filter((doc) => {
    const createdDate = new Date(doc.created_date);
    const createdYear = createdDate.getFullYear();
    return currentYear === createdYear;
  });

  // Filter Invoices
  // const documents = documents.filter((doc) => {
  //   return (
  //     doc.type.name === 'Rechnung'
  //   );
  // });

  // Sort by numbers
  documents.sort((a, b) => {
    return a.type.number - b.type.number;
  });


  // Check for duplicates & gaps
  // const start = invoices[0].type.number;
  // const end = invoices[invoices.length - 1].type.number;

  for (let i = 0; i < documents.length - 1; i++) {
    // Catch Gaps (not bigger than 50)
    if (documents[i + 1].type.number - documents[i].type.number > 1 &&
      documents[i + 1].type.number - documents[i].type.number < 50) {
      // get difference
      const gapLength =
        documents[i + 1].type.number - documents[i].type.number - 1;
      console.log(gapLength);

      // Add Gaps to problems
      for (let x = 1; x <= gapLength; x++) {
        problems[documents[i].type.number + x] = [];
      }
    }

    // Catch Duplicates
    if (documents[i + 1].type.number - documents[i].type.number === 0) {
      // If entry already exists, push new document
      if (problems[documents[i].type.number]) {
        problems[documents[i].type.number].push(documents[i + 1]);
      }
      // Create entry and add both problematic documents
      problems[documents[i].type.number] = [documents[i], documents[i + 1]];
    }
  }

  // Restructure Problems object to array
  const buffer = [];
  for (const [key, value] of Object.entries(problems)) {
    buffer.push({
      number: key,
      documents: value,
    });
  }

  console.log(buffer);


  setProblems([...buffer]);
}


// Validate Array
/**
 *
 * @param {array} array
 * @return {boolean}
 */
export function valArr(array) {
  if (Array.isArray(array)) {
    return true;
  }
  return false;
}

import React, { useState, useEffect, useRef, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Menu, Transition } from '@headlessui/react';
import ReactTooltip from 'react-tooltip';
import {
  MdAdd, MdClose, MdContentCopy, MdSettings, MdHelp,
  MdDelete, MdMenu, MdLink, MdEdit, MdMoreVert,
  MdSubdirectoryArrowLeft
} from 'react-icons/md';
import {
  addLogEntry, calcDocumentSums, millsToDate,
  numberFormatter, round, valArr
} from '../utilities';

// Import Dropdown Components
import ItemsDD from '../dropdowns/ItemsDD';
import SuperTB from '../dropdowns/SuperTB';
import AddressesDD from '../dropdowns/AddressesDD';
import ContactsDD from '../dropdowns/ContactsDD';
import DocumentTypesDD from '../dropdowns/DocumentTypesDD';
import MultiSelect from '../dropdowns/MultiSelect';

import { AuthContext, lb, mdb } from '../context/AuthContext';

import ModalConfirm from '../ModalConfirm';
import Modal from './Modal';
import { createDoc, RerenderContext, updateDoc } from '../cud';
import PositionModal from './PositionModal';

/**
 * This modal is shown on Document Save if the doc Data has an error.
 * It let's the user edit the JSON data manually in a textarea field.
 * @return {object} jsx
 */
function CorrectionModal({ showC, saveDoc, docDataC }) {
  const [show, setShow] = showC;
  const [docData, setDocData] = docDataC;
  const [string, setString] = useState('');

  useEffect(function () {
    setString(JSON.stringify(docData));
  }, [docData]);
  return show ? (
    <button
      onClick={(e) => {
        if (e.nativeEvent.pointerType === 'mouse') {
          setShow(false);
        }
      }}
      style={{ display: show ? 'block' : 'none' }}
      className="fill_container absolute right-0 bg-black p-16 overflow-hidden
      z-50 bg-opacity-50 xl:px-40">
      <div
        className="bg-white shadow overflow-hidden sm:rounded-lg pb-4
        h-full cursor-default flex flex-col items-center space-y-4"
        onClick={function (e) {
          e.stopPropagation();
        }}>
        <div className="pb-5 border-b border-gray-200
            p-8 flex justify-between">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Unbestimmte Werte gefunden!
          </h3>
        </div>
        <p>Hier hat etwas nicht geklappt. Im Datensatz des Dokuments befindet
          sich ein undefinierter Wert. <br />
          Bitte entfernen Sie im unteren Textfeld
          alle Werte &quot;undefined&quot; und &quot;null&quot;. <br />
          Drücken Sie dann auf Speichern</p>

        <textarea
          className="mt-1 focus:ring-blue-500
        focus:border-blue-500 block flex-grow
        shadow-sm sm:text-sm w-4/5
        border-gray-300 rounded-md"
          value={string}
          onInput={(e) => setString(e.target.value)}></textarea>
        <button
          disabled={(() => {
            return string.includes('undefined') ||
              string.includes('null');
          })()}
          onClick={() => {
            setDocData(JSON.parse(string));
            saveDoc(JSON.parse(string));
            setShow(false);
          }}
          className="inline-flex justify-center py-2 px-4 border
           border-transparent
           shadow-sm text-sm font-medium rounded-md
          text-white bg-blue-600 hover:bg-blue-700 focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
           disabled:opacity-50;">
          Speichern
        </button>

      </div>
    </button>
  ) : '';
}

CorrectionModal.propTypes = {
  showC: PropTypes.array,
  saveDoc: PropTypes.func,
  docDataC: PropTypes.array,
};


/**
 *
 * @return {object} jsx
 */
export default function DocumentPopup({ location, history }) {
  // ---------------------SECTION States etc. ------------------
  const { teamC, userC, isOfflineC, toastsC } = useContext(AuthContext);
  const currentTeam = teamC[0];
  const currentUser = userC[0];
  const isOffline = isOfflineC[0];
  const addToast = toastsC[1];

  const [documents, setDocuments] = useState([]);
  const [tags, setTags] = useState([]);
  const [contacts, setContacts] = useState([]);

  const [rerender, setRerender] = useContext(RerenderContext);

  // Tax Preset State depending on selected Contact
  const [standardTax, setStandardTax] = useState(19);
  const [validUntilDate, setValidUntilDate] = useState('');

  // Load Data
  useEffect(async function () {
    if (currentTeam) {
      const documents = await lb.collection('documents').get();
      valArr(documents) && setDocuments(documents);

      const tags = await lb.collection('tags').get();
      valArr(tags) && setTags(tags);

      const contacts = await lb.collection('contacts').get();
      valArr(contacts) && setContacts(contacts);

      const teamDoc = await mdb.collection('meta').doc('team').get();
      if (teamDoc.valid_until_days) {
        setValidUntilDate(Date.now() + teamDoc.valid_until_days * 86400000);
      }
    }
  }, [rerender]);

  // Helper state to block Buttons while Saving
  const [isSaving, setIsSaving] = useState(false);
  const [disableTypeSelect, setDisableTypeSelect] = useState(false);
  const [editTypeNr, setEditTypeNr] = useState(false);

  const [discountMode, setDiscountMode] = useState('percentage');

  // Confirms
  const [showcorrectionModal, setShowCorrectionModal] = useState(false);

  const [showContactPopup, setShowContactPopup] = useState(false);


  const [showPosDet, setShowPosDet] = useState('');

  // Standard Data Structure for the Document
  const dataStructure = {
    id: '',
    contact: '',
    sender_address: {},
    billing_address: {},
    delivery_address: {},
    official_in_charge: currentUser.uid,
    type: {},

    positions: [],
    reductions: [],
    tags: [],

    positions_total: '',
    net_total: '',
    gross_total: '',
    gross_total_after_reductions: '',
    tax_incl: false,
    total_tax_amount: '',
    total_tax_split: [],
    total_tax_amount_after_reductions: '',

    total_discount_amount: '',
    total_discount_percentage: '',
    total_discount_type: '',

    comment_1: '',
    comment_2: '',
    payment_conditions: '',
    delivery_conditions: '',

    document_created_date: Date.now(),
    valid_until_date: '',
    delivery_date: '',

    payment_target_days: '',

    external_letter_paper_url: '',
    internal_nr: '',
    show_sender_address: '',
    external_order_nr: '',
    show_weight: false,
  };
  const [documentData, setDocumentData] = useState(dataStructure);
  const [loadedReductions, setLoadedReductions] = useState([]);

  // Standard Data Structure for a new Position
  const positionDataStructure = {
    amount: '',
    unit: '',
    weight: '',
    title: '',
    description: '',
    price: '',
    tax: standardTax,
    discount: '',
    total_weight: '',
    total_price: '',
  };

  const [positionData, setPositionData] = useState(positionDataStructure);
  if (true === false) setPositionData();

  const [showWeight, setShowWeight] = useState(false);
  const [renderArticleCode, setRenderArticleCode] = useState(false);
  const [renderExternalCode, setRenderExternalCode] = useState(false);

  const [selectedItem, setSelectedItem] = useState('');

  const [selectedDocumentType, setSelectedDocumentType] = useState({});
  const [selectedContact, setSelectedContact] = useState('');
  const [selectedBillingAddress, setSelectedBillingAddress] = useState('');
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState('');
  const [selectedSenderAddress, setSelectedSenderAddress] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);

  const [providedComment1, setProvidedComment1] = useState('');
  const [providedComment2, setProvidedComment2] = useState('');
  const [providedPaymentConditions, setProvidedPaymentConditions] =
    useState('');
  const [providedDeliveryConditions, setProvidedDeliveryConditions] =
    useState('');
  const [selectedOfficialInCharge, setSelectedOfficialInCharge] =
    useState(currentUser.uid);

  const [linkedReductions, setLinkedReductions] = useState([]);

  const [paymentTargetDays, setPaymentTargetDays] = useState('');
  const [documentSums, setDocumentSums] = useState({});

  const [editableListElement, setEditableListElement] = useState('');

  const [modalConfirm, setModalConfirm] = useState({});

  // Refs
  const titleInputRef = useRef(null);
  const priceRef = useRef(null);
  const unitRef = useRef(null);


  // ------------------!SECTION-------------------------

  // Load Doc Data to Edit Document
  let docData;
  if (location.state) docData = location.state.docData;

  useEffect(function () {
    if (docData) {
      docData.type.id && setDisableTypeSelect(true);
      // Continue Document:
      // Finds Type doc with given Name and selects it
      if (docData.type.select) {
        lb.collection('document_types')
          .doc({ name: docData.type.select }).get().then((typeDoc) => {
            setSelectedDocumentType({
              id: typeDoc.id,
              abbreviation: typeDoc.abbreviation,
              name: typeDoc.name,
            });
          });
      } else {
        setSelectedDocumentType(docData.type);
      }
      setSelectedContact(docData.contact);
      setSelectedBillingAddress(docData.billing_address);
      setSelectedDeliveryAddress(docData.delivery_address);
      setSelectedSenderAddress(docData.sender_address);
      setProvidedComment1(docData.comment_1);
      setProvidedComment2(docData.comment_2);
      setSelectedTags([]);
      setProvidedPaymentConditions(docData.payment_conditions);
      setProvidedDeliveryConditions(docData.delivery_conditions);
      setPaymentTargetDays(docData.payment_target_days);

      setLinkedReductions(docData.reductions);

      setValidUntilDate(docData.valid_until_date);

      setSelectedOfficialInCharge(docData.official_in_charge);

      setDocumentData({ ...docData });
      setLoadedReductions([...docData.reductions]);

      // setDocumentSumsFunction(docData, documents, discountMode, docData.reductions)
    }
  }, []);

  // Update Conditions when Contact is selected
  useEffect(function () {
    const contact = contacts.find((c) => c.id === selectedContact);
    if (!contact) return;
    if (!providedPaymentConditions ||
      providedPaymentConditions.length === 0 &&
      contact.payment_conditions &&
      contact.payment_conditions.length > 0) {
      setProvidedPaymentConditions(contact.payment_conditions || '');
    }
    if (!providedDeliveryConditions ||
      providedDeliveryConditions.length === 0 &&
      contact.delivery_conditions &&
      contact.delivery_conditions.length > 0) {
      setProvidedDeliveryConditions(contact.delivery_conditions || '');
    }

    // TODO // Init Discount if it doesn't exist in Document Data
    // contact.discount_percentage &&
    // !documentData.total_discount_percentage &&
    //  handleDiscountChange(contact.discount_percentage, 'percentage');

    // Init Standard Tax for Items
    if (contact.tax_percentage || contact.tax_percentage === 0) {
      setStandardTax(contact.tax_percentage);
      setPositionData({ ...positionData, tax: contact.tax_percentage });
    } else {
      setStandardTax(19);
      setPositionData({ ...positionData, tax: 19 });
    }

    // Init other Settings
    setRenderArticleCode(contact.render_article_code ? true : false);
    setRenderExternalCode(contact.render_external_code ? true : false);
    if (contact.discount_percentage) {
      setDocumentData({
        ...documentData,
        total_discount_percentage: contact.discount_percentage,
      });
    }


    // Reset Addresses
    setSelectedBillingAddress('');
    setSelectedDeliveryAddress('');
  }, [selectedContact]);

  // Update Show Weight when Document Type is selected
  useEffect(function () {
    if (selectedDocumentType.name === 'Lieferschein') {
      setShowWeight(true);
    } else {
      setShowWeight(false);
    }
  }, [selectedDocumentType]);

  // Updates new Position Title when element is selected / Typed in
  useEffect(
    function () {
      const selIt = selectedItem;

      // Set Input Value to typed Value if no Item was selected
      if (!selIt.data) {
        return setPositionData({ ...positionData, title: selIt });
      };

      // Init Item Price
      let price = selIt.data.retail_price;

      // Check if there is a contact specific price
      const csf = selIt.data.contact_specific_fields;
      if (csf && csf[selectedContact] &&
        csf[selectedContact]['price']) {
        price = Number(csf[selectedContact]['price']);
      }

      // Init Object with Information from Item
      const dataObj = {
        ...positionData,
        title: `${renderArticleCode ?
          `${selIt.data.code} ` : ''}${selIt.data.name}`,
        description: `${renderExternalCode ?
          `${selIt.data.contact_specific_fields[selectedContact] &&
            selIt.data.contact_specific_fields[selectedContact].code ?
            `Code: ${selIt.data
              .contact_specific_fields[selectedContact].code}` :
            ''}` : ''}`,
        price: price,
        weight: selIt.data.weight || positionData.weight,
        unit: selIt.data.unit || positionData.unit,
        id: selIt.id,
        code: selIt.data.code,
      };
      // Set Tax if its defined in selected Element
      if (selIt.data.tax || selIt.data.tax === 0) {
        dataObj.tax = selIt.data.tax;
      } else {
        dataObj.tax = standardTax;
      }
      // Set Position Data State
      setPositionData({ ...dataObj });
      // Trigger Price Calculations
      handlePositionChange({ target: { name: 'price', value: price } },
        'string', { ...dataObj });
    },
    [selectedItem],
  );

  function setDocumentSumsFunction(documentData, documents, discountMode, linkedReductions) {

    const {
      positionsTotal,
      totalTaxAmount,
      totalTaxSplit,
      netTotal,
      grossTotal,
      reductionsTotal,
      grossTotalAfterReductions,
      totalTaxAmountAfterReductions,
      totalDiscountAmount,
      totalDiscountPercentage,
    } = calcDocumentSums(documentData, documents, discountMode, linkedReductions);

    setDocumentSums({
      positionsTotal,
      netTotal,
      grossTotal,
      totalTaxAmount,
      totalTaxSplit,
      totalTaxAmountAfterReductions,
      reductionsTotal,
      grossTotalAfterReductions,
      totalDiscountAmount,
      totalDiscountPercentage,
    });
  }

  // Updates Document Sums
  useEffect(function () {
    setDocumentSumsFunction(documentData, documents, discountMode, linkedReductions);
  }, [documentData, discountMode, linkedReductions, documents]);


  // Update Payment Target
  useEffect(function () {
    const textArr = providedPaymentConditions.split(' ');

    const numbers = textArr.map((el) => {
      if (el.length < 4 && !isNaN(el)) return Number(el);
      else if (el.length === 10) {
        const buffer = el.split('.').reverse();

        if (buffer.length === 3 && !isNaN(buffer.join(''))) {
          // Return difference between date and DateNow
          const date = new Date(buffer.join('-'));
          return round((date.getTime() - Date.now()) / 86400000, 0);
        }
      }
    });

    let counter = 0;
    for (const number of numbers) {
      if (number > counter) counter = number;
    }

    // Update Payment Target Days State
    setPaymentTargetDays(counter);
  }, [providedPaymentConditions]);

  /**
   * Handles Change in Input
   * @param {string} name
   * @param {any} value

   */
  function handleChange(name, value) {
    setDocumentData({
      ...documentData,
      [name]: value,
    });
  }

  /**
   * Handles Change in Position Input
   * @param {object} event
   * @param {string} dataType
   * @param {object} posData

   */
  function handlePositionChange(event, dataType = 'string', posData) {
    const { value, name } = event.target;
    const pd = posData || positionData;

    let name2;
    if (name === 'price') name2 = 'total_price';
    if (name === 'weight') name2 = 'total_weight';

    const formattedVal = dataType === 'number' ? Number(value) : String(value);

    if (name === 'amount') {
      setPositionData({
        ...pd,
        [name]: formattedVal,
        total_price: formattedVal ?
          (formattedVal * pd.price) * (1 - pd.discount / 100) : '',
        total_weight: formattedVal ? round(formattedVal * pd.weight, 2) : '',
      });
    } else if (name === 'discount') {
      setPositionData({
        ...pd,
        [name]: formattedVal,
        total_price: pd.amount ?
          round((pd.amount * pd.price) * (1 - formattedVal / 100), 2) : '',
      });
    } else if (name === 'price' || name === 'weight') {
      setPositionData({
        ...pd,
        [name]: formattedVal,
        [name2]: pd.amount ?
          round((pd.amount * formattedVal) * (1 - pd.discount / 100), 2) : '',
      });
    } else {
      setPositionData({
        ...pd,
        [name]: formattedVal,
      });
    }
  }

  /**
   * Handles Change in Position List
   * @param {number} index Row Index
   * @param {string} field Field Name
   * @param {object} e
   */
  function handlePositionListChange(index, field, e) {
    const positions = documentData.positions;
    const row = positions[index];

    // Check if Input is Number, if yes format value to Number
    let val;
    if (field === 'title' || field === 'description' || field === 'unit') {
      val = e.target.value;
    } else {
      val = Number(e.target.value);
    }
    row[field] = val;

    // Update total weight & price
    if (field === 'price') {
      if (row.amount) {
        row.total_price = val * row.amount *
          (1 - row.discount / 100);
      } else row.total_price = '';
    }

    if (field === 'amount') {
      if (val) {
        row.total_price = val * row.price *
          (1 - row.discount / 100);
        row.total_weight = val * row.weight;
      } else {
        row.total_price = '';
        row.total_weight = '';
      }
    }

    if (field === 'discount') {
      if (row.amount) {
        row.total_price = row.price * row.amount *
          (1 - val / 100);
      } else row.total_price = '';
    }

    if (field === 'weight') {
      if (row.amount) {
        row.total_weight = val * row.amount;
      } else row.total_weight = '';
    }

    setDocumentData({
      ...documentData,
      positions,
    });
  }

  // /**
  //  * Calculates Discount Amount and percentage
  //  * @param {number} input input
  //  * @param {string} type Type of input
  //  */
  // function handleDiscountChange(input, type) {
  //   if (type === 'percentage') {
  //     const amount = (input / 100) * documentSums.positionsTotal;
  //     setDocumentData({
  //       ...documentData,
  //       total_discount_percentage: input,
  //       total_discount_amount: amount,
  //     });
  //   } else if (type === 'amount') {
  //     const percentage = (input * 100) / documentSums.positionsTotal;
  //     setDocumentData({
  //       ...documentData,
  //       total_discount_percentage: percentage,
  //       total_discount_amount: input,
  //     });
  //   }
  // }


  /**
   * Calculates Date in milliseconds
   * @param {object} e Event
   */
  function handleDateChange(e) {
    const input = new Date(e.target.value);
    const date = input.getTime();

    setDocumentData({
      ...documentData,
      [e.target.name]: date,
    });
  }

  /**
   * Handles Position Submit
   * @param {object} data
   */
  function handlePositionSubmit(data) {
    // Push Address to contact Addresses
    const positions = documentData.positions;
    if (data) positions.push(data);
    else positions.push(positionData);

    setDocumentData({
      ...documentData,
      positions,
    });

    // Reset Inputs
    setPositionData(positionDataStructure);

    setSelectedItem('');
  }

  /**
   * Deletes Adress
   * @param {number} index

   */
  function deletePosition(index) {
    const positions = documentData.positions;
    positions.splice(index, 1);

    setDocumentData({
      ...documentData,
      positions: positions,
    });
  }

  /**
   * Handles Form Submit
   * @param {object} docData
   */
  async function handleSubmit(docData) {
    setIsSaving(true);
    const doDa = docData ? docData : documentData;
    // const contact = contacts.find((c) => c.id === selectedContact);

    // Undefined Checker (And Object editor)
    if (JSON.stringify(doDa).includes('undefined')) {
      return setShowCorrectionModal(true);
    }
    const data = {

      ...doDa,
      type: selectedDocumentType || {},
      contact: selectedContact || '',
      billing_address: selectedBillingAddress || {},
      delivery_address: selectedDeliveryAddress || {},
      sender_address: selectedSenderAddress || {},
      tags: selectedTags || {},
      payment_target_days: paymentTargetDays || '',

      comment_1: providedComment1 || '',
      comment_2: providedComment2 || '',

      positions_total: documentSums.positionsTotal,
      net_total: documentSums.netTotal,
      gross_total: documentSums.grossTotal,
      total_discount_amount: documentSums.totalDiscountAmount,
      total_discount_percentage: documentSums.totalDiscountPercentage,
      total_tax_amount: documentSums.totalTaxAmount,
      total_tax_split: documentSums.totalTaxSplit,
      reductions_total: documentSums.reductionsTotal,
      gross_total_after_reductions: documentSums.grossTotalAfterReductions,
      total_tax_amount_after_reductions: documentSums.totalTaxAmountAfterReductions,

      payment_conditions: providedPaymentConditions || '',
      delivery_conditions: providedDeliveryConditions || '',

      reductions: linkedReductions,
      show_weight: showWeight,
      official_in_charge: selectedOfficialInCharge,

      valid_until_date: selectedDocumentType.name === 'Angebot' ||
        selectedDocumentType.name === 'Auftragsbestätigung' ?
        validUntilDate : '',

    };
    console.log(data);

    // Remove/ book out items from warehouse
    if (selectedDocumentType.name === 'Lieferschein') {
      const docBuffer = documents.find((doc) => doc.id === data.id);
      const oldPositions = docBuffer && docBuffer.positions;
      for (const position of data.positions) {
        const id = position.id;
        let amount = position.amount;

        if (!id) continue;

        // Check for old positions
        if (oldPositions) {
          for (const oldPosition of oldPositions) {
            if (oldPosition.id === id) amount = amount - oldPosition.amount;
          }
        }

        // Remove from Database
        const buffer = await lb.collection('items').doc({ id: id }).get();
        const data = buffer ? buffer : null;
        const newStock = data.stock - amount;
        try {
          await updateDoc(currentTeam.id, 'items', id,
            { ...data, stock: newStock }, setRerender, isOffline);
        } catch (e) {
          addToast('error', `Fehler: ${position.title} konnte nicht ausgebucht werden`);
          console.log(e);
        }
      }
    }

    if (JSON.stringify(data).includes('undefined')) {
      return setShowCorrectionModal(true);
    }

    if (data.created_date && data.id) {
      // Edit existing Document
      try {
        await updateDoc(currentTeam.id, 'documents',
          data.id, data, setRerender, isOffline);
        console.log(data.contact);
        const contact = await lb.collection('contacts')
          .doc({ id: data.contact }).get();
        addLogEntry(currentTeam.id, 'documents', currentUser.uid,
          {
            action: 'bearbeitet',
            number: data.type.number,
            name: data.type.name,
            details: `für ${contact.name}`,
          }, setRerender, isOffline);
      } catch (e) {
        addToast('error', `Fehler: Dokument konnte nicht bearbeitet werden`);
        setIsSaving(false);
        return console.log(e);
      }
      addToast('success', 'Dokument bearbeitet');
    } else {
      // Invoices: Add Open Amount
      if (data.type.name === 'Rechnung') {
        data.open_amount =
          data.gross_total_after_reductions || data.gross_total;
      }

      // Save New Document
      console.log('saving new doc');
      try {
        await createDoc(currentTeam.id, 'documents',
          data, setRerender, isOffline);
        addToast('success', 'Dokument erstellt');
        // Add Log Entry
        console.log(data.contact);
        const contact = await lb.collection('contacts')
          .doc({ id: data.contact }).get();
        addLogEntry(currentTeam.id, 'documents', currentUser.uid,
          {
            action: 'erstellt',
            number: data.type.number,
            name: data.type.name,
            details: `für ${contact.name}`,
          }, setRerender, isOffline);
      } catch (e) {
        addToast('error', 'Fehler: Dokument konnte nicht erstellt werden');
        setIsSaving(false);
        return console.log(e);
      }
    }

    // // Set Linked Attribute in Abschlagsrechnungen
    // lb.config.debug = false;
    try {
      for (const id of loadedReductions) {
        if (data.reductions.includes(id)) continue;

        const document = await lb.collection('documents').doc({ id: id }).get();
        if (document) {
          // Remove all Links
          await updateDoc(currentTeam.id, 'documents', id,
            { ...document, linked: false }, null, isOffline);
        }
      }
      for (const id of data.reductions) {
        const document = await lb.collection('documents').doc({ id: id }).get();
        if (document) {
          // add Links
          await updateDoc(currentTeam.id, 'documents', id,
            { ...document, linked: true }, null, isOffline);
        }
      }
    } catch (e) {
      addToast('error', `Fehler: Abschlagsrechnungen
         konnten nicht verknüpft werden`);
      console.log(e);
    }

    setDocumentData(dataStructure);
    // Empty Selected Stuff (Not required: Component is unmounted)

    history.push('/documents');
    setIsSaving(false);
  }

  // --------------------SECTION Drag and Drop
  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * On Drag End
   * @param {object} result
   */
  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const positions = reorder(
      documentData.positions,
      result.source.index,
      result.destination.index,
    );

    setDocumentData({
      ...documentData,
      positions: positions,
    });
  }

  // ----------!SECTION

  return (
    <>
      {/* Confirm Modal */}
      <ModalConfirm stateC={[modalConfirm, setModalConfirm]} />

      <CorrectionModal
        showC={[showcorrectionModal, setShowCorrectionModal]}
        docDataC={[documentData, setDocumentData]}
        saveDoc={handleSubmit}>

      </CorrectionModal>
      <PositionModal documentData={documentData}
        handleChange={handlePositionListChange}
        showPositionDetailsC={[showPosDet, setShowPosDet]} />
      <Modal // TODO Provide Contact Data thorugh location State
        disableGoBack={true}
        onClose={() => {
          const selectedC = selectedContact;
          setSelectedContact('');
          setSelectedContact(selectedC);
        }}
        location={{ state: { contactId: selectedContact } }}
        component={'ContactPopup'}
        heading='Kontakt bearbeiten'
        showC={[showContactPopup, setShowContactPopup]}></Modal>
      <div className="flex-shrink bg-gray-100 flex flex-col overflow-hidden">
        <div className="pb-5 border-b border-gray-200 p-8 flex justify-between">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {(() => {
              if (location && location.state) {
                return (location.state.heading || 'Dokument hinzufügen');
              }
            })()}
          </h3>
          <Link to="/documents">
            <div className="flex-center text-gray-900">
              <MdClose size="2rem"></MdClose>
            </div>
          </Link>
        </div>
        <div className="overflow-y-auto flex-shrink space-y-6 p-2 md:p-8 flex flex-col items-center">
          <div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6 w-full max-w-6xl">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3
                  className="text-lg font-medium leading-6
                text-gray-900">
                  Allgemeine Informationen
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Starten Sie hier mit dem Erstellen des Dokuments.
                </p>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-2">
                  <div className="md:mt-5 col-span-6
                     md:mt-0 md:col-span-3 flex">
                    <div className='flex-grow'>
                      <div className="mt-4">
                        <DocumentTypesDD
                          disabled={disableTypeSelect}
                          // disabled={documentData.id &&
                          //    documentData.created_date ? true : false}
                          selectedDocumentTypeC={[
                            selectedDocumentType,
                            setSelectedDocumentType,
                          ]}></DocumentTypesDD>
                      </div>
                      <input
                        onInput={(e) => {
                          setSelectedDocumentType({
                            ...selectedDocumentType,
                            number: Number(e.target.value),
                          });
                        }}
                        value={selectedDocumentType.number || ''}
                        className="focus:ring-blue-500 focus:border-blue-500
                      block w-full mt-2 sm:text-sm border-gray-300 rounded-md"
                        style={{ display: editTypeNr ? 'block' : 'none' }}
                        type="number" />
                      <button
                        disabled={disableTypeSelect}
                        style={{
                          display: editTypeNr || disableTypeSelect ?
                            'none' : 'flex'
                        }}
                        onClick={() => setModalConfirm({
                          data: {
                            title: 'Nummer bearbeiten',
                            text: `Sie sind dabei, die Nummer dieses Dokuments zu bearbeiten.
                           Dies kann Lücken in ihrem Nummernkreis verursachen. Fortfahren?`,
                            buttonText: 'Nummer bearbeiten'
                          },
                          open: true,
                          func: () => setEditTypeNr(true),
                        })}
                        className='text-xs text-blue-500 flex-center
                       space-x-0.5'>
                        <MdEdit></MdEdit>
                        <p>Nummer bearbeiten</p>
                      </button>
                    </div>
                    <button
                      style={{ display: disableTypeSelect ? 'block' : 'none' }}
                      className='flex-shrink mt-9 mx-2'
                      onClick={() => setModalConfirm({
                        data: {
                          title: 'Typ bearbeiten',
                          text: `Sie sind dabei, den Typ dieses Dokuments zu bearbeiten.
                         Dadurch wird eine Lücke im Nummernkreis des alten Typs entstehen.
                          Fortfahren?`,
                          buttonText: 'Typ ändern'
                        },
                        open: true,
                        func: () => setDisableTypeSelect(false),
                      })} >
                      <MdEdit></MdEdit>
                    </button>
                  </div>
                  <div className="md:mt-5 md:mt-0 col-span-6
                     md:col-span-3 flex">
                    <div className="mt-4 flex-grow">
                      <ContactsDD
                        selectedContactC={[
                          selectedContact,
                          setSelectedContact,
                        ]}
                        selectedBillingAddressC={[
                          selectedBillingAddress,
                          setSelectedBillingAddress,
                        ]}
                        selectedDeliveryAddressC={[
                          selectedDeliveryAddress,
                          setSelectedDeliveryAddress,
                        ]}></ContactsDD>
                    </div>
                    <button
                      disabled={!selectedContact}
                      className='flex-shrink mt-9 mx-2 disabled:opacity-50'
                      onClick={() => setShowContactPopup(true)} >
                      <MdEdit></MdEdit>
                    </button>
                  </div>
                  <div className="md:mt-5 md:mt-0 col-span-6 md:col-span-3">
                    <div className="mt-4">
                      <AddressesDD
                        section="contacts"
                        name="Rechnungsadresse"
                        contactId={selectedContact}
                        optional={false}
                        selectedAddressC={[
                          selectedBillingAddress,
                          setSelectedBillingAddress,
                        ]}></AddressesDD>
                    </div>
                  </div>
                  <div className="md:mt-5 md:mt-0 col-span-6 md:col-span-3">
                    <div className="mt-4">
                      <AddressesDD
                        section="contacts"
                        name="Lieferadresse"
                        contactId={selectedContact}
                        optional={false}
                        selectedAddressC={[
                          selectedDeliveryAddress,
                          setSelectedDeliveryAddress,
                        ]}></AddressesDD>
                    </div>
                  </div>

                  <div className="md:mt-5 md:mt-0 col-span-6 md:col-span-6">
                    <div className="mt-4">
                      <SuperTB
                        tbCategory='comment_1'
                        providedDocumentCommentC={[
                          providedComment1,
                          setProvidedComment1,
                        ]}></SuperTB>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6 w-full max-w-6xl">
            <div className='flex mb-4'>
              <h3
                className="text-lg leading-6
                font-medium text-gray-900">
                Position hinzufügen
              </h3>
              <Menu as='div' className='relative mx-2'>
                <Menu.Button><MdSettings
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                /></Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out
                                           duration-100"
                  enterFrom="transform opacity-0
                                           scale-95"
                  enterTo="transform opacity-100
                                           scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100
                                           scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >

                  <Menu.Items className="absolute
                                           right-0
                                         w-56 mt-2 origin-top-right bg-white
                                          divide-y divide-gray-100 rounded-md
                                           shadow-lg ring-1 ring-black
                                            p-1 z-10
                                            ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${active &&
                            'bg-blue-100'} group flex
                                                    rounded-md items-center
                                                     w-full px-2 py-2 text-sm
                                                  font-medium justify-between`}
                          onClick={() => setShowWeight(!showWeight)}
                        >
                          <p>{showWeight ?
                            'Gewicht verstecken' : 'Gewicht anzeigen'}</p>
                          <div><MdHelp className='text-gray-700'
                            data-tip='Ermöglicht das Bearbeiten
                           und Einsehen von Artikelgewichten'/>
                            <ReactTooltip place="right"
                              type="info" effect="solid" />
                          </div>
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${active &&
                            'bg-blue-100'} group flex
                                                    rounded-md items-center
                                                     w-full px-2 py-2 text-sm
                                                  justify-between font-medium`}
                          onClick={() => setRenderArticleCode(
                            !renderArticleCode)}

                        >
                          <p>{renderArticleCode ?
                            'Artikelnr. verstecken' : 'Artikelnr. anzeigen'}</p>
                          <div><MdHelp className='text-gray-700'
                            data-tip='Beim Auswählen eines Artikels
                             wird die Artikelnr. im Namen angezeigt'/>
                            <ReactTooltip place="right"
                              type="info" effect="solid" />
                          </div>
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${active &&
                            'bg-blue-100'} group flex
                                                    rounded-md items-center
                                                     w-full px-2 py-2 text-sm
                                                  justify-between font-medium`}
                          onClick={() => setRenderExternalCode(
                            !renderExternalCode)}
                        >
                          <p>{renderExternalCode ?
                            'Externe Nr. verstecken' :
                            'Externe Nr. anzeigen'}</p>
                          <div><MdHelp className='text-gray-700'
                            data-tip='Beim Auswählen eines Artikels wird die
                             externe Artikelnr. (soweit vorhanden)
                              der Beschreibung hinzugefügt.'/>
                            <ReactTooltip place="right"
                              type="info" effect="solid" />
                          </div>
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
              {/* <p
                className="mt-1 text-sm
                text-gray-500">
                Fügen Sie hier Positionen hinzu.
              </p> */}
            </div>
            <div className="md:mt-5 md:mt-0 md:col-span-2">
              <div className={`grid gap-2 ${showWeight ?
                'grid-cols-12' : 'grid-cols-10'}`}>


                <div className="col-span-6 md:col-span-1">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700">
                    Menge
                  </label>
                  <input
                    type="number"
                    min="0"
                    name="amount"
                    autoComplete='off'
                    value={positionData.amount}
                    onInput={(e) => handlePositionChange(e)}
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 md:col-span-1">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700">
                    Einheit
                  </label>
                  <input
                    ref={unitRef}
                    type="text"
                    autoComplete='off'
                    name="unit"
                    value={positionData.unit}
                    onInput={(e) => handlePositionChange(e, 'string')}
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-12 md:col-span-3">
                  <ItemsDD
                    updateFunction={handlePositionChange}
                    selectedItemC={[selectedItem, setSelectedItem]}
                    nextInputRef={priceRef}
                    prevInputRef={unitRef}
                    includeCustomPositions={true}></ItemsDD>
                </div>

                {showWeight ? (
                  <div className="col-span-6 md:col-span-1">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700">
                      Gewicht
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        type="number"
                        name="weight"
                        autoComplete='off'
                        onInput={(e) => handlePositionChange(e)}
                        value={positionData.weight}
                        className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pr-9 sm:text-sm
                        border-gray-300 rounded-md"
                        placeholder="0,00"
                        aria-describedby="price-currency"
                      />
                      <div
                        className="absolute inset-y-0 right-0 pr-3 flex
                      items-center pointer-events-none">
                        <span
                          className="text-gray-500 sm:text-sm"
                          id="price-currency">
                          KG
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="col-span-6 sm:col-span-3 lg:col-span-1">
                  <label
                    htmlFor="postal_code"
                    className="block text-sm font-medium text-gray-700">
                    Einzelpreis
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      ref={priceRef}
                      type="number"
                      name="price"
                      autoComplete='off'
                      onInput={(e) => handlePositionChange(e)}
                      value={positionData.price}
                      className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pr-8 sm:text-sm
                        border-gray-300 rounded-md"
                      placeholder="0,00"
                      aria-describedby="price-currency"
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex
                      items-center pointer-events-none">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency">
                        €
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-span-6 md:col-span-1">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700">
                    Steuer
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      tabIndex={-1}
                      type="number"
                      name="tax"
                      onInput={(e) => handlePositionChange(e, 'number')}
                      value={positionData.tax}
                      className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pr-9 sm:text-sm
                        border-gray-300 rounded-md"
                      placeholder="0,00"
                      aria-describedby="price-currency"
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex
                      items-center pointer-events-none">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency">
                        %
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-span-6 md:col-span-1">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700">
                    Rabatt
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="number"
                      name="discount"
                      autoComplete='off'
                      onInput={(e) => handlePositionChange(e)}
                      value={positionData.discount}
                      className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pr-9 sm:text-sm
                        border-gray-300 rounded-md"
                      placeholder="0,00"
                      aria-describedby="price-currency"
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex
                      items-center pointer-events-none">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency">
                        %
                      </span>
                    </div>
                  </div>
                </div>

                {showWeight ? (
                  <div className="col-span-6 md:col-span-1">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700">
                      Gesamtgewicht
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        disabled
                        type="text"
                        name="total_weight"
                        onInput={(e) => handlePositionChange(e, 'number')}
                        value={positionData.total_weight}
                        className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pr-9 sm:text-sm
                        border-gray-300 rounded-md"
                        placeholder="0,00"
                        aria-describedby="price-currency"
                      />
                      <div
                        className="absolute inset-y-0 right-0 pr-3 flex
                      items-center pointer-events-none">
                        <span
                          className="text-gray-500 sm:text-sm"
                          id="price-currency">
                          KG
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="col-span-6 sm:col-span-3 lg:col-span-1">
                  <label
                    htmlFor="postal_code"
                    className="block text-sm font-medium text-gray-700">
                    Gesamtpreis
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      disabled
                      type="text"
                      name="total_price"
                      onInput={(e) => handlePositionChange(e, 'number')}
                      value={numberFormatter(positionData.total_price)}
                      className="focus:ring-blue-500 focus:border-blue-500
                        block w-full  pr-8 sm:text-sm
                        border-gray-300 rounded-md"
                      placeholder="0,00"
                      aria-describedby="price-currency"
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex
                      items-center pointer-events-none">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency">
                        €
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="col-span-6 sm:col-span-1 flex
                      items-end justify-end hide_mobile">
                  <button
                    onClick={() => handlePositionSubmit()}
                    type="button"
                    className="blue_btn">
                    +
                  </button>
                </div>

                <div className="col-span-12 md:col-span-3 col-start-1
                   md:col-start-3">
                  {/* <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700">
                    Beschreibung
                  </label> */}
                  <textarea
                    type="text"
                    name="description"
                    value={positionData.description}
                    onInput={(e) => handlePositionChange(e)}
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm
                            border-gray-300 rounded-md"></textarea>
                </div>
                <div
                  className="col-span-12 sm:col-span-1 flex
                      items-end justify-center show_mobile">
                  <button
                    onClick={() => handlePositionSubmit()}
                    type="button"
                    className="blue_btn">
                    Hinzufügen
                  </button>
                </div>
              </div>
              <div className="relative mb-4 mt-6">
                <div className="absolute inset-0 flex
                 items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="px-2 bg-white text-sm
                   text-gray-500">Positionen</span>
                </div>
              </div>
              <div className="col-span-6  mt-4">

                <DragDropContext
                  onDragEnd={(res) => onDragEnd(res)}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <ul
                        {...provided.droppableProps}
                        ref={provided.innerRef}

                      >
                        {(() => {
                          // Re Calculate Indexes
                          const positions = documentData.positions;
                          let indexCounter = 0;
                          for (
                            const [index, position] of positions.entries()) {
                            if (position !== 'break-page') {
                              positions[index].index = indexCounter;
                              indexCounter++;
                            }
                          }

                          return positions &&
                            Object.values(positions).map((el, index) => (
                              <Draggable key={index}
                                draggableId={`place-${index}`} index={index}>
                                {(provided, snapshot) => {
                                  return el === 'break-page' ? (
                                    <li ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}

                                      className={`select-none mb-8
                               ${snapshot.isDragging ?
                                          'bg-blue-100 rounded-md' : 'bg-white'}`}>
                                      <div className="flex">
                                        <div className="flex items-center
                                 justify-start">
                                          <div className='opacity-0'>
                                            <MdLink
                                              className="h-5 w-5 text-blue-400"
                                            />
                                          </div>
                                          <div className='flex justify-end hide_mobile
                                   items-center p-2'>
                                            <button type="button">
                                              <MdMenu
                                                className="h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </div>
                                        </div>

                                        <div className="w-full relative my-2">
                                          <div className="absolute inset-0 flex
                 items-center" aria-hidden="true">
                                            <div className="w-full
                                     border-t border-dashed border-gray-300" />
                                          </div>
                                          <div className="relative flex justify-center">
                                            <span className="px-2 bg-white text-sm
                   text-gray-500">Seitenumbruch</span>
                                          </div>
                                        </div>
                                        <div className='flex justify-start
                                   items-center p-2'>
                                          <button
                                            type="button"
                                            onClick={() => deletePosition(index)}>
                                            <MdDelete
                                              className="h-5 w-5 text-red-400"
                                              aria-hidden="true"
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </li>
                                  ) : (
                                    <li ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}

                                      className={`select-none ${snapshot.isDragging ?
                                        'bg-blue-100 rounded-md' : 'bg-white'}`}>
                                      <div
                                        className="flex items-center py-2 ">
                                        <div
                                          className="min-w-0 flex-1 flex items-center">
                                          <div
                                            className={`grid-cols-12 grid
                                   w-full ${showWeight ?
                                                'md:grid-cols-12' : 'md:grid-cols-10'}`}>
                                            <div className="flex items-center col-span-2
                                   md:col-span-1
                                   justify-start">
                                              <button
                                                onClick={() => {
                                                  setModalConfirm({
                                                    data: {
                                                      title: 'Verknüpfung entfernen',
                                                      text: `Sie sind dabei, eine Verknüpfung zu einem Artikel in
                                                     ihrem Warenlager aufzuheben. Für diese Position werden
                                                      keine automatischen Ausbuchungen mehr vorgenommen. Fortfahren?`,
                                                      buttonText: 'Entfernen'
                                                    },
                                                    open: true,
                                                    func: () => {
                                                      // Delete ID from
                                                      // current Position
                                                      const positions =
                                                        documentData.positions;
                                                      delete positions[index].id;

                                                      setDocumentData({
                                                        ...documentData,
                                                        positions,
                                                      });
                                                    }
                                                  })
                                                }
                                                }
                                                className={`rounded-full ${el.id ?
                                                  '' : 'opacity-0'}`}>
                                                <MdLink
                                                  className="h-5 w-5 text-blue-400"
                                                  data-tip='Mit Artikel
                                         aus Warenlager verknüpft'
                                                />
                                                <ReactTooltip className='bg-blue-900'
                                                  place="right"
                                                  type="info" effect="solid" />
                                              </button>
                                              <div className='flex justify-end hide_mobile
                                   items-center p-2'>
                                                <button type="button">
                                                  <MdMenu
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                  />
                                                </button>
                                              </div>
                                              <p
                                                className="text-sm font-medium
                          text-blue-600 truncate  p-2">
                                                {`${el.index + 1}.`}
                                              </p>
                                            </div>


                                            <div className='flex col-span-2
                                   md:col-span-1'>
                                              <div className='flex-center'>
                                                <input
                                                  onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                      // Prevents Default Behaviour
                                                      e.preventDefault();
                                                      setEditableListElement('');
                                                    }
                                                  }}
                                                  style={{
                                                    display: editableListElement ===
                                                      `amount ${index}` ?
                                                      'block' : 'none'
                                                  }}
                                                  value={el.amount}
                                                  onBlur={() => setEditableListElement(
                                                    ``)}
                                                  onInput={(e) => {
                                                    handlePositionListChange(
                                                      index, 'amount', e);
                                                  }}
                                                  className="shadow-sm
                                         focus:ring-indigo-500
                                     focus:border-indigo-500 block w-full
                                      sm:text-sm border-gray-300
                                       rounded-md" type="number" />
                                                <button
                                                  className="text-sm text-blue-600
                                    col-span-3 font-medium p-1 md:p-2"
                                                  type='button'
                                                  style={{
                                                    display: editableListElement !=
                                                      `amount ${index}` ?
                                                      'block' : 'none'
                                                  }}
                                                  onClick={() => {
                                                    setEditableListElement(
                                                      `amount ${index}`);
                                                  }}>
                                                  {el.amount || <MdAdd
                                                    className='text-blue-600
                                         font-large'/>}</button>

                                              </div>

                                              <div className='flex-center'>
                                                <input
                                                  onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                      // Prevents Default Behaviour
                                                      e.preventDefault();
                                                      setEditableListElement('');
                                                    }
                                                  }}
                                                  style={{
                                                    display: editableListElement ===
                                                      `unit ${index}` ?
                                                      'block' : 'none'
                                                  }}
                                                  value={el.unit}
                                                  onBlur={() => setEditableListElement(
                                                    ``)}
                                                  onInput={(e) => {
                                                    handlePositionListChange(
                                                      index, 'unit', e);
                                                  }}
                                                  className="shadow-sm
                                         focus:ring-indigo-500
                                     focus:border-indigo-500 block w-full
                                      sm:text-sm border-gray-300
                                       rounded-md" type="text" />
                                                <button
                                                  className="text-sm text-blue-600
                                    col-span-3 font-medium h-full p-1 md:p-2"
                                                  type='button'
                                                  style={{
                                                    display: editableListElement !=
                                                      `unit ${index}` ?
                                                      'block' : 'none'
                                                  }}
                                                  onClick={() => {
                                                    setEditableListElement(
                                                      `unit ${index}`);
                                                  }}>
                                                  {el.unit || <MdAdd
                                                    className='text-blue-600
                                         font-large'/>}</button>

                                              </div>
                                            </div>

                                            {showWeight ? <div className='flex-center
                                   hide_mobile'>
                                              <input
                                                onKeyDown={(e) => {
                                                  if (e.keyCode === 13) {
                                                    // Prevents Default Behaviour
                                                    e.preventDefault();
                                                    setEditableListElement('');
                                                  }
                                                }}
                                                style={{
                                                  display: editableListElement ===
                                                    `weight ${index}` ?
                                                    'block' : 'none'
                                                }}
                                                value={el.weight}
                                                onBlur={() => setEditableListElement(``)}
                                                onInput={(e) => handlePositionListChange(
                                                  index, 'weight', e)}
                                                className="shadow-sm focus:ring-indigo-500
                                     focus:border-indigo-500 block w-full
                                      sm:text-sm border-gray-300
                                       rounded-md" type="number" />
                                              <button
                                                className="text-sm text-gray-900
                                    col-span-3  p-2"
                                                type='button'
                                                style={{
                                                  display: editableListElement !=
                                                    `weight ${index}` ?
                                                    'block' : 'none'
                                                }}
                                                onClick={() => {
                                                  setEditableListElement(
                                                    `weight ${index}`);
                                                }}>
                                                {el.weight ? `${el.weight} kg` : <MdAdd
                                                  className='text-blue-600
                                         font-large'/>}</button>

                                            </div> : ''}

                                            <div className="col-span-6 md:col-span-3 flex
                                   justify-start" >
                                              <input
                                                onKeyDown={(e) => {
                                                  if (e.keyCode === 13) {
                                                    // Prevents Default Behaviour
                                                    e.preventDefault();
                                                    setEditableListElement('');
                                                  }
                                                }}
                                                style={{
                                                  display: editableListElement ===
                                                    `title ${index}` ?
                                                    'block' : 'none'
                                                }}
                                                ref={titleInputRef}
                                                value={el.title}
                                                onBlur={() => setEditableListElement(``)}
                                                onInput={(e) => handlePositionListChange(
                                                  index, 'title', e)}
                                                className="shadow-sm focus:ring-indigo-500
                                     focus:border-indigo-500 block w-full
                                      sm:text-sm border-gray-300
                                       rounded-md" type="text" />
                                              <button
                                                className="text-sm text-gray-900
                                    col-span-3 font-medium  p-2"
                                                type='button'
                                                style={{
                                                  display: editableListElement !=
                                                    `title ${index}` ?
                                                    'block' : 'none'
                                                }}
                                                onClick={() => {
                                                  setEditableListElement(
                                                    `title ${index}`);
                                                  titleInputRef.current.focus();
                                                }}>
                                                {el.title || <MdAdd
                                                  className='text-blue-600
                                         font-large'/>}</button>

                                            </div>

                                            <div className='flex items-center justify-end col-span-2 md:col-span-1'>
                                              <input
                                                onKeyDown={(e) => {
                                                  if (e.keyCode === 13) {
                                                    // Prevents Default Behaviour
                                                    e.preventDefault();
                                                    setEditableListElement('');
                                                  }
                                                }}
                                                style={{
                                                  display: editableListElement ===
                                                    `price ${index}` ?
                                                    'block' : 'none'
                                                }}
                                                value={el.price}
                                                onBlur={() => setEditableListElement(``)}
                                                onInput={(e) => handlePositionListChange(
                                                  index, 'price', e)}
                                                className="shadow-sm focus:ring-indigo-500
                                     focus:border-indigo-500 block w-full
                                      sm:text-sm border-gray-300
                                       rounded-md" type="number" />
                                              <button
                                                className="text-sm text-gray-900
                                    col-span-3 h-full p-2"
                                                type='button'
                                                style={{
                                                  display: editableListElement !=
                                                    `price ${index}` ?
                                                    'block' : 'none'
                                                }}
                                                onClick={() => {
                                                  setEditableListElement(
                                                    `price ${index}`);
                                                }}>
                                                {el.price ?
                                                  `${numberFormatter(el.price)}€` : <MdAdd
                                                    className='text-blue-600
                                     font-large'/>}</button>

                                            </div>

                                            <div className='flex-center hide_mobile'>
                                              <input
                                                onKeyDown={(e) => {
                                                  if (e.keyCode === 13) {
                                                    // Prevents Default Behaviour
                                                    e.preventDefault();
                                                    setEditableListElement('');
                                                  }
                                                }}
                                                style={{
                                                  display: editableListElement ===
                                                    `tax ${index}` ?
                                                    'block' : 'none'
                                                }}
                                                value={el.tax}
                                                onBlur={() => setEditableListElement(``)}
                                                onInput={(e) => handlePositionListChange(
                                                  index, 'tax', e)}
                                                className="shadow-sm focus:ring-indigo-500
                                     focus:border-indigo-500 block w-full
                                      sm:text-sm border-gray-300
                                       rounded-md" type="number" />
                                              <button
                                                className="text-sm text-gray-900
                                    col-span-3 h-full p-2"
                                                type='button'
                                                style={{
                                                  display: editableListElement !=
                                                    `tax ${index}` ?
                                                    'block' : 'none'
                                                }}
                                                onClick={() => {
                                                  setEditableListElement(
                                                    `tax ${index}`);
                                                }}>
                                                {el.tax ?
                                                  `${numberFormatter(
                                                    el.tax)}%` : <MdAdd
                                                    className='text-blue-600
                                         font-large'/>}</button>

                                            </div>

                                            <div className='flex hide_mobile'>
                                              <input
                                                onKeyDown={(e) => {
                                                  if (e.keyCode === 13) {
                                                    // Prevents Default Behaviour
                                                    e.preventDefault();
                                                    setEditableListElement('');
                                                  }
                                                }}
                                                style={{
                                                  display: editableListElement ===
                                                    `discount ${index}` ?
                                                    'block' : 'none'
                                                }}
                                                value={el.discount}
                                                onBlur={() => setEditableListElement(``)}
                                                onInput={(e) => handlePositionListChange(
                                                  index, 'discount', e)}
                                                className="shadow-sm focus:ring-indigo-500
                                     focus:border-indigo-500 block w-full
                                      sm:text-sm border-gray-300
                                       rounded-md" type="number" />
                                              <button
                                                className="text-sm text-gray-900
                                    col-span-3 h-full p-2"
                                                type='button'
                                                style={{
                                                  display: editableListElement !=
                                                    `discount ${index}` ?
                                                    'block' : 'none'
                                                }}
                                                onClick={() => {
                                                  setEditableListElement(
                                                    `discount ${index}`);
                                                }}>
                                                {el.discount ?
                                                  `${numberFormatter(
                                                    el.discount)}%` : <MdAdd
                                                    className='text-blue-600
                                         font-large'/>}</button>

                                            </div>

                                            {showWeight ? <p className="text-sm
                                  text-gray-900 p-2 hide_mobile">
                                              {el.total_weight ?
                                                `${el.total_weight} kg` : ''}
                                            </p> : ''}

                                            <p className="text-sm hide_mobile
                                   text-gray-900 p-2">
                                              {el.total_price &&
                                                `${numberFormatter(el.total_price)}€`}
                                            </p>

                                            <div style={{
                                              display: showWeight ?
                                                'none' : 'md:flex'
                                            }}
                                              className="flex justify-center
                                   col-start-12 hide_mobile">
                                              <div className='flex justify-end
                                   items-center p-2 hide_mobile'>
                                                <button
                                                  onClick={() => {
                                                    const positions =
                                                      documentData.positions;
                                                    positions.splice(
                                                      index + 1,
                                                      0, 'break-page');

                                                    setDocumentData({
                                                      ...documentData,
                                                      positions,
                                                    });
                                                  }}>
                                                  <MdSubdirectoryArrowLeft
                                                    className='h-5 w-5 text-gray-400' />
                                                </button>
                                              </div>

                                              <div className='flex justify-end
                                   items-center p-2 hide_mobile'>
                                                <button type="button"
                                                  onClick={() => setShowPosDet(index)}>
                                                  <MdEdit
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                  />
                                                </button>
                                              </div>
                                              <div className='flex justify-end
                                   items-center p-2 hide_mobile'>
                                                <button type="button"
                                                  onClick={() =>
                                                    handlePositionSubmit(
                                                      { ...el, index: null })}>
                                                  <MdContentCopy
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                  />
                                                </button>
                                              </div>
                                              <div className='flex justify-start
                                     hide_mobile
                                   items-center p-2'>
                                                <button
                                                  type="button"
                                                  onClick={() => deletePosition(index)}>
                                                  <MdDelete
                                                    className="h-5 w-5 text-red-400"
                                                    aria-hidden="true"
                                                  />
                                                </button>
                                              </div>
                                            </div>
                                            <div style={{
                                              display: showWeight ?
                                                'flex' : 'none'
                                            }}
                                              className='flex-center'>

                                              {/* Mobile Menu */}
                                              <Menu
                                                as="div" className="relative
                                 inline-block">

                                                <Menu.Button className='w-full
                                   items-center py-2 text-sm font-medium
                                    flex space-x-1'>
                                                  <MdMoreVert
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                  />
                                                </Menu.Button>

                                                <Menu.Items className="origin-top-right
                                   absolute
             top-0 -left-44 mt-2 w-40 text-black z-20
         rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
          focus:outline-none">
                                                  <div className="p-2 flex flex-col">
                                                    <Menu.Item>
                                                      <button
                                                        className='flex justify-start
                                            px-4 py-2 w-full text-sm font-medium
                                             hover:bg-gray-100'
                                                        type="button"
                                                        onClick={() => {
                                                          const positions =
                                                            documentData.positions;
                                                          positions.splice(
                                                            index + 1,
                                                            0, 'break-page');

                                                          setDocumentData({
                                                            ...documentData,
                                                            positions,
                                                          });
                                                        }}>
                                                        Seitenumbruch
                                                      </button>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                      <button
                                                        className='flex justify-start
                                            px-4 py-2 w-full text-sm font-medium
                                             hover:bg-gray-100'
                                                        type="button"
                                                        onClick={() =>
                                                          handlePositionSubmit(
                                                            { ...el, index: null })}>
                                                        Duplizieren
                                                      </button>
                                                    </Menu.Item>

                                                    <Menu.Item>
                                                      <button
                                                        className='flex justify-start
                                            px-4 py-2 w-full text-sm font-medium
                                             hover:bg-gray-100'
                                                        type="button"
                                                        onClick={() =>
                                                          setShowPosDet(index)}>
                                                        Bearbeiten
                                                      </button>
                                                    </Menu.Item>

                                                    <Menu.Item>
                                                      <button
                                                        className='flex justify-start
                                            px-4 py-2 w-full text-sm font-medium
                                             hover:bg-gray-100'
                                                        type="button"
                                                        onClick={() =>
                                                          deletePosition(index)}>
                                                        Löschen
                                                      </button>
                                                    </Menu.Item>
                                                  </div>
                                                </Menu.Items>
                                              </Menu>
                                            </div>


                                            <div className={`${showWeight ?
                                              'col-start-4' : 'col-start-3'} col-span-3
                                    hide_mobile`}>
                                              <textarea
                                                onKeyDown={(e) => {
                                                  if (e.ctrlKey && e.keyCode === 13) {
                                                    // Prevents Default Behaviour
                                                    e.preventDefault();
                                                    setEditableListElement('');
                                                  }
                                                }}
                                                style={{
                                                  display: editableListElement ===
                                                    `description ${index}` ?
                                                    'block' : 'none'
                                                }}
                                                value={el.description}
                                                onBlur={() => setEditableListElement(``)}
                                                onInput={(e) => handlePositionListChange(
                                                  index, 'description', e)}
                                                className="shadow-sm focus:ring-indigo-500
                                     focus:border-indigo-500 block w-full
                                      sm:text-sm border-gray-300
                                       rounded-md" type="text"></textarea>
                                              <button
                                                className="text-sm text-gray-900 text-left
                                    col-span-3 h-full p-2 whitespace-pre-wrap
                                     max-h-36 overflow-y-auto"
                                                type='button'
                                                style={{
                                                  display: editableListElement !=
                                                    `description ${index}` ?
                                                    'block' : 'none'
                                                }}
                                                onClick={() => {
                                                  setEditableListElement(
                                                    `description ${index}`);
                                                }}>
                                                {el.description ?
                                                  `${el.description}` : <MdAdd
                                                    className='text-blue-600
                                         font-large'/>}</button>

                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                }}
                              </Draggable>
                            ));
                        })()}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>


              </div>
              <div className="w-full border-t border-gray-300 my-4" />
              <div className="col-span-6 flex justify-end">
                <div className='w-64'>
                  <div className="flex justify-between">
                    <p className="font-medium">Zwischensumme:</p>
                    <p>{numberFormatter(documentSums.positionsTotal)}€</p>
                  </div>
                  {(() => {
                    const bufferArray = [];
                    if (documentSums.totalTaxSplit) {
                      for (const [key, value] of Object.entries(
                        documentSums.totalTaxSplit)) {
                        bufferArray.push(
                          <div key={key} className="flex justify-between">
                            <p className="font-medium">
                              {documentData.tax_incl &&
                                'enthält '}Steuer ({key}%):</p>
                            <p>{numberFormatter(value)}€</p>
                          </div>,
                        );
                      }
                    }
                    return bufferArray;
                  })()}
                  <div
                    style={{
                      display: documentSums.totalDiscountPercentage ?
                        'flex' : 'none'
                    }}
                    className="flex justify-between">
                    <p className="font-medium">Rabatt: {numberFormatter(
                      documentSums.totalDiscountPercentage)}%</p>
                    <p className=''>
                      -{numberFormatter(documentSums.totalDiscountAmount)}€</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-medium">Gesamtbetrag:</p>
                    <p className={`${!linkedReductions.length ? 'font-medium' : ''}`}>
                      {numberFormatter(documentSums.grossTotal)}€</p>
                  </div>
                  {linkedReductions.length ? (
                    <div className="flex justify-between mt-4">
                      <p className="font-medium">Forderungsbetrag:</p>
                      <p className='font-medium'>
                        {numberFormatter(documentSums.grossTotalAfterReductions)}€</p>
                    </div>
                  ) : ''}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: selectedDocumentType.name === 'Rechnung' ?
                'block' : 'none'
            }}
            className="bg-white shadow px-4 py-5 rounded-lg sm:p-6 max-w-6xl w-full">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3
                  className="text-lg font-medium leading-6
                text-gray-900">
                  Abschlagsrechnungen
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Verknüpfen Sie hier Abschlagsrechnungen
                </p>
              </div>
              <div className="col-span-2 rounded-md">
                <ul className="divide-y divide-gray-200">

                  {documents.filter((el) => el.type &&
                    el.type.name === 'Abschlagsrechnung')
                    .filter((el) =>
                      el.contact === selectedContact)
                    .filter((el) => {
                      if (!el.linked) return true;
                      console.log('loaded reductions', loadedReductions)
                      if (loadedReductions?.includes(el.id)) return true;
                      else return false
                    })
                    .sort((a, b) => b.created_date - a.created_date)
                    .map((el) => (
                      <li key={el.id}>
                        <div
                          className="px-4 py-4 flex items-center
                            sm:px-6 bg-gray-50 hover:bg-gray-100 rounded-md">
                          <div
                            className="min-w-0 flex-1 sm:flex
                              sm:items-center sm:justify-between">
                            <div className="truncate">
                              <div className="flex text-sm">
                                <p
                                  className="font-medium
                                    text-blue-600 truncate">
                                  {`${el.type.name} ${el.type.number}`}
                                </p>
                                {/* <p
                                      className="ml-1 flex-shrink-0
                                    font-normal text-gray-500">
                                      in {el.department}
                                    </p> */}
                              </div>
                              <div className="mt-2 flex">
                                <div
                                  className="flex items-center
                                    text-sm text-gray-500">
                                  {/* <MdDateRange
                                        className="flex-shrink-0 mr-1.5
                                        h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      /> */}
                                  <p>
                                    {`${numberFormatter(el
                                      .gross_total)}€`}

                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4 flex-shrink-0
                                 sm:mt-0 sm:ml-5">
                              <div className="flex overflow-hidden
                                   -space-x-1">
                                <input
                                  onChange={() => {
                                    const buffer = linkedReductions;
                                    if (buffer.includes(el.id)) {
                                      buffer.splice(buffer.findIndex(
                                        (bu) => bu === el.id), 1);
                                    } else {
                                      buffer.push(el.id);
                                    }
                                    setLinkedReductions([...buffer]);
                                  }}
                                  checked={linkedReductions
                                    .includes(el.id)}
                                  type="checkbox"
                                  className="focus:ring-blue-500 h-4
                                     w-4 text-blue-600
                      border-gray-300 rounded"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6 w-full max-w-6xl">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3
                  className="text-lg font-medium leading-6
                text-gray-900">
                  Weitere Daten
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Geben Sie hier weitere Daten für dieses Dokument an.
                </p>
              </div>
              <div className="md:mt-5 md:mt-0 md:col-span-2">
                <div className="mt-4 flex space-x-6">
                  <div className='flex-grow'>
                    <SuperTB
                      name="Zahlungsbedingungen"
                      tbCategory='payment_conditions'
                      providedDocumentCommentC={[
                        providedPaymentConditions,
                        setProvidedPaymentConditions,
                      ]}></SuperTB>

                  </div>
                  <div className='w-28'>
                    <label htmlFor="email" className="block text-sm
                    font-medium text-gray-700">
                      Zahlungsziel
                    </label>
                    <div className="mt-1">
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          value={paymentTargetDays}
                          onInput={(e) =>
                            setPaymentTargetDays(Number(e.target.value))}
                          type="number"
                          name="payment_target_days"
                          className="shadow-sm focus:ring-indigo-500
                         focus:border-indigo-500 block w-full pr-12
                         sm:text-sm border-gray-300 rounded-md"
                        />
                        <div
                          className="absolute inset-y-0 right-0 pr-3 flex
                      items-center pointer-events-none">
                          <span
                            className="text-gray-500 sm:text-sm"
                            id="price-currency">
                            Tage
                          </span>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <SuperTB
                    tbCategory='delivery_conditions'
                    name="Lieferbedingungen"
                    providedDocumentCommentC={[
                      providedDeliveryConditions,
                      setProvidedDeliveryConditions,
                    ]}></SuperTB>
                </div>

                <div className="mt-4">
                  <SuperTB
                    tbCategory='comment_2'
                    name="Kommentar 2"
                    providedDocumentCommentC={[
                      providedComment2,
                      setProvidedComment2,
                    ]}></SuperTB>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6 max-w-6xl">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3
                  className="text-lg font-medium leading-6
                text-gray-900">
                  Rabatt
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Geben Sie hier Rabatt für das aktuelle Dokument an.
                </p>
              </div>
              <div className="grid grid-cols-6 col-span-2 gap-6">
                <div className="col-span-3 sm:col-span-3 lg:col-span-1">
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="number"
                      name="total_discount_percentage"
                      onInput={(e) => {
                        setDiscountMode('percentage');
                        handleChange(
                          e.target.name, e.target.value);
                      }}
                      value={documentSums.totalDiscountPercentage}
                      className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pr-8 sm:text-sm
                        border-gray-300 rounded-md"
                      placeholder="0,00"
                      aria-describedby="price-currency"
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex
                      items-center pointer-events-none">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency">
                        %
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-center hide_mobile">
                  <p>=</p>
                </div>
                <div className="col-span-3 sm:col-span-3 lg:col-span-1">
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="number"
                      name="total_discount_amount"
                      onInput={(e) => {
                        setDiscountMode('amount');
                        handleChange(
                          e.target.name, e.target.value);
                      }}
                      value={documentSums.totalDiscountAmount}
                      className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pr-8 sm:text-sm
                        border-gray-300 rounded-md"
                      placeholder="0,00"
                      aria-describedby="price-currency"
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex
                      items-center pointer-events-none">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency">
                        €
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-span-6 col-start-1">
                  <div className="flex justify-between">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700">
                      Rabatt Art
                    </label>
                    <div>
                      <p className="block text-sm font-medium text-gray-500">
                        Optional
                      </p>
                    </div>
                  </div>
                  <input
                    type="text"
                    name="total_discount_type"
                    value={documentData.total_discount_type}
                    onInput={(e) => handleChange(
                      e.target.name, e.target.value)}
                    autoComplete="family-name"
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>



          <div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6 max-w-6xl">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3
                  className="text-lg font-medium leading-6
                text-gray-900">
                  Erweitert
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Hier können Sie erweiterte Optionen einstellen.
                </p>
              </div>
              <div className="grid grid-cols-6 col-span-2 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700">
                    Erstellungsdatum
                  </label>

                  <input
                    type="date"
                    name="document_created_date"
                    value={millsToDate(documentData.document_created_date,
                      'date_input')}
                    onInput={(e) => handleDateChange(e)}
                    autoComplete="family-name"
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div
                  style={{
                    display: selectedDocumentType.name === 'Angebot' ||
                      selectedDocumentType.name === 'Auftragsbestätigung' ?
                      'block' : 'none'
                  }}
                  className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700">
                    Gültigkeitsdatum
                  </label>

                  <input
                    type="date"
                    name="validUntilDate"
                    value={millsToDate(validUntilDate,
                      'date_input')}
                    onInput={(e) => {
                      const input = new Date(e.target.value);
                      const date = input.getTime();
                      setValidUntilDate(date);
                    }}
                    autoComplete="family-name"
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div
                  className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700">
                    Leistungsdatum
                  </label>

                  <input
                    type="date"
                    name="delivery_date"
                    value={millsToDate(documentData.delivery_date, 'date_input')}
                    onInput={(e) => {
                      console.log((new Date(e.target.value)).getTime());
                      handleChange(e.target.name, (new Date(e.target.value)).getTime())
                    }}
                    autoComplete="family-name"
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 sm:col-start-1">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700">
                    Externe Bestellnr.
                  </label>

                  <input
                    type="text"
                    name="external_order_nr"
                    value={documentData.external_order_nr}
                    onInput={(e) => handleChange(
                      e.target.name, e.target.value)}
                    autoComplete="family-name"
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <ContactsDD
                    name='Bearbeiter'
                    type='memberships'
                    selectedContactC={[
                      selectedOfficialInCharge,
                      setSelectedOfficialInCharge,
                    ]}
                  ></ContactsDD>
                  {/* <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700">
                    Bearbeiter
                  </label>

                  <input
                    type="text"
                    name="official_in_charge_name"
                    value={documentData.official_in_charge.name}
                    onInput={(e) => handleChange(e.target.name, e.target.value)}
                    autoComplete="family-name"
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
                  /> */}
                </div>

                <div className="col-span-6 sm:col-span-3 sm:col-start-1">
                  <AddressesDD
                    section="team"
                    name="Absenderadresse"
                    // contactId={selectedContact}
                    optional={false}
                    selectedAddressC={[
                      selectedSenderAddress,
                      setSelectedSenderAddress,
                    ]}></AddressesDD>
                </div>

                <div className='col-span-3'>
                  <label
                    htmlFor="tax_incl"
                    className="block text-sm font-medium text-gray-700">
                    Steuer
                  </label>
                  <select
                    name="tax_incl"
                    onInput={(e) => {
                      handleChange(e.target.name,
                        e.target.value === 'inkl.' ? true : false);
                    }}
                    value={documentData.tax_incl ? 'inkl.' : 'exkl.'}
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md">
                    <option>inkl.</option>
                    <option>exkl.</option>
                  </select>
                </div>

                <div className="col-span-6">
                  {/* <TagsDD
                    optional={false}
                    selectedTagsC={[selectedTags, setSelectedTags]}>
                    </TagsDD> */}
                  <MultiSelect
                    selectedArrC={[selectedTags, setSelectedTags]}
                    dataC={[tags, setTags]}
                    collection='tags'
                    filter={function (data) {
                      return data
                        .filter((el) => el.section === 'documents');
                    }}
                    search={function (data, input) {
                      return data.filter((el) => {
                        const text = el.text;
                        return text && text.toLowerCase().includes(input);
                      });
                    }}
                    dataStructure={function (inputValue, selectedColor) {
                      return {
                        text: inputValue,
                        color: selectedColor,
                        section: 'documents',
                      };
                    }}
                    name='Tags'
                    optional={false}
                  ></MultiSelect>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="flex justify-end w-full max-w-6xl mx-auto p-2 md:py-4">
          <button
            onClick={() => history && history.goBack()}
            className="bg-white py-2 px-4 border border-gray-300
          rounded-md shadow-sm text-sm font-medium text-gray-700
          hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2
          focus:ring-blue-500">
            Abbrechen
          </button>
          <button
            disabled={isSaving ||
              !selectedDocumentType.id && !selectedDocumentType.name ||
              !selectedContact.length > 0 ?
              true :
              false
            }
            onClick={() => {
              if (selectedDocumentType.name === 'Lieferschein') {
                setModalConfirm({
                  data: {
                    title: 'Automatische Ausbuchung',
                    text: 'Sie sind dabei, eine automatische Ausbuchung vorzunehmen.',
                    buttonText: 'Ausbuchen'
                  },
                  open: true,
                  func: handleSubmit,
                })
              } else {
                handleSubmit(documentData);
              }
            }}
            className="blue_btn">
            Speichern
          </button>
        </div>
      </div>
    </>
  );
}

DocumentPopup.propTypes = {
  location: PropTypes.object,
  state: PropTypes.object,
  history: PropTypes.object,
};

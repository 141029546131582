/** Add Item Component */
import React, { useState, useEffect, useRef, useContext } from 'react';
// import ProductCategoryDD from './ProductCategoryDD';
// import WarehousesDD from './WarehousesDD';
// import {MdHelp} from 'react-icons/md';
import PropTypes from 'prop-types';

import CustomFieldTypesDD from '../dropdowns/CustomFieldTypesDD';
// import ContactGroupsDD from '../dropdowns/ContactGroupsDD';
import SuperTB from '../dropdowns/SuperTB';

import { MdAddCircle, MdDelete, MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import { AuthContext } from '../context/AuthContext';
import MultiSelect from '../dropdowns/MultiSelect';
// import Localbase from 'localbase';
import { createDoc, RerenderContext, updateDoc } from '../cud';
import Localbase from 'localbase';
import { addLogEntry } from '../utilities';
/**
 *
 * @return {object} jsx
 */
export default function ContactPopup({ location, history, disableGoBack,
  heading, onSave }) {
  const [rerender, setRerender] = useContext(RerenderContext);
  // Auth Context
  const { teamC, isOfflineC, userC, toastsC } = useContext(AuthContext);
  const currentTeam = teamC[0];
  const isOffline = isOfflineC[0];
  const currentUser = userC[0];
  const addToast = toastsC[1];

  const [contactGroups, setContactGroups] = useState([]);

  const [isSaving, setIsSaving] = useState(false);


  const dataStructure = {
    number: '',
    name: '',
    type: 'Kunde',
    vat_nr: '',

    tax_percentage: '',
    discount_percentage: '',
    render_article_code: '',
    render_external_code: '',
    document_comment: '',
    delivery_conditions: '',
    payment_conditions: '',

    groups: [],
    custom_fields: [],
    addresses: [],
  };
  const [contactData, setContactData] = useState(dataStructure);

  const addressDataStructure = {
    salutation: '',
    name: '',
    name_2: '',
    address: '',
    address_2: '',
    postal_code: '',
    city: '',
    region: '',
    country: '',
  };

  const [addressData, setAddressData] = useState(addressDataStructure);

  const [selectedContactGroups, setSelectedContactGroups] = useState([]);
  const [selectedCustomFieldType, setSelectedCustomFieldType] = useState('');
  const [providedCustomFieldValue, setProvidedCustomFieldValue] = useState('');
  const [providedDocumentComment, setProvidedDocumentComment] = useState('');
  const [providedPaymentConditions, setProvidedPaymentConditions] =
    useState('');
  const [providedDeliveryConditions, setProvidedDeliveryConditions] =
    useState('');

  const customFieldTypeRef = useRef(null);
  const customFieldValueRef = useRef(null);

  useEffect(function () {
    if (location && location.state && location.state.contactId) {
      const lb = new Localbase(currentTeam.id);
      // lb.config.debug = false;
      lb.collection('contacts').doc({ id: location.state.contactId })
        .get().then((contact) => {
          const docData = contact &&
            { ...contact, id: location.state.contactId };

          if (docData) {
            setContactData({ ...docData });

            // Init Values of Dropdowns/Textboxes
            setSelectedContactGroups(docData.groups || []);
            setProvidedDocumentComment(docData.document_comment || '');
            setProvidedPaymentConditions(docData.payment_conditions || '');
            setProvidedDeliveryConditions(docData.delivery_conditions || '');
          }
        });
    }
  }, []);

  // Load Data
  useEffect(async function () {
    if (currentTeam) {
      const lb = new Localbase(currentTeam.id);
      // lb.config.debug = false;

      const contactGroups = await lb.collection('contact_groups').get();
      setContactGroups(contactGroups);
    }
  }, [currentTeam, rerender]);


  // // Load Contact Data to edit
  // useEffect(function() {
  //   if (docData) {
  //     setContactData({...docData});

  //     // Init Values of Dropdowns/Textboxes
  //     setSelectedContactGroups(docData.groups || []);
  //     setProvidedDocumentComment(docData.document_comment || '');
  //     setProvidedPaymentConditions(docData.payment_conditions || '');
  //     setProvidedDeliveryConditions(docData.delivery_conditions || '');
  //   }
  // }, []);

  // Focuses the custom field value Input when type is selected
  // useEffect(
  //     function() {
  //       if (
  //         providedCustomFieldValue.length === 0 &&
  //       selectedCustomFieldType.length > 0
  //       ) {
  //       // customFieldValueRef.current.focus();
  //       }
  //     }[selectedCustomFieldType],
  // );

  /**
   * Handles Change in Input
   * @param {object} event
   * @param {string} dataType

   */
  function handleChange(event, dataType = 'string') {
    const { value, name, checked } = event.target;

    dataType === 'boolean' &&
      setContactData({
        ...contactData,
        [name]: Boolean(checked),
      });

    dataType === 'string' &&
      setContactData({
        ...contactData,
        [name]: String(value),
      });

    dataType === 'number' &&
      setContactData({
        ...contactData,
        [name]: Number(value),
      });
  }

  /**
   * Handles Form Submit
   */
  async function handleSubmit() {

    // Check if Address Inputs are not empty
    if (Object.values(addressData).filter((el) => el).length) {
      return alert('Adressfelder nicht leer!')
    }

    setIsSaving(true);

    if (contactData.created_date && contactData.id) {
      // Edit existing Document
      try {
        await updateDoc(currentTeam.id, 'contacts', contactData.id, {
          ...contactData,
          groups: selectedContactGroups,
          document_comment: providedDocumentComment,
          payment_conditions: providedPaymentConditions,
          delivery_conditions: providedDeliveryConditions,
        }, setRerender, isOffline);
      } catch (e) {
        addToast('error', `Fehler: Kontakt konnte nicht bearbeitet werden`);
        setIsSaving(false);
        return console.log(e);
      }
      onSave && onSave();
      addToast('success', 'Kontakt bearbeitet');
      addLogEntry(currentTeam.id, 'contacts', currentUser.uid,
        {
          action: 'bearbeitet',
          number: contactData.number,
          name: contactData.name,
        }, setRerender, isOffline);
    } else {
      // Save New Document
      try {
        await createDoc(currentTeam.id, 'contacts', {
          ...contactData,
          groups: selectedContactGroups,
          document_comment: providedDocumentComment,
          payment_conditions: providedPaymentConditions,
          delivery_conditions: providedDeliveryConditions,
        }, setRerender, isOffline);
      } catch (e) {
        addToast('error', 'Fehler: Kontakt konnte nicht erstellt werden');
        setIsSaving(false);
        return console.log(e);
      }
      onSave && onSave();
      addToast('success', 'Kontakt erstellt');
      // Add Log Entry
      addLogEntry(currentTeam.id, 'contacts', currentUser.uid,
        {
          action: 'erstellt',
          number: contactData.number,
          name: contactData.name,
        }, setRerender, isOffline);
    }

    setIsSaving(false);

    if (!disableGoBack) history.goBack();
  }

  /**
   * Handles Form Submit
   * @param {object} event
   */
  function handleAddressSubmit() {
    // Push Address to contact Addresses
    const addresses = contactData.addresses;
    addresses.push(addressData);
    setContactData({ ...contactData, addresses: addresses });
    // Reset Address Inputs
    setAddressData(addressDataStructure);
  }

  /**
   * Handles Change in Address Input
   * @param {object} event

   */
  function handleAddressChange(event) {
    const { value, name } = event.target;

    setAddressData({
      ...addressData,
      [name]: String(value),
    });
  }

  /**
   * Deletes Adress
   * @param {number} index

   */
  function deleteAddress(index) {
    const addresses = contactData.addresses;
    addresses.splice(index, 1);

    setContactData({
      ...contactData,
      addresses: addresses,
    });
  }
  /**
 * Deletes Adress
 * @param {number} index

 */
  function editAddress(index, data) {
    const addresses = contactData.addresses;
    addresses.splice(index, 1)

    console.log({ ...data });
    setAddressData({ ...data })
    setContactData({
      ...contactData,
      addresses: addresses,
    });
  }

  /**
   * Adds Custom Field
   */
  function addCustomField() {
    // Return if one Input is empty
    if (
      providedCustomFieldValue.length === 0 ||
      selectedCustomFieldType.length === 0
    ) {
      return;
    }

    const csf = contactData.custom_fields;
    csf.push({
      custom_field_name: selectedCustomFieldType,
      custom_field_value: providedCustomFieldValue,
    });

    setContactData({
      ...contactData,
      custom_fields: csf,
    });

    setSelectedCustomFieldType('');
    setProvidedCustomFieldValue('');
    customFieldTypeRef.current.focus();
  }

  /**
 * Deletes Custom Field
 * @param {number} index

 */
  function editCustomField(index, data) {
    const customFields = contactData.custom_fields;
    customFields.splice(index, 1);
    setSelectedCustomFieldType(data.custom_field_name);
    setProvidedCustomFieldValue(data.custom_field_value);
    setContactData({
      ...contactData,
      custom_fields: customFields,
    });
  }

  /**
   * Deletes Custom Field
   * @param {number} index

   */
  function deleteCustomField(index) {
    const customFields = contactData.custom_fields;
    customFields.splice(index, 1);

    setContactData({
      ...contactData,
      custom_fields: customFields,
    });
  }

  return (
    <div
      onClick={(e) => console.log('ping')}
      className="flex-shrink h-full bg-gray-100 flex flex-col">
      <div className="pb-5 border-b border-gray-200 p-8 flex justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {(() => {
            if (location && location.state) {
              return (location.state.heading || 'Kontakt hinzufügen');
            }
          })()}
        </h3>
        {!disableGoBack ? (
          <button onClick={() => {
            history.goBack();
          }}>
            <div className="flex-center text-gray-900">
              <MdClose size="2rem"></MdClose>
            </div>
          </button>
        ) : ''}
      </div>
      <div className="overflow-y-auto flex-shrink min-h-0 space-y-6 p-2 md:p-8 flex flex-col items-center">
        <div id='general'
          className="bg-white shadow px-4 py-5 rounded-lg sm:p-6 max-w-6xl">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Allgemeine Informationen
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Fügen Sie hier allgemeine Kontaktinformationen ein.
              </p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-2">
                  <label
                    htmlFor="type"
                    className="block text-sm font-medium text-gray-700">
                    Typ
                  </label>
                  <select
                    disabled={contactData.created_date &&
                      contactData.id ? true : false}
                    required
                    name="type"
                    onChange={(e) => handleChange(e)}
                    value={contactData.type}
                    className="mt-1 focus:ring-blue-500
                    focus:border-blue-500 block w-full shadow-sm
                    sm:text-sm border-gray-300 rounded-md">
                    <option>Kunde</option>
                    <option>Lieferant</option>
                  </select>
                </div>

                {/* <div className="col-span-6 sm:col-span-4">
                    <label
                      htmlFor="number"
                      className="block text-sm font-medium text-gray-700">
                      Nummer
                    </label>
                    <input
                      // disabled
                      type="text"
                      name="number"
                      onChange={(e) => handleChange(e)}
                      value={contactData.number}
                      className="mt-1 focus:ring-blue-500
                    focus:border-blue-500 block w-full shadow-sm
                    sm:text-sm border-gray-300 rounded-md"
                    />
                  </div> */}

                <div className="col-span-6 sm:col-span-4 col-start-1">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    required
                    type="text"
                    name="name"
                    onChange={(e) => handleChange(e)}
                    value={contactData.name}
                    className="mt-1 focus:ring-blue-500
                    focus:border-blue-500 block w-full shadow-sm
                    sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-4">
                  <label
                    htmlFor="vat_nr"
                    className="block text-sm font-medium text-gray-700">
                    Ust Id.
                  </label>
                  <input
                    type="text"
                    name="vat_nr"
                    onInput={(e) => handleChange(e)}
                    value={contactData.vat_nr}
                    className="mt-1 focus:ring-blue-500
                    focus:border-blue-500 block w-full shadow-sm
                    sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6">
                  <MultiSelect
                    selectedArrC={[
                      selectedContactGroups,
                      setSelectedContactGroups,
                    ]}
                    dataC={[contactGroups, setContactGroups]}
                    collection='contact_groups'
                    filter={(data) => data}
                    search={function (data, input) {
                      return data.filter((el) => {
                        const name = el.name;
                        return name && name.toLowerCase().includes(input);
                      });
                    }}
                    dataStructure={function (inputValue, selectedColor) {
                      return {
                        name: inputValue,
                        color: selectedColor,
                      };
                    }}
                    name='Kontaktgruppen'
                    optional={false}
                  ></MultiSelect>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id='custom_fields'
          className="bg-white shadow px-4 py-5 rounded-lg sm:p-6 max-w-6xl">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Weitere Kontaktdaten
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Fügen Sie hier flexibel weitere Daten hinzu. Wählen Sie zuerst
                den Typ, und geben Sie dann den entsprechenden Wert an.
              </p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-2">
                  <CustomFieldTypesDD
                    customFieldValueRef={customFieldValueRef}
                    ref={customFieldTypeRef}
                    selectedCustomFieldTypeC={[
                      selectedCustomFieldType,
                      setSelectedCustomFieldType,
                    ]}></CustomFieldTypesDD>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <div className="flex justify-between">
                    <label
                      htmlFor="account-number"
                      className="block text-sm font-medium text-gray-700">
                      Wert
                    </label>
                    {providedCustomFieldValue.length > 0 &&
                      selectedCustomFieldType.length > 0 && (
                        <span
                          className="text-sm text-gray-500"
                          id="email-optional">
                          &quot;Enter&quot; drücken zum Speichern.
                        </span>
                      )}
                  </div>
                  <input
                    ref={customFieldValueRef}
                    onKeyDown={(e) => {
                      e.keyCode === 13 && e.preventDefault();
                      e.keyCode === 13 && addCustomField();
                    }}
                    type="text"
                    name="custom_field_value"
                    onChange={(e) => {
                      setProvidedCustomFieldValue(e.target.value);
                    }}
                    value={providedCustomFieldValue}
                    autoComplete="family-name"
                    className="mt-1 focus:ring-blue-500
                    focus:border-blue-500 block w-full shadow-sm
                    sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 md:col-span-1
                   flex items-end justify-center md:justify-end">
                  <button
                    onClick={() => addCustomField()}
                    type="button"
                    className="flex-center px-4 py-2 border
                    border-transparent shadow-sm text-sm font-medium
                    rounded-md text-white bg-blue-600 hover:bg-blue-700
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-blue-500">
                    <MdAddCircle className=" h-5 w-5" aria-hidden="true" />
                  </button>
                </div>

                <div className="col-span-6 max-h-48 overflow-auto">
                  <ul id='display_custom_fields'
                    className="divide-y divide-gray-200">
                    {contactData.custom_fields &&
                      Object.values(contactData.custom_fields).map(
                        (el, index) => (
                          <li key={index}>
                            <div
                              className="flex items-center px-4
                              py-4 sm:px-6">
                              <div
                                className="min-w-0 flex-1 flex
                                items-center">
                                <div
                                  className="min-w-0 flex-1 px-4
                                    md:grid
                              md:grid-cols-2 md:gap-4">
                                  <div>
                                    <p
                                      className="text-sm font-medium
                                  text-blue-600 truncate">
                                      {el.custom_field_name}
                                    </p>
                                  </div>
                                  <div className="hidden md:block">
                                    <div>
                                      <p className="text-sm text-gray-900">
                                        {el.custom_field_value}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='flex space-x-2'>
                                <button
                                  onClick={() => editCustomField(index, el)}>
                                  <MdEdit
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </button>
                                <button
                                  id='delete_custom_field'
                                  onClick={() => deleteCustomField(index)}>
                                  <MdDelete
                                    className="h-5 w-5 text-red-400"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </li>
                        ),
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id='addresses'
          className="bg-white shadow px-4 py-5 rounded-lg sm:p-6 max-w-6xl">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Adressen
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Fügen Sie hier Rechnungs- und Lieferadressen ein. Alle eingegebenen Adressinformationen werden auf Dokumenten angezeigt
              </p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-4 sm:col-span-2">
                  <label
                    htmlFor="salutation"
                    className="block text-sm font-medium text-gray-500">
                    Anrede
                  </label>
                  <select
                    onChange={(e) => handleAddressChange(e)}
                    value={addressData.salutation}
                    name="salutation"
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md">
                    <option></option>
                    <option>Herr</option>
                    <option>Frau</option>
                    <option>Firma</option>
                  </select>
                </div>
                <div className="sm:col-start-1 col-span-6 sm:col-span-3">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-800">
                    Name/Firma
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={addressData.name}
                    onChange={(e) => handleAddressChange(e)}
                    autoComplete="off"
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="name_2"
                    className="block text-sm font-medium text-gray-500">
                    Zusatz
                  </label>
                  <input
                    type="text"
                    name="name_2"
                    value={addressData.name_2}
                    onChange={(e) => handleAddressChange(e)}
                    autoComplete="off"
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-800">
                    Straße & Hausnr.
                  </label>
                  <input
                    type="text"
                    name="address"
                    value={addressData.address}
                    onChange={(e) => handleAddressChange(e)}
                    autoComplete="email"
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="address_2"
                    className="block text-sm font-medium text-gray-500">
                    Zusatz
                  </label>
                  <input
                    type="text"
                    name="address_2"
                    value={addressData.address_2}
                    onChange={(e) => handleAddressChange(e)}
                    autoComplete="email"
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    htmlFor="postal_code"
                    className="block text-sm font-medium text-gray-800">
                    PLZ
                  </label>
                  <input
                    type="text"
                    name="postal_code"
                    value={addressData.postal_code}
                    onChange={(e) => handleAddressChange(e)}
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500
                            block w-full shadow-sm sm:text-sm
                            border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-800">
                    Stadt
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={addressData.city}
                    onChange={(e) => handleAddressChange(e)}
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500
                            block w-full shadow-sm sm:text-sm
                            border-gray-300 rounded-md"
                  />
                </div>
                <div
                  className="col-span-6 sm:col-span-3
                      lg:col-span-2 lg:col-start-1">
                  <label
                    htmlFor="region"
                    className="block text-sm font-medium text-gray-500">
                    Region
                  </label>
                  <input
                    type="text"
                    name="region"
                    value={addressData.region}
                    onChange={(e) => handleAddressChange(e)}
                    className="mt-1 focus:ring-blue-500
                            focus:border-blue-500
                            block w-full shadow-sm sm:text-sm
                            border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-500">
                    Land
                  </label>
                  <input
                    type="text"
                    value={addressData.country}
                    onChange={(e) => handleAddressChange(e)}
                    name="country"
                    className="mt-1 block w-full py-2 px-3 border
                            border-gray-300 bg-white rounded-md shadow-sm
                            focus:outline-none focus:ring-blue-500
                            focus:border-blue-500 sm:text-sm"
                  />
                </div>
                <div
                  className="col-span-6 sm:col-span-2 flex
                      items-end justify-end">
                  <button
                    id='add_address'
                    onClick={() => handleAddressSubmit()}
                    type="button"
                    className="inline-flex justify-center py-2
                        px-4 border
                        border-transparent shadow-sm text-sm font-medium
                        rounded-md
                        text-white bg-blue-600 hover:bg-blue-700
                        focus:outline-none
                        focus:ring-2 focus:ring-offset-2
                        focus:ring-blue-500">
                    Hinzufügen
                  </button>
                </div>
              </div>
              <div className="col-span-6 max-h-48 overflow-auto mt-4">
                <ul className="divide-y divide-gray-200">
                  {contactData.addresses &&
                    Object.values(contactData.addresses).map(
                      (el, index) => (
                        <li key={index}>
                          <div
                            className="flex items-center px-4
                              py-4 sm:px-6">
                            <div
                              className="min-w-0 flex-1 flex
                                items-center">
                              <div
                                className="min-w-0 flex-1 px-4
                                    md:grid
                              md:grid-cols-2 md:gap-4">
                                <div>
                                  <p
                                    className="text-sm font-medium
                                  text-blue-600 truncate">
                                    {`${el.salutation} ${el.name}`}
                                  </p>
                                </div>
                                <div className="hidden md:block">
                                  <div>
                                    <p className="text-sm text-gray-900">
                                      {`${el.address}, 
                                          ${el.postal_code} 
                                          ${el.city}`}
                                    </p>
                                    <p className="text-sm text-gray-900">
                                      {el.country}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='flex space-x-2'>
                              <button
                                onClick={() => editAddress(index, el)}>
                                <MdEdit
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </button>
                              <button
                                id='delete_address'
                                type="button"
                                onClick={() => deleteAddress(index)}>
                                <MdDelete
                                  className="h-5 w-5 text-red-400"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </li>
                      ),
                    )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id='standards'
          className="bg-white shadow px-4 py-5 rounded-lg sm:p-6 max-w-6xl">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3
                className="text-lg font-medium leading-6
                text-gray-900">
                Standartwerte
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Geben Sie hier Standartwerte für diesen Kontakt ein. Diese
                werden in jedes Dokument für diesen Kontakt eingefügt.
              </p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="flex justify-start items-center space-x-4">
                <p>Rabatt:</p>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    onInput={(e) => handleChange(e)}
                    value={contactData.discount_percentage}
                    type="number"
                    name="discount_percentage"
                    className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pr-8 sm:text-sm
                        border-gray-300 rounded-md"
                    placeholder="0,00"
                  />
                  <div
                    className="absolute inset-y-0 right-0 pr-3 flex
                      items-center pointer-events-none">
                    <span
                      className="text-gray-500 sm:text-sm">
                      %
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex justify-start items-center space-x-4">
                <p>Steuer:</p>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    onInput={(e) => handleChange(e)}
                    value={contactData.tax_percentage}
                    type="number"
                    name="tax_percentage"
                    className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pr-8 sm:text-sm
                        border-gray-300 rounded-md"
                    placeholder="19,00"
                  />
                  <div
                    className="absolute inset-y-0 right-0 pr-3 flex
                      items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">
                      %
                    </span>
                  </div>
                </div>
              </div>
              <div className="relative flex items-start mt-4">
                <div className="flex items-center h-5">
                  <input
                    onChange={(e) => handleChange(e, 'boolean')}
                    checked={contactData.render_article_code}
                    name="render_article_code"
                    type="checkbox"
                    className="focus:ring-blue-500 h-4 w-4 text-blue-600
                      border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="render_article_code"
                    className="font-medium text-gray-700">
                    Artikelnummer anzeigen
                  </label>
                  <p className="text-gray-500">
                    Zeigt die Nummer eines Artikels auf Dokumenten an
                  </p>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex items-center h-5 mt-4">
                  <input
                    onChange={(e) => handleChange(e, 'boolean')}
                    checked={contactData.render_external_code}
                    name="render_external_code"
                    type="checkbox"
                    className="focus:ring-blue-500 h-4 w-4 text-blue-600
                      border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="render_external_code"
                    className="font-medium text-gray-700">
                    Externe Nummer anzeigen
                  </label>
                  <p id="candidates-description" className="text-gray-500">
                    Zeigt die externe Nummer eines Artikels
                    auf Dokumenten an (falls vorhanden)
                  </p>
                </div>
              </div>

              <div className='mt-4'>
                <SuperTB
                  name='Zahlungsbedingungen'
                  providedDocumentCommentC={[
                    providedPaymentConditions,
                    setProvidedPaymentConditions,
                  ]}></SuperTB>
              </div>

              <div className="mt-4">
                <SuperTB
                  name='Lieferbedingungen'
                  providedDocumentCommentC={[
                    providedDeliveryConditions,
                    setProvidedDeliveryConditions,
                  ]}></SuperTB>
              </div>

              <div className="mt-4">
                <SuperTB
                  name='Dokumentenkommentar'
                  providedDocumentCommentC={[
                    providedDocumentComment,
                    setProvidedDocumentComment,
                  ]}></SuperTB>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end p-2 md:py-4 max-w-6xl mx-auto w-full">
        <button
          onClick={() => history && history.goBack()}
          className="bg-white py-2 px-4 border border-gray-300
          rounded-md shadow-sm text-sm font-medium text-gray-700
          hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2
          focus:ring-blue-500">
          Abbrechen
        </button>
        <button
          id='save_contact'
          disabled={isSaving}
          onClick={() => {
            handleSubmit();
          }}
          className="ml-3 inline-flex justify-center py-2 px-4 border
          border-transparent shadow-sm text-sm font-medium rounded-md
          text-white bg-blue-600 hover:bg-blue-700 focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Speichern
        </button>
      </div>
    </div >
  );
}

ContactPopup.propTypes = {
  location: PropTypes.object,
  state: PropTypes.object,
  bdId: PropTypes.string,
  history: PropTypes.object,
  disableGoBack: PropTypes.string,
  heading: PropTypes.string,
  onSave: PropTypes.func,
};

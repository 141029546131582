import React, {useState, useEffect} from 'react';
import {AuthProvider} from './context/AuthContext';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Login from './login/Login';
import Sidebar from './Sidebar';
import MobileSidebar from './MobileSidebar';
import Dashboard from './dashboard/Dashboard';
import Documents from './documents/Documents';
import Items from './warehouse/Items';
import Contacts from './contacts/Contacts';
import ShowItem from './warehouse/ShowItem';
import ItemPopup from './warehouse/ItemPopup';
import Inventory from './warehouse/Inventory';
import ContactPopup from './contacts/ContactPopup';
import Settings from './settings/Settings';
import DocumentPopup from './documents/DocumentPopup';
import {RerenderProvider} from './cud';
import Register from './register/Register';
import ManualInOut from './warehouse/ManualInOut';
import AuthRoute from './AuthRoute';
import Notifications from './Notifications';
import Toasts from './Toasts';

// --- Joyride Library for App Demo Tour ----------
// import Joyride from 'react-joyride';
// const steps = [
//   {
//     target: '.tools_navigation',
//     content: 'This is my first Step',
//     disableBeacon: true,
//   },
//   {
//     target: '.category_navigation',
//     content: 'This is my second Step',
//   },
// ];

// ------------------SECTION Ctrl K Listener -------------------
/**
 * @return {object} jsx
 * */
function App() {
  // Keydown Event Listener
  document.addEventListener('keydown', function(event) {
    event && handleKeyUp(event);
  });

  /**
   * ctrl K Processor
   * @param {object} e
   */
  function handleKeyUp(e) {
    if (e.ctrlKey && e.keyCode == 75) {
      e.preventDefault();
      document.querySelector('#search') &&
        (function() {
          const event = new Event('input', {
            bubbles: true,
            cancelable: true,
          });
          const el = document.querySelector('#search');
          el.focus();
          el.value = '';
          el.dispatchEvent(event);
        })();
    }
  }
  // ---------------------------!SECTION -------------------------


  // ------------------SECTION Delay Notifications on initial load
  // (prevent OFFLINE Message on reload) --------------------
  const [showNotifications, setShowNotifications] = useState('none');
  useEffect(function() {
    setTimeout(() => setShowNotifications('block'), 5000);
  }, []);
  // ---------------------------!SECTION -------------------------

  return (
    <AuthProvider>
      <RerenderProvider>
        <Router>
          <div className="App overscroll-y-contain flex h-screen w-screen
           overflow-hidden flex-col md:flex-row">
            <Sidebar></Sidebar>

            <div className="flex flex-col w-full min-h-0 flex-grow
             bg-gray-100 relative">
              <div
                style={{display: showNotifications}}>
                <Notifications></Notifications>
              </div>

              <div className="flex flex-col flex-grow
               bg-gray-100 relative min-h-0">
                <Toasts></Toasts>


                <Route path="/" exact component={Login}></Route>
                <Route path="/register" exact component={Register}></Route>
                <AuthRoute path="/support">
                  <div
                    className="fill_container p-4
            bg-gray-100 rounded-md flex-center">
                    <iframe
                      className="h-full w-full rounded-md"
                      src="https://warest.tawk.help"
                      frameBorder="0"></iframe>
                  </div>
                </AuthRoute>

                <AuthRoute path="/documents"
                  exact component={Documents}></AuthRoute>
                <AuthRoute
                  path="/documents/input"
                  exact
                  component={DocumentPopup}></AuthRoute>
                <AuthRoute path="/dashboard"
                  exact component={Dashboard}></AuthRoute>
                <AuthRoute path="/warehouse" exact
                  component={Items}></AuthRoute>
                <AuthRoute path="/warehouse/input"
                  exact component={ItemPopup}></AuthRoute>
                <AuthRoute path="/warehouse/manual" exact
                  component={ManualInOut}></AuthRoute>
                <AuthRoute path="/warehouse/inventory"
                  exact component={Inventory}></AuthRoute>
                <AuthRoute path="/warehouse/show" component={ShowItem} />
                <AuthRoute path="/contacts" exact component={Contacts}>
                </AuthRoute>
                <AuthRoute
                  path="/contacts/input"
                  exact
                  goBack={true}
                  component={ContactPopup}></AuthRoute>

                <AuthRoute path="/settings" component={Settings}></AuthRoute>

                {/* <Joyride steps={steps} continuous={true} /> */}
              </div>
            </div>
            <MobileSidebar/>
          </div>
        </Router>
      </RerenderProvider>
    </AuthProvider>
  );
}

export default App;

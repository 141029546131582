/** Warehouse Items Dropdown
 * Returns selected Item: String or Object with Item Data
 */

import React, {useContext, useState, useEffect} from 'react';
import {MdUnfoldMore, MdAdd} from 'react-icons/md';
import PropTypes from 'prop-types';
import {AuthContext, lb} from '../context/AuthContext';
import {getSearchResults} from '../utilities';
import {createDoc, RerenderContext} from '../cud';
// import Localbase from 'localbase';
// const lb = new Localbase('E3BYTC3e42z8XLiLTK3K');
import Localbase from 'localbase';


/**
 *
 * @return {object} jsx
 */
export default function ItemsDD({selectedItemC, prevInputRef,
  nextInputRef, updateFunction,
  disableCreation, filterFunc, includeCustomPositions, showDescription}) {
  // -------------------Variables-------------------------------
  // Items Context
  const [rerender, setRerender] = useContext(RerenderContext);

  // Items Context
  const {teamC, isOfflineC, toastsC} = useContext(AuthContext);
  const currentTeam = teamC[0];
  const isOffline = isOfflineC[0];
  const addToast = toastsC[1];

  const [warehouses, setWarehouses] = useState([]);

  const [fullItemName, setFullItemName] = useState('');

  // Selected Item State that is returned
  const [selectedItem, setSelectedItem] = selectedItemC;

  // Utility States
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [inputValue, setInputValue] = useState('');
  // -------------------Variables End-------------------------------

  // Load Data
  useEffect(async function() {
    if (currentTeam) {
      const warehouses = await lb.collection('warehouses').get();
      setWarehouses(warehouses);
    }
  }, [rerender, currentTeam]);

  // Updates Input Value when Item is selected or typed
  useEffect(
      function() {
        if (typeof selectedItem === 'string') setInputValue(selectedItem);
        else if (typeof selectedItem === 'object') {
          setInputValue(selectedItem.data.name);
        }
      },
      [selectedItem],
  );

  // ---------------------------SECTION Utility Functions--------------------

  /** Handles Search Input and generates Search Results
   * @param {object} value
   */
  async function handleSearchInput(value) {
    setSelectedItem(value);
    setShowDropdown(true);
    const lb = new Localbase(currentTeam.id);
    const items = await lb.collection('items').get();
    // const customPositions = await lb.collection('custom_positions').get();
    // const itemsIndex =
    // await lb.collection('search-indexes').doc('items').get();
    // const customPositionsIndex = await lb.collection('search-indexes')
    //     .doc('custom_positions').get();
    // TODO Fix Items Index with custom Positions

    // const index = itemsIndex && itemsIndex.index;
    // const index = [];
    // itemsIndex && index.push(...itemsIndex.index);
    // includeCustomPositions &&
    //  customPositionsIndex &&
    //   index.push(...customPositionsIndex.index);

    // // Append Custom Positions if true
    // let buffer = includeCustomPositions ?
    //     items.concat(customPositions) : items;
    let buffer = items;

    // Apply filter if defined
    buffer = filterFunc ? filterFunc(buffer) : buffer;

    buffer = buffer.filter((el) => !el.deleted);
    if (value && value.length > 0) {
      // Re add index TODO
      setSearchResults(getSearchResults(value, buffer));
    } else {
      setSearchResults(buffer);
    }
  }

  /**
   * Gets triggered when an element is selected through ENTER or Click
   * @param {object} el Element from Search Results
   */
  function handleSelect(el) {
    setSelectedItem({id: el.id, data: el});
    setShowDropdown(false);
    nextInputRef && nextInputRef.current.focus();
  }

  // ---------------------------!SECTION----------------------

  return (
    <div className='relative'>
      <div className="flex justify-between">
        <label
          htmlFor="account-number"
          className="block text-sm font-medium text-gray-700">
          Name
        </label>
        {inputValue.length > 0 &&
        searchResults.length === 0 &&
        typeof selectedItem != 'object' &&
        !disableCreation &&
        showDropdown === true ? (
          <span className="text-sm text-gray-500" id="email-optional">
            &quot;Enter&quot; drücken für neue Kategorie.
          </span>
        ) : (
          ''
        )}
      </div>

      <div className="mt-1 relative rounded-md shadow-sm">
        {/* Workaround to disable Autocomplete */}
        <input type='hidden' value='something'/>
        <input
          onKeyDown={(e) => {
            // Tab Key
            if (e.keyCode === 9) {
              e.preventDefault();
              if (e.shiftKey) {
                prevInputRef && prevInputRef.current.focus();
              } else {
                nextInputRef && nextInputRef.current.focus();
              }
            }
            e.keyCode === 13 && e.preventDefault();

            if (e.keyCode === 13 &&
              inputValue.length > 0 &&
              !disableCreation &&
              searchResults.length === 0) {
              createDoc(currentTeam.id, 'custom_positions',
                  {name: selectedItem}, setRerender, isOffline)
                  .catch((e) => {
                    addToast('error', `Fehler: Position konnte
                       nicht gespeichert werden`);
                    return console.log(e);
                  });
            }

            e.keyCode === 13 &&
              searchResults.length > 0 &&
              handleSelect(searchResults[0]);
          }}
          // Toggle Dropdown depending on Focus
          onFocus={(e) => {
            // clear Input field
            handleSearchInput(e.target.value);
            setShowDropdown(true);
          }}
          onBlur={() => setShowDropdown(false)}
          onInput={(e) => handleSearchInput(e.target.value)}
          autoComplete="really"
          type="text"
          name="account-number"
          id="account-number"
          className="focus:ring-blue-500 focus:border-blue-500
          block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
          value={inputValue}
        />
        <button
          tabIndex={-1}
          type="button"
          onClick={() => {
            inputValue.length > 0 &&
            searchResults.length === 0 &&
            selectedItem.length === 0 ?
              console.log('neues Kategorie saved.') :
              setShowDropdown(!showDropdown);
          }}
          className="absolute inset-y-0 right-0 pr-3 flex
        items-center">
          {inputValue.length > 0 &&
          searchResults.length === 0 &&
          selectedItem.length === 0 ? (
            <MdAdd
              className="h-5 w-5 bg-blue-100 text-blue-400 rounded-md"
              aria-hidden="true"
            />
          ) : (
            <MdUnfoldMore
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          )}
        </button>
      </div>
      <div
        className=" mt-2 p-2 max-h-28 overflow-y-auto overflow-x-visible
        rounded-md shadow-lg bg-white ring-1 ring-black
        ring-opacity-5 focus:outline-none
        absolute w-full top-16 z-10"
        style={{display: showDropdown ? 'block' : 'none'}}>
        {searchResults.map((el) => {
          const warehouse =
           warehouses && warehouses.find((wh) => wh.id === el.warehouse) || {};
          return (
            <button
              onMouseEnter={() => {
                setFullItemName(el.name);
              }}
              onMouseLeave={() => {
                setFullItemName('');
              }}
              type="button"
              className="w-full flex justify-between
               hover:bg-gray-100 rounded-md p-1 space-x-1"
              onMouseDown={() => handleSelect(el)}
              key={el.id}>
              <div className='flex flex-col
               items-start font-small text-sm text-gray-500'>
                <p className='truncate'>{el.code}</p>
                <div className="flex">
                  <div className="flex-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110
                      4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                    </svg>
                  </div>
                  <p className='truncate'>{warehouse.name}</p>
                </div>
              </div>
              <div className='relative overflow-x-visible
               items-start font-small text-sm flex-shrink min-w-0'>
                <p className='font-medium truncate
                 flex-shrink min-w-0'>{el.name}</p>
                {showDescription ?
               <p className='truncate
               flex-shrink min-w-0'>{el.product_description}</p> :
               ''}
              </div>
            </button>
          );
        } )}
      </div>
      <span style={{left: '550px'}}
        className={`bg-gray-100 shadow-md p-2 rounded-md
       absolute top-20 w-full ${fullItemName.length ? '' : 'hidden'}`}>
        {fullItemName}</span>
    </div>
  );
}

ItemsDD.propTypes = {
  selectedItemC: PropTypes.array,
  nextInputRef: PropTypes.any,
  prevInputRef: PropTypes.any,
  updateFunction: PropTypes.any,
  disableCreation: PropTypes.bool,
  filterFunc: PropTypes.func,
  includeCustomPositions: PropTypes.bool,
  showDescription: PropTypes.bool,
};


/** Contacts Component */
import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';
// import ReactTooltip from 'react-tooltip';

import { getSearchResults, limitString, millsToDate } from '../utilities';
import {
  MdEdit, MdDelete, MdClose,
  MdSearch, MdFilterList, MdMoreVert
} from 'react-icons/md';
import ShowContact from './ShowContact';
import { Link } from 'react-router-dom';
// import Localbase from 'localbase';
import { AuthContext } from '../context/AuthContext';
import { deleteDoc, RerenderContext } from '../cud';
import Localbase from 'localbase';
import ModalConfirm from '../ModalConfirm';


/**
 *
 * @return {object} jsx
 */
export default function Contacts() {
  const [rerender, setRerender] = useContext(RerenderContext);
  const { teamC, isOfflineC } = useContext(AuthContext);
  const currentTeam = teamC[0];
  const isOffline = isOfflineC[0];

  const [displayContacts, setDisplayContacts] = useState([]);
  // const [contactsIndex, setContactsIndex] = useState([]);

  const [activeFilter, setActiveFilter] = useState([]);
  const [activeSearch, setActiveSearch] = useState({ value: '' });

  const [modalConfirm, setModalConfirm] = useState({});


  const searchInputRef = useRef(null);

  const [showContact, setShowContact] = useState({
    display: 'none',
    contactData: {},
  });

  // Init saved Search String
  useEffect(function () {
    // Fetch Saved Searches from Database
    const savedSearches = JSON.parse(localStorage.getItem('savedSearches'));
    // if saved string exists, handle search with it
    if (savedSearches && savedSearches.contacts) {
      handleSearchInput(savedSearches.contacts);
      searchInputRef.current.value = savedSearches.contacts;
    }
  }, []);

  // Update Search Index and Display Contacts State when
  // Contacts Change
  useEffect(
    async function () {
      if (currentTeam) {
        const lb = new Localbase(currentTeam.id);
        // lb.config.debug = false;

        // Load Docs from LB
        let buffer = await lb.collection('contacts').get();
        const indexDoc = await lb.collection('search-indexes')
          .doc('contacts').get();
        const index = indexDoc && indexDoc.index;

        // Search
        if (activeSearch.value.length > 0) {
          buffer = activeSearch.filter(buffer, index);
        }

        // Filter out deleted Docs
        buffer = buffer.filter((el) => !el.deleted);

        // Apply Filter
        for (const filter of activeFilter) {
          buffer = filter.filter(buffer);
        }

        // Sort
        buffer = buffer.sort((el1, el2) => {
          const sortPreset = JSON.parse(localStorage.getItem('sortPresets'));
          if (sortPreset && sortPreset.contacts) {
            return el2[sortPreset.contacts] - el1[sortPreset.contacts];
          }
          return el2['created_date'] - el1['created_date'];
        });

        // Limit Length to 40
        buffer = buffer.filter((el, i) => i <= 40);

        setDisplayContacts([...buffer]);
      }
    },
    [activeFilter, activeSearch, currentTeam, rerender],
  );

  /**
   * Searches Documents
   * @param {string} input
   */
  function handleSearchInput(input) {
    // Save Search String to Local Storage for persistance
    const savedSearches = JSON.parse(localStorage.getItem('savedSearches'));
    localStorage.setItem('savedSearches', JSON.stringify({
      ...savedSearches,
      contacts: input,
    }));

    setActiveSearch({
      value: input,
      filter: function (docs, index) {
        return getSearchResults(input, docs, index);
      },
    });
  }

  return (
    <>
      {/* Confirm Modal */}
      <ModalConfirm stateC={[modalConfirm, setModalConfirm]} />
      <ShowContact showContactC={[showContact, setShowContact]}></ShowContact>
      <div className="flex flex-col p-4 bg-gray-100 h-full">
        <div className="w-full pb-4 shrink-0 flex space-x-2 md:justify-between">
          <div className="w-96 shrink-0">
            {/* <label
            htmlFor="search"
            className="block text-sm font-medium text-gray-700">
            Quick search
          </label> */}
            <div className=" relative flex items-center">
              <div
                className="absolute inset-y-0 left-0 pl-3 flex
            items-center pointer-events-none"
                aria-hidden="true">
                <MdSearch
                  className="mr-3 h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                ref={searchInputRef}
                onKeyDown={(e) => {
                  e.keyCode === 13 && handleSearchInput(e.target.value);
                }}
                onInput={(e) => {
                  if (!e.target.value) handleSearchInput('');
                }}
                type="text"
                name="search"
                placeholder="Suche"
                id="search"
                className="shadow-sm focus:ring-blue-500
              focus:border-blue-500 block w-full pr-12 pl-9
              sm:text-sm border-gray-300 rounded-md"
              />
              <div
                className="absolute inset-y-0 right-0 flex
            py-1.5 pr-1.5">
                <kbd
                  className="inline-flex items-center border
              border-gray-200 rounded px-2 text-sm font-sans
              font-medium text-gray-400">
                  ⌘K
                </kbd>
              </div>
            </div>
          </div>

          <div className="flex space-x-2">
            <Menu as="div" className="relative inline-block z-20 text-left">
              <div
                className={`relative items-center px-4 py-2
             rounded-md border border-gray-300 bg-white text-sm
             font-medium  focus:z-10 h-full
              focus:outline-none focus:ring-1 focus:ring-blue-500
               focus:border-blue-500 flex space-x-2
                text-gray-700 hover:bg-gray-50`}
              >
                <Menu.Button className='flex-center'>
                  <p className='mr-2 font-medium
                  hide_mobile'>
                    Sortieren
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round"
                      strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                  </svg>
                </Menu.Button>
              </div>


              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right
                 absolute right-0 mt-2 w-56
         rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
          focus:outline-none">
                  <div className="p-2">
                    <Menu.Item>
                      {({ active }) => (
                        <button className={`flex justify-start px-4 py-2 w-full
                      text-sm rounded-md font-medium hover:bg-gray-50`}
                          onClick={() => {
                            const sortPresets =
                              JSON.parse(localStorage.getItem('sortPresets'));
                            localStorage.setItem('sortPresets', JSON.stringify({
                              ...sortPresets,
                              contacts: 'created_date',
                            }));
                            setRerender('');
                            setRerender('contacts');
                          }}>
                          Erstellungsdatum</button>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <button className={`flex justify-start px-4 py-2 w-full
                      text-sm rounded-md font-medium hover:bg-gray-50`}
                          onClick={() => {
                            const sortPresets =
                              JSON.parse(localStorage.getItem('sortPresets'));
                            localStorage.setItem('sortPresets', JSON.stringify({
                              ...sortPresets,
                              contacts: 'updated_date',
                            }));

                            setRerender('');
                            setRerender('contacts');
                          }}>
                          Bearbeitungsdatum</button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            <Menu as="div" className="relative inline-block z-40 text-left">
              <div
                className={`relative items-center px-4 py-2
             rounded-md border border-gray-300 bg-white text-sm
             font-medium  focus:z-10 h-full
              focus:outline-none focus:ring-1 focus:ring-blue-500
               focus:border-blue-500 flex space-x-2
               ${activeFilter.length > 0 ?
                    'bg-blue-500 text-blue-100 hover:bg-blue-600' :
                    'text-gray-700 hover:bg-gray-50'}`}
              >
                <Menu.Button className='flex'>
                  <p className='mr-2 font-medium
                  hide_mobile'>{activeFilter.length > 0 ?
                      'Filter aktiv' :
                      'Filtern'}
                  </p>

                  <button
                    className='flex-center'
                    onClick={(e) => {
                      if (activeFilter.length > 0) {
                        e.stopPropagation();
                        setActiveFilter([]);
                      }
                    }}>
                    {activeFilter.length > 0 ?
                      <MdClose size='1.3rem'></MdClose> :
                      <MdFilterList size='1.3rem'></MdFilterList>
                    }
                  </button>
                </Menu.Button>
              </div>


              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right
                 absolute right-0 mt-2 w-56
         rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
          focus:outline-none">
                  <div className="p-2">
                    <Menu.Item>
                      {({ active }) => (
                        <button className={`flex justify-start px-4 py-2 w-full
                      text-sm rounded-md font-medium ${activeFilter.map((el) =>
                          el.type).includes('customers') ?
                            'bg-blue-500 hover:bg-blue-600 text-white' :
                            'hover:bg-gray-50'}`}
                          onClick={() => {
                            // Save Filter State to Buffer
                            const buffer = activeFilter;
                            // Remove old Filter with same Type
                            const oldFilter = buffer.findIndex((el) =>
                              el.type === 'customers');
                            if (oldFilter !== -1) buffer.splice(oldFilter, 1);
                            // Add New filter to filter array
                            buffer.push({
                              type: 'customers',
                              filter: function (buffer) {
                                return buffer.filter((doc, index) =>
                                  doc.type === 'Kunde');
                              },
                            });
                            // Set Filter State
                            setActiveFilter([...buffer]);
                          }}>
                          Kunden</button>


                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button className={`flex justify-start px-4 py-2 w-full
                      text-sm rounded-md font-medium ${activeFilter.map((el) =>
                          el.type).includes('suppliers') ?
                            'bg-blue-500 hover:bg-blue-600 text-white' :
                            'hover:bg-gray-50'}`}
                          onClick={() => {
                            // Save Filter State to Buffer
                            const buffer = activeFilter;
                            // Remove old Filter with same Type
                            const oldFilter = buffer.findIndex((el) =>
                              el.type === 'suppliers');
                            if (oldFilter !== -1) buffer.splice(oldFilter, 1);
                            // Add New filter to filter array
                            buffer.push({
                              type: 'suppliers',
                              filter: function (buffer) {
                                return buffer.filter((doc, index) =>
                                  doc.type === 'Lieferant');
                              },
                            });
                            // Set Filter State
                            setActiveFilter([...buffer]);
                          }}>
                          Lieferanten</button>


                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div className='flex flex-col px-4 py-2'>
                          <div className="flex items-center justify-between
                           py-2">
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium
                               text-gray-700">
                              Von
                            </label>

                            <input
                              value={(() => {
                                const value = activeFilter.find((el) =>
                                  el.type === 'start_date');
                                if (value) return value.value;
                                else return '';
                              })()}
                              onClick={(e) => e.stopPropagation()}
                              onInput={(e) => {
                                const buffer = activeFilter;
                                const oldFilter = buffer.findIndex((el) =>
                                  el.type === 'start_date');
                                if (oldFilter !== -1) {
                                  buffer
                                    .splice(oldFilter, 1);
                                }
                                buffer.push({
                                  value: e.target.value,
                                  type: 'start_date',
                                  filter: function (buffer) {
                                    return buffer.filter((doc, index) => {
                                      const date = new Date(e.target.value)
                                        .getTime();
                                      // With Time Correction
                                      return doc.created_date >=
                                        date - 7200000;
                                    });
                                  },
                                });
                                setActiveFilter([...buffer]);
                              }}
                              type="date"
                              name="start_date"
                              className="m-1 focus:ring-blue-500
                                  focus:border-blue-500 block w-36
                                  shadow-sm sm:text-sm
                                   border-gray-300 rounded-md"
                            />
                          </div>
                          <div className="flex items-center justify-between
                           py-2">
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium
                               text-gray-700">
                              Bis
                            </label>

                            <input
                              value={(() => {
                                const value = activeFilter.find((el) =>
                                  el.type === 'end_date');
                                if (value) return value.value;
                                else return '';
                              })()}
                              onClick={(e) => e.stopPropagation()}
                              onInput={(e) => {
                                const buffer = activeFilter;
                                const oldFilter = buffer.findIndex((el) =>
                                  el.type === 'end_date');
                                if (oldFilter !== -1) {
                                  buffer
                                    .splice(oldFilter, 1);
                                }
                                buffer.push({
                                  value: e.target.value,
                                  type: 'end_date',
                                  filter: function (buffer) {
                                    return buffer.filter((doc, index) => {
                                      const date = new Date(e.target.value)
                                        .getTime();
                                      // With Time Correction
                                      return doc.created_date <=
                                        date + 79200000;
                                    });
                                  },
                                });
                                setActiveFilter([...buffer]);
                              }}
                              type="date"
                              name="end_date"
                              className="m-1 focus:ring-blue-500
                                  focus:border-blue-500 block w-36
                                  shadow-sm sm:text-sm
                                   border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      )}
                    </Menu.Item>

                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>


        </div>
        <div
          className="shadow overflow-auto flex-grow
          border-b border-gray-200 rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 table-fixed">
            <thead className="bg-gray-50">
              <tr className='hide_mobile'>
                <th scope="col" style={{ width: '20%' }} className="table_header">
                  Nummer
                </th>
                <th scope="col" style={{ width: '30%' }} className="table_header">
                  Name
                </th>
                <th scope="col" style={{ width: '20%' }} className="table_header">
                  Typ
                </th>
                {/* <th scope="col" className="table_header">
                  Intern
                </th> */}
                <th scope="col" style={{ width: '20%' }} className="table_header">
                  Erstellt
                </th>
                <th
                  scope="col"
                  style={{ width: '5%' }}
                  className="table_header"></th>
                <th
                  scope="col"
                  style={{ width: '5%' }}
                  className="table_header"></th>
              </tr>
            </thead>
            <tbody id='list' className="bg-white divide-y divide-gray-200 ">
              {displayContacts.map((doc, index) => {
                return doc.number || doc.number === 0 || doc.name ? (
                  <tr
                    key={doc.id}
                    className={(() => {
                      let buffer = '';
                      if (!doc.synced) {
                        buffer += 'unsynced ';
                      }
                      if (!(index % 2 === 0)) {
                        buffer += 'bg-gray-100 ';
                      }
                      return buffer;
                    })()}
                  >
                    <td className="px-3 pr-0 py-4 whitespace-nowrap show_mobile">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 
                      font-semibold rounded-full ${doc.type === 'Lieferant' ?
                            'bg-yellow-100 text-yellow-800' :
                            'bg-green-100 text-green-800'
                          }`}>
                        {doc.type === 'Lieferant' ? 'L' : 'K'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap hide_mobile">
                      <div className="flex items-center">
                        {/* <div
                        className="flex flex-grow-0 h-10 w-10 items-center
                        justify-center">
                        {doc.type === 'Kunde' &&
                         <MdGroup size='1.1rem'/>}
                        {doc.type === 'Lieferant' &&
                           <MdLocalShipping size='1.1rem'/>}
                      </div> */}
                        <div className="ml-4">
                          <button
                            onClick={function () {
                              console.log('hello');
                              setShowContact({
                                display: 'flex',
                                contactData: doc,
                              });
                            }}
                            className="text-sm font-medium
                          text-gray-900">
                            {doc.number ?
                              <p>{doc.number}</p> : !isOffline ?
                                <div className="h-5 w-5 p-1 flex-center">
                                  <div className="spinner"></div>
                                </div> : ''}
                          </button>
                          <div
                            className="text-sm
                          text-gray-500"></div>
                        </div>
                      </div>
                    </td>

                    <td className="px-3 md:px-6 py-4 whitespace-nowrap">
                      <button
                        onClick={function () {
                          setShowContact({
                            display: 'flex',
                            contactData: doc,
                          });
                        }}
                        className="text-sm font-medium
                      text-gray-900 flex space-x-2">
                        <p>{limitString(doc.name, 24)}</p>
                        <p className='show_mobile text-gray-500'>{doc.number}</p>
                      </button>
                      {/* <ReactTooltip place="top"
                     type="info" effect="solid"/> */}
                      <div
                        className="text-sm
                      text-gray-500">
                        {/* TODO Contact Groups */}
                        {/* {`${doc.contact_group}`} */}

                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap hide_mobile">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 
                      font-semibold rounded-full ${doc.type === 'Lieferant' ?
                            'bg-yellow-100 text-yellow-800' :
                            'bg-green-100 text-green-800'
                          }`}>
                        {doc.type || 'Kunde'}
                      </span>
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap
                    text-sm text-gray-500 hide_mobile">
                      {millsToDate(doc.created_date)}
                    </td>
                    <td
                      className="px-4 py-4 whitespace-nowrap
                    text-sm text-gray-500 hide_mobile">
                      <Link
                        to={{
                          pathname: '/contacts/input',
                          state: {
                            contactId: doc.id,
                            heading: 'Kontakt bearbeiten',
                          },
                        }}
                        className="edit_contact">
                        <MdEdit data-tip='Kontakt bearbeiten' size="1.3rem" />
                        {/* <ReactTooltip place="top"
                       type="info" effect="solid"/> */}
                      </Link>
                    </td>
                    <td
                      className="px-4 py-4 whitespace-nowrap
                    text-sm text-gray-500 hide_mobile">
                      <button
                        className=''
                        onClick={() => {
                          setModalConfirm({
                            data: {
                              title: 'Kontakt löschen',
                              text: 'Sie sind dabei, einen Kontakt zu löschen. Möchten Sie fortfahren?',
                              buttonText: 'Löschen'
                            },
                            open: true,
                            func: async () => {
                              await deleteDoc(
                                currentTeam.id,
                                'contacts',
                                doc.id,
                                setRerender,
                              );
                            }

                          })
                        }}
                      >
                        <MdDelete size="1.3rem" />
                      </button>
                    </td>
                    {/* Mobile Menu */}
                    <td className="show_mobile">
                      <Menu as="div" className="relative inline-block">
                        <div className="p-1 h-full w-full">
                          <div className='flex-center h-full w-full z-10'>
                            <Menu.Button>
                              <MdMoreVert size='1.3rem'></MdMoreVert>
                            </Menu.Button>
                          </div>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute
             top-8 -left-48 mt-2 w-56 text-black z-20
         rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
          focus:outline-none">
                            <div className="p-2 flex flex-col">
                              <Menu.Item>
                                <Link
                                  className='flex justify-start px-4 py-2 w-full
                              text-sm rounded-md font-medium hover:bg-gray-50
                              '
                                  to={{
                                    pathname: '/contacts/input',
                                    state: {
                                      contactId: doc.id,
                                      heading: 'Kontakt bearbeiten',
                                    },
                                  }}
                                >
                                  Bearbeiten
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <button
                                  className='flex justify-start px-4 py-2 w-full
                              text-sm rounded-md font-medium hover:bg-gray-50
                              opacity-50'
                                  onClick={() => {
                                    setModalConfirm({
                                      data: {
                                        title: 'Kontakt löschen',
                                        text: 'Sie sind dabei, einen Kontakt zu löschen. Möchten Sie fortfahren?',
                                        buttonText: 'Löschen'
                                      },
                                      open: true,
                                      func: async () => {
                                        await deleteDoc(
                                          currentTeam.id,
                                          'contacts',
                                          doc.id,
                                          setRerender,
                                        );
                                      }

                                    })
                                  }}
                                >
                                  Löschen
                                </button>
                              </Menu.Item>


                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </td>
                  </tr>
                ) : (
                  <tr
                    key={doc.id}
                    className={
                      'flex-center py-4 ' +
                      (!(index % 2 === 0) ? 'bg-gray-100' : '')
                    }>
                    <td>Nicht lesbar.</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

import React, { Fragment, useContext, useRef, useState, useEffect } from 'react';
// import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { MdClose, MdSearch, MdFilterList, MdKeyboardArrowLeft } from 'react-icons/md';
import { addLogEntry, getSearchResults, limitString, millsToDate, numberFormatter } from '../utilities';
import { AuthContext } from '../context/AuthContext';
import PropTypes from 'prop-types';
import { createDoc, RerenderContext, updateDoc } from '../cud';
// import Localbase from 'localbase';
import Localbase from 'localbase';
import { Menu, Transition } from '@headlessui/react';



/**
 *
 * @return {object} jsx
 */
export default function Inventory({ history }) {
  const [rerender, setRerender] = useContext(RerenderContext);

  const { teamC, userC, isOfflineC, toastsC } = useContext(AuthContext);
  const currentTeam = teamC[0];
  const currentUser = userC[0];
  const isOffline = isOfflineC[0];
  const addToast = toastsC[1];

  const [invItems, setItems] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [inventories, setInventories] = useState([]);

  const warehouseRef = useRef(null);
  const inventoryRef = useRef(null);
  const nameRef = useRef(null);
  const noteRef = useRef(null);

  const [inventoryItems, setInventoryItems] = useState([]);
  const [displayItems, setDisplayItems] = useState([]);
  const [inventoryWarehouse, setInventoryWarehouse] = useState('');
  const [activeInventory, setActiveInventory] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  const searchInputRef = useRef(null);
  const [activeFilter, setActiveFilter] = useState([]);
  const [activeSearch, setActiveSearch] = useState({ value: '' });
  const [sortPreset, setSortPreset] = useState('');

  // init Sort Preset
  useEffect(() => {
    const sortPreset = JSON.parse(localStorage.getItem('sortPresets'));

    sortPreset && setSortPreset(sortPreset.items);
  })

  function changeSortPreset(value) {
    const sortPresets = JSON.parse(localStorage.getItem('sortPresets'));
    localStorage.setItem('sortPresets', JSON.stringify({
      ...sortPresets,
      items: value,
    }));
    setSortPreset(value);
  }

  // Load Data
  useEffect(async function () {
    if (currentTeam) {
      const lb = new Localbase(currentTeam.id);
      // lb.config.debug = false;

      const items = await lb.collection('items').get();
      setItems(items);

      const warehouses = await lb.collection('warehouses').get();
      setWarehouses(warehouses);

      const inventories = await lb.collection('inventories').get();
      setInventories(inventories);
    }
  }, [rerender]);

  // Init Items of selected Warehouse
  useEffect(function () {
    if (inventoryWarehouse && !activeInventory) {
      const inventoryIt = invItems.filter((el) => {
        return el.warehouse && el.warehouse === inventoryWarehouse;
      }).map((el) => ({ ...el, old_stock: el.stock }))

      let buffer = {};
      for (const el of inventoryIt) {
        buffer[el.id] = el
      }
      setInventoryItems(buffer);
      setDisplayItems(buffer);
    }
  }, [inventoryWarehouse]);

  useEffect(function () {
    if (currentTeam) {
      const buffer = {};

      let invItems = Object.values(inventoryItems);
      // Search
      if (activeSearch.value.length > 0) {
        invItems = activeSearch.filter(Object.values(inventoryItems));
      }

      // Filter out deleted Docs
      invItems = invItems.filter((el) => !el.deleted);

      // Sort
      const sortPreset = JSON.parse(localStorage.getItem('sortPresets'));
      invItems = invItems.sort((a, b) => {
        if (sortPreset?.items === 'product_description') {
          if (a?.length && b?.length) {
            return a['product_description'][0].localeCompare(b['product_description'][0]);
          }
        }
        return b[sortPreset?.items] - a[sortPreset?.items];
      })



      // Sort Array of groups
      function maxNum(array) {
        return Math.max.apply(Math, array);
      }
      // return maxNum(b.items.map((el) => el[sortPreset.items])) - maxNum(a.items.map((el) => el[sortPreset.items]));


      // Apply Filters
      for (const filter of activeFilter) {
        invItems = filter.filter(invItems);
      }

      // Limit Length to 500
      invItems = invItems.filter((el, i) => i <= 500);

      // convert into object
      let buffer4 = {};
      for (const el of invItems) {
        buffer4[el.id] = el
      }

      setDisplayItems({ ...buffer4 });
    }
  }, [rerender, activeFilter, activeSearch, currentTeam, sortPreset, activeInventory]
  );

  /**
   *
   * @param {object} doc Id of Inventory to continue
   */
  function continueInventory(doc) {
    setInventoryWarehouse(doc.warehouse);
    setActiveInventory(doc.id);
    setInventoryItems(doc.items);
    nameRef.current.value = doc.name;
    noteRef.current.value = doc.note;
  }

  /**
   * Handles Stock Input
   * @param {string} id item Id
   * @param {object} e Event
   */
  function handleDataInput(id, name, e) {
    let data = inventoryItems;
    if (name === 'stock') data[id][name] = Number(e.target.value);
    else data[id][name] = e.target.value;
    data[id].modified = true;
    setInventoryItems({ ...data });
  }

  /**
  * Searches Items
  * @param {string} input
  */
  function handleSearchInput(input) {

    setActiveSearch({
      value: input,
      filter: function (docs, index) {
        return getSearchResults(input, docs, index);
      },
    });
  }

  /**
   * Handles Submit
   * @param {object} e
   */
  async function handleSubmit() {
    setIsSaving(true);

    for (const value of Object.values(inventoryItems)) {
      const lb = new Localbase(currentTeam.id);
      // lb.config.debug = false;
      const data = await lb.collection('items').doc({ id: value.id }).get();
      try {
        await updateDoc(currentTeam.id, 'items', value.id,
          { ...data, stock: value.stock, product_description: value.product_description, name: value.name }, setRerender, isOffline);
      } catch (e) {
        addToast('error', `${data.name || 'Artikel'}
         konnte nicht gebucht werden`);
      }
    };

    // End Active Inventory if it exists
    if (activeInventory) {
      try {
        await updateDoc(currentTeam.id, 'inventories',
          activeInventory, { ongoing: false }, setRerender, isOffline);
      } catch (e) {
        addToast('error', 'Fehler: Inventur konnte nicht beendet werden');
        return console.log(e);
      }
    }

    // Add Log Entry
    addLogEntry(currentTeam.id, 'inventories',
      currentUser.uid,
      {
        action: 'beendet', number: '', name: nameRef.current.value,
        details: { items: inventoryItems, warehouse: inventoryWarehouse }
      },
      setRerender, isOffline);


    setIsSaving(false);
    history.push('/warehouse');
    addToast('success', 'Inventur gebucht');
  }

  /**
   * Handles Save
   */
  async function saveInventory() {
    setIsSaving(true);

    // TODO Edit Inventory
    try {
      console.log(activeInventory);
      if (activeInventory) {
        await updateDoc(currentTeam.id, 'inventories', activeInventory, {
          name: nameRef.current.value,
          note: noteRef.current.value,
          items: { ...inventoryItems },
          ongoing: true,
          warehouse: inventoryWarehouse,
        }, setRerender, isOffline);
      } else {
        await createDoc(currentTeam.id, 'inventories', {
          name: nameRef.current.value,
          note: noteRef.current.value,
          items: { ...inventoryItems },
          ongoing: true,
          warehouse: inventoryWarehouse,
        }, setRerender, isOffline);
      }
    } catch (e) {
      addToast('error', 'Fehler: Inventur konnte nicht gespeichert werden');
      return console.log(e);
    }

    setIsSaving(false);
    history.push('/warehouse');
    addToast('success', 'Inventur gespeichert');
  }

  return (
    <div className="h-screen bg-gray-100 flex flex-col fill_container">
      <div className="pb-5 border-b border-gray-200 p-8 flex justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Inventur
        </h3>
        <Link to="/warehouse">
          <div className="flex-center text-gray-900">
            <MdClose size="2rem"></MdClose>
          </div>
        </Link>
      </div>
      <div className="overflow-y-auto flex-grow-1">
        <div className="space-y-6 p-2 md:p-8">
          <div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Inventur verwalten
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Laden Sie hier eine aktive Inventur,
                  oder starten Sie eine neue.
                </p>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-6">

                  <div className='col-span-3'>
                    <label htmlFor="location" className="block text-sm
       font-medium text-gray-700">
                      Lager auswählen
                    </label>
                    <select
                      ref={warehouseRef}
                      id="location"
                      name="location"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base
         border-gray-300 focus:outline-none focus:ring-indigo-500
          focus:border-indigo-500 sm:text-sm rounded-md"
                    >
                      {(() => {
                        const buffer = warehouses;

                        if (warehouses.length > 0) {
                          // Remove warehouses with active Inventory
                          for (let i = buffer.length - 1; i >= 0; i--) {
                            for (const inventory of inventories) {
                              if (inventory.warehouse === buffer[i].id &&
                                inventory.ongoing === 'true') {
                                buffer.splice(i, 1);
                              }
                            }
                          }

                          return buffer.map((el) => <option key={el.id}
                            value={el.id}>{el.name}</option>);
                        }
                      })()}
                    </select>
                  </div>

                  <div className="col-span-3 md:col-span-2 flex items-end">
                    <button
                      type='button'
                      onClick={() => {
                        // Add Log Entry
                        addLogEntry(currentTeam.id, 'inventories',
                          currentUser.uid,
                          {
                            action: 'gestartet', number: '',
                            name: 'eine Inventur',
                            details: { warehouse: inventoryWarehouse }
                          },
                          setRerender, isOffline);

                        setInventoryWarehouse(
                          warehouseRef.current.value);
                        nameRef.current.value = `Inventur ${warehouses.find((el) => el.id === warehouseRef.current.value)?.name}`;
                      }}
                      className="blue_btn">Inventur starten</button>
                  </div>

                  <div className="relative col-span-6">
                    <div className="absolute inset-0 flex
                     items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <span className="px-2 bg-white text-sm
                       text-gray-500">Oder</span>
                    </div>
                  </div>

                  <div className='col-span-3'>
                    <label htmlFor="location" className="block text-sm
       font-medium text-gray-700">
                      Aktive Inventur auswählen
                    </label>
                    <select
                      ref={inventoryRef}
                      name="location"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base
         border-gray-300 focus:outline-none focus:ring-indigo-500
          focus:border-indigo-500 sm:text-sm rounded-md"
                    >
                      {inventories.filter((el) => el.ongoing)
                        .map((el) => <option key={el.id}
                          value={el.id}>{el.name}</option>,
                        )}
                    </select>
                  </div>

                  <div className="col-span-3 md:col-span-2 flex items-end">
                    <button
                      type='button'
                      onClick={() => continueInventory(
                        inventories.find((el) => el.id ===
                          inventoryRef.current.value))}
                      className="blue_btn">Inventur fortsetzen</button>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex space-x-2 md:justify-between">
            <div className="w-96 shrink-0">
              {/* <label
            htmlFor="search"
            className="block text-sm font-medium text-gray-700">
            Quick search
          </label> */}
              <div className="relative flex items-center">
                <div
                  className="absolute inset-y-0 left-0 pl-3 flex
            items-center pointer-events-none"
                  aria-hidden="true">
                  <MdSearch
                    className="mr-3 h-4 w-4 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  ref={searchInputRef}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    e.keyCode === 13 && handleSearchInput(e.target.value);
                  }}
                  onInput={(e) => {
                    e.stopPropagation();
                    if (!e.target.value) handleSearchInput('');
                  }}
                  type="text"
                  name="search"
                  placeholder="Suche"
                  id="search"
                  className="shadow-sm focus:ring-blue-500
              focus:border-blue-500 block w-full pr-12 pl-9
              sm:text-sm border-gray-300 rounded-md"
                />
                <div
                  className="absolute inset-y-0 right-0 flex
            py-1.5 pr-1.5 hide_mobile">
                  <kbd
                    className="inline-flex items-center border
              border-gray-200 rounded px-2 text-sm font-sans
              font-medium text-gray-400">
                    ⌘K
                  </kbd>
                </div>
              </div>
            </div>

            <div className="flex space-x-2">
              <Menu onClick={(e) => e.stopPropagation()} as="div" className="relative inline-block z-20 text-left">
                <div
                  className={`relative items-center px-4 py-2
             rounded-md border border-gray-300 bg-white text-sm
             font-medium  focus:z-10 h-full
              focus:outline-none focus:ring-1 focus:ring-blue-500
               focus:border-blue-500 flex space-x-2
                text-gray-700 hover:bg-gray-50`}
                >
                  <Menu.Button className='flex-center'>
                    <p className='mr-2 font-medium
                  hide_mobile'>
                      Sortieren
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6" fill="none"
                      viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round"
                        strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                    </svg>
                  </Menu.Button>
                </div>


                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right
                 absolute right-0 mt-2 w-56
         rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
          focus:outline-none">
                    <div className="p-2">
                      <Menu.Item>
                        {({ active }) => (
                          <button type='button' className={`flex justify-start px-4 py-2 w-full
                      text-sm rounded-md font-medium hover:bg-gray-50`}
                            onClick={() => {
                              changeSortPreset('stock')
                            }}>
                            Bestand</button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button type='button' className={`flex justify-start px-4 py-2 w-full
                      text-sm rounded-md font-medium hover:bg-gray-50`}
                            onClick={() => {
                              changeSortPreset('product_description')
                            }}>
                            Beschreibung</button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button type='button' className={`flex justify-start px-4 py-2 w-full
                      text-sm rounded-md font-medium hover:bg-gray-50`}
                            onClick={() => {
                              changeSortPreset('created_date')

                            }}>
                            Erstellungsdatum</button>
                        )}
                      </Menu.Item>

                      <Menu.Item>
                        {({ active }) => (
                          <button type='button' className={`flex justify-start px-4 py-2 w-full
                      text-sm rounded-md font-medium hover:bg-gray-50`}
                            onClick={() => {
                              changeSortPreset('updated_date')

                            }}>
                            Bearbeitungsdatum</button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>


            </div>

          </div>

          {/* <div className="bg-white shadow px-4 py-5 rounded-lg
           sm:p-6 divide-y divide-gray-200 "> */}
          <div
            className="shadow overflow-auto h-full
          border-b border-gray-200 rounded-lg">
            <table className="min-w-full divide-y
               divide-gray-200 table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  <th style={{ width: '30%' }}
                    scope="col" className="table_header">
                    Name
                  </th>
                  <th style={{ width: '20%' }}
                    scope="col" className="table_header hide_mobile">
                    Beschreibung
                  </th>
                  <th style={{ width: '10%' }}
                    scope="col" className="table_header hide_mobile">
                    Preis
                  </th>
                  <th style={{ width: '10%' }}
                    scope="col" className="table_header">
                    Soll-Bestand
                  </th>
                  <th style={{ width: '30%' }}
                    scope="col" className="table_header">
                    Ist-Bestand
                  </th>
                  <th style={{ width: '30%' }}
                    scope="col" className="table_header">
                    Erst./Bearb.
                  </th>
                  {/* <th scope="col" className="table_header"></th>
                    <th scope="col" className="table_header"></th> */}
                </tr>
              </thead>
              <tbody className='bg-white'>
                {Object.entries(displayItems).map(([key, value], index) => (
                  <tr
                    key={key}
                    className={' ' + (!(index % 2 === 0) ? inventoryItems[key].modified ?
                      'bg-green-200' : 'bg-red-200' : inventoryItems[key].modified ? 'bg-green-100' : 'bg-red-100')}>
                    <td className="px-2 md:px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div
                          className="ml-4">
                          <input
                            onInput={(e) => handleDataInput(key, 'name', e)}
                            value={inventoryItems[key]?.name}
                            className="text-sm font-medium
                          text-gray-900 text-left px-2 py-1" />
                          {/* {`${limitString(value.name, 50)}`} */}
                          <div
                            className="text-sm
                          text-gray-500 text-left">
                            {`Artikelcode: ${value.code}`}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="px-3 md:px-6 py-4
                     whitespace-nowrap hide_mobile">
                      <textarea
                        className="focus:ring-blue-500
                      focus:border-blue-500 block flex-grow
                      shadow-sm sm:text-sm w-4/5
                      border-gray-300 rounded-md"
                        onInput={(e) => handleDataInput(key, 'product_description', e)}
                        value={inventoryItems[key]?.product_description}>
                      </textarea>
                      {/* <p
                        className="text-sm
                            text-gray-900">
                        {`${el.product_description}`}
                      </p> */}
                    </td>

                    <td className="px-3 md:px-6 py-4
                     whitespace-nowrap hide_mobile">
                      {value.retail_price &&
                        <p
                          className="text-sm
                            text-gray-900">
                          {`${numberFormatter(value.retail_price)}€`}
                        </p>
                      }
                    </td>

                    <td className="px-3 md:px-6 py-4 whitespace-nowrap">
                      <p
                        className="text-sm font-medium
                            text-gray-900">
                        {`${numberFormatter(value.old_stock, true)}`}
                      </p>
                    </td>

                    <td
                      className="px-3 md:px-6 py-4 whitespace-nowrap
                      text-sm text-gray-500">
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="number"
                          name="weight"
                          onInput={(e) => handleDataInput(key, 'stock', e)}
                          value={inventoryItems[key]?.stock || ''}
                          className="focus:ring-blue-500 focus:border-blue-500
                        block w-full md:pr-9 sm:text-sm
                        border-gray-300 rounded-md"
                        />
                        <div
                          className="absolute inset-y-0 right-0 pr-3 flex
                      items-center pointer-events-none hide_mobile">
                          <span
                            className="text-gray-500 sm:text-sm"
                            id="price-currency">
                            {value.unit}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap
                    text-sm text-gray-500 hide_mobile">
                      {millsToDate(value.created_date)} <br />
                      ({millsToDate(value.updated_date)})
                    </td>


                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* </div> */}

          <div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6">
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Beschreibungen
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Fügen Sie hier einen Inventurnamen und eine Notiz hinzu.
                </p>
              </div>
              <div className="col-span-3">
                <div className='w-full md:w-1/2'>
                  <label htmlFor="email" className="block
                   text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <div className="mt-1">
                    <input
                      ref={nameRef}
                      type="text"
                      name="name"
                      required
                      className="shadow-sm focus:ring-indigo-500
                       focus:border-indigo-500 block w-full
                        sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className='mt-4'>
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700">
                    Notiz
                  </label>
                  <div className="mt-1">
                    <textarea
                      ref={noteRef}
                      name="note"
                      rows={3}
                      className="shadow-sm focus:ring-blue-500
                    focus:border-blue-500 block w-full sm:text-sm border
                    border-gray-300 rounded-md"
                    />
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    Interne Notiz für ihre Inventur.
                  </p>
                </div>
              </div>
            </div>
          </div>


          <div className="flex justify-end">
            <Link
              to="/warehouse"
              className="bg-white py-2 px-4 border border-gray-300
          rounded-md shadow-sm text-sm font-medium text-gray-700
          hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2
          focus:ring-blue-500">
              Abbrechen
            </Link>
            <button
              disabled={isSaving}
              type='button'
              onClick={() => saveInventory()}
              className="ml-3 bg-white py-2 px-4 border border-gray-300
          rounded-md shadow-sm text-sm font-medium text-gray-700
          hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2
          focus:ring-blue-500">
              Speichern
            </button>
            <button
              disabled={isSaving}
              onClick={() => handleSubmit()}
              className="ml-3 inline-flex justify-center py-2 px-8 border
          border-transparent shadow-sm text-sm font-medium rounded-md
          text-white bg-blue-600 hover:bg-blue-700 focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Buchen
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

Inventory.propTypes = {
  history: PropTypes.object,
};

/** Shows Item Details */
import React, {useContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {millsToDate, numberFormatter} from '../utilities';
import {RerenderContext} from '../cud';
import {AuthContext} from '../context/AuthContext';
// import Localbase from 'localbase';
// import {LogContext} from '../dashboard/LogContext';
import {MdClose} from 'react-icons/md';
import Localbase from 'localbase';


/**
 *
 * @return {object} jsx
 */
export default function ShowItem({showItemC}) {
  const rerender = useContext(RerenderContext)[0];

  const {teamC} = useContext(AuthContext);
  const currentTeam = teamC[0];

  const [showItem, setShowItem] = showItemC;
  const item = showItem.itemData;

  const [warehouses, setWarehouses] = useState([]);
  const [contacts, setContacts] = useState([]);

  // Load Data
  useEffect(async function() {
    if (currentTeam) {
      const lb = new Localbase(currentTeam.id);
      // lb.config.debug = false;
      const warehouses = await lb.collection('warehouses').get();
      setWarehouses(warehouses);

      const contacts = await lb.collection('contacts').get();
      setContacts(contacts);
    }
  }, [rerender]);

  return (
    <button
      onClick={function() {
        setShowItem({display: 'none', itemData: {}});
      }}
      style={{display: showItem.display}}
      className="fill_container absolute flex-center bg-black md:p-16
      overflow-hidden z-30 bg-opacity-50 xl:px-40">
      <div
        onClick={function(e) {
          e.stopPropagation();
        }}
        className="bg-white shadow overflow-hidden sm:rounded-lg
       cursor-default max-w-5xl">
        <div className="py-5 px-6 h-20 flex justify-between">
          <div className='flex flex-col items-start'>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {item.name}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {`${item.code}     `}
            </p>
          </div>
          <button
            onClick={function() {
              setShowItem({display: 'none', itemData: {}});
            }}>
            <MdClose size='2rem'></MdClose>
          </button>
        </div>
        <div
          style={{height: 'calc(100% - 5rem)'}}
          className="border-t border-gray-200 text-left">
          <dl className="overflow-y-auto h-full">
            <div
              className="bg-gray-100 px-6 py-5 grid
                grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Bestand(max/min)
              </dt>
              <dd
                className="text-right text-sm text-gray-900
                  sm:mt-0 col-span-2">
                {`${item.stock} (${item.max_stock || ''}/
                    ${item.min_stock || ''}) ${item.unit || ''}`}
              </dd>
            </div>
            <div
              className="bg-white px-6 py-5 grid
                grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Gewicht (kg)
              </dt>
              <dd
                className="text-right text-sm text-gray-900
                  sm:mt-0 col-span-2">
                {`${item.article_weight || ''}`}
              </dd>
            </div>
            <div
              className="bg-gray-100 px-6 py-5 grid
                grid-cols-3 sm:gap-4 sm:px-6">
              <dt
                className="text-sm font-medium
                  text-gray-500">
                Lagerplatz
              </dt>
              <dd
                className="text-right text-sm text-gray-900
                  sm:mt-0 col-span-2">
                {`${
                  (warehouses &&
                    item.warehouse &&
                    warehouses.find((el) => el.id === item.warehouse)
                        .name) ||
                  ''
                }`}
              </dd>
            </div>

            <div
              className="bg-white px-6 py-5 grid
                grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Kategorie</dt>
              <dd
                className="text-right text-sm text-gray-900
                  sm:mt-0 col-span-2">
                {`${item.category || ''}`}
              </dd>
            </div>
            <div
              className="bg-gray-100 px-6 py-5 grid
                grid-cols-3 sm:gap-4 sm:px-6">
              <dt
                className="text-sm font-medium
                  text-gray-500">
                Lieferant
              </dt>
              <dd
                className="text-right text-sm text-gray-900
                  sm:mt-0 col-span-2">
                {`${
                  (contacts &&
                    item.supplier &&
                    contacts.find((el) => el.id === item.supplier)
                        .name) ||
                  ''
                }`}
              </dd>
            </div>
            <div
              className="bg-white px-6 py-5 grid
                grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Verkaufspreis (Steuer)
              </dt>
              <dd
                className="text-right text-sm text-gray-900
                  sm:mt-0 col-span-2">
                {`${numberFormatter(
                    item.retail_price) || ''}€ (${item.tax || ''}%)`}
              </dd>
            </div>
            <div
              className="bg-gray-100 px-6 py-5 grid
                grid-cols-3 sm:gap-4 sm:px-6">
              <dt
                className="text-sm font-medium
                  text-gray-500">
                Produktbeschreibung
              </dt>
              <dd
                className="text-right text-sm text-gray-900
                  sm:mt-0 col-span-2">
                {`${item.product_description || ''}`}
              </dd>
            </div>
            <div
              className="px-6 py-5 grid
                grid-cols-3 sm:gap-4 sm:px-6">
              <dt
                className="text-sm font-medium
                  text-gray-500">
                Interne Notiz
              </dt>
              <dd
                className="text-right text-sm text-gray-900
                  sm:mt-0 col-span-2">
                {`${item.internal_note || ''}`}
              </dd>
            </div>
            <div
              className="px-6 py-5 grid bg-gray-100
                grid-cols-3 sm:gap-4 sm:px-6">
              <dt
                className="text-sm font-medium
                  text-gray-500">
                Erstellt / Bearbeitet
              </dt>
              <dd
                className="text-right text-sm text-gray-900
                  sm:mt-0 col-span-2">
                {`${millsToDate(item.created) || ''} / ${
                  millsToDate(item.last_changed) || ''
                }`}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </button>
  );
}

ShowItem.propTypes = {
  //   location: PropTypes.object,
  //   state: PropTypes.object,
  showItemC: PropTypes.array,
};

/** Shows Item Details */
import React from 'react';
import PropTypes from 'prop-types';
import ContactPopup from '../contacts/ContactPopup';


/**
 *
 * @return {object} jsx
 */
export default function Modal({component, showC, onClose, ...rest}) {
  const [show, setShow] = showC;

  /**
   * Closes Modal
   */
  function close() {
    if (onClose) {
      onClose();
    }
    setShow(false);
  }

  return show ? (
      <button
        onClick={(e) => {
          if (e.nativeEvent.pointerType === 'mouse') {
            close();
          }
        }}
        style={{display: show ? 'block' : 'none'}}
        className="fill_container absolute right-0 bg-black p-16 overflow-hidden
        z-50 bg-opacity-50 xl:px-40">
        <div
          className="bg-white shadow overflow-hidden sm:rounded-lg
          h-full cursor-default"
          onClick={function(e) {
            e.stopPropagation();
          }}>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
            </h3>
          </div>
          {component === 'ContactPopup' ? <ContactPopup onSave={close}
            {...rest} /> : ''}
        </div>
      </button>
    ) : '';
}

Modal.propTypes = {
  component: PropTypes.any,
  showC: PropTypes.array,
  heading: PropTypes.string,
  onClose: PropTypes.func,
};

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import {AuthContext} from '../context/AuthContext';
import { useLocation } from 'react-router-dom';
import { MdCheck, MdDelete, MdEdit } from 'react-icons/md';
import { limitString, numberFormatter } from '../utilities';
import { createDoc, deleteDoc, RerenderContext, updateDoc } from '../cud';
// import Localbase from 'localbase';
import { AuthContext, lb } from '../context/AuthContext';
import Localbase from 'localbase';


/**
 *
 * @return {object} jsx
 */
export default function DocumentsSettings({ state }) {
  const [rerender, setRerender] = useContext(RerenderContext);
  const { pathname } = useLocation();

  const { teamC, isOfflineC } = useContext(AuthContext);
  const currentTeam = teamC[0];
  const isOffline = isOfflineC[0];

  const [tags, setTags] = useState([]);
  const [documentComments, setDocumentComments] = useState([]);

  const tbd = {
    category: '',
    abbreviation: '',
    text: '',
  };
  const [textBlockData, setTextBlockData] = useState(tbd);
  const [editBlockRow, setEditBlockRow] = useState({});

  const [documentSettings, setDocumentSettings] = state;

  const reminderDataStructure = {
    name: '',
    addon: '',
    fee: '',
    fee_name: '',
    comment: '',
  };

  const [reminderData, setReminderData] = useState(reminderDataStructure);

  // Load Data
  useEffect(async function () {
    if (currentTeam) {
      const lb = new Localbase(currentTeam.id);
      // lb.config.debug = false;
      const tags = await lb.collection('tags').get();
      setTags(tags);

      const documentComments = await lb.collection('document_comments').get();
      setDocumentComments(documentComments);
    }
  }, [rerender]);

  console.log(documentSettings.nks);

  /**
   * Handles Change in Address Input
   * @param {object} event

   */
  function handleReminderChange(event) {
    const { value, name } = event.target;

    if (name === 'fee') {
      setReminderData({
        ...reminderData,
        [name]: Number(value),
      });
    } else {
      setReminderData({
        ...reminderData,
        [name]: String(value),
      });
    }
  }

  /**
   * Handles Form Submit
   * @param {object} event
   */
  function addReminder() {
    // Push Address to contact Addresses
    const reminders = documentSettings.reminders;
    reminders.push(reminderData);
    setDocumentSettings({ ...documentSettings, reminders: reminders });
    // Reset Address Inputs
    setReminderData(reminderDataStructure);
  }

  /**
   * Deletes Adress
   * @param {number} index

   */
  function deleteReminder(index) {
    const reminders = documentSettings.reminders;
    reminders.splice(index, 1);

    setDocumentSettings({
      ...documentSettings,
      reminders: reminders,
    });
  }

  return (
    <div
      className="space-y-6"
      style={{ display: pathname === '/settings/documents' ? 'block' : 'none' }}>
      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div>
            <h3
              className="text-lg leading-6
                font-medium text-gray-900">
              Nummernkreise
            </h3>
            <p
              className="mt-1 text-sm
                text-gray-500">
              Verwalten Sie hier die Nummernkreise ihrer Dokumente.
            </p>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <div
              className=" col-span-6 shadow overflow-auto h-full
          border-b border-gray-200 rounded-lg">
              <table className="min-w-full divide-y
               divide-gray-200 table-fixed">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" style={{ width: '25%' }}
                      className="table_header">
                      Typ
                    </th>
                    <th scope="col" style={{ width: '20%' }}
                      className="table_header">
                      Kürzel
                    </th>
                    <th scope="col" style={{ width: '25%' }}
                      className="table_header">
                      Nummer
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white
                 rounded-lg divide-y divide-gray-200 ">
                  {documentSettings.nks.map((el, index) => (
                    <tr
                      key={el.id}>

                      <td className='p-2'>
                        <div className="truncate">
                          <div className="flex text-sm">
                            <p
                              className="font-medium
                              text-blue-600 truncate">
                              {el.name}
                            </p>
                            {/* <p
                                  className="ml-1 flex-shrink-0
                              font-normal text-gray-500">
                                in {el.department}
                                </p> */}
                          </div>
                        </div>
                      </td>
                      <td className='p-2'>
                        <input
                          className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pl-10 sm:text-sm border-gray-300
                        rounded-md"
                          value={el.abbreviation}
                          onInput={(e) => {
                            const nks = documentSettings.nks;
                            nks[index].abbreviation = e.target.value;
                            setDocumentSettings({
                              ...documentSettings,
                              nks: nks,
                            });
                          }}
                          type="text" />
                      </td>
                      <td className='p-2'>
                        <input
                          className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pl-10 sm:text-sm border-gray-300
                        rounded-md"
                          value={el.nk}
                          onInput={(e) => {
                            const nks = documentSettings.nks;
                            nks[index].nk = Number(e.target.value) || '';
                            setDocumentSettings({
                              ...documentSettings,
                              nks: nks,
                            });
                          }}
                          type="number" />
                      </td>

                    </tr>
                  ))}


                  <tr>

                    <td className='p-2'>
                      <div className="truncate">
                        <div className="flex text-sm">
                          <p
                            className="font-medium
                              text-blue-600 truncate">
                            Interner Nummernkreis
                          </p>
                          {/* <p
                                  className="ml-1 flex-shrink-0
                              font-normal text-gray-500">
                                in {el.department}
                                </p> */}
                        </div>
                      </div>
                    </td>
                    <td className='p-2'>
                    </td>
                    <td className='p-2'>
                      <input
                        className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pl-10 sm:text-sm border-gray-300
                        rounded-md"
                        value={documentSettings.internalNk}
                        onInput={(e) => {
                          console.log('ping');
                          setDocumentSettings({
                            ...documentSettings,
                            internalNk: Number(e.target.value) || '',
                          });
                        }}
                        type="number" />
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div>
            <h3
              className="text-lg leading-6
                font-medium text-gray-900">
              Briefpapier
            </h3>
            <p
              className="mt-1 text-sm
                text-gray-500">
              Verwalten Sie hier das Briefpapier für ihre Dokumente.
            </p>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <fieldset className="space-y-5 col-span-6 md:col-span-3">
              <div>
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      checked={documentSettings.showSenderAddress}
                      onChange={(e) => {
                        setDocumentSettings({
                          ...documentSettings,
                          showSenderAddress: e.target.checked,
                        });
                      }}
                      aria-describedby="candidates-description"
                      type="checkbox"
                      className="focus:ring-blue-500 h-4 w-4 text-blue-600
                      border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      className="font-medium text-gray-700">
                      Absenderadresse anzeigen
                    </label>
                    <p id="candidates-description" className="text-gray-500">
                      Zeigt die Adresse des Absenders auf dem Dokument an.
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>

            {/* <div className="sm:col-span-6">
              <label
                htmlFor="cover-photo"
                className="block text-sm font-medium text-gray-700">
                Briefpapier
              </label>
              <div
                className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2
              border-gray-300 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true">
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4
                      4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656
                      0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4
                      4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer
                       bg-white rounded-lg rounded-md
                      font-medium text-blue-600 hover:text-blue-500
                      focus-within:outline-none focus-within:ring-2
                      focus-within:ring-offset-2 focus-within:ring-blue-500">
                      <span>Datei auswählen</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                      />
                    </label>
                    <p className="pl-1">oder hier hinein ziehen</p>
                  </div>
                  <p className="text-xs text-gray-500">PNG, JPG bis 10MB</p>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div>
            <h3
              className="text-lg leading-6
            font-medium text-gray-900"></h3>
            <p
              className="mt-1 text-sm
            text-gray-500">
              Aktives Briefpapier:
            </p>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <p className="text-gray-500 col-span-6">
              Keine Aktives Briefpapier.
            </p>
          </div> */}
        </div>
      </div>

      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div>
            <h3
              className="text-lg leading-6
                font-medium text-gray-900">
              Gültigkeitsdatum
            </h3>
            <p
              className="mt-1 text-sm
                text-gray-500">
              Hier können Sie das Standard Gültigkeitsdatum für
              Angebote und Auftragsbestätigungen in Tagen einstellen.
              Wenn keines gesetzt werden soll, geben sie 0 ein.
            </p>
          </div>


          <div className="mt-1 relative rounded-md shadow-sm w-28">
            <input
              type="number"
              name="validUntilDays"
              onInput={(e) => {
                setDocumentSettings({
                  ...documentSettings,
                  validUntilDays: Number(e.target.value),
                });
              }}
              value={documentSettings.validUntilDays}

              className="focus:ring-blue-500 focus:border-blue-500
                        block w-full  pr-12 sm:text-sm
                        border-gray-300 rounded-md"
            />
            <div
              className="absolute inset-y-0 right-0 pr-3 flex
                      items-center pointer-events-none">
              <span
                className="text-gray-500 sm:text-sm"
                id="price-currency">
                Tage
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div>
            <h3
              className="text-lg leading-6
                font-medium text-gray-900">
              Zahlungen einpflegen
            </h3>
            <p
              className="mt-1 text-sm
                text-gray-500">
              Mit dieser Funktion können Sie Dokumenten eingegangene Zahlungen
              hinzufügen. Dadurch kann z.B schnell eingesehen werden, welche
              Rechnungen schon beglichen wurden. Nach einem anpassbaren Zeitraum
              werden unbeglichene Dokumente rot markiert, und im Dashboard
              angezeigt.
            </p>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <fieldset className="space-y-5 col-span-6 md:col-span-3">
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    checked={documentSettings.activateSettleFeature}
                    onChange={(e) => {
                      setDocumentSettings({
                        ...documentSettings,
                        activateSettleFeature: e.target.checked,
                      });
                    }}
                    name="activateSettleFeature"
                    type="checkbox"
                    className="focus:ring-blue-500 h-4 w-4 text-blue-600
                    border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="activateSettleFeature"
                    className="font-medium text-gray-700">
                    Funktion aktivieren
                  </label>
                  <p id="comments-description" className="text-gray-500">
                    Fügt der Dokumentenliste einen Button zum Zahlungsbereich
                    hinzu.
                  </p>
                </div>
              </div>
            </fieldset>

            {/* <div className="flex-center col-span-3">
              <button
                type="submit"
                className="bg-blue-600 border
                border-transparent rounded-md
                shadow-sm py-2 px-4 inline-flex
                justify-center text-sm font-medium
                text-white hover:bg-blue-700
                focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-blue-500">
                Alle Dokumente begleichen
              </button>
            </div> */}
          </div>
        </div>
      </div>

      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div>
            <h3
              className="text-lg leading-6
                font-medium text-gray-900">
              Zahlungserinnerungen
            </h3>
            {/* <p
              className="mt-1 text-sm
                text-gray-500">
              Mit dieser Funktion können Sie ein Ereignis
               mit einem Dokumententag verknüpfen.
                Wenn sie z.B einen Tag &apos;Gedruckt&apos; erstellt haben,
                 können Sie diesen mit dem Ereignis Herunterladen verknüpfen.
                 Nun wird dieser Tag immer hinzugefügt,
                  wenn ein Dokument heruntergeladen wurde.
            </p> */}
          </div>

          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">

              <div className="col-span-6 sm:col-span-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  onInput={(e) => handleReminderChange(e)}
                  value={reminderData.name}
                  className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div className="col-span-6 sm:col-span-2">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-700">
                  Addon
                </label>
                <input
                  type="text"
                  name="addon"
                  onInput={(e) => handleReminderChange(e)}
                  value={reminderData.addon}
                  autoComplete="email"
                  className="mt-1 focus:ring-blue-500
                            focus:border-blue-500 block w-full
                            shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="postal_code"
                  className="block text-sm font-medium text-gray-700">
                  Gebühr
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    type="number"
                    name="fee"
                    onInput={(e) => handleReminderChange(e)}
                    value={reminderData.fee}
                    className="focus:ring-blue-500 focus:border-blue-500
                        block w-full pr-8 sm:text-sm
                        border-gray-300 rounded-md"
                    placeholder="0,00"
                    aria-describedby="price-currency"
                  />
                  <div
                    className="absolute inset-y-0 right-0 pr-3 flex
                      items-center pointer-events-none">
                    <span
                      className="text-gray-500 sm:text-sm"
                      id="price-currency">
                      €
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-span-6 sm:col-span-6 lg:col-span-4">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700">
                  Gebührbezeichnung
                </label>
                <input
                  type="text"
                  name="fee_name"
                  onInput={(e) => handleReminderChange(e)}
                  value={reminderData.fee_name}
                  className="mt-1 focus:ring-blue-500
                            focus:border-blue-500
                            block w-full shadow-sm sm:text-sm
                            border-gray-300 rounded-md"
                />
              </div>
              <div
                className="col-span-6 sm:col-span-3
                      lg:col-span-2 lg:col-start-1">
                <label
                  htmlFor="region"
                  className="block text-sm font-medium text-gray-700">
                  Kommentar
                </label>
                <select
                  onInput={(e) => handleReminderChange(e)}
                  value={reminderData.comment}
                  name="comment"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base
                border-gray-300 focus:outline-none focus:ring-indigo-500
                focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option></option>
                  {documentComments.map((dc) =>
                    <option value={dc.id}
                      key={dc.id}>{dc.text}</option>)}
                </select>
              </div>
              <div
                className="col-span-6 sm:col-span-2 flex
                      items-end justify-end">
                <button
                  type="button"
                  onClick={() => addReminder()}
                  className="inline-flex justify-center py-2
                        px-4 border
                        border-transparent shadow-sm text-sm font-medium
                        rounded-md
                        text-white bg-blue-600 hover:bg-blue-700
                        focus:outline-none
                        focus:ring-2 focus:ring-offset-2
                        focus:ring-blue-500">
                  Hinzufügen
                </button>
              </div>
            </div>
            <div className="col-span-6 max-h-48 overflow-auto mt-4">
              <ul className="divide-y divide-gray-200">
                {documentSettings.reminders &&
                  Object.values(documentSettings.reminders).map(
                    (el, index) => (
                      <li key={index}>
                        <div
                          className="flex items-center px-4
                              py-4 sm:px-6">
                          <div
                            className="min-w-0 flex-1 flex
                                items-center">
                            <div
                              className="min-w-0 flex-1 px-4
                                    md:grid
                              md:grid-cols-3 md:gap-4">
                              <div>
                                <p
                                  className="text-sm font-medium
                                  text-blue-600 truncate">
                                  {`${index + 1}.  ${el.name}`}
                                </p>
                              </div>
                              <div className="hidden md:block col-span-2">
                                <div>
                                  <p className="text-sm text-gray-900">
                                    {`${el.fee_name}
                                          ${numberFormatter(el.fee)}€`}
                                  </p>
                                  <div className='flex'>
                                    <p className="text-sm font-medium">
                                      Kommentar:
                                    </p>
                                    <p className="text-sm ml-1
                                           text-gray-900">
                                      {limitString(
                                        documentComments.find((c) =>
                                          c.id === el.comment)
                                          .text, 32)}
                                    </p>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              type="button"
                              onClick={() => deleteReminder(index)}
                            >
                              <MdDelete
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </li>
                    ),
                  )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div>
            <h3
              className="text-lg leading-6
                font-medium text-gray-900">
              Auto Tagging
            </h3>
            <p
              className="mt-1 text-sm
                text-gray-500">
              Mit dieser Funktion können Sie ein Ereignis
              mit einem Dokumententag verknüpfen.
              Wenn sie z.B einen Tag &apos;Gedruckt&apos; erstellt haben,
              können Sie diesen mit dem Ereignis Herunterladen verknüpfen.
              Nun wird dieser Tag immer hinzugefügt,
              wenn ein Dokument heruntergeladen wurde.
            </p>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="flex col-span-6">
              <div className="relative flex flex-col md:flex-row
               md:space-x-2 items-start md:items-center">
                <label
                  htmlFor="activateSettleFeature"
                  className="font-medium text-gray-700">
                  Download (blank)
                </label>
                <select
                  value={documentSettings.autoTagging.onDownloadBlank}
                  onInput={(e) => {
                    const buffer = documentSettings.autoTagging;
                    buffer.onDownloadBlank = e.target.value;
                    setDocumentSettings({
                      ...documentSettings,
                      autoTagging: buffer,
                    });
                  }}
                  name="tags"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base
                border-gray-300 focus:outline-none focus:ring-indigo-500
                focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option></option>
                  {tags.map((tag) =>
                    <option value={tag.id}
                      key={tag.id}>{tag.text}</option>)}
                </select>
                <p id="comments-description" className="text-gray-500 text-xs">
                  Wird beim Herunterladen des Dokuments ausgelöst
                </p>
              </div>
            </div>

            <div className="flex col-span-6">
              <div className="relative flex flex-col md:flex-row
               md:space-x-2 items-start md:items-center">
                <label
                  htmlFor="activateSettleFeature"
                  className="font-medium text-gray-700">
                  Download (Briefpapier)
                </label>
                <select
                  value={documentSettings.autoTagging.onDownloadLetterPaper}
                  onInput={(e) => {
                    const buffer = documentSettings.autoTagging;
                    buffer.onDownloadLetterPaper = e.target.value;
                    setDocumentSettings({
                      ...documentSettings,
                      autoTagging: buffer,
                    });
                  }}
                  name="tags"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base
                border-gray-300 focus:outline-none focus:ring-indigo-500
                focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option></option>
                  {tags.map((tag) =>
                    <option value={tag.id}
                      key={tag.id}>{tag.text}</option>)}
                </select>
                <p id="comments-description" className="text-gray-500 text-xs">
                  Wird beim Herunterladen des Dokuments ausgelöst
                </p>
              </div>
            </div>

            <div className="flex col-span-6">
              <div className="relative flex flex-col md:flex-row
               md:space-x-2 items-start md:items-center">
                <label
                  htmlFor="activateSettleFeature"
                  className="font-medium text-gray-700">
                  Weiterführen
                </label>
                <select
                  value={documentSettings.autoTagging.onContinue}
                  onInput={(e) => {
                    const buffer = documentSettings.autoTagging;
                    buffer.onContinue = e.target.value;
                    setDocumentSettings({
                      ...documentSettings,
                      autoTagging: buffer,
                    });
                  }}
                  name="tags"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base
                border-gray-300 focus:outline-none focus:ring-indigo-500
                focus:border-indigo-500 sm:text-sm rounded-md"
                  defaultValue="Canada"
                >
                  <option></option>
                  {tags.map((tag) =>
                    <option value={tag.id}
                      key={tag.id}>{tag.text}</option>)}
                </select>
                <p id="comments-description" className="text-gray-500 text-xs">
                  Wird beim Weiterführen des Dokuments ausgelöst
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div
        className="shadow rounded-md
          sm:overflow-hidden">
        <div
          className="bg-white rounded-lg py-6 px-4
            space-y-6 sm:p-6">
          <div className="flex justify-between">
            <div>
              <h3
                className="text-lg leading-6
                font-medium text-gray-900">
                Textbausteine
              </h3>
              <p
                className="mt-1 text-sm
                text-gray-500">
                Verwalten Sie hier ihre Textbausteine für Dokumente.
              </p>
            </div>

          </div>
          <div className="grid grid-cols-6 gap-6">
            <div
              className=" col-span-6 shadow overflow-auto h-full
          border-b border-gray-200 rounded-lg">
              <table className="min-w-full divide-y
               divide-gray-200 table-fixed">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" style={{ width: '20%' }}
                      className="table_header">
                      Kategorie
                    </th>
                    <th scope="col" style={{ width: '20%' }}
                      className="table_header">
                      Kürzel
                    </th>
                    <th scope="col" style={{ width: '60%' }}
                      className="table_header">
                      Text
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white
                 rounded-lg divide-y divide-gray-200 ">

                  <tr className='border-b border-gray-700'>

                    <td className='p-2'>
                      <div className="truncate">
                        <div className="flex-center text-sm">
                          <p
                            className="font-medium w-full
                              text-blue-600 truncate">
                            <select
                              value={textBlockData.category}
                              onInput={(e) => {
                                const tbd = textBlockData;
                                tbd.category = e.target.value;
                                setTextBlockData({ ...tbd });
                              }}
                              className="focus:ring-blue-500 w-full
                                 focus:border-blue-500 outline-0
                        block w-full sm:text-sm border-gray-300
                        rounded-md"
                              type="text" >
                              <option value="">General</option>
                              <option value="payment_conditions">
                                Zahlungsbedingung</option>
                              <option value="delivery_conditions">
                                Lieferbedingungen</option>
                              <option value="comment_1">Kommentar 1</option>
                              <option value="comment_2">Kommentar 2</option>
                            </select>
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className='p-2'>
                      <input
                        value={textBlockData.abbreviation}
                        onInput={(e) => {
                          const tbd = textBlockData;
                          tbd.abbreviation = e.target.value;
                          setTextBlockData({ ...tbd });
                        }}
                        className="focus:ring-blue-500 focus:border-blue-500
                        block w-full sm:text-sm border-gray-300
                        rounded-md"
                        type="text" />
                    </td>
                    <td className='p-2'>
                      <textarea
                        onInput={(e) => {
                          const tbd = textBlockData;
                          tbd.text = e.target.value;
                          setTextBlockData({ ...tbd });
                        }}
                        className="focus:ring-blue-500 focus:border-blue-500
                        block w-full sm:text-sm border-gray-300
                        rounded-md"
                        value={textBlockData.text}
                        type="number"></textarea>
                    </td>
                    <td>
                      <button
                        onClick={(el) => {
                          createDoc(currentTeam.id,
                            'document_comments', textBlockData,
                            setRerender, isOffline);
                          setTextBlockData({
                            text: '',
                            category: '', abbreviation: ''
                          });
                          console.log(textBlockData);
                        }}
                        className="inline-flex justify-center py-2
                        px-2 border mx-2
                        border-transparent shadow-sm text-sm font-medium
                        rounded-md
                        text-blue-500 bg-blue-100 hover:bg-blue-200
                        focus:outline-none
                        focus:ring-2 focus:ring-offset-2
                        focus:ring-blue-500">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12
                     0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </button>
                    </td>

                  </tr>

                  {documentComments
                    .filter((el) => !el.deleted)
                    .sort((a, b) => {
                      const fa = a.category?.toLowerCase();
                      const fb = b.category?.toLowerCase();

                      if (fa < fb) {
                        return -1;
                      }
                      if (fa > fb) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((el, index) => (
                      <tr
                        key={el.id}>

                        {/* ----------- View Text Block --------------- */}
                        <td className={editBlockRow.id === el.id ?
                          'hidden' : ''}>
                          <p className='px-4 py-2
                             text-sm text-blue-600'>
                            {!el.category ? 'General' : ''}
                            {el.category === 'payment_conditions' ?
                              'Zahlungsbedingungen' : ''}
                            {el.category === 'delivery_conditions' ?
                              'Lieferbedingungen' : ''}
                            {el.category === 'comment_1' ? 'Kommentar 1' : ''}
                            {el.category === 'comment_2' ? 'Kommentar 2' : ''}

                          </p>
                        </td>

                        <td className={editBlockRow.id === el.id ?
                          'hidden' : ''}>
                          <p className='px-4 py-2 text-center text-gray-800'>
                            {el.abbreviation}
                          </p>
                        </td>

                        <td className={editBlockRow.id === el.id ?
                          'hidden' : ''}>
                          <p className='whitespace-pre-wrap px-4 py-2
                             text-gray-800'>
                            {el.text}
                          </p>
                        </td>

                        {/* -------------- Edit Text Block ------------ */}
                        <td className={`p-2 ${editBlockRow.id === el.id ? '' : 'hidden'}`}>
                          <div className="truncate">
                            <div className="flex-center text-sm">
                              <p
                                className="font-medium w-full
                              text-blue-600 truncate">
                                {/* {el.category || 'General'} */}
                                <select
                                  onInput={(e) => {
                                    const ebr = editBlockRow;
                                    ebr.category = e.target.value;
                                    setEditBlockRow({ ...ebr });
                                  }}
                                  className="focus:ring-blue-500 w-full
                                 focus:border-blue-500 outline-0
                        block w-full sm:text-sm border-gray-300
                        rounded-md"
                                  value={editBlockRow.category}
                                  type="text" >
                                  <option value="">General</option>
                                  <option value="payment_conditions">
                                    Zahlungsbedingung</option>
                                  <option value="delivery_conditions">
                                    Lieferbedingungen</option>
                                  <option value="comment_1">
                                    Kommentar 1</option>
                                  <option value="comment_2">
                                    Kommentar 2</option>
                                </select>
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className={`p-2 ${editBlockRow.id === el.id ? '' : 'hidden'}`}>
                          <input
                            onInput={(e) => {
                              const ebr = editBlockRow;
                              ebr.abbreviation = e.target.value;
                              setEditBlockRow({ ...ebr });
                            }}
                            className="focus:ring-blue-500
                               focus:border-blue-500
                        block w-full sm:text-sm border-gray-300
                        rounded-md"
                            value={editBlockRow.abbreviation}
                            type="text" />
                        </td>
                        <td className={`p-2 ${editBlockRow.id === el.id ? '' : 'hidden'}`}>
                          <textarea
                            onInput={(e) => {
                              const ebr = editBlockRow;
                              ebr.text = e.target.value;
                              setEditBlockRow({ ...ebr });
                            }}
                            className="focus:ring-blue-500
                               focus:border-blue-500
                        block w-full sm:text-sm border-gray-300
                        rounded-md"
                            value={editBlockRow.text}
                            type="number"></textarea>
                        </td>
                        <td>
                          <button
                            onClick={async () => {
                              // Toggle Edit Mode or save changes
                              if (editBlockRow.id === el.id) {
                                const doc = await lb
                                  .collection('document_comments')
                                  .doc({ id: el.id }).get();
                                await updateDoc(currentTeam.id,
                                  'document_comments', el.id, {
                                  ...doc,
                                  category: editBlockRow.category,
                                  abbreviation: editBlockRow.abbreviation || '',
                                  text: editBlockRow.text || '',
                                });
                                setEditBlockRow({});
                              } else {
                                setEditBlockRow({
                                  id: el.id,
                                  category: el.category,
                                  abbreviation: el.abbreviation,
                                  text: el.text,
                                });
                              }
                            }}
                            className="inline-flex justify-center py-2
                        px-2 border ml-2
                        border-transparent text-sm font-medium
                        rounded-md
                        text-gray-500
                        focus:outline-none
                        focus:ring-2 focus:ring-offset-2
                        focus:ring-blue-500">
                            {editBlockRow.id === el.id ?
                              <MdCheck className='h-5 w-5'></MdCheck> :
                              <MdEdit className='h-5 w-5'></MdEdit>
                            }
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              console.log('deleting block' + el.id);
                              deleteDoc(currentTeam.id, 'document_comments',
                                el.id, setRerender, isOffline);
                            }}
                            className="inline-flex justify-center py-2
                        px-2 border mr-2
                        border-transparent text-sm font-medium
                        rounded-md
                        text-red-500
                        focus:outline-none
                        focus:ring-2 focus:ring-offset-2
                        focus:ring-blue-500">
                            <MdDelete className='h-5 w-5'></MdDelete>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DocumentsSettings.propTypes = {
  state: PropTypes.array,
};


/** Shows Item Details */
import React from 'react';
import PropTypes from 'prop-types';


/**
 *
 * @return {object} jsx
 */
export default function SettleDocument({settleDocC}) {
  const [settleDoc, setSettleDoc] = settleDocC;
  let data = {type: {}};
  if (settleDoc) data = settleDoc;


  return (
    <button
      onClick={function() {
        setSettleDoc(null);
      }}
      style={{display: settleDoc ? 'block' : 'none'}}
      className="fill_container absolute right-0 bg-black p-16 overflow-hidden
      z-10 bg-opacity-50">
      <div
        className="bg-white shadow overflow-hidden sm:rounded-lg
        h-full cursor-default"
        onClick={function(e) {
          e.stopPropagation();
        }}>
        {data.type.name}
      </div>
    </button>
  );
}

SettleDocument.propTypes = {
  //   location: PropTypes.object,
  //   state: PropTypes.object,
  settleDocC: PropTypes.array,
};

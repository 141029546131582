/** Product Category Dropdown */

import React, {useContext, useState, useEffect} from 'react';
import {MdUnfoldMore, MdAdd} from 'react-icons/md';
import PropTypes from 'prop-types';
import {AuthContext} from '../context/AuthContext';
// import Localbase from 'localbase';
import {createDoc, RerenderContext} from '../cud';
import Localbase from 'localbase';
import {getSearchResults} from '../utilities';

/**
 *
 * @return {object} jsx
 */
export default function WarehousesDD({selectedWarehouseC}) {
  const [, setRerender] = useContext(RerenderContext);
  const {teamC, isOfflineC, toastsC} = useContext(AuthContext);
  const currentTeam = teamC[0];
  const isOffline = isOfflineC[0];
  const addToast = toastsC[1];

  const [showDropdown, setShowDropdown] = useState(false);

  const [searchResults, setSearchResults] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const [selectedWarehouse, setSelectedWarehouse] = selectedWarehouseC;

  useEffect(
      async function() {
      // Updates Input Value when Warehouse is selected
        if (selectedWarehouse.length > 0) {
          const lb = new Localbase(currentTeam.id);
          // lb.config.debug = false;
          const warehouse = await lb.collection('warehouses')
              .doc({id: selectedWarehouse}).get();
          warehouse && setInputValue(warehouse.name);
        }

        // empties Input value when Category is deleted
        selectedWarehouse.length === 0 && setInputValue('');
      },
      [selectedWarehouse],
  );

  /** Handle Search Input
   * @param {object} value
   */
  async function handleSearchInput(value) {
    setInputValue(value);
    setShowDropdown(true);

    const lb = new Localbase(currentTeam.id);
    // lb.config.debug = false;
    let warehouses = await lb.collection('warehouses').get();

    warehouses = warehouses.filter((el) => !el.deleted);
    if (value && value.length > 0) {
      setSearchResults(getSearchResults(value, warehouses));
    } else {
      setSearchResults(warehouses);
    }
  }

  /**
   *
   * @param {string} id
   */
  function handleSelect(id) {
    setSelectedWarehouse(id);
    setShowDropdown(false);
  }

  /**
   * Adds a new PCat and selects it
   * @param {string} name Name of Product Category
   */
  function addWarehouse(name) {
    setShowDropdown(false);
    // Add new Product Category
    createDoc(currentTeam.id, 'warehouses', {
      name: name,
      blocked: false,
    }, setRerender, isOffline)
    //     .then((arr) => {
    //   handleSelect(arr[0].id);
    // });
        .catch((e) => {
          addToast('error', `Fehler: Lager konnte nicht hinzugefügt werden`);
          return console.log(e);
        });
  }

  return (
    <div className='relative'>
      <div className="flex justify-between">
        <label
          htmlFor="account-number"
          className="block text-sm font-medium text-gray-700">
          Lager
        </label>
        {inputValue.length > 0 &&
          searchResults.length === 0 &&
          selectedWarehouse.length === 0 && (
          <span className="text-sm text-gray-500" id="email-optional">
              &quot;Enter&quot; drücken für neues Lager.
          </span>
        )}
      </div>

      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          onKeyDown={(e) => {
            e.keyCode === 13 && e.preventDefault();
            e.keyCode === 13 &&
              inputValue.length > 0 &&
              searchResults.length === 0 &&
              selectedWarehouse.length === 0 &&
              addWarehouse(inputValue);

            e.keyCode === 13 &&
              searchResults.length > 0 &&
              handleSelect(searchResults[0].id);
          }}
          // Toggle Dropdown depending on Focus
          onFocus={() => {
            setShowDropdown(true);
            setInputValue('');
            setSelectedWarehouse('');
            handleSearchInput('');
          }}
          onBlur={() => setShowDropdown(false)}
          onInput={(e) => handleSearchInput(e.target.value)}
          autoComplete="off"
          type="text"
          name="account-number"
          id="account-number"
          className="focus:ring-blue-500 focus:border-blue-500
          block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
          value={inputValue}
        />
        <button
          tabIndex={-1}
          type="button"
          onClick={() => {
            inputValue.length > 0 &&
            searchResults.length === 0 &&
            selectedWarehouse.length === 0 ?
            addWarehouse(inputValue) :
              setShowDropdown(!showDropdown);
          }}
          className="absolute inset-y-0 right-0 pr-3 flex
        items-center">
          {inputValue.length > 0 &&
          searchResults.length === 0 &&
          selectedWarehouse.length === 0 ? (
            <MdAdd
              className="h-5 w-5 bg-blue-100 text-blue-400 rounded-md"
              aria-hidden="true"
            />
          ) : (
            <MdUnfoldMore
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          )}
        </button>
      </div>
      <div
        className=" mt-2 p-2 max-h-28 overflow-auto
        rounded-md shadow-lg bg-white ring-1 ring-black
        ring-opacity-5 focus:outline-none
        absolute w-full top-16 z-10"
        style={{display: showDropdown ? 'block' : 'none'}}>
        {searchResults.map((el) => (
          <button
            type="button"
            className="w-full text-left hover:bg-gray-100 rounded-md p-1"
            onMouseDown={() => handleSelect(el.id)}
            key={el.id}>
            {el.name}
          </button>
        ))}
      </div>
    </div>
  );
}

WarehousesDD.propTypes = {
  selectedWarehouseC: PropTypes.array,
};

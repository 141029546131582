import React, {useContext, useState, useRef, useEffect} from 'react';
import {MdCheck} from 'react-icons/md';
import {AuthContext, lb, mdb} from './context/AuthContext';
import {fs, FieldValue} from './firebase';
import PropTypes from 'prop-types';
import {createDoc, RerenderContext} from './cud';
/**
 * Component for creating and joining teams
 * @return {object} jsx
 */
export default function TeamManagement({history, modeState}) {
  const [rerender, setRerender] = useContext(RerenderContext);
  const {userC, isOfflineC, teamC, toastsC} = useContext(AuthContext);
  const currentUser = userC[0];
  const currentTeam = teamC[0];
  const addToast = toastsC[1];
  const [teams, setTeams] = useState([]);
  const isOffline = isOfflineC[0];

  const [userDoc, setUserDoc] = useState({});

  const [mode, setMode] = useState('manage');
  const [lastMode, setLastMode] = useState('');
  const teamNameRef = useRef(null);
  const teamIdRef = useRef(null);
  const teamPinRef = useRef(null);
  // const currentTeam = teamC[0];

  useEffect(function() {
    modeState && setMode(modeState);
  }, []);

  useEffect(async function() {
    if (currentTeam) {
      const userDoc = await mdb.collection('meta').doc('user').get();
      setUserDoc(userDoc);

      const membershipTeams = await Promise.all(
          userDoc.team_memberships.map(async (el) => {
            return await fs.collection('teams').doc(el).get();
          }));

      setTeams(membershipTeams);
    }
  }, [rerender]);

  /**
   *
   * @param {string} team Team ID
   */
  async function switchTeam(team) {
    console.log(team);
    await lb.delete();
    await mdb.collection('meta').doc('team').delete();
    localStorage.removeItem('teamId');
    const userDoc = await mdb.collection('meta').doc('user').get();
    await mdb.collection('meta').doc('user')
        .set({...userDoc, active_team: team});

    await fs.collection('users').doc(currentUser.uid)
        .set({active_team: team}, {merge: true});
    // history.push('/dashboard');
    window.location.href = '/dashboard';
  }

  /**
   * Connects a user to a team
   * @param {string} teamId Team Id
   * @param {string} teamRole Role of User in Team
   * @param {string} invitationId Invitation Id
   */
  async function connectUserToTeam(teamId, teamRole, invitationId) {
    // Add User in created Team
    await fs.collection('teams').doc(teamId)
        .collection('memberships')
        .doc(currentUser.uid)
        .set({
          user: currentUser.uid,
          name: currentUser.displayName,
          email: currentUser.email,
          team_role: teamRole,
          invitation_id: invitationId || '',
          new_to_team: true,
        }, {merge: true});

    // Add User Roles
    await fs.collection('teams').doc(teamId)
        .collection('membership_roles')
        .doc(currentUser.uid)
        .set({
          role: teamRole,
          invitation_id: invitationId || '',
        }, {merge: true});

    // Add Team ID As active team to user doc
    await fs.collection('users')
        .doc(currentUser.uid)
        .set({
          active_team: teamId,
          team_memberships: FieldValue.arrayUnion(teamId),
        }, {merge: true});
  }

  /**
   *
   * @param {string} teamId Team ID
   * @param {string} pin Access PIN
   */
  async function joinTeam(teamId, pin) {
  // Check Invitations
    const invitations = await fs.collection('teams')
        .doc(teamId).collection('invitations')
        .where('password', '==', pin)
        .where('email', '==', currentUser.email)
        .get();

    if (invitations.docs.length === 0) return 'Daten ungültig';

    const doc = invitations.docs[0];

    // Fetch User Role & invitation id
    const teamRole = doc.role;
    const invitationId = doc.invitation_id;

    // Connect User To Team
    await connectUserToTeam(docRef.id, teamRole, invitationId);

    // Delete Invitation
    fs.collection('teams')
        .doc(teamId)
        .collection('invitations')
        .doc(doc.id)
        .delete();
  }

  /**
   * Creates New Team
   * @param {string} teamName
   */
  async function createTeam(teamName) {
    const date = Date.now();
    // Create Team
    const docRef = await fs.collection('teams')
        .add({
          name: teamName,
          created_by: currentUser.uid,
          trial_phase: true,

          created_date: date,
          updated_date: date,
          deleted: false,

          customer_nk: 1,
          supplier_nk: 1,
          internal_nk: 1,
        }).catch((e) => {
          console.log('couldnt create Doc!');
        });

    // Connect User to team
    await connectUserToTeam(docRef.id, 'Administrator');

    // Init Document Types
    const documentTemplates = await fs.collection('document_templates').get();
    for (const template of documentTemplates.docs) {
      try {
        createDoc(docRef.id, 'document_types',
            {...template, number: 0}, setRerender, isOffline);
      } catch (e) {
        addToast('error',
            'Fehler: Dokumenten-Typen können nicht initialisiert werden.');
        return console.log(e);
      }
    }

    // TODO Switch Team

    history.push('/dashboard');
  }

  // Offline Fallback
  if (isOffline) {
    return (
      <div
        className="w-full bg-gray-50 flex flex-col justify-center
    py-12 sm:px-6 lg:px-8 items-center rounded-md">
        <h1 className='font-bold text-4xl
       text-gray-800 my-4'>Willkommen bei Warest!</h1>
        <p>Sie scheinen nicht mit dem Internet verbunden zu sein. <br />
       Bitte gehen Sie Online, um Warest zu benutzen.</p>
      </div>
    );
  }

  // Return based on Mode (manage, join, create)
  if (mode === 'noTeam') {
    return (<div
      className="w-full bg-gray-50 flex flex-col justify-center
    py-12 sm:px-6 lg:px-8 items-center rounded-md">
      <h1 className='font-bold text-4xl
       text-gray-800 my-4'>Willkommen bei Warest!</h1>
      <button
        disabled={isOffline}
        className=" flex justify-center py-2 px-4 border
        border-transparent rounded-md shadow-sm text-sm
        font-medium w-64 my-4
        text-white bg-blue-600 hover:bg-blue-700
        focus:outline-none disabled:opacity-50
        focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={() => {
          setLastMode(mode);
          setMode('join');
        }
        }>Einem Unternehmen beitreten</button>
      <button
        disabled={isOffline}
        className=" flex justify-center py-2 px-4 border
        border-transparent rounded-md shadow-sm text-sm
        font-medium w-64 my-4
        text-white bg-blue-600 hover:bg-blue-700
        focus:outline-none disabled:opacity-50
        focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={() => {
          setLastMode(mode);
          setMode('create');
        }
        }>Ein Unternehmen erstellen</button>
    </div>);
  }
  if (mode === 'join') {
    return (<div
      className="w-full bg-gray-50 flex flex-col justify-center
    py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md flex flex-col">
        {/* <img className="mx-auto h-20 w-auto" src={logoBlue} /> */}
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Team Beitreten
        </h2>
        <button
          onClick={() => setMode(lastMode)}
          className="mt-2 text-center text-sm text-blue-600
          hover:text-blue-500">
          Zurück
        </button>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div>
            <label
              htmlFor="teamId"
              className="block text-sm font-medium text-gray-700">
                Team ID
            </label>
            <div className="mt-1">
              <input
                ref={teamIdRef}
                name="teamId"
                type="text"
                //  onChange={(e) => handleChange(e)}
                //  value={loginData.email}
                required
                className="appearance-none block w-full px-3 py-2 border
                  border-gray-300 rounded-md shadow-sm placeholder-gray-400
                  focus:outline-none focus:ring-blue-500
                  focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="pin"
              className="block text-sm font-medium text-gray-700">
                Zugangs-PIN
            </label>
            <div className="mt-1">
              <input
                ref={teamPinRef}
                name="pin"
                type="password"
                required
                //  onChange={(e) => handleChange(e)}
                //  value={loginData.password}
                className="appearance-none block w-full px-3 py-2
                  border
                  border-gray-300 rounded-md shadow-sm
                  placeholder-gray-400
                  focus:outline-none focus:ring-blue-500
                  focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>

          {/* <div className="flex items-center justify-between">
               <div className="flex items-center">
                 <input
                   id="remember-me"
                   name="remember-me"
                   type="checkbox"
                   className="h-4 w-4 text-blue-600
                  focus:ring-blue-500
                  border-gray-300 rounded"
                 />
                 <label
                   htmlFor="remember-me"
                   className="ml-2 block text-sm text-gray-900">
                  Eingeloggt bleiben
                 </label>
               </div>

               <div className="text-sm">
                 <a
                   href="#"
                   className="font-medium text-blue-600
                  hover:text-blue-500">
                  Passwort vergessen?
                 </a>
               </div>
             </div> */}

          <div>
            <button
              onClick={() => joinTeam(teamIdRef.current.value,
                  teamPinRef.current.value)}
              className="w-full flex justify-center py-2 px-4 border
                border-transparent rounded-md shadow-sm text-sm
                font-medium mt-4
                text-white bg-blue-600 hover:bg-blue-700
                focus:outline-none
                focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Beitreten
            </button>
          </div>
        </div>
      </div>
    </div>);
  }

  if (mode === 'create') {
    return (<div
      className="w-full bg-gray-50 flex flex-col justify-center
py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md flex flex-col">
        {/* <img className="mx-auto h-20 w-auto" src={logoBlue} /> */}
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
     Team Erstellen
        </h2>
        <button
          onClick={() => setMode(lastMode)}
          className="mt-2 text-center text-sm text-blue-600
     hover:text-blue-500">
     Zurück
        </button>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700">
           Team Name
            </label>
            <div className="mt-1">
              <input
                ref={teamNameRef}
                name="teamName"
                //  onChange={(e) => handleChange(e)}
                //  value={loginData.email}
                required
                className="appearance-none block w-full px-3 py-2 border
             border-gray-300 rounded-md shadow-sm placeholder-gray-400
             focus:outline-none focus:ring-blue-500
             focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>

          {/* <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              className="h-4 w-4 text-blue-600
             focus:ring-blue-500
             border-gray-300 rounded"
            />
            <label
              htmlFor="remember-me"
              className="ml-2 block text-sm text-gray-900">
             Eingeloggt bleiben
            </label>
          </div>

          <div className="text-sm">
            <a
              href="#"
              className="font-medium text-blue-600
             hover:text-blue-500">
             Passwort vergessen?
            </a>
          </div>
        </div> */}

          <div>
            <button
              onClick={() => {
                createTeam(teamNameRef.current.value);
              }}
              className="w-full flex justify-center py-2 px-4 border
           border-transparent rounded-md shadow-sm text-sm
           font-medium mt-4
           text-white bg-blue-600 hover:bg-blue-700
           focus:outline-none
           focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
           Erstellen
            </button>
          </div>
        </div>
      </div>
    </div>);
  }

  if (mode === 'manage') {
    return (<div className="grid grid-cols-6 gap-6">
      <div className="col-span-6 flex justify-between">
        <div>
          <h3
            className="text-lg leading-6
        font-medium text-gray-900">
        Unternehmens-Mitgliedschaften
          </h3>
          <p
            className="mt-1 text-sm
        text-gray-500">
        Wechseln Sie hier ihr aktives Unternehmen.
          </p>
        </div>
        <div className='flex flex-col space-y-2'>
          <button
            className='text-sm text-blue-600
        hover:text-blue-500'
            onClick={() => {
              setLastMode(mode);
              setMode('join');
            }
            }>Einem Unternehmen beitreten</button>
          <button
            className='text-sm text-blue-600
        hover:text-blue-500'
            onClick={() => {
              setLastMode(mode);
              setMode('create');
            }
            }>Ein Unternehmen erstellen</button>
        </div>
      </div>
      {/* <div className="col-span-6">
      {currentUser &&
        userDoc.team_memberships.map((el) => {
          return <div key={el}>{el}</div>;
        })}
    </div> */}
      <div
        className="bg-white shadow overflow-hidden
    sm:rounded-md col-span-6">
        <ul className="divide-y divide-gray-200">
          {teams && teams.map((team) => (
            <li key={team.id}>

              <div
                className={`px-4 py-4 flex items-center
              sm:px-6 ${
                team === userDoc.active_team ?
              'bg-blue-100 hover:bg-blue-200' :
              'bg-gray-50 hover:bg-gray-100'}`}>
                <div
                  className="min-w-0 flex-1 sm:flex
                sm:items-center sm:justify-between">
                  <div className="truncate">
                    <div className="flex text-sm">
                      <p
                        className="font-medium
                      text-blue-600 truncate">
                        {team.data().name}
                      </p>

                    </div>
                    <div className="mt-2 flex">
                      <div
                        className="flex items-center
                      text-sm text-gray-500">
                        <p>
                          {`ID: ${team.id}`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                    <div className="flex overflow-hidden -space-x-1">
                      {/* {position.applicants.map((applicant) => (
                <img
                  key={applicant.email}
                  className="inline-block h-6 w-6
                  rounded-full ring-2 ring-white"
                  src={applicant.imageUrl}
                  alt={applicant.name}
                />
              ))} */}
                    </div>
                  </div>
                </div>
                <div>
                  {
                team.id === userDoc.active_team ?
                <button className="bg-white py-2 px-4
                 border border-gray-300
                rounded-md shadow-sm text-sm font-medium text-gray-700
                hover:bg-gray-50 focus:outline-none
                 focus:ring-2 focus:ring-offset-2
                focus:ring-blue-500 flex items-center">
                  <MdCheck className='mr-1'></MdCheck>
                  Aktiv</button> :
              <button
                onClick={() => {
                  switchTeam(team.id);
                }}
                className="blue_btn">Wechseln</button>}

                </div>
              </div>

            </li>
          ))}
        </ul>
      </div>
    </div>);
  }
}

TeamManagement.propTypes = {
  history: PropTypes.object,
  modeState: PropTypes.any,
};
